

export default function ThemeQuoteCard(props) {
  const {
    quote: { id, quote, author },
    onThemeCardClick,
  } = props;

  return (
    <div
      className="round-box box-shadow-3d my-3 p-3 bg-clearWithHov"
      onClick={onThemeCardClick}
      data-content="quote"
      data-id={id}
    >
      <div className={`font-italic`} dangerouslySetInnerHTML={{__html: quote}}></div>
      <div>{author}</div>
    </div>
  );
}
