import { useContext } from "react";
import ThemeBigCard from "../../components/ThemeBigCard";
import { GiSaveArrow } from "react-icons/gi";
import { UserContext } from "../../../../../context/UserContext";
import {  RunSessContext } from "../../../../../context/RunSessContext";
const DiscussionQuestions = props => {
  const {
    discussions,
    onThemeCardClick,
    isRunningSession,
    currentPage,
    program,
  } = props;
  const { state } = useContext(UserContext);
      const { state: sessState } = useContext(RunSessContext);
  return (
    <>
       {program != "reflections" &&  (<div className="d-flex mb-5">
      
          <h3>
            {state.user.role == "ROLE_FACILITATOR" &&sessState.memberView !== true&&
              (currentPage ? `${currentPage + 3}.` : `7.`)}{" "}
            Discussion
          </h3>
       
        {!isRunningSession&& program !== "reflections" ? (
          <button
            className="ml-2  btn-info btn-extra-radius border-0 px-3 text-white align-items-center"
            onClick={() => onThemeCardClick("addNote", "_", 1)}
          >
            <GiSaveArrow className="mr-2" />
            <span>Add Note</span>
          </button>
        ) : (
          <></>
        )}
      </div> )}


      <ThemeBigCard
        discussions={[ {id:"addin",question:"How's life? How has your week (or weekend) been?"},...discussions]}
        isJavaTips={false}
        program={program}
        onThemeCardClick={() => onThemeCardClick("question")}
      />
    </>
  );
};

export default DiscussionQuestions;
