import { useRef, useEffect } from "react";
import SongPlayerRefProp from "./SongPlayerRefProp"


export default function SyncMusicController(props) {
  const { musicUrl, handleMusicPlay, title, songLocation, isSync } = props;

  const audioRef = useRef(null);

  // when a facilitator clicks a music play btn, the songLocation changes
  // useEffect(() => {
  //   if (isSync) {
  //     if (songLocation !== "") {
  //       audioRef.current.play();
  //     } else {
  //       audioRef.current.pause();
  //     }
  //   }
  // }, [songLocation]);
  function fade(audio, last){
    if(audio.volume > 0.1){
        audio.volume -= 0.001;
        setTimeout(fade(audio,last), 5000);
      }else{
        audio.pause();
        audio.volume = last;
    }
}

  useEffect(() => {
    if (isSync) {
      if (songLocation !== "") {
        audioRef.current.play();
      } else {
        // console.log(songLocation)
        // console.log( audioRef.current.volume)
        fade(audioRef.current, audioRef.current.volume )
      }
    }
  }, [songLocation]);
  // when a facilitator turns off the sync btn
  if (!isSync) {
    return (
      <div className="col pl-0 pr-4">
        <SongPlayerRefProp audioRef={audioRef}  songurl={musicUrl} isSyncFlag={false} funcProp={title} handleMusicPlay={console.log} ></SongPlayerRefProp>
      </div>
    );
  } else if (songLocation === "" || songLocation === undefined) {
    // when the facilitator plays music
    return (
      <div className="col pl-0 pr-4">
        <SongPlayerRefProp audioRef={audioRef}  songurl={musicUrl} isSyncFlag={true} funcProp={title} handleMusicPlay={handleMusicPlay} ></SongPlayerRefProp>
      </div>
    );
  } else {
    // when the music is played in member side by sync
    return (
      <div className="col pl-0 pr-4">
        <SongPlayerRefProp audioRef={audioRef} 
         songurl={songLocation} isSyncFlag={false} funcProp={title} handleMusicPlay={console.log} ></SongPlayerRefProp>
      </div>
    );
  }
}
