import StyledContentLoader from "styled-content-loader";
import {
  MdHome,MdLocationOn
} from "react-icons/md";
import { BsCalendar, BsHouseDoor, BsPeopleFill, BsTag } from "react-icons/bs";
import { AiOutlineReload } from "react-icons/ai";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow:"0px 0px 0px 0px rgba(0, 0, 0, 0.0) ",
    border: "none",
    backgroundColor:"rgba(0, 0, 0, 0.0)",
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  integrated: {
    backgroundColor:"rgba(0,0,0,0.5)",
    width: "100%",
    height: "100%",

  },
  small: {
    backgroundColor:"rgba(0,0,0,0.5)",
    width: "70px",
    height: "70px",
  },
  large: {
    backgroundColor:"rgba(0,0,0,0.5)",
    width: "70px",
    height: "70px",
  },
}));
/**
 * @memberof GenericComponents
 * @function mainLoading
 * @description
 * A generic loading component that is used to show a loading animation while data is being fetched.
 * @param {object} props 
 * @param {string} props.type - name of the ui component/s it is standing in for
 * 
 * @example
 * return (
 *   <MainLoading type={"group-cards"}   />
 * ) 
 */
const MainLoading = ({  type }) => {
  const classes = useStyles();
  if (type === "group-cards") {
    return (
      <>
        <div  className={`p-3  col-10  col-md-6 col-lg-4 `}>
      <div
      style={{minHeight:"15rem", height: "100%"}}
        className={"border box-shadow bg-clear d-flex  round-box px-4 pt-2 pb-2"}
        role={"button"}

      >
<div className="d-flex align-self-between">
<div className={`row`}>
            <div className={`col col-12`}>
            <div className={`col my-3 py-0`}>
            <StyledContentLoader isLoading={true}>
               <h5 className=" my-3 align-self-start d-flex align-items-center">
Home


<span>

</span>
</h5>
</StyledContentLoader>
                         

</div>
</div>

<div className="row">
<div className="col col-12 align-items-center">
        <div className="icon-text my-3">
          <span>
            <BsCalendar />
          </span>
          <span className={`col col-12 `}>          <StyledContentLoader isLoading={true}>

<div className={`col col-12`}>Home</div>
</StyledContentLoader></span>
        </div>

        <div className="icon-text my-3">
          <span>
            <AiOutlineReload />
          </span>
         <span className={`col col-12`}>          <StyledContentLoader isLoading={true}>

<div className={`col  col-12`}>Home</div>
</StyledContentLoader></span>
        </div>

        <div className="icon-text my-3">
          <span  className="">
            <MdLocationOn />
          </span>
         <span className={`col col-12`}>          <StyledContentLoader isLoading={true}>

<div className={`col col-12`}>Home</div>
</StyledContentLoader></span>
        </div>
        <div className="icon-text my-3">
          <span>
            <BsHouseDoor />
          </span>
         <span className={`col col-12`}>          <StyledContentLoader isLoading={true}>

<div className={`col col-12`}>Home</div>
</StyledContentLoader></span>
        </div>
        <div className="icon-text my-3">
          <span>
            <BsPeopleFill />
          </span>
          <span className={`col col-12`}>          <StyledContentLoader isLoading={true}>

<div className={`col col-12`}>Home</div>
</StyledContentLoader></span>
        </div>

        <div className={`icon-text  my-3  `}>
          <span>
            <BsTag />
          </span>  
          <span className={`col col-12`}>          <StyledContentLoader isLoading={true}>

<div className={`col col-12`}>Home</div>
</StyledContentLoader></span>

       
        </div>


          </div>
          </div>
          </div>
          </div>
      </div>
    </div>
       </>)}
  if (type === "theme-cards") {
    return (
      <>

<div className={`p-2`}>

        <div
          className="position-relative text-center d-flex align-items-center justify-content-center theme-card"
       
        >
           <StyledContentLoader isLoading={true}>


<img
            className="not-draggable round-box"

            height={150}
            width={200}
         
            alt=""
            />
</StyledContentLoader>
 <h5 className="position-absolute text-wrap text-white my-auto mx-auto">


          </h5>{" "}
        </div>
      
 </div>

      </>)}
  if (type === "add-role-member") {
    return (
      <>  <div className={`col pl-0 py-0 col-12 d-flex  bg-clearWithHov bg-clear   my-1  rounded border useBorder    box-shadow align-items-center`}>
      <div style={{minWidth:"70px"}} className={`col col-1  ml-0 pl-0 `}>
      <Avatar  variant={'rounded'}  className={classes.large}    ></Avatar>
      </div>
      <div className={`col col-8 d-flex justify-content-start`}>
      <div className={`row py-2  w-75`}>
      <div className={`col col-12 my-1`}>
      <StyledContentLoader isLoading={true}>

<div>Home</div>
</StyledContentLoader>
      </div>
      <div className={`col col-12`}>
      <StyledContentLoader isLoading={true}>

<div>Home</div>
</StyledContentLoader>
      </div>
      </div>
      </div>
      
      
      
   


   </div> </>)}
  if (type === "open-chat") {
    return (
      <>


      <div className={`p-2 col-12 col-lg-6`}>
     
     <div
       className="position-relative text-center bg-clear bg-clearWithHov useBorder round-box  d-flex align-items- justify-content-center theme-card"
       
     >
     <div
       className="position-relative text-center d-flex align-items-center justify-content-center theme-card"
    
     >
      <StyledContentLoader isLoading={true}>


<img
         className="not-draggable round-box"
        
         height={150}
         width={200}
 />      
  
</StyledContentLoader>  
     </div>
       <div className={`col text-center text-wrap text-white my-auto mx-auto`}>
       <div className={`row text-center text-wrap text-white my-auto mx-auto`}>
       <h6 className=" col text-center text-wrap text-white my-auto mx-auto">
       <StyledContentLoader isLoading={true}>

<div>Home</div>
</StyledContentLoader>
       </h6>{" "}
       
       </div>
       

       <div className={`row text-center  text-wrap text-white my-auto mx-auto`}>
       <div className={`col mt-2 `}>

    
  
     <div className={`rounded bg-clear p-2 useBorder shadow`} key={0}>
               <span  className={`font-weight-bold mb-1 `}>
               <StyledContentLoader isLoading={true}>

<div>Home</div>
</StyledContentLoader>
                 </span>
               <div className="mt-1 ">
               <StyledContentLoader isLoading={true}>

                    <div>Home</div>
                  </StyledContentLoader>
               
               </div>
               </div>
       
         

       </div>
     
       </div>
       {/*  */}
      


 </div>

 </div>
      </div>

      </>)}
  if (type === "home") {
    return (
      <>
        <nav style={{height:"62px"}} className="navbar navbar-expand-lg navbar-light bg-light fixed-top">

          
          <div
            className={` w-100`}
            id="navbar"
          >
            <div className="navbar-nav d-flex flex-row justify-content-between w-100">
              <div className="navbar-nav d-none d-lg-flex">
                <div className="mr-2">
                  <StyledContentLoader isLoading={true}>
                    <div className="mr-2">
                      <MdHome />
                    </div>
                    <div>Home</div>
                  </StyledContentLoader>
                </div>
                <div className="mr-2">
                  <StyledContentLoader isLoading={true}>
                    <div className="mr-2">
                      <MdHome />
                    </div>
                    <div>Home</div>
                  </StyledContentLoader>
                </div>

                <div className="mr-2">
                  {" "}
                  <StyledContentLoader isLoading={true}>
                    <div className="mr-2">
                      <MdHome />
                    </div>
                    <div>Home</div>
                  </StyledContentLoader>
                </div>



          
              </div>

              {/* The content below will only display if the screen resolution is MORE than md */}
              <div className="navbar-nav d-none d-lg-flex">
                <div className="mr-2">
                  <StyledContentLoader isLoading={true}>
                    <div className="mr-2">
                      <MdHome />
                    </div>
                    <div>Home</div>
                  </StyledContentLoader>
                </div>
                <div className="mr-2">
                  <StyledContentLoader isLoading={true}>
                    <div className="mr-2">
                      <MdHome />
                    </div>
                    <div>Home</div>
                  </StyledContentLoader>
                </div>

                <div className="mr-2">
                  {" "}
                  <StyledContentLoader isLoading={true}>
                    <div className="mr-2">
                      <MdHome />
                    </div>
                    <div>Home</div>
                  </StyledContentLoader>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* This span should have the exact same height as the navbar to prevent the navbar from covering other elements */}
        <div style={{ height: "56px" }} />

        <div className={"container my-3"}>
          <div className="row">
            <div className="col-sm-12 col-md-7">
              
              <div className={" "}>
              <h4 className="mb-3 ">Sessions</h4>
                <div className={" "}>
                  <div
                    style={{ height: "47vh", width: "100%" }}
                    className="position-relative round-box d-flex align-items-end justify-content-center bg-clear"
                  >
                    {/* <img src={theme.image} alt={theme.name} /> */}
                    <div
                      style={{ height: "93%", width: "93%" }}
                      className=" round-box my-3 align-self-center   "
                    >
                      {" "}
                      <StyledContentLoader isLoading={true}>
                        <div
                          style={{ height: "100%", width: "100%" }}
                          className=" round-box bg-white  "
                        />{" "}
                      </StyledContentLoader>
                    </div>
                    <div
                      style={{
                        backgroundColor: "rgba(0,0,0,.5)",
                        borderRadius: "0 0 15px 15px",
                      }}
                      className="position-absolute  text-white my-auto mx-auto w-100 p-3"
                    >
                      <div className="py-2 ml-1 row">
                        <div className={"col-9"}>
                          <h5>
                            <div className="mr-3 text-capitalize"></div>

                            <StyledContentLoader isLoading={true}>
                              {" "}
                              <div
                                className={`badge  badge-success
                    `}
                              >
                                joinable
                              </div>
                            </StyledContentLoader>
                          </h5>
                          <div className="mb-0 font-weight-light">
                            <StyledContentLoader isLoading={true}>
                              {" "}
                              <div className="d-block d-lg-inline">
                                {"MMMM Do YYYY, h:mm:ss a"}
                              </div>{" "}
                            </StyledContentLoader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={" "}>
                  <div
                    className={
                      "border my-3 container bg-clear box-3d box-shadow round-box py-1 py-lg-2"
                    }
                  >
                    <div className="pl-3  py-2d-flex row">
                    <div className={"col-9"}>
                        <div className={"row px-3 my-2"}>
                          <h5>
                            {" "}
                            <StyledContentLoader isLoading={true}>
                              <div className="mr-3 text-capitalize">
                                hgjkghjkgjhyk
                              </div>
                            </StyledContentLoader>
                          </h5>{" "}
                          <StyledContentLoader isLoading={true}>
                            <div className={`mt-1 mb-1 badge badge-success`}>
                              joinable
                            </div>
                          </StyledContentLoader>
                        </div>
                        <div className="mb-0 font-weight-light">
                          <StyledContentLoader isLoading={true}>
                            <div className="d-block d-lg-inline">
                              MMMM Do YYYY, h:mm:ss a
                            </div>
                          </StyledContentLoader>
                        </div>
                      </div>

                      <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
                        <StyledContentLoader isLoading={true}>
                          {" "}
                          <button className="btn px-3 px-lg-4 btn-sm btn-info btn-extra-radius">
                            Open
                          </button>
                        </StyledContentLoader>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={""}>
                  <div
                    className={
                      "border my-3 container bg-clear box-3d box-shadow round-box py-1 py-lg-2"
                    }
                  >
                    <div className="pl-3 py-2d-flex row">
                    <div className={"col-9"}>
                        <div className={"row px-3 my-2"}>
                          <h5>
                            {" "}
                            <StyledContentLoader isLoading={true}>
                              <div className="mr-3 text-capitalize">
                                hgjkghjkgjhyk
                              </div>
                            </StyledContentLoader>
                          </h5>{" "}
                          <StyledContentLoader isLoading={true}>
                            <div className={`mt-1 mb-1 badge badge-success`}>
                              joinable
                            </div>
                          </StyledContentLoader>
                        </div>
                        <div className="mb-0 font-weight-light">
                          <StyledContentLoader isLoading={true}>
                            <div className="d-block d-lg-inline">
                              MMMM Do YYYY, h:mm:ss a
                            </div>
                          </StyledContentLoader>
                        </div>
                      </div>

                      <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
                        <StyledContentLoader isLoading={true}>
                          {" "}
                          <button className="btn px-3 px-lg-4 btn-sm btn-info btn-extra-radius">
                            Open
                          </button>
                        </StyledContentLoader>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={""}>
                  <div className={"border my-3 container bg-clear box-3d box-shadow round-box py-1 py-lg-2" }>
                    <div className="pl-3 py-2d-flex row">
                      <div className={"col-9"}>
                        <div className={"row px-3 my-2"}>
                          <h5>
                            {" "}
                            <StyledContentLoader isLoading={true}>
                              <div className="mr-3 text-capitalize">
                                hgjkghjkgjhyk
                              </div>
                            </StyledContentLoader>
                          </h5>{" "}
                          <StyledContentLoader isLoading={true}>
                            <div className={`mt-1 mb-1 badge badge-success`}>
                              joinable
                            </div>
                          </StyledContentLoader>
                        </div>
                        <div className="mb-0 font-weight-light">
                          <StyledContentLoader isLoading={true}>
                            <div className="d-block d-lg-inline">
                              MMMM Do YYYY, h:mm:ss a
                            </div>
                          </StyledContentLoader>
                        </div>
                      </div>

                      <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
                        <StyledContentLoader isLoading={true}>
                          {" "}
                          <button className="btn px-3 px-lg-4 btn-sm btn-info btn-extra-radius">
                            Open
                          </button>
                        </StyledContentLoader>
                      </div>
                    </div>
                  </div>
                </div>
                

                <div>
                  <div className="d-flex flex-row justify-content-center my-4">
                    <StyledContentLoader isLoading={true}>
                      {" "}
                      <button className="btn btn-primary btn-extra-radius px-4">
                        See All Sessions
                      </button>
                    </StyledContentLoader>
                  </div>
                </div>
              </div>
            </div>
              <div className="col-sm-12 col-md-5">
              <div>
      <div className={""}>
      <h4 className="mb-3">Resources</h4>
      <div className="mb-4 border shadow round-box">
      <div className="row mx-auto">
        <div
          className={
            "px-0 d-flex px-0 d-flex col-lg-4 col-4"
          }
        >
         <div
                      style={{ height: "100%", width: "100%",borderRadius: "15px 0 0 15px" }}
                      className="  my-3 align-self-center   "
                    >
                      {" "}
                      <StyledContentLoader isLoading={true}>
                        <div
                          style={{ height: "100%", width: "100%",borderRadius: "15px 0 0 15px" }}
                          className="bg-white py-5 "
                        />{" "}
                      </StyledContentLoader>
                    </div>
        </div>

        <div
          className={
            "mt-2 px-2 d-flex flex-column col-lg-8 col-md-8 col-8"
          }
        >
          <div className="d-flex flex-row justify-content-between">
            <StyledContentLoader isLoading={true}><h4 className="text-info">ffghdfghfgdhs</h4> </StyledContentLoader>
            
          </div>

          <StyledContentLoader isLoading={true}><p
            className="align-self-stretch mt-1 mb-2  mr-5 text-wrap"
            style={{ wordBreak: "break-all" }}
          >
           hgkfkjfhgjfghjfghjfghj
          </p> </StyledContentLoader>
          <StyledContentLoader isLoading={true}><p
            className="align-self-stretch  mr-5 text-wrap"
            style={{ wordBreak: "break-all" }}
          >
           hgkfkjfhgjfghjfghjfghjfghj
          </p> </StyledContentLoader>
        </div>
      </div>
    </div>
      <div className="mb-4 border shadow round-box">
      <div className="row mx-auto">
        <div
          className={
            "px-0 d-flex px-0 d-flex col-lg-4 col-4"
          }
        >
         <div
                      style={{ height: "100%", width: "100%",borderRadius: "15px 0 0 15px" }}
                      className="  my-3 align-self-center   "
                    >
                      {" "}
                      <StyledContentLoader isLoading={true}>
                        <div
                          style={{ height: "100%", width: "100%",borderRadius: "15px 0 0 15px" }}
                          className="bg-white py-5 "
                        />{" "}
                      </StyledContentLoader>
                    </div>
        </div>

        <div
          className={
            "mt-2 px-2 d-flex flex-column col-lg-8 col-md-8 col-8"
          }
        >
          <div className="d-flex flex-row justify-content-between">
            <StyledContentLoader isLoading={true}><h4 className="text-info">ffghdfghfgdhs</h4> </StyledContentLoader>
            
          </div>

          <StyledContentLoader isLoading={true}><p
            className="align-self-stretch mt-1 mb-2  mr-5 text-wrap"
            style={{ wordBreak: "break-all" }}
          >
           hgkfkjfhgjfghjfghjfghj
          </p> </StyledContentLoader>
          <StyledContentLoader isLoading={true}><p
            className="align-self-stretch  mr-5 text-wrap"
            style={{ wordBreak: "break-all" }}
          >
           hgkfkjfhgjfghjfghjfghjfghj
          </p> </StyledContentLoader>
        </div>
      </div>
    </div>
    <div>
                  <div className="d-flex flex-row justify-content-center my-4">
                    <StyledContentLoader isLoading={true}>
                      {" "}
                      <button className="btn btn-primary btn-extra-radius px-4">
                        See All Sessions
                      </button>
                    </StyledContentLoader>
                  </div>
                </div>
 </div>
    </div>
        </div>
          </div>
        </div>
      </>
    );
};
if(type === "sessSmall"){
    return(
        <div className={""}>
                  <div className={"border my-3 container bg-clear box-3d box-shadow round-box py-1 py-lg-2" }>
                    <div className="pl-3 py-2d-flex row">
                      <div className={"col-9"}>
                        <div className={"row px-3 my-2"}>
                          <h5>
                            {" "}
                            <StyledContentLoader isLoading={true}>
                              <div className="mr-3 text-capitalize">
                                hgjkghjkgjhyk
                              </div>
                            </StyledContentLoader>
                          </h5>{" "}
                          <StyledContentLoader isLoading={true}>
                            <div className={`mt-1 mb-1 badge badge-success`}>
                              joinable
                            </div>
                          </StyledContentLoader>
                        </div>
                        <div className="mb-0 font-weight-light">
                          <StyledContentLoader isLoading={true}>
                            <div className="d-block d-lg-inline">
                              MMMM Do YYYY, h:mm:ss a
                            </div>
                          </StyledContentLoader>
                        </div>
                      </div>

                      <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
                        <StyledContentLoader isLoading={true}>
                          {" "}
                          <button className="btn px-3 px-lg-4 btn-sm btn-info btn-extra-radius">
                            Open
                          </button>
                        </StyledContentLoader>
                      </div>
                    </div>
                  </div>
                </div>
    )
}
if(type === "sessLarge"){
    return(
                      <div className={" "}>
        <div
          style={{ height: "47vh", width: "100%" }}
          className="position-relative round-box d-flex align-items-end justify-content-center bg-clear"
        >
          {/* <img src={theme.image} alt={theme.name} /> */}
          <div
            style={{ height: "90%", width: "90%" }}
            className=" round-box my-3 align-self-center   "
          >
            {" "}
            <StyledContentLoader isLoading={true}>
              <div
                style={{ height: "100%", width: "100%" }}
                className=" round-box bg-white  "
              />{" "}
            </StyledContentLoader>
          </div>
          <div
            style={{
              backgroundColor: "rgba(0,0,0,.5)",
              borderRadius: "0 0 15px 15px",
            }}
            className="position-absolute  text-white my-auto mx-auto w-100 p-3"
          >
            <div className="py-2 ml-1 row">
              <div className={"col-9"}>
                <h5>
                  <div className="mr-3 text-capitalize"></div>

                  <StyledContentLoader isLoading={true}>
                    {" "}
                    <div
                      className={`badge  badge-success
          `}
                    >
                      joinable
                    </div>
                  </StyledContentLoader>
                </h5>
                <div className="mb-0 font-weight-light">
                  <StyledContentLoader isLoading={true}>
                    {" "}
                    <div className="d-block d-lg-inline">
                      {"MMMM Do YYYY, h:mm:ss a"}
                    </div>{" "}
                  </StyledContentLoader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
if(type === "sessCont"){
    return(
        <div className={" "}>
        <h4 className="mb-3 ">Sessions</h4>
          <div className={" "}>
            <div
              style={{ height: "47vh", width: "100%" }}
              className="position-relative round-box d-flex align-items-end justify-content-center bg-clear"
            >
              {/* <img src={theme.image} alt={theme.name} /> */}
              <div
                style={{ height: "90%", width: "90%" }}
                className=" round-box my-3 align-self-center   "
              >
                {" "}
                <StyledContentLoader isLoading={true}>
                  <div
                    style={{ height: "100%", width: "100%" }}
                    className=" round-box bg-white  "
                  />{" "}
                </StyledContentLoader>
              </div>
              <div
                style={{
                  backgroundColor: "rgba(0,0,0,.5)",
                  borderRadius: "0 0 15px 15px",
                }}
                className="position-absolute  text-white my-auto mx-auto w-100 p-3"
              >
                <div className="py-2 ml-1 row">
                  <div className={"col-9"}>
                    <h5>
                      <div className="mr-3 text-capitalize"></div>

                      <StyledContentLoader isLoading={true}>
                        {" "}
                        <div
                          className={`badge  badge-success
              `}
                        >
                          joinable
                        </div>
                      </StyledContentLoader>
                    </h5>
                    <div className="mb-0 font-weight-light">
                      <StyledContentLoader isLoading={true}>
                        {" "}
                        <div className="d-block d-lg-inline">
                          {"MMMM Do YYYY, h:mm:ss a"}
                        </div>{" "}
                      </StyledContentLoader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={" "}>
            <div
              className={
                "border my-3 container bg-clear box-3d box-shadow round-box py-1 py-lg-2"
              }
            >
              <div className="pl-3  py-2d-flex row">
              <div className={"col-9"}>
                  <div className={"row px-3 my-2"}>
                    <h5>
                      {" "}
                      <StyledContentLoader isLoading={true}>
                        <div className="mr-3 text-capitalize">
                          hgjkghjkgjhyk
                        </div>
                      </StyledContentLoader>
                    </h5>{" "}
                    <StyledContentLoader isLoading={true}>
                      <div className={`mt-1 mb-1 badge badge-success`}>
                        joinable
                      </div>
                    </StyledContentLoader>
                  </div>
                  <div className="mb-0 font-weight-light">
                    <StyledContentLoader isLoading={true}>
                      <div className="d-block d-lg-inline">
                        MMMM Do YYYY, h:mm:ss a
                      </div>
                    </StyledContentLoader>
                  </div>
                </div>

                <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
                  <StyledContentLoader isLoading={true}>
                    {" "}
                    <button className="btn px-3 px-lg-4 btn-sm btn-info btn-extra-radius">
                      Open
                    </button>
                  </StyledContentLoader>
                </div>
              </div>
            </div>
          </div>
          <div className={""}>
            <div
              className={
                "border my-3 container bg-clear box-3d box-shadow round-box py-1 py-lg-2"
              }
            >
              <div className="pl-3 py-2d-flex row">
              <div className={"col-9"}>
                  <div className={"row px-3 my-2"}>
                    <h5>
                      {" "}
                      <StyledContentLoader isLoading={true}>
                        <div className="mr-3 text-capitalize">
                          hgjkghjkgjhyk
                        </div>
                      </StyledContentLoader>
                    </h5>{" "}
                    <StyledContentLoader isLoading={true}>
                      <div className={`mt-1 mb-1 badge badge-success`}>
                        joinable
                      </div>
                    </StyledContentLoader>
                  </div>
                  <div className="mb-0 font-weight-light">
                    <StyledContentLoader isLoading={true}>
                      <div className="d-block d-lg-inline">
                        MMMM Do YYYY, h:mm:ss a
                      </div>
                    </StyledContentLoader>
                  </div>
                </div>

                <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
                  <StyledContentLoader isLoading={true}>
                    {" "}
                    <button className="btn px-3 px-lg-4 btn-sm btn-info btn-extra-radius">
                      Open
                    </button>
                  </StyledContentLoader>
                </div>
              </div>
            </div>
          </div>
          <div className={""}>
            <div className={"border my-3 container bg-clear box-3d box-shadow round-box py-1 py-lg-2" }>
              <div className="pl-3 py-2d-flex row">
                <div className={"col-9"}>
                  <div className={"row px-3 my-2"}>
                    <h5>
                      {" "}
                      <StyledContentLoader isLoading={true}>
                        <div className="mr-3 text-capitalize">
                          hgjkghjkgjhyk
                        </div>
                      </StyledContentLoader>
                    </h5>{" "}
                    <StyledContentLoader isLoading={true}>
                      <div className={`mt-1 mb-1 badge badge-success`}>
                        joinable
                      </div>
                    </StyledContentLoader>
                  </div>
                  <div className="mb-0 font-weight-light">
                    <StyledContentLoader isLoading={true}>
                      <div className="d-block d-lg-inline">
                        MMMM Do YYYY, h:mm:ss a
                      </div>
                    </StyledContentLoader>
                  </div>
                </div>

                <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
                  <StyledContentLoader isLoading={true}>
                    {" "}
                    <button className="btn px-3 px-lg-4 btn-sm btn-info btn-extra-radius">
                      Open
                    </button>
                  </StyledContentLoader>
                </div>
              </div>
            </div>
          </div>
          

          <div>
            <div className="d-flex flex-row justify-content-center my-4">
              <StyledContentLoader isLoading={true}>
                {" "}
                <button className="btn btn-primary btn-extra-radius px-4">
                  See All Sessions
                </button>
              </StyledContentLoader>
            </div>
          </div>
        </div>
    )
}
}

export default MainLoading;
