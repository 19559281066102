import { createContext, useReducer } from "react";

const Scopes = {
  MASTER: "master",
  FACILITATOR: "facilitator",
  FACILITATORJOINABLE: "facilitatorJoinable",
  MEMBER: "member",
  MEMBERVIEW: "memberView",
  DIRECTSECONDARY: "directSecondary",
};
const initialState = {
  myRemoteMuteState: false,
  myRemoteVidState: false,
  myRemotePalamiState: false,
  myRemoteRoomState: false,
  hardMute: false,
  program: "",
  memberView: false,
  masterId: "",
  amIMaster: false,
  isDirect: false,
  forceSecondary: false,
  scope: Scopes.MEMBER,
  playedSongs: [],
  syncIsOn: true,
};

const ACTIONS = {
  CLEAR: "clear",
  UPDATE_ALL: "UPDATE_ALL",
  UPDATE_ONE: "UPDATE_ONE",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_ALL:
      return { ...action.payload };
    case ACTIONS.UPDATE_ONE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const RunSessContext = createContext(initialState);

const RunSessProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <RunSessContext.Provider value={{ state, dispatch }}>
      {props.children}
    </RunSessContext.Provider>
  );
};

export { RunSessContext, RunSessProvider, ACTIONS, Scopes };
