import { Component, useEffect, useState } from "react";
import { BiMessageAltError, BiRefresh, BiMailSend } from "react-icons/bi";
import { FcInfo } from "react-icons/fc";

import GenericModal from "./GenericModal";
import {
  useAddErrorLogMutation,
  useAddUserMessageToErrorLogMutation,
} from "../graphql/generated/graphql";
import { toast } from "react-toastify";
import moment from "moment";
import LogRocket from "logrocket";
/**
 * @memberof GenericComponents
 * @function ErrorBoundary
 *  @description
 * A generic use error box component that catches all errors and displays them in a nice way. It also logs the error to the database and allows the user to send a message to the developer.
 * @param {object} props
 * @param {string} props.boundary the boundary of the component to log at what level the error was thrown
 * @example
 * <ErrorBoundary boundary={"OOOOF top level crash"} />
 */
const ErrorComponent = props => {
  const { errorObjForDB, getCallStack } = props;
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [errorObj, setErrorObj] = useState({ ...errorObjForDB });
  const [addErrorLog] = useAddErrorLogMutation();
  const [addUserMessage] = useAddUserMessageToErrorLogMutation();

  useEffect(() => {
    LogRocket.error(errorObjForDB);
    LogRocket.error(errorObj);
    if (!errorObj.id) {
      //do the thing
      //console.log("we sending error?")
      addErrorLog({
        variables: {
          ...errorObj,
        },
      }).then(res => {
        if (res.errors) {
          LogRocket.error(res.errors);
          console.log(res.errors);
        } else {
          setErrorObj({ ...errorObj, id: res.data.addErrorLog.id });
        }
      });
    }
  }, [errorObj]);

  const handleAddMessage = () => {
    if (message !== "" && errorObj.id != "") {
      addUserMessage({
        variables: {
          id: errorObj.id,
          userMessage: message,
        },
      }).then(res => {
        if (res.errors) {
          LogRocket.error(res.errors);
          console.log(res.errors);
        } else {
          console.log(res.data);
          setErrorObj({
            ...errorObj,
            userMessage: res.data.addUserMessageToErrorLog.userMessage,
          });
          toast.info("Thank you!");
        }
      });
    }
    setInfoModalIsOpen(false);
  };

  return (
    <div
      className={`my-3 py-3 col-12 bg-cleard error-box-shadow round-box useBorder `}
    >
      <h5>
        <BiMessageAltError className={`text-danger`} /> Whoops... Something went
        wrong.
      </h5>
      <div>
        A small chunklet of this app isn't displaying properly here due to an
        error.
      </div>
      <div className={``}>
        Usually a quick refresh will solve the problem. Try clicking the{" "}
        <span className={`font-italic font-weight-bold`}>Refresh</span> button
        below one time to potentially solve the problem.
      </div>
      <div className={`mt-4 row d-flex`}>
        <div className={`col col-12 d-flex`}>
          If you want to be a superhero click the button below and give the
          developer some information about the error!{" "}
        </div>
        <div className={`col col-12 mt-2 d-flex justify-content-center`}>
          <span className={`col col-4 d-flex`}>
            <button
              onClick={() => setInfoModalIsOpen(true)}
              className={`btn btn-success btn-small mx-auto`}
            >
              <BiMailSend /> Send Info
            </button>
          </span>
          <span className={`col col-4 d-flex`}>
            <button
              onClick={() => window.location.replace(window.location.href)}
              className={`btn btn-info btn-small mx-auto`}
            >
              <BiRefresh /> Refresh
            </button>
          </span>
        </div>
      </div>
      <GenericModal
        modalIsOpen={infoModalIsOpen}
        setModalIsOpen={setInfoModalIsOpen}
      >
        <div className="round-box  box-shadow align-self-center p-5">
          <div>
            <h5>
              Wow you actually opted to help! You're amazing and we truely
              appreciate the help.
            </h5>

            <div>Here's a list of the type of information that will help:</div>
            <ul>
              <li>
                The type of device you're using. (eg. desktop, laptop, tablet)
              </li>
              <li>
                The browser you are using. (eg. Chrome, FireFox, Edge, Internet
                Explorer, Safari)
              </li>
              <li>If it is a computer, whether it is Windows or Apple.</li>
              <li>
                A funny joke that will make the developer giggle while solving
                this error.
              </li>
            </ul>
            <textarea
              className={`w-100`}
              type="text"
              value={message}
              placeholder={"Input details here"}
              onChange={e => {
                setMessage(e.target.value);
              }}
            ></textarea>
            <span className={`d-flex align-items-center mb-1`}>
              <FcInfo className={`mr-2`} /> If you are unsure about any/all of
              these details don't sweat it. You wanted to help and that still
              makes you a superhero!
            </span>
            <button
              className={`btn btn-success mr-2`}
              onClick={() => handleAddMessage()}
            >
              Submit
            </button>
            <button
              className={`btn btn-danger`}
              onClick={() => {
                setInfoModalIsOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </GenericModal>
    </div>
  );
};

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: { message: "", stack: "" },
    errorObjForDB: {
      id: "",
      error: "",
      componentStack: "",
      platform: "",
      userAgent: "",
      boundary: "",
      userMessage: "",
    },
    getCallStack: () => {
      return this.state.info.componentStack;
    },
    info: { componentStack: "" },
  };

  static getDerivedStateFromError = error => {
    // console.log("hi from beautiful derivedStateFromError-landia")
    // console.log(error)
    return {
      error,
      errorObjForDB: {
        id: "",
        error: error.message,
        componentStack: error.stack,
        platform: navigator.platform,
        userAgent: navigator.userAgent,
        boundary: "",
        userMessage: "",
      },
      hasError: true,
    };
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    // console.log("hi from beautiful componentDidCatch-landia")
    // console.log("hi from beautiful didCatch-landia")
    // console.log(`${error}`)
    // console.log(`${info.componentStack}`)
    // console.log(navigator.platform)
    // console.log(navigator.userAgent)
    if (error.message.indexOf("Loading chunk") !== -1) {
      const chunk = localStorage.getItem("chunck-loading-error");

      if (!chunk || !moment().isAfter(moment(chunk))) {
        localStorage.setItem(
          "chunck-loading-error",
          `${moment().add(2, "mins").toISOString()}`
        );
        window.location.replace(window.location.href);
      }
    }
    this.setState({
      error,
      info,
      errorObjForDB: {
        id: "",
        error: error.message,
        componentStack: info.componentStack,
        platform: navigator.platform,
        userAgent: navigator.userAgent,
        boundary: this.props.boundary,
        userMessage: "",
      },
      hasError: true,
    });
  };

  render() {
    const { hasError, error, info, errorObjForDB } = this.state;
    const { message } = error;
    const { componentStack } = info;
    //  let errorObjForDB = {

    //    id: "",
    //    error: message,
    //    componentStack: componentStack,
    //    platform: navigator.platform,
    //    userAgent: navigator.userAgent,
    //    userMessage: ""

    //   }
    // console.log( errorObjForDB);
    const { children, boundary } = this.props;

    return hasError ? (
      <ErrorComponent
        getCallStack={this.state.getCallStack}
        errorObjForDB={{ ...errorObjForDB, boundary: this.props.boundary }}
      />
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
