import { Suspense, lazy, useMemo } from "react";
import { SubscriptionProvider } from "../../context/SubscriptionContext";

const SubscriptionContextContainer = () => {
  const RunningSessionContainer = useMemo(
    () => lazy(() => import("../RunningSession/RunningSessionContainer")),
    []
  );
  return (
    <>
      <SubscriptionProvider>
        <Suspense fallback={<div></div>}>
          <RunningSessionContainer />
        </Suspense>
      </SubscriptionProvider>
    </>
  );
};

export default SubscriptionContextContainer;
