import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
/**
 *
 * @namespace PrivateRoute
 */
/**
 * @memberof PrivateRoute
 * @function PrivateRoute
 * @param {object} props
 * @param {object} props.component The component to render
 * @param {string[]} props.roles The roles that the user must have to access the route
 * @description
 * This component renders a route if the user has the correct roles. If the user does not have the correct roles, it redirects to the login page.
 * @example
 * <PrivateRoute component={component} roles={roles} />
 * @returns {ReactElement}
 */
const directOnly = process.env.REACT_APP_DIRECT_ONLY === "true";
const PrivateRoute = ({
  component: Component,
  roles,
  notForDirect,
  ...rest
}) => {
  const { state } = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={props => {
        const currentUser = state.user;
        if (!currentUser || !currentUser.id || !state.accessToken) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/signin", state: { from: props.location } }}
            />
          );
        }

        // check if route is restricted by role
        if (
          (roles && roles.indexOf(currentUser.role) === -1) ||
          (directOnly && notForDirect)
        ) {
          // role not authorized so redirect to home page
          return <Redirect to={{ pathname: "/home" }} />;
        }

        // authorized so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
