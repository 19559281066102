import React from 'react';
import { NavLink } from "react-router-dom";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaApple, FaChrome } from "react-icons/fa";
import { MdSmartphone } from "react-icons/md";
import { BiError } from "react-icons/bi";

import './Landing.css';

const Faq = () => {
    return (
        <div class="landing-page">
            <div className="d-flex flex-row justify-content-center">
                <div className="mt-5 mb-5 col-10 col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                    <div className="row">
                        <div className={`col col-12 d-flex flex-column justify-content-center`}>
                            <div className="landing-heading">
                                Frequently Asked <span className="text-landing">Questions</span>
                            </div>

                            <details className="details">
                                <summary className="summary">What devices do you support?</summary>
                                <div className="d-flex align-items-center mt-3">
                                    <span className="text-weight-light align-items-center">
                                        <AiOutlineInfoCircle className={`text-info mr-1 landing-icon`} /> For access to all features choose the <FaChrome className={`landing-icon `}></FaChrome> Chrome Browser on a computer or Android tablet.
                                    </span>
                                </div>
                                <div className="d-flex align-items-center my-3">
                                    <span className="text-weight-light align-items-center ">
                                        <BiError className={`text-danger mr-1 landing-icon`} />Some features are not fully supported on <FaApple className={`landing-icon`}></FaApple> iOS tablets and <MdSmartphone className={`landing-icon `} /> Android/iOS smart phones.
                                    </span>
                                </div>
                            </details>

                            <details className="details">
                                <summary className="summary">I want to join the community, where do I do that?</summary>
                                For group rates and partner packages get in touch with us via the contact page <NavLink to="/contact" className="text-landing">here</NavLink>!
                                <br></br>New users can sign up <NavLink to="/register" className="text-landing">here</NavLink>!
                            </details>

                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center text-white landing-background">
                <div className="mt-1 mb-5 col-10 col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                    <div className="row">
                        <div className={`col col-12 d-flex flex-column justify-content-center`}>

                            <details className="details">
                                <summary className="summary">Can I invite a friend to attend a group?</summary>
                                Yes, once you are registered you can send a link to the group for your friends or
family to participate.
                            </details>
                            <details className="details">
                                <summary className="summary">Who facilitates JAVAconnects groups?</summary>
                                Trained facilitators guide the groups.
                            </details>
 </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <div className="mt-1 mb-5 col-10 col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                    <div className="row">
                        <div className={`col col-12 d-flex flex-column justify-content-center`}>
                           
                            <details className="details">
                                <summary className="summary">Can I become a facilitator?</summary>
                                Yes, anyone can learn to facilitate the groups – it’s designed to be easy.
There is a 1-hour training video with additional support as needed.
                            </details>

                            <details className="details">
                                <summary className="summary">Can I earn money as a facilitator?</summary>
                                Yes, we will have paid facilitator positions available in the near future.
                                Please get in touch with us at <a className={`text-landing`} href="mailto:info@javaconnects.com"> info@javaconnects.com</a>
                            </details>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq
