import Modal from "react-modal";
import { MdClose } from "react-icons/md";
/**
 * @memberof GenericComponents
 * @function GenericModal
 * @description
 * A generic modal component that can be used to display any content. OnBlur the modal will close. The background behind the modal is slightly greyed out and blured to make it more visible.
 * @param {object} props
 * @param {boolean} props.modalIsOpen a boolean to determine if the modal is open or not
 * @param {function} props.setModalIsOpen a function to set the modalIsOpen state
 * @param {string} props.addStyle a string to add a style to the modal
 * @param {string} props.addClass a string to add a class to the modal
 * @param {boolean} props.keepAlive a boolean to determine if the modal should close onBlur
 * @returns {React.Component}
 * @example
 * <GenericModal
 *  modalIsOpen={modalIsOpen}
 * setModalIsOpen={setModalIsOpen}
 * addStyle={addStyle}
 * addClass={addClass}
 * keepAlive={keepAlive}
 * />
 */

export default function GenericModal(props) {
  const { modalIsOpen, setModalIsOpen, addStyle, addClass, keepAlive } = props;
  const safetyStyle = addStyle ? addStyle : "";
  const safeClass = addClass ? addClass : "";
  const afterOpenModal = e => {
    e.contentEl.blur();
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  Modal.setAppElement("#root");

  return (
    <Modal
      className={`generic-modal bg-cleard-modal box-shadow round-box  position-relative ${safeClass}`}
      isOpen={modalIsOpen}
      onAfterOpen={e => {
        afterOpenModal(e);
      }}
      onRequestClose={keepAlive ? () => {} : closeModal}
      htmlOpenClassName={"ReactModal__Html--open"}
      closeTimeoutMS={300}
      style={{ zIndex: 2000, border: 0, maxHeight: "98vh", ...safetyStyle }}
    >
      {!keepAlive && (
        <div
          onClick={closeModal}
          style={{zIndex:9999}}
          className="d-flex align-items-center round-box justify-content-center position-absolute modal-close-button bg-clear box-shadow-3d"
        >
          <MdClose className="d-flex text-white m-0" />
        </div>
      )}
      <div className="w-100 h-100 round-box content rounded-scroll">{props.children}</div>
    </Modal>
  );
}
