import React from "react";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import CommonSVG from "../CommonSVG";


// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  
`;

class LoadingSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    return (
      <div className=" d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
       <div className="row my-auto">
       <div className="col col-12 d-flex align-items-center justify-content-center mb-5">
       <CommonSVG width={"250px"} height={"auto"} className={""} ></CommonSVG>
			</div>
       <div className="col col-10 mx-auto progress-bar d-flex align-items-center justify-content-center">
       <div className=" rounded box-shadow-3d bg-clear progress-bar">
				<span className="progress-bar-fill" style={{width:"100%"}}></span>
			</div>
			</div>
			</div>
      </div>
    );
  }
}

export default LoadingSpinner;
