import { MdInfoOutline, MdRecordVoiceOver } from "react-icons/md";
import { useContext } from "react";
import { RunSessContext, Scopes } from "../../context/RunSessContext";
function FacilitationPrompt(props) {
  const { content, type } = props;
  const { state } = useContext(RunSessContext);
  return (
    <div
      className={`
      ${state.scope == Scopes.DIRECTSECONDARY && "d-none"} 
      fac-prom round-box box-shadow-3d  my-4 px-2 py-1`}
    >
      <div className={`  px-2 text-fac`}>
        {type == "tip" ? (
          <>
            <MdInfoOutline /> Facilitation tip
          </>
        ) : (
          <>
            <MdRecordVoiceOver /> Facilitation prompt
          </>
        )}
      </div>
      <div className={` my-2 px-2 py-1`}>
        {content.map(c => (
          <div className={`content-fac`}>{c}</div>
        ))}
      </div>
    </div>
  );
}

export default FacilitationPrompt;
