


export default function ThemeBigCard(props) {
  const { reflection, isJavaTips, onThemeCardClick } = props;

  return (
    <div
    style={{}}
      className={`round-box box-shadow-3d p-3 ${
        !isJavaTips ? "bg-clearWithHov " : "gray-filled-1"
      }`}
      onClick={onThemeCardClick}
      data-content="question"
    >


      <div>
       

        {reflection.map(question => (
            <p
              key={question.id}
              className="py-1 mb-0 d-flex align-items-center"
            >

              <span className="ml-2" dangerouslySetInnerHTML={{__html: question.question}}></span>
            </p>
          ))}
      </div>
    </div>
  );
}
