import { useState, useContext, useEffect, useCallback } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { FaVolumeOff } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import { UserContext } from "./../../context/UserContext";
import { RunSessContext, Scopes } from "./../../context/RunSessContext";
import { SubscriptionContext } from "../../context/SubscriptionContext";

const useStyles = makeStyles(theme => ({
  root: {
    width: "95%",
    paddingTop: "0.6em",
  },
  margin: {
    height: "30%",
    paddingTop: "0%",
  },
}));
function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = withStyles({
  root: {
    color: "grey",
    height: 8,
    paddingY: 10,
  },
  thumb: {
    height: "1rem",
    width: "1rem",
    backgroundColor: "lightGrey",
    border: "2px solid currentColor",
    marginTop: -4,
    marginLeft: -7,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
    //marginbottom:1
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
const directOnly = process.env.REACT_APP_DIRECT_ONLY === "true";

function SongPlayer({
  songurl,
  audioRef,
  isSyncFlag,
  handleMusicPlay,
  funcProp,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(100);
  const handlePlay = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setPlayButton(
        <FaPause
          onClick={handlePlay}
          className={``}
          style={{ fontSize: "1em", marginRight: 3, zIndex: 1 }}
        ></FaPause>
      );
      if (isSyncFlag) {
        handleMusicPlay(true, funcProp);
      }
    } else {
      fade(audioRef.current, audioRef.current.volume);

      setPlayButton(
        <FaPlay
          onClick={handlePlay}
          style={{ fontSize: "1em", marginRight: 3, zIndex: 1 }}
        ></FaPlay>
      );
      if (isSyncFlag) {
        handleMusicPlay(false, funcProp);
      }
    }
  };
  const [playButton, setPlayButton] = useState(
    <FaPlay
      onClick={handlePlay}
      style={{ fontSize: "1em", marginRight: 3, zIndex: 1 }}
    ></FaPlay>
  );
  const { state } = useContext(UserContext);
  const { state: sessState } = useContext(RunSessContext);
  const { state: {status} } = useContext(SubscriptionContext);

  const handleChange = (event, newValue) => {
    console.log(audioRef.current.volume);
    setValue(newValue);
    audioRef.current.volume = newValue / 100;
  };
  useEffect(() => {
    if (sessState.hardMute == true) {
      setValue(0);
      audioRef.current.volume = 0;
    } else {
      //  setValue(100);
      //  audioRef.current.volume = 1
    }
  }, [sessState, audioRef.current]);
  const handleMute = () => {
    if (audioRef.current.volume === 0) {
      setValue(100);
      audioRef.current.volume = 1;
    } else {
      setValue(0);
      audioRef.current.volume = 0;
    }
  };
  const handleEnded = useCallback(() => {
    handleMusicPlay(false, funcProp);
    console.log("ended");
  }, [funcProp]);
  const handleLocalPlay = useCallback(() => {
    if(directOnly && status == "joinable" && sessState.scope !== Scopes.MASTER){
      setValue(0);
      audioRef.current.volume = 0;
    }
  }, [sessState]);
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("ended", handleEnded);
      audioRef.current.addEventListener("play", handleLocalPlay);

      return () => {
        audioRef.current.removeEventListener("ended", handleEnded);
      };
    }
  }, [audioRef]);

  function fade(audio, last) {
    if (audio.volume > 0.1) {
      audio.volume -= 0.001;
      setTimeout(fade(audio, last), 5000);
    } else {
      audio.pause();
      audio.volume = last;
    }
  }

  return (
    <div
      className={
        "row justify-content-center ml-1 d-flex my-3  bg-zero border useBorder round-box box-shadow"
      }
    >
      <div className={"col col-12 d-flex"}>
        <div className="col  d-flex text-success align-items-center col-1 pl-0">
          {state.user.role == "ROLE_MEMBER" ? <></> : playButton}
        </div>
        <div className="col col-7 position-relative">
          <audio
            //   style={{width:"100%"}}
            className={"position-absolute "}
            style={{
              backgroundColor: "rgba(0,0,0,0) !important",
              bottom: "50%",
              left: "50%",
              transform: "translate(-50%, 50%)",
            }}
            ref={audioRef}
            muted={sessState.hardmute}
            controls
            //autoPlay={false}
            controlsList="nodownload"
            src={"https://d30mg0zmvh01j5.cloudfront.net" + songurl}
          />
        </div>
        <div className={"col-4 pl-0 my-1"}>
          <div className={"row align-items-center justify-content-around"}>
            <div className={"col col-2 mr-3"}>
              <div
                role="button"
                className={` my-auto  pr-0 align-self-center rounded-circle ${
                  audioRef.current != null && audioRef.current.volume <= 0.01
                    ? "text-danger"
                    : "text-success"
                }`}
                onClick={handleMute}
              >
                <FaVolumeOff
                  style={{ fontSize: "2rem", paddingRight: 1 }}
                ></FaVolumeOff>
              </div>
            </div>
            <div className={"col col-7  pl-0  align-items-center"}>
              <div className={classes.root}>
                <Grid className={classes.margin} container>
                  <Grid item xs>
                    <PrettoSlider
                      valueLabelDisplay="off"
                      aria-label="pretto slider"
                      value={value}
                      onChange={handleChange}
                      aria-labelledby="continuous-slider"
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-8 pr-0">
        <div className={"row"}>
            {state.user.role=="ROLE_MEMBER"?<></>:
          <div
            role="button"
            className={`col  col-1 align-self-center align-items-center justify-content-center mb-1 pb-1 align-self-start text-success  rounded-circle `}
            onClick={handlePlay}
            
          >
            {state.user.role=="ROLE_MEMBER"?<></>:playButton}
          </div>
            }

          <div className={"col  w-50 pt-1 "}>
            <audio
              //   style={{width:"100%"}}
              className={"w-100 "}
              style={{backgroundColor:"rgba(0,0,0,0) !important"}}
              ref={audioRef}
              muted={sessState.hardmute}
              controls
              //autoPlay={false}
              controlsList="nodownload"
              src={"https://d30mg0zmvh01j5.cloudfront.net" + songurl}
            />
          </div>
        </div>
      </div>

      <div className={"col-4 pl-0 my-1"}>
        <div className={"row align-items-center justify-content-around"}>
          <div className={"col col-2 mr-3"}>

          <div
            role="button"
            className={` my-auto  pr-0 align-self-center rounded-circle ${
              audioRef.current != null && audioRef.current.volume <= 0.01
                ? "text-danger"
                : "text-success"
            }`}
            onClick={handleMute}
          >
            <FaVolumeOff
              style={{ fontSize: "2rem", paddingRight: 1 }}
            ></FaVolumeOff>
          </div>
          </div>
          <div className={"col col-7  pl-0  align-items-center"}>

          <div className={classes.root}>

            <Grid className={classes.margin} container>
              <Grid item xs>
                <PrettoSlider
                  valueLabelDisplay="off"
                  aria-label="pretto slider"
                  value={value}
                  onChange={handleChange}
                  aria-labelledby="continuous-slider"
                />
              </Grid>
            </Grid>
          </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default SongPlayer;
