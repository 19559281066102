import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { setAccessToken } from "../auth/accessToken";
import { CLEAR, SET_ACCESS_TOKEN, UserContext } from "../context/UserContext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import { Routes } from "./Routes";

import MainLoading from "./MainLoading";
import ErrorBoundary from "./ErrorBoundary";
import { DataStoreProvider } from "../context/DataStoreContext";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

LogRocket.init('if8502/dev-javaconnects');
setupLogRocketReact(LogRocket);
const libraries = ["places"];

function App() {
  const [loading, setLoading] = useState(true);
  const { dispatch } = useContext(UserContext);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  // Attempt to get a new access token using the refresh token
  // If it fails, all the user data currently being held by the frontend will be deleted
  // and user will be redirected to the sign in page
  useEffect(() => {
    fetch(process.env.REACT_APP_REFRESH_TOKEN, {
      method: "POST",
      credentials: "include",
    }).then(async x => {
      const { ok, accessToken } = await x.json();
      if (ok) {
        setAccessToken(accessToken);
        dispatch({ type: SET_ACCESS_TOKEN, payload: { accessToken } });
      } else {
        setAccessToken("");
        dispatch({ type: CLEAR });
      }
      setLoading(false);
    });
  }, []);

  // While waiting for the server response, display some loading message
  // TODO: Replace this loading message with a loading spinner or something more graphical
  if (loading) {
    return (
      <MainLoading
        isCollapsed={false}
        type={"home"}
        isLoading={true}
      ></MainLoading>
    );
  }

  return (
    <ErrorBoundary boundary={"OOOOF top level crash"}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        theme="colored"
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <DataStoreProvider>
        <Router>
          <Routes />
        </Router>
      </DataStoreProvider>
    </ErrorBoundary>
  );
}

export default App;
