import { ReactComponent as JCLogo } from "@SVG/JClogo6.svg";
import { ReactComponent as JMLogo } from "@SVG/JMLogo-ye.svg";
import { ReactComponent as JSCLogo } from "@SVG/JSClogo1.svg";
const svgMap = new Map([
  [
    "jc",
    {
      getComponent: (width, height, className) => {
        return <JCLogo width={width} height={height} className={className} />;
      },
    },
  ],
  [
    "jsc",
    {
      getComponent: (width, height, className) => {
        return <JSCLogo width={width} height={height} className={className} />;
      },
    },
  ],
  [
    "jm",
    {
      getComponent: (width, height, className) => {
        return <JMLogo width={width} height={height} className={className} />;
      },
    },
  ],
]);
const CommonSVG = ({ width, height, className, svg }) => {
  if (svgMap.has(svg))
    return svgMap.get(svg).getComponent(width, height, className);
  else return <JCLogo width={width} height={height} className={className} />;
};

export default CommonSVG;
