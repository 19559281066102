import {
  useContext,
  useEffect,
  useState,
  Suspense,
  lazy,
  useMemo,
} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  SET_COMMUNITY_NAME,
  SET_ME_DATA,
  CLEAR,
  SET_FRIENDS,
  UserContext,
} from "../../context/UserContext";
import { ACTIONS, DataStoreContext } from "../../context/DataStoreContext";
import { setAccessToken } from "./../../auth/accessToken";

import PrivateRoute from "./../PrivateRoute";
import LoginForm from "../Login/LoginForm";
import {
  ACTIONS as STYLE_ACTIONS,
  StyleContext,
} from "../../context/StyleContext";
import FontSizeButtons from "../RunningSession/FontSizeButtons";
import ThemeContainer from "../RunningSession/Theme/ThemeContainer";

import { RunSessProvider } from "../../context/RunSessContext";
import {
  ROLE_FACILITATOR,
  ROLE_MEMBER,
  ROLE_ADMIN,
  ROLE_MOD,
} from "../../auth/roles";
import {
  useLogoutMutation,
  useCommunityLazyQuery,
  useVersionLazyQuery,
  useThemesLazyQuery,
  useMeLightLazyQuery,
  useFriendsLazyQuery,
  useAddOrEditUiMetricsMutation,
} from "../../graphql/generated/graphql";
import moment from "moment";
import { CommunityRegProvider } from "../../context/CommunityRegContext";
import "react-toastify/dist/ReactToastify.css";
import { toast, cssTransition } from "react-toastify";

import OptOut from "../OptOut";
import TestSubscription from "../TestSubscription";

import ErrorBoundary from "../ErrorBoundary";

import LogoutPage from "../Login/LogoutPage";
import useInterval from "@use-it/interval";
import MainLoading from "../MainLoading";
import LogRocket from "logrocket";
import { AiOutlineRobot } from "react-icons/ai";
import SideNav from "../Landing/SideNav";
import AboutUs from "../Landing/AboutUs";
import ContactUs from "../Landing/ContactUs";
import Faq from "../Landing/Faq";
import { useMediaQuery } from "react-responsive";
import { useDebouncedCallback } from "../../hooks/useDebounce";
import SubscriptionContextContainer from "../RunningSession/SubscriptionContextContainer";

const bounce = cssTransition({
  enter: "animate__bounceIn",
  exit: "animate__bounceOut",
});
const directOnly = process.env.REACT_APP_DIRECT_ONLY === "true";
/**
 *
 * @namespace Routes
 */
/**
 * @memberof Routes
 * @function Routes
 * @description
 * This component is the main component of the application and is responsible for routing and authorization specific routes.
 * @example
 * <Routes />
 * @returns {ReactElement}
 */
export const Routes = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 580px)` });
  const MemberProfile = useMemo(
    () => lazy(() => import("../MemberProfile/MemberProfile")),
    []
  );
  const RunningSessionContainer = SubscriptionContextContainer;
  const MemberProfileEdit = useMemo(
    () => lazy(() => import("../MemberProfile/MemberProfile-edit")),
    []
  );
  const FriendsContainer = useMemo(
    () => lazy(() => import("../Friends/FriendsContainer")),
    []
  );
  const SwitchUser = useMemo(() => lazy(() => import("../SwitchUser")), []);
  const WaitingRoom = useMemo(() => lazy(() => import("../WaitingRoom")), []);
  const RequestReset = useMemo(
    () => lazy(() => import("../PasswordReset/RequestReset")),
    []
  );
  const ResetPasswordForm = useMemo(
    () => lazy(() => import("../PasswordReset/ResetPasswordForm")),
    []
  );
  const ConfirmEmail = useMemo(
    () => lazy(() => import("../Email/EmailConfirm")),
    []
  );
  const LandingContainer = useMemo(
    () => lazy(() => import("../Landing/LandingContainer")),
    []
  );
  const NestLandingContainer = useMemo(
    () => lazy(() => import("../Landing/NestLandingContainer")),
    []
  );
  const NestRegistrationForm = useMemo(
    () => lazy(() => import("../Registrations/NestRegistrationForm")),
    []
  );
  const AdminNavbar = useMemo(() => lazy(() => import("../Admin/Navbar")), []);
  const Home = useMemo(() => lazy(() => import("../Home/Home")), []);
  const AddTheme = useMemo(
    () => lazy(() => import("../Admin/ThemeAdd/AddTheme")),
    []
  );
  const CommunityContainer = useMemo(
    () => lazy(() => import("../Admin/CommunityContainer")),
    []
  );
  const VersionContainer = useMemo(
    () => lazy(() => import("../Admin/VersionContainer")),
    []
  );

  const Billing = useMemo(() => lazy(() => import("../Billing/BillingPage")), []);

  const [showChat, setShowChat] = useState(false);
  const [setUiMetrics] = useAddOrEditUiMetricsMutation();
  const handleUiMetricsUpdate = useDebouncedCallback(uiMetrics => {
    if (state?.user?.id)
      setUiMetrics({
        variables: {
          user: state.user.id,
          fontSize: `${uiMetrics}`,
        },
      });
  }, 800);
  const { dispatch: styleDispatch, state: styleState } = useContext(
    StyleContext
  );
  const handleChangeFont = operation => {
    if (operation === "+") {
      if (styleState.fontSize < 1.4) {
        const newFontSize = styleState.fontSize + 0.2;
        styleDispatch({
          type: STYLE_ACTIONS.UPDATE_ONE,
          payload: {
            fontSize: styleState.fontSize + 0.2,
          },
        });
        handleUiMetricsUpdate(newFontSize);
      }
    } else {
      if (styleState.fontSize > 1.0) {
        const newFontSize = styleState.fontSize - 0.2;
        styleDispatch({
          type: STYLE_ACTIONS.UPDATE_ONE,
          payload: {
            fontSize: styleState.fontSize - 0.2,
          },
        });
        handleUiMetricsUpdate(newFontSize);
      }
    }
  };
  const LazySchedule = useMemo(
    () => lazy(() => import("../Schedule/Schedule")),
    []
  );
  const ThemeLibrary = useMemo(
    () => lazy(() => import("../ThemeLibrary/ThemeLibrary")),
    []
  );
  const OpenChatRoomContainer = useMemo(
    () => lazy(() => import("../OpenChatRoom/OpenChatRoomContainer")),
    []
  );
  const OpenChatRoomsSelector = useMemo(
    () => lazy(() => import("../OpenChatRoom/OpenChatRoomsSelector")),
    []
  );
  const OpenChatWaitingRoom = useMemo(
    () => lazy(() => import("../OpenChatRoom/OpenChatWaitingRoom")),
    []
  );
  const TrainingContainer = useMemo(
    () => lazy(() => import("../Training/TrainingContainer")),
    []
  );
  const ResourceLibrary = useMemo(
    () => lazy(() => import("../Resource/ResourceLibrary")),
    []
  );
  const AdminDashboard = useMemo(
    () => lazy(() => import("../Admin/AdminDashboard")),
    []
  );
  const AdminContainer = useMemo(
    () => lazy(() => import("../Admin/AdminContainer")),
    []
  );
  const StaffContainer = useMemo(
    () => lazy(() => import("../Admin/Staff/StaffContainer")),
    []
  );
  const ErrorLogContainer = useMemo(
    () => lazy(() => import("../Admin/Errors/ErrorLogContainer")),
    []
  );
  const SongEditor = useMemo(
    () => lazy(() => import("../Admin/Song/SongEditor")),
    []
  );
  const Plans = useMemo(
    () => lazy(() => import("../Admin/Plans/Plans")),
    []
  );
  const PlanDetails = useMemo(
    () => lazy(() => import("../Admin/Plans/PlanDetails")),
    []
  );
  const Navbar = useMemo(() => lazy(() => import("../Navbar")), []);
  const [checked, setChecked] = useState(false);
  const Manage = useMemo(() => lazy(() => import("../Manage/Manage")), []);
  const Group = useMemo(() => lazy(() => import("../Manage/Group")), []);
  const ChatContainer = useMemo(
    () => lazy(() => import("../Chat/ChatContainer")),
    []
  );
  const RegistrationForm = useMemo(
    () => lazy(() => import("../Registrations/RegistrationForm")),
    []
  );
  const [logout, { client }] = useLogoutMutation();
  const { dispatch, state } = useContext(UserContext);
  const [
    getVersion,
    { loading: vLoading, error: vError, data: vData },
  ] = useVersionLazyQuery();
  const [
    getThemes,
    { loading: tLoading, error: tError, data: tData },
  ] = useThemesLazyQuery();
  const [
    getMeData,
    { loading: meLoading, error: meError, data: meData },
  ] = useMeLightLazyQuery();
  const [
    getFriends,
    { loading: frLoading, error: frError, data: frData },
  ] = useFriendsLazyQuery();
  const { state: dsState, dispatch: dataStoreDispatch } = useContext(
    DataStoreContext
  );

  const [executeQuery, { data, error, loading }] = useCommunityLazyQuery();

  const [appVersion, setAppVersion] = useState("1.4.1");
  const [redirectRoute, setRedirectRoute] = useState(
    localStorage.getItem("hasVisit")
      ? localStorage.getItem("hasVisit")
      : "/welcome"
  );

  const [accountErr, setAccountErr] = useState("");

  const [dbVersion, setdbVersion] = useState("");
  const [wasReset, setWasReset] = useState(false);
  const safariRobot = (
    <div>
      <AiOutlineRobot className={` landing-icon`}></AiOutlineRobot> Beep Boop:{" "}
      <br></br>Uh-Oh our robots detected that you're using the safari browser.{" "}
      <br></br>You may have trouble with the video/voice chat.<br></br> Please
      download/use Chrome for access to a smooth experience.
    </div>
  );
  useEffect(() => {
    if (
      navigator.userAgent.indexOf("Chrome") == -1 &&
      navigator.userAgent.indexOf("CriOS") == -1 &&
      navigator.userAgent.indexOf("Safari") != -1
    ) {
      toast.error(safariRobot, { autoClose: false, toastId: `safari` });
      LogRocket.error("Safari Browser Detected");
    }

    getVersion();
  }, []);

  useEffect(() => {
    const hasVisit = localStorage.getItem("hasVisit");
    if (hasVisit && redirectRoute !== "/login") {
      setRedirectRoute("/login");
    } else {
      localStorage.setItem("hasVisit", "/login");
    }
  }, []);

  useInterval(() => {
    if (dbVersion && appVersion != dbVersion.v) {
      console.log(`app: ${appVersion} ${" "}db: ${dbVersion.v}`);
      if (moment().isAfter(moment(dbVersion.readAt))) {
        toast.dark(`Updating to v. ${dbVersion.v} ...`);

        //  setAppVersion(vData.version.v)
        window.location.replace(window.location.href);
      }
    }
  }, 60000);
  useInterval(() => {
    setWasReset(false);
    getVersion();
  }, 600000);
  const logoutProcedure = async () => {
    await logout();
    await client.clearStore(); // Clears the apollo cache
    setAccessToken("");
    if (state && state.user) {
      if (
        state.user.status == true &&
        moment().isAfter(state.user.validUntil)
      ) {
        setAccountErr("Account expired");
      } else if (state.user.status === false) {
        setAccountErr("Account suspended");
      }
    }
    dispatch({ type: CLEAR });
  };
  useEffect(() => {
    if (state.user && state.user.communityId && !state.user.communityName) {
      executeQuery({
        variables: {
          id: state.user.communityId,
        },
      });
    }
  }, [state]);
  useEffect(() => {
    if (!state.accessToken) {
      logoutProcedure();
      setWasReset(true);
    }
    if (
      state.accessToken != null &&
      state.user != null &&
      dsState.themes.length <= 0
    ) {
      console.log("boom");
      getThemes();
    }
  }, [state]);
  useEffect(() => {
    if (state?.user?.id) {
      const { innerHeight, innerWidth, outerHeight, outerWidth } = window;
      setUiMetrics({
        variables: {
          user: state.user.id,
          innerDimensions: {
            height: innerHeight,
            width: innerWidth,
          },
          outerDimensions: { height: outerHeight, width: outerWidth },
        },
      });
    }
  }, [state?.user?.id]);
  useEffect(() => {
    if (wasReset) {
      getVersion();
      // console.log("wasReset == true")
    } else {
      // console.log("wasReset == false")
    }
  }, [wasReset]);

  useEffect(() => {
    if (data && data.community) {
      dispatch({
        type: SET_COMMUNITY_NAME,
        payload: {
          communityName: data.community.name,
        },
      });
    }
  }, [data]);

  useEffect(() => {
    if (state.user) {

      getMeData();
      getFriends({
        skip: directOnly,
      });
    }
  }, [state]);
  useEffect(() => {
    if (state?.user?.id) {
      LogRocket.debug("identify user", { user: state.user });
      LogRocket.identify(state.user.id, {
        name: `${state.user.firstName} ${state.user.lastName}`,
        email: state.user.email,
      });
    }
  }, [state?.user?.id]);
  const [variant, setVariant] = useState("no test");
  useEffect(async () => {
    if (window.dataLayer) {
      await window.dataLayer.push({ event: "optimize.activate" });
      // test me after the gym
      //await window.dataLayer.push({ event: "page_view", page_title: "test" });
    }
    const intervalId = setInterval(() => {
      if (window.google_optimize !== undefined) {
        let gVariant = window.location.href.includes("dev.")
          ? window.google_optimize.get("-mwYJKhiQPW-8Rmn02CFbQ")
          : window.google_optimize.get("3ns2ILyASk6-8gJqWGgDiQ");
        setVariant(gVariant);
        clearInterval(intervalId);
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (meData && meData.meLight && !state.user.email) {
      dispatch({
        type: SET_ME_DATA,
        payload: {
          email: meData.meLight.email,
          firstName: meData.meLight.firstName,
          lastName: meData.meLight.lastName,
          nickname: meData.meLight.nickname,
          profilePicUrl: meData.meLight.profilePicUrl,
          id: meData.meLight.id,
        },
      });
    }
  }, [meData]);
  useEffect(() => {
    if (frData && frData.friends && frData.friends.friends) {
      let temp = [
        ...frData.friends.friends.map(f => {
          return f.id;
        }),
      ];

      dispatch({
        type: SET_FRIENDS,
        payload: {
          friends: temp,
        },
      });
    }
  }, [frData]);

  useEffect(() => {
    if (vData && vData.version) {
      setdbVersion({ ...vData.version });
    }
  }, [vData]);
  useEffect(() => {
    if (tData && tData.themes) {
      dataStoreDispatch({
        type: ACTIONS.UPDATE_ALL,
        payload: {
          themes: [...tData.themes],
          music: [...tData.themes.filter(t => t.program == "music")],
          reflections: [
            ...tData.themes.filter(t => t.program == "reflections"),
          ],
        },
      });
    }
  }, [tData]);

  // prettier-ignore
  const routesConfig = [

    // path: "/admin", roles: [ROLE_ADMIN], component: AdminContainer, exact: true },
    { path: "/home", roles: [ROLE_MEMBER, ROLE_FACILITATOR, ROLE_MOD], component: Home, exact: true },
    { path: "/theme_library", roles: [ROLE_FACILITATOR], component: ThemeLibrary, exact: true },
    { path: "/theme/:id", roles: [ROLE_FACILITATOR], component: ThemeContainer, exact: true },
    { path: "/resources", roles: [ROLE_FACILITATOR], component: ResourceLibrary, exact: true },
    { path: "/session/:id", roles: [ROLE_MEMBER, ROLE_FACILITATOR, ROLE_MOD], component: RunningSessionContainer, exact: true },
    { path: "/optout/:id", roles: [ROLE_MEMBER, ROLE_FACILITATOR, ROLE_MOD], component: OptOut, exact: true },
    { path: "/manage", roles: [ROLE_FACILITATOR, ROLE_MOD],  component: Manage, exact: true },
    { path: "/group/:id", roles: [ROLE_FACILITATOR, ROLE_MOD], component: Group, exact: true },
    { path: "/profile", roles: [ROLE_MEMBER, ROLE_FACILITATOR], component: MemberProfile, exact: true },
    { path: "/profile-edit", roles: [ROLE_MEMBER, ROLE_FACILITATOR], component: MemberProfileEdit, exact: true },
    { path: "/schedule", roles: [ROLE_MEMBER, ROLE_FACILITATOR, ROLE_MOD], notForDirect:true, component: LazySchedule, exact: true },
    { path: "/switchuser", roles: [ROLE_FACILITATOR], notForDirect:true, component: SwitchUser, exact: true },
    { path: "/waitingroom/:id", roles: [ROLE_MEMBER, ROLE_FACILITATOR], component: WaitingRoom, exact: true, },
    { path: "/friends", roles: [ROLE_MEMBER, ROLE_FACILITATOR], notForDirect:true, component: FriendsContainer, exact: true, },
    { path: "/test", roles: [ROLE_FACILITATOR], component: TestSubscription, exact: true },
    { path: "/register", roles: [ROLE_FACILITATOR], component: RegistrationForm, exact: true },
    { path: "/training", roles: [ROLE_FACILITATOR], component: TrainingContainer, exact: true },
    { path: "/training/:id", roles: [ROLE_FACILITATOR], component: TrainingContainer, exact: true },
    { path: "/ocwaitingroom/:id", roles: [ROLE_MEMBER, ROLE_FACILITATOR], notForDirect:true, component: OpenChatWaitingRoom, exact: true },
    { path: "/chatroom/:id", roles: [ROLE_MEMBER, ROLE_FACILITATOR], notForDirect:true, component: OpenChatRoomContainer, exact: true },
    { path: "/openchatrooms", roles: [ROLE_MEMBER, ROLE_FACILITATOR], notForDirect:true, component: OpenChatRoomsSelector, exact: true },
    { path: "/confirm/:id", roles: [ROLE_MEMBER, ROLE_FACILITATOR, ROLE_MOD], component: ConfirmEmail, exact: true },
    { path: "/billing", roles: [ROLE_FACILITATOR, ROLE_MOD], component: Billing, exact: true },
    { path: "/billing/:status", roles: [ROLE_FACILITATOR, ROLE_MOD], component: Billing, exact: true },
    // { path: "/", roles: [ROLE_USER, ROLE_FACILITATOR], component: OrderPage, exact: true }, // This is redirect
  ];

  const renderRoutes = routesConfig => {
    return routesConfig.map(
      ({ path, roles, notForDirect, component, exact }) => (
        <PrivateRoute
          key={path}
          path={path}
          notForDirect={notForDirect}
          component={component}
          roles={roles}
          exact={exact}
        />
      )
    );
  };

  useEffect(() => {
    if (!state.accessToken && accountErr) {
      toast.error(accountErr);
      setAccountErr("");
    }
  }, [state]);

  if (!state.accessToken) {
    //logoutProcedure();

    return (
      <CommunityRegProvider>
        <div>
          <SideNav />
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              {
                (document.body.style.background =
                  "linear-gradient(315deg,#0a0a0a 0%,  rgb(29, 32, 36) 74% )")
              }

              <Route path="/">
                <Switch>
                  {/* <Route path="/" exact component={LandingContainer} /> */}
                  <Route path="/welcome" exact component={LandingContainer} />
                  <Route path="/register" exact component={RegistrationForm} />
                  {/* <Route path="/javaconnects" exact component={JavaConnects} /> */}
                  {/* <Route path="/programs" exact component={Programs} /> */}
                  <Route path="/about" exact component={AboutUs} />
                  <Route path="/contact" exact component={ContactUs} />
                  <Route path="/faq" exact component={Faq} />
                  <Route
                    path="/welcome/:id"
                    exact
                    component={NestLandingContainer}
                  />
                  <Route
                    path="/register/:id"
                    exact
                    component={NestRegistrationForm}
                  />
                  <Route
                    path="/recover_password"
                    exact
                    component={RequestReset}
                  />
                  <Route
                    path="/reset/:id"
                    exact
                    component={ResetPasswordForm}
                  />
                  <Route path="/optout/:id" exact component={OptOut} />
                  <Route path="/waitingroom/:id" exact component={LoginForm} />
                  <Route path="/confirm/:id" exact component={LoginForm} />
                  <Route path="/login" exact component={LoginForm} />
                  <Route>
                    <Redirect to={redirectRoute} />
                  </Route>
                </Switch>
              </Route>

              <Route>
                <Redirect to="/" />
              </Route>
            </Switch>
          </Suspense>
          <div
            style={{ position: "fixed", bottom: 0, right: 10, zIndex: -1 }}
            className={`text-fac font-weight-bold`}
          >
            v. {appVersion}
            {variant == 0 && "-a"}
            {variant == 1 && "-b"}
          </div>
        </div>
      </CommunityRegProvider>
    );
  }
  // A WILD CHUNKY IF CONDITION APPEARED
  // IT SAID -> PLEASE HELP ME LOSE SOME WEIGHT :'(

  if (
    state.accessToken &&
    state.user.role !== ROLE_ADMIN &&
    !state.user.status
  ) {
    client.clearStore(); // Clears the apollo cache
    setAccessToken("");

    logoutProcedure();
  }
  if (state.accessToken && state.user.role == ROLE_ADMIN) {
    return (
      <>
        <Route path="/login" component={LoginForm} />
        <Route path="/logout" component={LogoutPage} />

        <Suspense fallback={<div>Loading...</div>}>
          <div class="container-fluid">
            <div class="row min-vh-100 flex-column flex-md-row">
              <AdminNavbar></AdminNavbar>

              <main class="col admin-main py-3 flex-grow-1">
                <Switch>
                  {
                    (document.body.style.backgroundImage =
                      "radial-gradient(circle,   hsla(0, 0%, 5%, 0.882),  hsla(0,0%,0%,.882)) ")
                  }
                  <PrivateRoute
                    path={"/admin/dashboard"}
                    component={AdminDashboard}
                    roles={ROLE_ADMIN}
                    exact={true}
                  />
                  <PrivateRoute
                    path={"/"}
                    component={AdminDashboard}
                    roles={ROLE_ADMIN}
                    exact={true}
                  />
                  <PrivateRoute
                    path={"/admin/staff"}
                    component={StaffContainer}
                    roles={ROLE_ADMIN}
                    exact={true}
                  />
                  <PrivateRoute
                    path={"/admin/users"}
                    component={AdminContainer}
                    roles={ROLE_ADMIN}
                    exact={true}
                  />
                  <PrivateRoute
                    path={"/admin/community"}
                    component={CommunityContainer}
                    roles={ROLE_ADMIN}
                    exact={true}
                  />
                  <PrivateRoute
                    path={"/admin/errors"}
                    component={ErrorLogContainer}
                    roles={ROLE_ADMIN}
                    exact={true}
                  />
                  <PrivateRoute
                    path={"/admin/manage"}
                    component={Manage}
                    roles={ROLE_ADMIN}
                    exact={true}
                  />

                  <PrivateRoute
                    path={"/group/:id"}
                    component={Group}
                    roles={ROLE_ADMIN}
                    exact={true}
                  />
                  <PrivateRoute
                    path={"/admin/theme"}
                    component={AddTheme}
                    roles={ROLE_ADMIN}
                    exact={true}
                  />
                  <PrivateRoute
                    path={"/admin/theme/:id"}
                    component={AddTheme}
                    roles={ROLE_ADMIN}
                    exact={true}
                  />
                  <PrivateRoute
                    path={"/admin/version"}
                    component={VersionContainer}
                    roles={ROLE_ADMIN}
                    exact={true}
                  />
                  <PrivateRoute
                    path={"/admin/song"}
                    component={SongEditor}
                    roles={ROLE_ADMIN}
                    exact={true}
                  />
                  <PrivateRoute
                    path={"/admin/plans"}
                    component={Plans}
                    roles={ROLE_ADMIN}
                    exact={true}
                  />
                  <PrivateRoute
                    path={"/admin/plan/:id"}
                    component={PlanDetails}
                    roles={ROLE_ADMIN}
                    exact={true}
                  />
                  <Route>
                    <Redirect to="/admin/dashboard" />
                  </Route>
                </Switch>
              </main>
            </div>
          </div>
        </Suspense>
      </>
    );
  }
  if (state.accessToken && state.user.role !== "ROLE_ADMIN") {
    // document.body.style.backgroundImage = "radial-gradient(circle,   hsla(0, 0%, 5%, 0.882),  hsla(0,0%,0%,.882)) "
  }
  return (
    <RunSessProvider>
      <div style={{ fontSize: `${styleState.fontSize}rem ` }}>
        <div
          style={{ minHeight: "99vh", zIndex: -100, opacity: 0.99 }}
          onClick={() => {
            setShowChat(false);
            setChecked(false);
          }}
        >
          <div className={`${isMobile ? "d-none" : ""}`}>
            {" "}
            <FontSizeButtons
              fontSize={styleState.fontSize}
              handleChangeFont={handleChangeFont}
              isHome={true}
            />
          </div>
          <Switch>
            {/* Not sure if the route below was ever being used */}
            {/* <Route path="/login" component={LoginForm} />  */}
            <Route path="/logout" component={LogoutPage} />
            {/*<Route path="/register" component={RegistrationForm} />*/}
            <Route path="/recover_password">
              <div>Recover password not implemented</div>
            </Route>

            <Route path="/">
              <Suspense fallback={<div></div>}>
                <Navbar checked={checked} setChecked={setChecked} />
              </Suspense>

              <ErrorBoundary boundary={"tokened router"}>
                <Suspense
                  fallback={
                    <MainLoading
                      isCollapsed={false}
                      type={"home"}
                      isLoading={true}
                    ></MainLoading>
                  }
                >
                  <Switch>
                    {renderRoutes(routesConfig)}

                    <Route>
                      <Redirect to="/home" />
                    </Route>
                  </Switch>
                </Suspense>

                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    left: 10,
                    opacity: 0.19,
                    zIndex: -1,
                  }}
                  className={`text-fac font-weight-bold`}
                >
                  v. {appVersion}
                </div>
              </ErrorBoundary>
            </Route>
          </Switch>
          {!directOnly && (
            <div style={{}} className={`${isMobile ? "d-none" : ""}`}>
              <Suspense fallback={<div></div>}>
                <ChatContainer
                  showChat={showChat}
                  setShowChat={setShowChat}
                ></ChatContainer>
              </Suspense>
            </div>
          )}
        </div>
      </div>
    </RunSessProvider>
  );
};
