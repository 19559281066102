import { FaLightbulb, FaUserAlt, FaTrashAlt } from "react-icons/fa";
/**
 * @memberof GenericComponents
 * @description
 * A generic use comment box component
 * @function CommentBox
 * @param {object} props
 * @param {string} props.content the content of the comment
 * @param {string} props.noteId the id of the comment
 * @param {function} props.handleDeleteJavaTip a function to delete a java tip
 * @param {boolean} props.isSessionNote a boolean to determine if the comment is a session note
 * @param {object} props.author the author of the comment
 * @param {boolean} props.isRunningSession a boolean to determine if the component is in a running session
 * @returns {React.Component}
 * @example
 * <CommentBox
 * content={content}
 * noteId={noteId}
 * handleDeleteJavaTip={handleDeleteJavaTip}
 * isSessionNote={isSessionNote}
 * author={author}
 * isRunningSession={isRunningSession}
 * />
 */
export default function CommentBox(props) {
  const {
    content,
    noteId,
    handleDeleteJavaTip,
    isSessionNote,
    author,
    isRunningSession,
  } = props;

  return (
    <div className="my-3 w-100 border round-box px-3 py-2">
      <div className="text-info d-flex align-items-center font-size-2 mb-2">
        {!isSessionNote && (
          <>
            <FaLightbulb className="d-flex ml-0 mr-2" />
            <span className="mb-0">Java Tip</span>
            <span className="ml-auto mr-0" />
          </>
        )}
        {isSessionNote && (
          <div className="d-flex justify-content-between w-100 align-items-center">
            <div>
              <FaUserAlt className="mr-2" />
              <span className="mb-0">
                {author && author.firstName + " " + author.lastName}
              </span>
            </div>

            {isRunningSession === false && (
              <div>
                <button
                  onClick={e => handleDeleteJavaTip(e, noteId)}
                  className="border-0 text-danger"
                  style={{ background: "white" }}
                >
                  <FaTrashAlt />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <div>
        <p className="card-text">{content && content}</p>
      </div>
    </div>
  );
}
