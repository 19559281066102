import { useContext } from "react";
import { UserContext } from "../../../../../context/UserContext";

import { GiSaveArrow } from "react-icons/gi";

import { FaHandPaper } from "react-icons/fa";
import DiscussionQuestions from "../DiscussionQuestions";
import FacilitationPrompt from "../../../FacilitationPrompt";
import { MdInfoOutline } from "react-icons/md";
import { RunSessContext, Scopes } from "../../../../../context/RunSessContext";
import { SubscriptionContext } from "../../../../../context/SubscriptionContext";
const Sharing = props => {
  const {
    isRunningSession,
    onThemeCardClick,
    currentPage,
    discussions,
    program,
  } = props;
  const {
    state: { userList },
  } = useContext(SubscriptionContext);
  const { state } = useContext(UserContext);
  const { state: sessState } = useContext(RunSessContext);

  return (
    <>
      <div className="d-flex">
        <h3>
          {state.user.role == "ROLE_FACILITATOR" &&
            sessState.memberView !== true &&
            `${currentPage + 1}.`}{" "}
          Sharing
        </h3>
        {!isRunningSession ? (
          <button
            className="ml-2  btn-info btn-extra-radius border-0 px-3 text-white align-items-center"
            onClick={() => onThemeCardClick("addNote", "_", 2)}
          >
            <GiSaveArrow className="mr-2" />
            <span>Add Note</span>
          </button>
        ) : (
          <></>
        )}
      </div>
      {state.user.role == "ROLE_FACILITATOR" && sessState.memberView !== true && (
        <>
          <FacilitationPrompt
            content={[
              "Now I invite you to share your thoughts on the theme, quote or reflection. Feel free to share whatever is on your mind. You can also pass on sharing for today.",
              <div>Who would like to begin?</div>,
            ]}
          />
          {sessState.scope !== Scopes.DIRECTSECONDARY && (
            <>
              <h6 className="text-center  d-flex justify-content-start align-items-center">
                <span
                  className={` d-flex justify-content-start align-items-center ml-2 px-2 text-fac`}
                >
                  {" "}
                  <MdInfoOutline className={`mr-1`} /> Facilitation tips:
                </span>
              </h6>
              <ul>
                <li>Invite members to share on any of the materials</li>
                <li>
                  Guide and monitor (prompt the next person to share; tactfully
                  cut in if someone is sharing too long; who hasn’t shared yet,
                  who would like to share next…)
                </li>
                <li>
                  Ask any of the 3 Questions linked to the theme as needed
                  during the sharing
                </li>
              </ul>
            </>
          )}
          <DiscussionQuestions
            style={{ minHeight: 9000 }}
            discussions={discussions}
            currentPage={currentPage}
            nomt={true}
            isRunningSession={isRunningSession}
            program={program}
            onThemeCardClick={onThemeCardClick}
          />
        </>
      )}

      {userList && userList.filter(u => u.palamiState == true).length > 0 && (
        <div
          className={`round-box box-shadow col bg-dark col-6 d-flex flex-column justify-content-center border useBorder`}
        >
          <div className={`row`}>
            <h6 className={`col col-12 mt-2  d-flex justify-content-center `}>
              Volunteered to share:
            </h6>
          </div>
          <div className={`row d-flex justify-content-center `}>
            {userList
              .filter(u => u.palamiState == true)
              .map(user => {
                return (
                  <div
                    className={`round-box bg-clear box-shadow mx-1 my-2 py-3 col col-6 d-flex justify-content-center align-content-center border useBorder`}
                  >
                    <span className={`mx-3 my-auto round-box`}>
                      {user.name}
                    </span>
                    <h6 className={`mx-3 bg-info my-auto  round-box py-2 px-3`}>
                      <FaHandPaper />
                    </h6>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default Sharing;
