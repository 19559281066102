import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type using Date ISO String format */
  DateISO: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type Admin = {
  __typename?: 'Admin';
  id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type AdminPayload = {
  __typename?: 'AdminPayload';
  id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type Answer = {
  __typename?: 'Answer';
  id: Scalars['ID'];
  section?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  textAns?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  createdAt: Scalars['DateISO'];
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type Changes = {
  __typename?: 'Changes';
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  songs?: Maybe<Array<Maybe<Song>>>;
  discussions?: Maybe<Array<Maybe<Discussion>>>;
  reflection?: Maybe<Array<Maybe<Reflection>>>;
  quotes?: Maybe<Array<Maybe<Quote>>>;
  sensoryCues?: Maybe<Array<Maybe<SensoryCue>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  id: Scalars['ID'];
  message: Scalars['String'];
  from: User;
  replyTo?: Maybe<ChatMessage>;
  isSeen?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sentAt?: Maybe<Scalars['DateISO']>;
  createdAt: Scalars['DateISO'];
};

export type ChatRoom = {
  __typename?: 'ChatRoom';
  id: Scalars['ID'];
  users: Array<Maybe<ChatUser>>;
  sortedIdList?: Maybe<Array<Maybe<Scalars['ID']>>>;
  messageLog?: Maybe<Array<Maybe<ChatMessage>>>;
  isTyping?: Maybe<Array<Maybe<User>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isGroup?: Maybe<Scalars['Boolean']>;
  roomName?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateISO'];
};

export type ChatRoomPayload = {
  __typename?: 'ChatRoomPayload';
  id: Scalars['ID'];
  op?: Maybe<Scalars['String']>;
  users: Array<Maybe<ChatUser>>;
  sortedIdList?: Maybe<Array<Maybe<Scalars['ID']>>>;
  messageLog?: Maybe<Array<Maybe<ChatMessage>>>;
  isTyping?: Maybe<Array<Maybe<User>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isGroup?: Maybe<Scalars['Boolean']>;
  roomName?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['ID']>;
};

export type ChatSeenPayload = {
  __typename?: 'ChatSeenPayload';
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  profilePicUrl?: Maybe<Scalars['String']>;
  lastSeenMessage?: Maybe<Scalars['ID']>;
  isTyping?: Maybe<Scalars['Boolean']>;
};

export type ChatUser = {
  __typename?: 'ChatUser';
  id: Scalars['ID'];
  user?: Maybe<User>;
  messageLog?: Maybe<Array<Maybe<ChatMessage>>>;
  lastSeenMessage?: Maybe<ChatMessage>;
  unsentMessage?: Maybe<Scalars['String']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  isTyping?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
};

export type Community = {
  __typename?: 'Community';
  id: Scalars['ID'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  maxUsers: Scalars['Int'];
  maxGroups: Scalars['Int'];
  members?: Maybe<Array<Maybe<User>>>;
  facilitators?: Maybe<Array<Maybe<User>>>;
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
  plan?: Maybe<Plan>;
};

export enum CurrencyCode {
  Cad = 'CAD',
  Usd = 'USD'
}


export type DimensionStatsPayload = {
  __typename?: 'DimensionStatsPayload';
  id?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  userCount?: Maybe<Scalars['Int']>;
};

export type Dimensions = {
  __typename?: 'Dimensions';
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type DimensionsInput = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type Discussion = {
  __typename?: 'Discussion';
  id: Scalars['ID'];
  question: Scalars['String'];
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type Element = {
  __typename?: 'Element';
  tag: Scalars['String'];
  content: Scalars['String'];
};

export type Error = {
  __typename?: 'Error';
  path?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ErrorLog = {
  __typename?: 'ErrorLog';
  id: Scalars['ID'];
  error?: Maybe<Scalars['String']>;
  componentStack?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  boundary?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userMessage?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  createdAt: Scalars['DateISO'];
  updatedAt: Scalars['DateISO'];
};

export type Faq = {
  __typename?: 'Faq';
  id: Scalars['ID'];
  question: Scalars['String'];
  answer: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['DateISO'];
  frequency: Scalars['String'];
  room: Scalars['String'];
  program: Scalars['String'];
  owner?: Maybe<User>;
  regLinks?: Maybe<Array<Maybe<RegLink>>>;
  community?: Maybe<Community>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  facilitators?: Maybe<Array<Maybe<User>>>;
  inviteRequests?: Maybe<Array<Maybe<User>>>;
  members?: Maybe<Array<Maybe<User>>>;
  errors?: Maybe<Array<Maybe<Error>>>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDirect?: Maybe<Scalars['Boolean']>;
  isFrozen?: Maybe<Scalars['Boolean']>;
  phantomEnd?: Maybe<Scalars['DateISO']>;
  createdAt: Scalars['DateISO'];
  usedThemes?: Maybe<Array<Maybe<UsedThemes>>>;
};

export type JavaTip = {
  __typename?: 'JavaTip';
  id: Scalars['ID'];
  author?: Maybe<User>;
  body: Scalars['String'];
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  token: Scalars['String'];
};

export type MemberFeedback = {
  __typename?: 'MemberFeedback';
  createdBy?: Maybe<User>;
  emotion?: Maybe<Scalars['String']>;
  currentScreen?: Maybe<Scalars['Int']>;
  songPlaying?: Maybe<Scalars['String']>;
  videoChatStatus?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  createAdmin?: Maybe<AdminPayload>;
  editAdmin?: Maybe<AdminPayload>;
  deleteAdmin?: Maybe<AdminPayload>;
  addAnswer?: Maybe<Questionnaire>;
  editAnswer?: Maybe<Questionnaire>;
  deleteAnswer?: Maybe<Questionnaire>;
  removeAnswer?: Maybe<Questionnaire>;
  login?: Maybe<LoginResponse>;
  resetPassword?: Maybe<ResetResponse>;
  sendResetEmail?: Maybe<Scalars['Boolean']>;
  revokeRefreshTokenForUser?: Maybe<Scalars['Boolean']>;
  switchUser?: Maybe<LoginResponse>;
  logout?: Maybe<Scalars['Boolean']>;
  changePassword?: Maybe<Scalars['Boolean']>;
  changePasswordByReset?: Maybe<Scalars['Boolean']>;
  addChatRoom?: Maybe<ChatRoom>;
  addUserToChatRoom?: Maybe<ChatRoom>;
  removeUserFromChatRoom?: Maybe<ChatRoom>;
  sendMessage?: Maybe<ChatRoom>;
  seeMessage?: Maybe<ChatRoom>;
  editTyping?: Maybe<ChatRoom>;
  setUnsentMessage?: Maybe<ChatRoom>;
  deleteMessage?: Maybe<ChatRoom>;
  hideMessage?: Maybe<ChatRoom>;
  deleteChatRoom?: Maybe<ChatRoom>;
  addCommunity?: Maybe<Community>;
  editCommunity?: Maybe<Community>;
  deleteCommunity?: Maybe<Community>;
  addUserToCommunity?: Maybe<Community>;
  removeUserFromCommunity?: Maybe<Community>;
  addDiscussionToTheme?: Maybe<Theme>;
  editDiscussionFromTheme?: Maybe<Theme>;
  deleteDiscussion?: Maybe<Theme>;
  removeDiscussion?: Maybe<Theme>;
  addErrorLog?: Maybe<ErrorLog>;
  addUserMessageToErrorLog?: Maybe<ErrorLog>;
  addFaq?: Maybe<Faq>;
  editFaq?: Maybe<Faq>;
  deleteFaq?: Maybe<Faq>;
  addGroup?: Maybe<Group>;
  editGroup?: Maybe<Group>;
  deleteGroup?: Maybe<Group>;
  freezeGroup?: Maybe<Group>;
  toggleDirect?: Maybe<Group>;
  addRegLinkToGroup?: Maybe<Group>;
  removeRegLinkFromGroup?: Maybe<Group>;
  addUserToGroup?: Maybe<Group>;
  addTagToGroup?: Maybe<Group>;
  removeUserFromGroup?: Maybe<Group>;
  removeTagFromGroup?: Maybe<Group>;
  denyRequest?: Maybe<Group>;
  requestInvite?: Maybe<Group>;
  addJavaTipToTheme?: Maybe<Theme>;
  editJavaTip?: Maybe<Theme>;
  deleteJavaTip?: Maybe<JavaTip>;
  removeJavaTip?: Maybe<Theme>;
  addNoteForUser?: Maybe<User>;
  editNoteFromUser?: Maybe<User>;
  deleteNote?: Maybe<User>;
  removeNoteFromUser?: Maybe<User>;
  addNotification?: Maybe<Notification>;
  editNotification?: Maybe<Notification>;
  markSeenNotifications?: Maybe<Scalars['Boolean']>;
  deleteNotification?: Maybe<Notification>;
  enterOpenChatRoom?: Maybe<OpenChatRoom>;
  leaveOpenChatRoom?: Maybe<OpenChatRoom>;
  beHereNow?: Maybe<OpenChatRoom>;
  nextOrAddOpenChatRoom?: Maybe<OpenChatRoom>;
  sendMessageToOpenChat?: Maybe<OpenChatRoom>;
  deleteOpenChatRoom?: Maybe<OpenChatRoom>;
  createPlan?: Maybe<Plan>;
  editPlan?: Maybe<Plan>;
  subscribeToPlan?: Maybe<Scalars['String']>;
  addQuestionnaire?: Maybe<Questionnaire>;
  addQuestionnaireCommunity?: Maybe<Questionnaire>;
  editQuestionnaireCommunity?: Maybe<Questionnaire>;
  editQuestionnaire?: Maybe<Questionnaire>;
  deleteQuestionnaire?: Maybe<Questionnaire>;
  addQuoteToTheme?: Maybe<Theme>;
  editQuote?: Maybe<Theme>;
  deleteQuote?: Maybe<Theme>;
  removeQuote?: Maybe<Theme>;
  addReflectionToTheme?: Maybe<Theme>;
  editReflectionFromTheme?: Maybe<Theme>;
  deleteReflection?: Maybe<Theme>;
  removeReflection?: Maybe<Theme>;
  addRegLink?: Maybe<RegLink>;
  editRegLink?: Maybe<RegLink>;
  addGroupToRegLink?: Maybe<RegLink>;
  removeGroupFromRegLink?: Maybe<RegLink>;
  deleteRegLink?: Maybe<RegLink>;
  addElement?: Maybe<Resource>;
  addResource?: Maybe<Resource>;
  editResource?: Maybe<Resource>;
  deleteResource?: Maybe<Resource>;
  addSensoryCueToTheme?: Maybe<Theme>;
  editSensoryCue?: Maybe<Theme>;
  deleteSensoryCue?: Maybe<SensoryCue>;
  removeSensoryCue?: Maybe<SensoryCue>;
  addSession?: Maybe<Session>;
  addSessions?: Maybe<Session>;
  editSession?: Maybe<Session>;
  deleteSession?: Maybe<Session>;
  handoverMaster?: Maybe<Session>;
  addUserToSession?: Maybe<Session>;
  removeUserFromSession?: Maybe<Session>;
  changeTheme?: Maybe<Session>;
  changeDateTime?: Maybe<Session>;
  addUserToWaitingRoom?: Maybe<Session>;
  removeUserFromWaitingRoom?: Maybe<Session>;
  editRunningSession?: Maybe<Session>;
  startSession?: Maybe<Session>;
  stopSession?: Maybe<Session>;
  addMemberFeedback?: Maybe<Session>;
  editSessionMemberRole?: Maybe<Session>;
  editSessionMemberMuteState?: Maybe<Session>;
  editAllMuteState?: Maybe<Session>;
  editSessionMemberVidState?: Maybe<Session>;
  editSessionMemberPalamiState?: Maybe<Session>;
  editSessionMemberMirrored?: Maybe<Session>;
  editSessionMemberRoomState?: Maybe<Session>;
  triggerHeadCount?: Maybe<Scalars['Boolean']>;
  editSessionMemberSharing?: Maybe<Session>;
  editAllRoomState?: Maybe<Session>;
  editAllVidState?: Maybe<Session>;
  setLastActive?: Maybe<Scalars['Boolean']>;
  leaveSession?: Maybe<Scalars['Boolean']>;
  addSong?: Maybe<Song>;
  editSong?: Maybe<Song>;
  deleteSong?: Maybe<Song>;
  addTheme?: Maybe<Theme>;
  editTheme?: Maybe<Theme>;
  deleteTheme?: Maybe<Theme>;
  addSongToTheme?: Maybe<Theme>;
  removeSongFromTheme?: Maybe<Theme>;
  addQuote?: Maybe<Theme>;
  editQuoteInTheme?: Maybe<Theme>;
  removeQuoteFromTheme?: Maybe<Theme>;
  addTraining?: Maybe<Training>;
  editTraining?: Maybe<Training>;
  deleteTraining?: Maybe<Training>;
  addOrEditUiMetrics?: Maybe<Scalars['Boolean']>;
  /** Create a new user */
  addUser?: Maybe<User>;
  /** Create a new user and add them to a community and groups using a registration link */
  addUserAndPipeGroup?: Maybe<User>;
  editUser?: Maybe<User>;
  addProfilePic?: Maybe<User>;
  editRole?: Maybe<User>;
  sendRequest?: Maybe<User>;
  actionRequest?: Maybe<User>;
  blockUser?: Maybe<User>;
  unfriendUser?: Maybe<User>;
  editDefaults?: Maybe<User>;
  switchPrimeCommunity?: Maybe<User>;
  deleteUser?: Maybe<User>;
  confirmEmail?: Maybe<User>;
  sendConfirmationEmail?: Maybe<Scalars['Boolean']>;
  editVersion?: Maybe<Version>;
};


export type MutationCreateAdminArgs = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationEditAdminArgs = {
  id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  oldPassword?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationDeleteAdminArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationAddAnswerArgs = {
  member: Scalars['ID'];
  session?: Maybe<Scalars['ID']>;
  stage: Scalars['String'];
  section?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  body: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
  textAns?: Maybe<Scalars['String']>;
};


export type MutationEditAnswerArgs = {
  id: Scalars['ID'];
  questionnaire: Scalars['ID'];
  number: Scalars['Int'];
  body: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
  textAns?: Maybe<Scalars['String']>;
};


export type MutationDeleteAnswerArgs = {
  id: Scalars['ID'];
  questionnaire: Scalars['ID'];
};


export type MutationRemoveAnswerArgs = {
  id: Scalars['ID'];
  questionnaire: Scalars['ID'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  passReset: Scalars['String'];
};


export type MutationSendResetEmailArgs = {
  email: Scalars['String'];
};


export type MutationRevokeRefreshTokenForUserArgs = {
  id: Scalars['ID'];
};


export type MutationSwitchUserArgs = {
  user: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationChangePasswordByResetArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationAddChatRoomArgs = {
  users: Array<Maybe<Scalars['ID']>>;
  roomName?: Maybe<Scalars['String']>;
  isGroup?: Maybe<Scalars['Boolean']>;
};


export type MutationAddUserToChatRoomArgs = {
  id: Scalars['ID'];
  user: Array<Maybe<Scalars['ID']>>;
};


export type MutationRemoveUserFromChatRoomArgs = {
  id: Scalars['ID'];
  user: Array<Maybe<Scalars['ID']>>;
};


export type MutationSendMessageArgs = {
  id: Scalars['ID'];
  message: Scalars['String'];
  from: Scalars['ID'];
  replyTo?: Maybe<Scalars['ID']>;
  sentAt: Scalars['DateISO'];
};


export type MutationSeeMessageArgs = {
  id: Scalars['ID'];
  message: Scalars['ID'];
};


export type MutationEditTypingArgs = {
  id: Scalars['ID'];
  areThey: Scalars['Boolean'];
};


export type MutationSetUnsentMessageArgs = {
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};


export type MutationDeleteMessageArgs = {
  id: Scalars['ID'];
  message: Scalars['ID'];
};


export type MutationHideMessageArgs = {
  id: Scalars['ID'];
  message: Scalars['ID'];
};


export type MutationDeleteChatRoomArgs = {
  id: Scalars['ID'];
};


export type MutationAddCommunityArgs = {
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  maxUsers: Scalars['Int'];
  maxGroups: Scalars['Int'];
};


export type MutationEditCommunityArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  maxUsers?: Maybe<Scalars['Int']>;
  maxGroups?: Maybe<Scalars['Int']>;
};


export type MutationDeleteCommunityArgs = {
  id: Scalars['ID'];
};


export type MutationAddUserToCommunityArgs = {
  user: Scalars['ID'];
  as: Scalars['String'];
  community: Scalars['ID'];
};


export type MutationRemoveUserFromCommunityArgs = {
  user: Scalars['ID'];
  as: Scalars['String'];
  community: Scalars['ID'];
};


export type MutationAddDiscussionToThemeArgs = {
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
};


export type MutationEditDiscussionFromThemeArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
};


export type MutationDeleteDiscussionArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationRemoveDiscussionArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddErrorLogArgs = {
  error?: Maybe<Scalars['String']>;
  componentStack?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  boundary?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userMessage?: Maybe<Scalars['String']>;
};


export type MutationAddUserMessageToErrorLogArgs = {
  id: Scalars['ID'];
  userMessage?: Maybe<Scalars['String']>;
};


export type MutationAddFaqArgs = {
  answer: Scalars['String'];
  question?: Maybe<Scalars['String']>;
};


export type MutationEditFaqArgs = {
  id: Scalars['ID'];
  answer: Scalars['String'];
  question?: Maybe<Scalars['String']>;
};


export type MutationDeleteFaqArgs = {
  id: Scalars['ID'];
};


export type MutationAddGroupArgs = {
  name: Scalars['String'];
  startDate: Scalars['String'];
  frequency: Scalars['String'];
  room: Scalars['String'];
  community?: Maybe<Scalars['ID']>;
  program: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  isPublic: Scalars['Boolean'];
  isDirect?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationEditGroupArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateISO']>;
  frequency?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  community?: Maybe<Scalars['ID']>;
  program?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDirect?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationDeleteGroupArgs = {
  id: Scalars['ID'];
};


export type MutationFreezeGroupArgs = {
  id: Scalars['ID'];
  siONo?: Maybe<Scalars['Boolean']>;
};


export type MutationToggleDirectArgs = {
  id: Scalars['ID'];
  siONo?: Maybe<Scalars['Boolean']>;
};


export type MutationAddRegLinkToGroupArgs = {
  id: Scalars['ID'];
  link: Scalars['ID'];
};


export type MutationRemoveRegLinkFromGroupArgs = {
  id: Scalars['ID'];
  link: Scalars['ID'];
};


export type MutationAddUserToGroupArgs = {
  user: Scalars['ID'];
  as: Scalars['String'];
  group: Scalars['ID'];
};


export type MutationAddTagToGroupArgs = {
  tag: Scalars['String'];
  group: Scalars['ID'];
};


export type MutationRemoveUserFromGroupArgs = {
  user: Scalars['ID'];
  as: Scalars['String'];
  group: Scalars['ID'];
};


export type MutationRemoveTagFromGroupArgs = {
  tag: Scalars['String'];
  group: Scalars['ID'];
};


export type MutationDenyRequestArgs = {
  user: Scalars['ID'];
  group: Scalars['ID'];
};


export type MutationRequestInviteArgs = {
  user: Scalars['ID'];
  group: Scalars['ID'];
};


export type MutationAddJavaTipToThemeArgs = {
  theme: Scalars['ID'];
  author?: Maybe<Scalars['ID']>;
  body: Scalars['String'];
};


export type MutationEditJavaTipArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
  body?: Maybe<Scalars['String']>;
};


export type MutationDeleteJavaTipArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationRemoveJavaTipArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddNoteForUserArgs = {
  author: Scalars['ID'];
  user: Scalars['ID'];
  content: Scalars['String'];
};


export type MutationEditNoteFromUserArgs = {
  id: Scalars['ID'];
  user: Scalars['ID'];
  content: Scalars['String'];
};


export type MutationDeleteNoteArgs = {
  id: Scalars['ID'];
  user: Scalars['ID'];
};


export type MutationRemoveNoteFromUserArgs = {
  id: Scalars['ID'];
  user: Scalars['ID'];
};


export type MutationAddNotificationArgs = {
  owner: Scalars['ID'];
  type: Scalars['String'];
  user?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['ID']>;
  session?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
};


export type MutationEditNotificationArgs = {
  id: Scalars['ID'];
  owner?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['ID']>;
  session?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  seenEh?: Maybe<Scalars['Boolean']>;
  actionedEh?: Maybe<Scalars['Boolean']>;
};


export type MutationMarkSeenNotificationsArgs = {
  notifs: Array<Maybe<Scalars['ID']>>;
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationEnterOpenChatRoomArgs = {
  id: Scalars['ID'];
};


export type MutationLeaveOpenChatRoomArgs = {
  id: Scalars['ID'];
};


export type MutationBeHereNowArgs = {
  id: Scalars['ID'];
};


export type MutationNextOrAddOpenChatRoomArgs = {
  theme: Scalars['ID'];
};


export type MutationSendMessageToOpenChatArgs = {
  id: Scalars['ID'];
  message: Scalars['String'];
  from: Scalars['ID'];
  replyTo?: Maybe<Scalars['ID']>;
  sentAt: Scalars['DateISO'];
};


export type MutationDeleteOpenChatRoomArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePlanArgs = {
  name?: Maybe<Scalars['String']>;
  maxGroups?: Maybe<Scalars['Int']>;
  maxUsers?: Maybe<Scalars['Int']>;
  showPlan?: Maybe<Scalars['Boolean']>;
  stripePriceData?: Maybe<Array<Maybe<PriceDataInput>>>;
  currency?: Maybe<CurrencyCode>;
};


export type MutationEditPlanArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  maxGroups?: Maybe<Scalars['Int']>;
  maxUsers?: Maybe<Scalars['Int']>;
  showPlan?: Maybe<Scalars['Boolean']>;
  stripePriceData?: Maybe<Array<Maybe<PriceDataInput>>>;
  currency?: Maybe<CurrencyCode>;
};


export type MutationSubscribeToPlanArgs = {
  id?: Maybe<Scalars['ID']>;
  planId?: Maybe<Scalars['String']>;
  priceId?: Maybe<Scalars['String']>;
};


export type MutationAddQuestionnaireArgs = {
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage: Scalars['String'];
  optOut: Scalars['Boolean'];
  isDone?: Maybe<Scalars['Boolean']>;
};


export type MutationAddQuestionnaireCommunityArgs = {
  member: Scalars['ID'];
  community: Scalars['ID'];
  stage: Scalars['String'];
  openDate?: Maybe<Scalars['DateISO']>;
  optOut: Scalars['Boolean'];
  isDone?: Maybe<Scalars['Boolean']>;
};


export type MutationEditQuestionnaireCommunityArgs = {
  id: Scalars['ID'];
  member?: Maybe<Scalars['ID']>;
  community?: Maybe<Scalars['ID']>;
  stage?: Maybe<Scalars['String']>;
  openDate?: Maybe<Scalars['DateISO']>;
  optOut: Scalars['Boolean'];
  isDone?: Maybe<Scalars['Boolean']>;
};


export type MutationEditQuestionnaireArgs = {
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage: Scalars['String'];
  optOut?: Maybe<Scalars['Boolean']>;
  isDone?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteQuestionnaireArgs = {
  id: Scalars['ID'];
};


export type MutationAddQuoteToThemeArgs = {
  theme: Scalars['ID'];
  author?: Maybe<Scalars['String']>;
  quote: Scalars['String'];
};


export type MutationEditQuoteArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
  author?: Maybe<Scalars['String']>;
  quote?: Maybe<Scalars['String']>;
};


export type MutationDeleteQuoteArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationRemoveQuoteArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddReflectionToThemeArgs = {
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
};


export type MutationEditReflectionFromThemeArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
};


export type MutationDeleteReflectionArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationRemoveReflectionArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddRegLinkArgs = {
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  community?: Maybe<Scalars['ID']>;
  link: Scalars['String'];
};


export type MutationEditRegLinkArgs = {
  id: Scalars['ID'];
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  community?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
};


export type MutationAddGroupToRegLinkArgs = {
  id: Scalars['ID'];
  group: Scalars['ID'];
};


export type MutationRemoveGroupFromRegLinkArgs = {
  id: Scalars['ID'];
  group: Scalars['ID'];
};


export type MutationDeleteRegLinkArgs = {
  id: Scalars['ID'];
};


export type MutationAddElementArgs = {
  id: Scalars['ID'];
  tag: Scalars['String'];
  content: Scalars['String'];
};


export type MutationAddResourceArgs = {
  category: Scalars['String'];
  thumb: Scalars['String'];
  text?: Maybe<Array<Maybe<Scalars['String']>>>;
  link: Scalars['String'];
};


export type MutationEditResourceArgs = {
  id: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['String']>;
  text?: Maybe<Array<Maybe<Scalars['String']>>>;
  link?: Maybe<Scalars['String']>;
};


export type MutationDeleteResourceArgs = {
  id: Scalars['ID'];
};


export type MutationAddSensoryCueToThemeArgs = {
  theme: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
  imageUrl: Scalars['String'];
};


export type MutationEditSensoryCueArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};


export type MutationDeleteSensoryCueArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationRemoveSensoryCueArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddSessionArgs = {
  date: Scalars['DateISO'];
  group: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddSessionsArgs = {
  date: Scalars['DateISO'];
  group: Scalars['ID'];
  theme: Scalars['ID'];
  freq: Scalars['String'];
  isDirect?: Maybe<Scalars['Boolean']>;
};


export type MutationEditSessionArgs = {
  id: Scalars['ID'];
  date?: Maybe<Scalars['DateISO']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteSessionArgs = {
  id: Scalars['ID'];
};


export type MutationHandoverMasterArgs = {
  session: Scalars['ID'];
  newMaster: Scalars['ID'];
};


export type MutationAddUserToSessionArgs = {
  user: Scalars['ID'];
  as: Scalars['String'];
  session: Scalars['ID'];
};


export type MutationRemoveUserFromSessionArgs = {
  user: Scalars['ID'];
  as: Scalars['String'];
  session: Scalars['ID'];
};


export type MutationChangeThemeArgs = {
  session: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationChangeDateTimeArgs = {
  group: Scalars['ID'];
  date: Scalars['DateISO'];
};


export type MutationAddUserToWaitingRoomArgs = {
  user: Scalars['ID'];
  session: Scalars['ID'];
};


export type MutationRemoveUserFromWaitingRoomArgs = {
  user: Scalars['ID'];
  session: Scalars['ID'];
};


export type MutationEditRunningSessionArgs = {
  id: Scalars['ID'];
  currentScreen?: Maybe<Scalars['Int']>;
  songPlaying?: Maybe<Scalars['String']>;
  videoChatStatus?: Maybe<Scalars['String']>;
  isDirect?: Maybe<Scalars['Boolean']>;
  contentId?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  globalMessage?: Maybe<Scalars['String']>;
};


export type MutationStartSessionArgs = {
  session: Scalars['ID'];
};


export type MutationStopSessionArgs = {
  session: Scalars['ID'];
  boot?: Maybe<Scalars['Boolean']>;
};


export type MutationAddMemberFeedbackArgs = {
  session: Scalars['ID'];
  user: Scalars['ID'];
  emotion: Scalars['String'];
  currentScreen?: Maybe<Scalars['Int']>;
  songPlaying?: Maybe<Scalars['String']>;
  videoChatStatus?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
};


export type MutationEditSessionMemberRoleArgs = {
  session: Scalars['ID'];
  member: Scalars['ID'];
  role: Scalars['String'];
};


export type MutationEditSessionMemberMuteStateArgs = {
  session: Scalars['ID'];
  member: Scalars['ID'];
  muteState: Scalars['Boolean'];
  room: Scalars['Boolean'];
  role: Scalars['String'];
};


export type MutationEditAllMuteStateArgs = {
  session: Scalars['ID'];
  muteState: Scalars['Boolean'];
};


export type MutationEditSessionMemberVidStateArgs = {
  session: Scalars['ID'];
  member: Scalars['ID'];
  vidState: Scalars['Boolean'];
  role: Scalars['String'];
};


export type MutationEditSessionMemberPalamiStateArgs = {
  session: Scalars['ID'];
  member: Scalars['ID'];
  palamiState: Scalars['Boolean'];
  role: Scalars['String'];
};


export type MutationEditSessionMemberMirroredArgs = {
  session: Scalars['ID'];
  member: Scalars['ID'];
  isMirrored: Scalars['Boolean'];
};


export type MutationEditSessionMemberRoomStateArgs = {
  session: Scalars['ID'];
  member: Scalars['ID'];
  roomState: Scalars['Boolean'];
  muteState: Scalars['Boolean'];
  role: Scalars['String'];
};


export type MutationTriggerHeadCountArgs = {
  session: Scalars['ID'];
  trigger: Scalars['Boolean'];
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  ip?: Maybe<Scalars['String']>;
};


export type MutationEditSessionMemberSharingArgs = {
  session: Scalars['ID'];
  member: Scalars['ID'];
  sharePhoto: Scalars['Boolean'];
  shareQuote: Scalars['Boolean'];
  shareSong: Scalars['Boolean'];
  role: Scalars['String'];
};


export type MutationEditAllRoomStateArgs = {
  session: Scalars['ID'];
};


export type MutationEditAllVidStateArgs = {
  session: Scalars['ID'];
  vidState: Scalars['Boolean'];
};


export type MutationSetLastActiveArgs = {
  session: Scalars['ID'];
  member: Scalars['ID'];
  role: Scalars['String'];
  exit?: Maybe<Scalars['Boolean']>;
};


export type MutationLeaveSessionArgs = {
  session: Scalars['ID'];
};


export type MutationAddSongArgs = {
  title: Scalars['String'];
  lyrics: Scalars['String'];
  url: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
};


export type MutationEditSongArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  lyrics?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};


export type MutationDeleteSongArgs = {
  id: Scalars['ID'];
};


export type MutationAddThemeArgs = {
  name: Scalars['String'];
  category: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  program: Scalars['String'];
};


export type MutationEditThemeArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
};


export type MutationDeleteThemeArgs = {
  id: Scalars['ID'];
};


export type MutationAddSongToThemeArgs = {
  song: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationRemoveSongFromThemeArgs = {
  song: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddQuoteArgs = {
  quote: Scalars['String'];
  author: Scalars['String'];
  theme: Scalars['ID'];
};


export type MutationEditQuoteInThemeArgs = {
  quoteId: Scalars['ID'];
  quote: Scalars['String'];
  author: Scalars['String'];
  theme: Scalars['ID'];
};


export type MutationRemoveQuoteFromThemeArgs = {
  quote: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddTrainingArgs = {
  user?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  link: Scalars['String'];
  type: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  step?: Maybe<Scalars['Int']>;
};


export type MutationEditTrainingArgs = {
  id: Scalars['ID'];
  user?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  step?: Maybe<Scalars['Int']>;
};


export type MutationDeleteTrainingArgs = {
  id: Scalars['ID'];
};


export type MutationAddOrEditUiMetricsArgs = {
  user: Scalars['ID'];
  fontSize?: Maybe<Scalars['String']>;
  mirroredVideo?: Maybe<Scalars['Boolean']>;
  innerDimensions?: Maybe<DimensionsInput>;
  outerDimensions?: Maybe<DimensionsInput>;
};


export type MutationAddUserArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  ageGroup?: Maybe<Scalars['String']>;
  living?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  community?: Maybe<Scalars['ID']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['DateISO']>;
  optOut?: Maybe<Scalars['Boolean']>;
};


export type MutationAddUserAndPipeGroupArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  living?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  validUntil?: Maybe<Scalars['DateISO']>;
  optOut?: Maybe<Scalars['Boolean']>;
  community?: Maybe<Scalars['ID']>;
  studyConsent?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationEditUserArgs = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  living?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  community?: Maybe<Scalars['ID']>;
  studyConsent?: Maybe<Scalars['Boolean']>;
  lastQuestionnaire?: Maybe<Scalars['ID']>;
  validUntil?: Maybe<Scalars['DateISO']>;
  dateOfBirth?: Maybe<Scalars['DateISO']>;
  optOut?: Maybe<Scalars['Boolean']>;
};


export type MutationAddProfilePicArgs = {
  pic: Scalars['Upload'];
};


export type MutationEditRoleArgs = {
  id: Scalars['ID'];
  role: Scalars['String'];
};


export type MutationSendRequestArgs = {
  id: Scalars['ID'];
};


export type MutationActionRequestArgs = {
  id: Scalars['ID'];
  action: Scalars['Boolean'];
  notif?: Maybe<Scalars['ID']>;
};


export type MutationBlockUserArgs = {
  id: Scalars['ID'];
};


export type MutationUnfriendUserArgs = {
  id: Scalars['ID'];
};


export type MutationEditDefaultsArgs = {
  id: Scalars['ID'];
  defaultCam?: Maybe<Scalars['Boolean']>;
  defaultMic?: Maybe<Scalars['Boolean']>;
};


export type MutationSwitchPrimeCommunityArgs = {
  id: Scalars['ID'];
  community?: Maybe<Scalars['ID']>;
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationConfirmEmailArgs = {
  id: Scalars['ID'];
  emailConfirmationCode?: Maybe<Scalars['String']>;
};


export type MutationSendConfirmationEmailArgs = {
  id: Scalars['ID'];
};


export type MutationEditVersionArgs = {
  id: Scalars['ID'];
  v: Scalars['String'];
  pass: Scalars['String'];
};

export type Note = {
  __typename?: 'Note';
  id: Scalars['ID'];
  author?: Maybe<User>;
  content: Scalars['String'];
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  owner: User;
  type: Scalars['String'];
  user?: Maybe<User>;
  group?: Maybe<Group>;
  session?: Maybe<Session>;
  message: Scalars['String'];
  seenEh?: Maybe<Scalars['Boolean']>;
  actionedEh?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  createdAt: Scalars['DateISO'];
  updatedAt: Scalars['DateISO'];
};

export type OpenChatMember = {
  __typename?: 'OpenChatMember';
  id: Scalars['ID'];
  user?: Maybe<User>;
  palamiState?: Maybe<Scalars['Boolean']>;
  twilioName?: Maybe<Scalars['String']>;
  lastActive?: Maybe<Scalars['DateISO']>;
};

export type OpenChatMembersPayload = {
  __typename?: 'OpenChatMembersPayload';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  palamiState?: Maybe<Scalars['Boolean']>;
  twilioName?: Maybe<Scalars['String']>;
  profilePicUrl?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lastActive?: Maybe<Scalars['DateISO']>;
};

export type OpenChatPayload = {
  __typename?: 'OpenChatPayload';
  id: Scalars['ID'];
  op?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  from: User;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sentAt?: Maybe<Scalars['DateISO']>;
  createdAt: Scalars['DateISO'];
};

export type OpenChatRoom = {
  __typename?: 'OpenChatRoom';
  id: Scalars['ID'];
  openChatMembers?: Maybe<Array<Maybe<OpenChatMember>>>;
  theme?: Maybe<Theme>;
  messageLog?: Maybe<Array<Maybe<ChatMessage>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type Plan = {
  __typename?: 'Plan';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  maxGroups?: Maybe<Scalars['Int']>;
  maxUsers?: Maybe<Scalars['Int']>;
  showPlan?: Maybe<Scalars['Boolean']>;
  stripePriceData?: Maybe<Array<Maybe<PriceData>>>;
  currency?: Maybe<CurrencyCode>;
};

export type PriceData = {
  __typename?: 'PriceData';
  id?: Maybe<Scalars['ID']>;
  priceId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  interval?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['Boolean']>;
};

export type PriceDataInput = {
  price?: Maybe<Scalars['Int']>;
  interval?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  getAdmin?: Maybe<AdminPayload>;
  answer?: Maybe<Answer>;
  answerList?: Maybe<Array<Maybe<Answer>>>;
  me?: Maybe<User>;
  meLight?: Maybe<User>;
  checkPasswordExist?: Maybe<Scalars['Boolean']>;
  chatRoom?: Maybe<ChatRoom>;
  myChatRooms?: Maybe<Array<Maybe<ChatRoom>>>;
  getUnsentMessage?: Maybe<Scalars['String']>;
  getOlderMessages?: Maybe<Array<Maybe<ChatMessage>>>;
  community?: Maybe<Community>;
  communityByName?: Maybe<Community>;
  communitySearchByName?: Maybe<Community>;
  communities?: Maybe<Array<Maybe<Community>>>;
  errorLogs?: Maybe<Array<Maybe<ErrorLog>>>;
  faqs?: Maybe<Array<Maybe<Faq>>>;
  group?: Maybe<Group>;
  groups?: Maybe<Array<Maybe<Group>>>;
  publicGroups?: Maybe<Array<Maybe<Group>>>;
  groupCountStats?: Maybe<Array<Maybe<Group>>>;
  groupsByTag?: Maybe<Array<Maybe<Group>>>;
  myGroups?: Maybe<Array<Maybe<Group>>>;
  myManagedGroups?: Maybe<Array<Maybe<Group>>>;
  myRequests?: Maybe<Array<Maybe<Group>>>;
  openInviteRequests?: Maybe<Array<Maybe<Group>>>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  unseenNotifications?: Maybe<Array<Maybe<Notification>>>;
  openChatRoom?: Maybe<OpenChatRoom>;
  openChatRoomsByTheme?: Maybe<Array<Maybe<OpenChatRoom>>>;
  openChatRooms?: Maybe<Array<Maybe<OpenChatRoom>>>;
  getPlan?: Maybe<Plan>;
  getPlans?: Maybe<Array<Maybe<Plan>>>;
  getCommunitiesUsingPlan?: Maybe<Array<Maybe<Community>>>;
  getCustomerPortal?: Maybe<Scalars['String']>;
  questionnaire?: Maybe<Questionnaire>;
  questionnaireUserSession?: Maybe<Questionnaire>;
  questionnaireUser?: Maybe<Array<Maybe<Questionnaire>>>;
  questionnaires?: Maybe<Array<Maybe<Questionnaire>>>;
  checkQuestionnaireExists?: Maybe<Scalars['Boolean']>;
  checkQuestionnaireExistsCommunity?: Maybe<Scalars['Boolean']>;
  regLink?: Maybe<RegLink>;
  regLinks?: Maybe<Array<Maybe<RegLink>>>;
  regLinkByLink?: Maybe<RegLink>;
  regLinksByCommunity?: Maybe<Array<Maybe<RegLink>>>;
  regLinkSearch?: Maybe<Array<Maybe<RegLink>>>;
  checkRegLinkExists?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<Resource>;
  resources?: Maybe<Array<Maybe<Resource>>>;
  session?: Maybe<Session>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  closeSessions?: Maybe<Array<Maybe<Session>>>;
  futureSessions?: Maybe<Array<Maybe<Session>>>;
  myFutureSessions?: Maybe<Array<Maybe<Session>>>;
  amIApproved?: Maybe<Scalars['Boolean']>;
  sessionsByGroup?: Maybe<Array<Maybe<Session>>>;
  nextSessionByGroup?: Maybe<Session>;
  song?: Maybe<Song>;
  songs?: Maybe<Array<Maybe<Song>>>;
  theme?: Maybe<Theme>;
  themes?: Maybe<Array<Maybe<Theme>>>;
  themePhotos?: Maybe<Array<Maybe<Theme>>>;
  getThemeAuditById?: Maybe<ThemeAudit>;
  getThemeAuditByThemeId?: Maybe<Array<Maybe<ThemeAudit>>>;
  training?: Maybe<Training>;
  myTrainings?: Maybe<Array<Maybe<Training>>>;
  trainings?: Maybe<Array<Maybe<Training>>>;
  allUiMetrics?: Maybe<Array<Maybe<UiMetrics>>>;
  countUniqueDimensions?: Maybe<Array<Maybe<DimensionStatsPayload>>>;
  user?: Maybe<User>;
  userDefaults?: Maybe<User>;
  userCommunities?: Maybe<User>;
  userByName?: Maybe<User>;
  userByFirstName?: Maybe<User>;
  usersByFuzzyName?: Maybe<Array<Maybe<User>>>;
  users?: Maybe<Array<Maybe<User>>>;
  subUsers?: Maybe<Array<Maybe<User>>>;
  friends?: Maybe<User>;
  friendRequests?: Maybe<User>;
  friendSuggestions?: Maybe<Array<Maybe<User>>>;
  friendForNewChat?: Maybe<Array<Maybe<User>>>;
  checkForPending?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Version>;
};


export type QueryGetAdminArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type QueryAnswerArgs = {
  id: Scalars['ID'];
};


export type QueryCheckPasswordExistArgs = {
  id: Scalars['ID'];
};


export type QueryChatRoomArgs = {
  id: Scalars['ID'];
};


export type QueryGetUnsentMessageArgs = {
  id: Scalars['ID'];
};


export type QueryGetOlderMessagesArgs = {
  id: Scalars['ID'];
  prevLast: Scalars['ID'];
  until?: Maybe<Scalars['ID']>;
};


export type QueryCommunityArgs = {
  id: Scalars['ID'];
};


export type QueryCommunityByNameArgs = {
  name: Scalars['String'];
};


export type QueryCommunitySearchByNameArgs = {
  name: Scalars['String'];
};


export type QueryErrorLogsArgs = {
  page?: Maybe<Scalars['Int']>;
};


export type QueryGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGroupsByTagArgs = {
  tags: Array<Maybe<Scalars['String']>>;
};


export type QueryOpenInviteRequestsArgs = {
  user: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QueryOpenChatRoomArgs = {
  id: Scalars['ID'];
};


export type QueryOpenChatRoomsByThemeArgs = {
  theme: Scalars['ID'];
};


export type QueryGetPlanArgs = {
  id?: Maybe<Scalars['ID']>;
  showPrice?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCommunitiesUsingPlanArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetCustomerPortalArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryQuestionnaireArgs = {
  id: Scalars['ID'];
};


export type QueryQuestionnaireUserSessionArgs = {
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage?: Maybe<Scalars['String']>;
};


export type QueryQuestionnaireUserArgs = {
  member: Scalars['ID'];
  stage?: Maybe<Scalars['String']>;
};


export type QueryCheckQuestionnaireExistsArgs = {
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage: Scalars['String'];
};


export type QueryCheckQuestionnaireExistsCommunityArgs = {
  member: Scalars['ID'];
  community: Scalars['ID'];
  stage: Scalars['String'];
};


export type QueryRegLinkArgs = {
  id: Scalars['ID'];
};


export type QueryRegLinkByLinkArgs = {
  link: Scalars['String'];
};


export type QueryRegLinksByCommunityArgs = {
  community: Scalars['ID'];
};


export type QueryRegLinkSearchArgs = {
  link: Scalars['String'];
};


export type QueryCheckRegLinkExistsArgs = {
  link: Scalars['String'];
};


export type QueryResourceArgs = {
  id: Scalars['ID'];
};


export type QuerySessionArgs = {
  id: Scalars['ID'];
};


export type QueryCloseSessionsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryFutureSessionsArgs = {
  nth: Scalars['Int'];
};


export type QueryMyFutureSessionsArgs = {
  nth: Scalars['Int'];
  directOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryAmIApprovedArgs = {
  session: Scalars['ID'];
};


export type QuerySessionsByGroupArgs = {
  group: Scalars['ID'];
  isPast?: Maybe<Scalars['Boolean']>;
};


export type QueryNextSessionByGroupArgs = {
  group: Scalars['ID'];
};


export type QuerySongArgs = {
  id: Scalars['ID'];
};


export type QueryThemeArgs = {
  id: Scalars['ID'];
};


export type QueryGetThemeAuditByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetThemeAuditByThemeIdArgs = {
  id: Scalars['ID'];
};


export type QueryTrainingArgs = {
  id: Scalars['ID'];
};


export type QueryMyTrainingsArgs = {
  id: Scalars['ID'];
};


export type QueryAllUiMetricsArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountUniqueDimensionsArgs = {
  type?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserDefaultsArgs = {
  id: Scalars['ID'];
};


export type QueryUserCommunitiesArgs = {
  id: Scalars['ID'];
};


export type QueryUserByNameArgs = {
  first?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['String']>;
};


export type QueryUserByFirstNameArgs = {
  firstName?: Maybe<Scalars['String']>;
};


export type QueryUsersByFuzzyNameArgs = {
  name?: Maybe<Scalars['String']>;
  isReqSearch?: Maybe<Scalars['Boolean']>;
};


export type QueryFriendSuggestionsArgs = {
  skip?: Maybe<Scalars['Int']>;
};


export type QueryCheckForPendingArgs = {
  id: Scalars['ID'];
};

export type Questionnaire = {
  __typename?: 'Questionnaire';
  id: Scalars['ID'];
  member: Scalars['ID'];
  session?: Maybe<Scalars['ID']>;
  community?: Maybe<Scalars['ID']>;
  stage: Scalars['String'];
  answers?: Maybe<Array<Maybe<Answer>>>;
  optOut: Scalars['Boolean'];
  isDone?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  openDate?: Maybe<Scalars['DateISO']>;
  createdAt: Scalars['DateISO'];
};

export type Quote = {
  __typename?: 'Quote';
  id: Scalars['ID'];
  author: Scalars['String'];
  quote: Scalars['String'];
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type Reflection = {
  __typename?: 'Reflection';
  id: Scalars['ID'];
  question: Scalars['String'];
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type RegLink = {
  __typename?: 'RegLink';
  id: Scalars['ID'];
  groups?: Maybe<Array<Maybe<Group>>>;
  community?: Maybe<Community>;
  link: Scalars['String'];
  createdAt: Scalars['DateISO'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ResetResponse = {
  __typename?: 'ResetResponse';
  token: Scalars['String'];
};

export type Resource = {
  __typename?: 'Resource';
  id: Scalars['ID'];
  category: Scalars['String'];
  thumb: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Array<Maybe<Scalars['String']>>>;
  link?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type RunningSessionSubscriptionPayload = {
  __typename?: 'RunningSessionSubscriptionPayload';
  sessionMaster?: Maybe<Scalars['ID']>;
  currentScreen?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  isDirect?: Maybe<Scalars['Boolean']>;
  songPlaying?: Maybe<Scalars['String']>;
  videoChatStatus?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
  globalMessage?: Maybe<Scalars['String']>;
};

export type SensoryCue = {
  __typename?: 'SensoryCue';
  id: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
  imageUrl: Scalars['String'];
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type Session = {
  __typename?: 'Session';
  id: Scalars['ID'];
  date: Scalars['DateISO'];
  group: Group;
  program: Scalars['String'];
  theme: Theme;
  facilitators?: Maybe<Array<Maybe<SessionFacilitator>>>;
  members?: Maybe<Array<Maybe<User>>>;
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isFrozen?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
  waitingRoom?: Maybe<Array<Maybe<User>>>;
  sessionMembers?: Maybe<Array<Maybe<SessionMember>>>;
  sessionMaster?: Maybe<User>;
  currentScreen?: Maybe<Scalars['Int']>;
  isDirect?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  songPlaying?: Maybe<Scalars['String']>;
  videoChatStatus?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
  globalMessage?: Maybe<Scalars['String']>;
};

export type SessionFacilitator = {
  __typename?: 'SessionFacilitator';
  id: Scalars['ID'];
  user?: Maybe<User>;
  nickname?: Maybe<Scalars['String']>;
  sessionRole?: Maybe<Scalars['String']>;
  muteState?: Maybe<Scalars['Boolean']>;
  vidState?: Maybe<Scalars['Boolean']>;
  palamiState?: Maybe<Scalars['Boolean']>;
  isMirrored?: Maybe<Scalars['Boolean']>;
  roomState?: Maybe<Scalars['Boolean']>;
  twilioName?: Maybe<Scalars['String']>;
  lastActive?: Maybe<Scalars['DateISO']>;
};

export type SessionMember = {
  __typename?: 'SessionMember';
  id: Scalars['ID'];
  user?: Maybe<User>;
  nickname?: Maybe<Scalars['String']>;
  sessionRole?: Maybe<Scalars['String']>;
  muteState?: Maybe<Scalars['Boolean']>;
  vidState?: Maybe<Scalars['Boolean']>;
  palamiState?: Maybe<Scalars['Boolean']>;
  roomState?: Maybe<Scalars['Boolean']>;
  sharePhoto?: Maybe<Scalars['Boolean']>;
  shareQuote?: Maybe<Scalars['Boolean']>;
  shareSong?: Maybe<Scalars['Boolean']>;
  isMirrored?: Maybe<Scalars['Boolean']>;
  twilioName?: Maybe<Scalars['String']>;
  lastActive?: Maybe<Scalars['DateISO']>;
  feedback?: Maybe<Array<Maybe<MemberFeedback>>>;
};

export type Song = {
  __typename?: 'Song';
  id: Scalars['ID'];
  title: Scalars['String'];
  lyrics: Scalars['String'];
  url: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _empty?: Maybe<Scalars['String']>;
  liveChatRoom?: Maybe<ChatRoomPayload>;
  chatRoomSeen?: Maybe<Array<Maybe<ChatSeenPayload>>>;
  openChatRoomMembers?: Maybe<Array<Maybe<OpenChatMembersPayload>>>;
  openChatMessageLog?: Maybe<OpenChatPayload>;
  sessionModified?: Maybe<RunningSessionSubscriptionPayload>;
  sessionWaitingRoom?: Maybe<Array<Maybe<MiniUser>>>;
  sessionMembers?: Maybe<Array<Maybe<MiniUser>>>;
  sessionFacilitators?: Maybe<Array<Maybe<MiniUser>>>;
  sessionMixedUsers?: Maybe<Array<Maybe<MiniUserMixed>>>;
  sessionHeadCount?: Maybe<TriggerHeadCountPayload>;
};


export type SubscriptionLiveChatRoomArgs = {
  id: Scalars['ID'];
};


export type SubscriptionChatRoomSeenArgs = {
  id: Scalars['ID'];
};


export type SubscriptionOpenChatRoomMembersArgs = {
  id: Scalars['ID'];
};


export type SubscriptionOpenChatMessageLogArgs = {
  id: Scalars['ID'];
};


export type SubscriptionSessionModifiedArgs = {
  id: Scalars['ID'];
};


export type SubscriptionSessionWaitingRoomArgs = {
  id: Scalars['ID'];
};


export type SubscriptionSessionMembersArgs = {
  id: Scalars['ID'];
};


export type SubscriptionSessionFacilitatorsArgs = {
  id: Scalars['ID'];
};


export type SubscriptionSessionMixedUsersArgs = {
  id: Scalars['ID'];
};


export type SubscriptionSessionHeadCountArgs = {
  id: Scalars['ID'];
};

export type Theme = {
  __typename?: 'Theme';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: Scalars['String'];
  program: Scalars['String'];
  imageUrl: Scalars['String'];
  songs?: Maybe<Array<Maybe<Song>>>;
  discussions?: Maybe<Array<Maybe<Discussion>>>;
  reflection?: Maybe<Array<Maybe<Reflection>>>;
  quotes?: Maybe<Array<Maybe<Quote>>>;
  sensoryCues?: Maybe<Array<Maybe<SensoryCue>>>;
  javaTips?: Maybe<Array<Maybe<JavaTip>>>;
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type ThemeAudit = {
  __typename?: 'ThemeAudit';
  id: Scalars['ID'];
  editedBy?: Maybe<AdminPayload>;
  theme?: Maybe<Scalars['ID']>;
  auditedId?: Maybe<Scalars['String']>;
  audited?: Maybe<Scalars['String']>;
  was?: Maybe<Changes>;
  is?: Maybe<Changes>;
  createdAt?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Training = {
  __typename?: 'Training';
  id: Scalars['ID'];
  user?: Maybe<User>;
  name: Scalars['String'];
  link: Scalars['String'];
  type: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  step?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateISO'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type TriggerHeadCountPayload = {
  __typename?: 'TriggerHeadCountPayload';
  trigger?: Maybe<Scalars['Boolean']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  ip?: Maybe<Scalars['String']>;
};

export type UiMetrics = {
  __typename?: 'UiMetrics';
  id: Scalars['ID'];
  user: User;
  fontSize?: Maybe<Scalars['String']>;
  mirroredVideo?: Maybe<Scalars['Boolean']>;
  innerDimensions?: Maybe<Array<Maybe<Dimensions>>>;
  outerDimensions?: Maybe<Array<Maybe<Dimensions>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  createdAt: Scalars['DateISO'];
  updatedAt: Scalars['DateISO'];
};


export type UsedThemes = {
  __typename?: 'UsedThemes';
  theme: Scalars['ID'];
  lastUsed: Scalars['DateISO'];
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalcode?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  living?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateISO']>;
  address?: Maybe<Scalars['String']>;
  profilePicUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  parentUser?: Maybe<Scalars['ID']>;
  friends?: Maybe<Array<Maybe<User>>>;
  existingChat?: Maybe<Array<Maybe<User>>>;
  friendRequests?: Maybe<Array<Maybe<User>>>;
  blockedUsers?: Maybe<Array<Maybe<User>>>;
  groupAsFacilitator?: Maybe<Array<Maybe<Group>>>;
  groupAsMember?: Maybe<Array<Maybe<Group>>>;
  community?: Maybe<Community>;
  communities?: Maybe<Array<Maybe<Community>>>;
  location?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  bio?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<Maybe<Scalars['Int']>>>;
  studyConsent?: Maybe<Scalars['Boolean']>;
  lastQuestionnaire?: Maybe<Questionnaire>;
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateISO']>;
  validUntil?: Maybe<Scalars['DateISO']>;
  optOut?: Maybe<Scalars['Boolean']>;
  emailConfirmationCode?: Maybe<Scalars['String']>;
  emailIsConfirmed?: Maybe<Scalars['Boolean']>;
  defaultCam?: Maybe<Scalars['Boolean']>;
  defaultMic?: Maybe<Scalars['Boolean']>;
};

export type Version = {
  __typename?: 'Version';
  id?: Maybe<Scalars['ID']>;
  v: Scalars['String'];
  readAt: Scalars['DateISO'];
};

export type MiniUser = {
  __typename?: 'miniUser';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  muteState?: Maybe<Scalars['Boolean']>;
  vidState?: Maybe<Scalars['Boolean']>;
  palamiState?: Maybe<Scalars['Boolean']>;
  isMirrored?: Maybe<Scalars['Boolean']>;
  roomState?: Maybe<Scalars['Boolean']>;
  twilioName?: Maybe<Scalars['String']>;
  lastActive?: Maybe<Scalars['DateISO']>;
};

export type MiniUserMixed = {
  __typename?: 'miniUserMixed';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  bio?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<Maybe<Scalars['Int']>>>;
  muteState?: Maybe<Scalars['Boolean']>;
  vidState?: Maybe<Scalars['Boolean']>;
  palamiState?: Maybe<Scalars['Boolean']>;
  isMirrored?: Maybe<Scalars['Boolean']>;
  roomState?: Maybe<Scalars['Boolean']>;
  sharePhoto?: Maybe<Scalars['Boolean']>;
  shareQuote?: Maybe<Scalars['Boolean']>;
  shareSong?: Maybe<Scalars['Boolean']>;
  twilioName?: Maybe<Scalars['String']>;
  profilePicUrl?: Maybe<Scalars['String']>;
  lastActive?: Maybe<Scalars['DateISO']>;
};

export type GroupFragmentFragment = (
  { __typename?: 'Group' }
  & Pick<Group, 'id' | 'name' | 'startDate' | 'frequency' | 'room' | 'isDirect' | 'program' | 'phantomEnd'>
  & { usedThemes?: Maybe<Array<Maybe<(
    { __typename?: 'UsedThemes' }
    & Pick<UsedThemes, 'theme' | 'lastUsed'>
  )>>>, facilitators?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'role'>
    & SmallUserFragmentFragment
  )>>>, members?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'role'>
    & SmallUserFragmentFragment
  )>>> }
);

export type DimensionsFragmentFragment = (
  { __typename?: 'DimensionStatsPayload' }
  & Pick<DimensionStatsPayload, 'id' | 'width' | 'height' | 'userCount'>
);

export type FriendFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'email'>
  & SmallUserFragmentFragment
);

export type MeLightFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'email'>
  & SmallUserFragmentFragment
);

export type MiniUserFragmentFragment = (
  { __typename?: 'miniUser' }
  & Pick<MiniUser, 'id' | 'firstName' | 'lastName' | 'nickname'>
);

export type PlanFragmentFragment = (
  { __typename?: 'Plan' }
  & Pick<Plan, 'id' | 'name' | 'productId' | 'maxGroups' | 'maxUsers' | 'showPlan' | 'currency'>
  & { stripePriceData?: Maybe<Array<Maybe<(
    { __typename?: 'PriceData' }
    & Pick<PriceData, 'id' | 'priceId' | 'price' | 'interval' | 'show'>
  )>>> }
);

export type SessionFragmentFragment = (
  { __typename?: 'Session' }
  & Pick<Session, 'id' | 'date' | 'program' | 'status' | 'isDirect'>
  & { sessionMaster?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>, facilitators?: Maybe<Array<Maybe<(
    { __typename?: 'SessionFacilitator' }
    & Pick<SessionFacilitator, 'nickname' | 'sessionRole' | 'muteState' | 'vidState' | 'palamiState' | 'roomState'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'bio' | 'interests'>
      & SmallUserFragmentFragment
    )> }
  )>>> }
);

export type SessionMemberFragmentFragment = (
  { __typename?: 'SessionMember' }
  & Pick<SessionMember, 'nickname' | 'lastActive' | 'sessionRole' | 'muteState' | 'vidState' | 'palamiState' | 'roomState'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'bio' | 'interests'>
    & SmallUserFragmentFragment
  )> }
);

export type SmallUserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'profilePicUrl'>
);

export type ThemeAuditChangesFragmentFragment = (
  { __typename?: 'Changes' }
  & Pick<Changes, 'name' | 'category' | 'program' | 'imageUrl'>
  & { songs?: Maybe<Array<Maybe<(
    { __typename?: 'Song' }
    & Pick<Song, 'id' | 'title' | 'lyrics' | 'url' | 'imageUrl'>
  )>>>, discussions?: Maybe<Array<Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'question'>
  )>>>, reflection?: Maybe<Array<Maybe<(
    { __typename?: 'Reflection' }
    & Pick<Reflection, 'question'>
  )>>>, quotes?: Maybe<Array<Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, 'author' | 'quote'>
  )>>>, sensoryCues?: Maybe<Array<Maybe<(
    { __typename?: 'SensoryCue' }
    & Pick<SensoryCue, 'id' | 'title' | 'body' | 'imageUrl'>
  )>>> }
);

export type ThemeAuditFragmentFragment = (
  { __typename?: 'ThemeAudit' }
  & Pick<ThemeAudit, 'id' | 'theme' | 'audited' | 'auditedId' | 'createdAt'>
  & { editedBy?: Maybe<(
    { __typename?: 'AdminPayload' }
    & Pick<AdminPayload, 'firstName' | 'lastName'>
  )>, was?: Maybe<(
    { __typename?: 'Changes' }
    & ThemeAuditChangesFragmentFragment
  )>, is?: Maybe<(
    { __typename?: 'Changes' }
    & ThemeAuditChangesFragmentFragment
  )> }
);

export type ThemeFragmentFragment = (
  { __typename?: 'Theme' }
  & Pick<Theme, 'id' | 'name' | 'category' | 'imageUrl' | 'program'>
  & { discussions?: Maybe<Array<Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id' | 'question' | 'isDeleted'>
  )>>>, reflection?: Maybe<Array<Maybe<(
    { __typename?: 'Reflection' }
    & Pick<Reflection, 'id' | 'question' | 'isDeleted'>
  )>>>, songs?: Maybe<Array<Maybe<(
    { __typename?: 'Song' }
    & Pick<Song, 'id' | 'title' | 'lyrics' | 'url' | 'imageUrl' | 'isDeleted'>
  )>>>, quotes?: Maybe<Array<Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, 'id' | 'author' | 'quote' | 'isDeleted'>
  )>>>, sensoryCues?: Maybe<Array<Maybe<(
    { __typename?: 'SensoryCue' }
    & Pick<SensoryCue, 'id' | 'title' | 'body' | 'imageUrl' | 'isDeleted'>
  )>>> }
);

export type ActionRequestMutationVariables = Exact<{
  id: Scalars['ID'];
  action: Scalars['Boolean'];
  notif?: Maybe<Scalars['ID']>;
}>;


export type ActionRequestMutation = (
  { __typename?: 'Mutation' }
  & { actionRequest?: Maybe<(
    { __typename?: 'User' }
    & { friendRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email'>
      & SmallUserFragmentFragment
    )>>> }
  )> }
);

export type AddAnswerMutationVariables = Exact<{
  member: Scalars['ID'];
  session?: Maybe<Scalars['ID']>;
  stage: Scalars['String'];
  section?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  body: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
  textAns?: Maybe<Scalars['String']>;
}>;


export type AddAnswerMutation = (
  { __typename?: 'Mutation' }
  & { addAnswer?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id'>
  )> }
);

export type AddChatRoomMutationVariables = Exact<{
  users: Array<Maybe<Scalars['ID']>>;
  roomName?: Maybe<Scalars['String']>;
  isGroup?: Maybe<Scalars['Boolean']>;
}>;


export type AddChatRoomMutation = (
  { __typename?: 'Mutation' }
  & { addChatRoom?: Maybe<(
    { __typename?: 'ChatRoom' }
    & Pick<ChatRoom, 'id' | 'sortedIdList' | 'isDeleted' | 'isGroup' | 'group' | 'roomName'>
    & { users: Array<Maybe<(
      { __typename?: 'ChatUser' }
      & Pick<ChatUser, 'isTyping' | 'isHidden' | 'unsentMessage' | 'isRemoved'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )>, messageLog?: Maybe<Array<Maybe<(
        { __typename?: 'ChatMessage' }
        & Pick<ChatMessage, 'id' | 'message' | 'isSeen' | 'isDeleted' | 'createdAt' | 'sentAt'>
        & { from: (
          { __typename?: 'User' }
          & SmallUserFragmentFragment
        ), replyTo?: Maybe<(
          { __typename?: 'ChatMessage' }
          & Pick<ChatMessage, 'id' | 'message'>
        )> }
      )>>>, lastSeenMessage?: Maybe<(
        { __typename?: 'ChatMessage' }
        & Pick<ChatMessage, 'id'>
      )> }
    )>> }
  )> }
);

export type AddCommunityMutationVariables = Exact<{
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  maxUsers: Scalars['Int'];
  maxGroups: Scalars['Int'];
}>;


export type AddCommunityMutation = (
  { __typename?: 'Mutation' }
  & { addCommunity?: Maybe<(
    { __typename?: 'Community' }
    & Pick<Community, 'name' | 'phone' | 'email' | 'address' | 'maxUsers' | 'maxGroups'>
  )> }
);

export type AddDiscussionToThemeMutationVariables = Exact<{
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
}>;


export type AddDiscussionToThemeMutation = (
  { __typename?: 'Mutation' }
  & { addDiscussionToTheme?: Maybe<(
    { __typename?: 'Theme' }
    & { discussions?: Maybe<Array<Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id' | 'question'>
    )>>> }
  )> }
);

export type AddErrorLogMutationVariables = Exact<{
  error?: Maybe<Scalars['String']>;
  componentStack?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  boundary?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userMessage?: Maybe<Scalars['String']>;
}>;


export type AddErrorLogMutation = (
  { __typename?: 'Mutation' }
  & { addErrorLog?: Maybe<(
    { __typename?: 'ErrorLog' }
    & Pick<ErrorLog, 'id'>
  )> }
);

export type AddFaqMutationVariables = Exact<{
  answer: Scalars['String'];
  question: Scalars['String'];
}>;


export type AddFaqMutation = (
  { __typename?: 'Mutation' }
  & { addFaq?: Maybe<(
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'question' | 'answer'>
  )> }
);

export type AddGroupMutationVariables = Exact<{
  name: Scalars['String'];
  startDate: Scalars['String'];
  frequency: Scalars['String'];
  room: Scalars['String'];
  community?: Maybe<Scalars['ID']>;
  location?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  program: Scalars['String'];
  isPublic: Scalars['Boolean'];
  isDirect?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type AddGroupMutation = (
  { __typename?: 'Mutation' }
  & { addGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
  )> }
);

export type AddJavaTipToThemeMutationVariables = Exact<{
  theme: Scalars['ID'];
  author?: Maybe<Scalars['ID']>;
  body: Scalars['String'];
}>;


export type AddJavaTipToThemeMutation = (
  { __typename?: 'Mutation' }
  & { addJavaTipToTheme?: Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'id'>
    & { javaTips?: Maybe<Array<Maybe<(
      { __typename?: 'JavaTip' }
      & Pick<JavaTip, 'id'>
      & { author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>>> }
  )> }
);

export type AddMemberFeedbackMutationVariables = Exact<{
  session: Scalars['ID'];
  user: Scalars['ID'];
  emotion: Scalars['String'];
  currentScreen?: Maybe<Scalars['Int']>;
  songPlaying?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
}>;


export type AddMemberFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { addMemberFeedback?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type AddNoteForUserMutationVariables = Exact<{
  user: Scalars['ID'];
  author: Scalars['ID'];
  content: Scalars['String'];
}>;


export type AddNoteForUserMutation = (
  { __typename?: 'Mutation' }
  & { addNoteForUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type AddNotificationMutationVariables = Exact<{
  owner: Scalars['ID'];
  type: Scalars['String'];
  user?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['ID']>;
  session?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
}>;


export type AddNotificationMutation = (
  { __typename?: 'Mutation' }
  & { addNotification?: Maybe<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'type' | 'message' | 'seenEh' | 'actionedEh' | 'createdAt'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ), user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id'>
    )>, session?: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'id'>
    )> }
  )> }
);

export type AddOrEditUiMetricsMutationVariables = Exact<{
  user: Scalars['ID'];
  fontSize?: Maybe<Scalars['String']>;
  mirroredVideo?: Maybe<Scalars['Boolean']>;
  innerDimensions?: Maybe<DimensionsInput>;
  outerDimensions?: Maybe<DimensionsInput>;
}>;


export type AddOrEditUiMetricsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addOrEditUiMetrics'>
);

export type AddProfilePicMutationVariables = Exact<{
  pic: Scalars['Upload'];
}>;


export type AddProfilePicMutation = (
  { __typename?: 'Mutation' }
  & { addProfilePic?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'profilePicUrl'>
  )> }
);

export type AddQuestionnaireMutationVariables = Exact<{
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage: Scalars['String'];
  optOut: Scalars['Boolean'];
  isDone: Scalars['Boolean'];
}>;


export type AddQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { addQuestionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'member' | 'session' | 'stage' | 'optOut' | 'isDone'>
  )> }
);

export type AddQuestionnaireCommunityMutationVariables = Exact<{
  member: Scalars['ID'];
  community: Scalars['ID'];
  stage: Scalars['String'];
  optOut: Scalars['Boolean'];
  isDone: Scalars['Boolean'];
}>;


export type AddQuestionnaireCommunityMutation = (
  { __typename?: 'Mutation' }
  & { addQuestionnaireCommunity?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'member' | 'session' | 'stage' | 'optOut' | 'isDone' | 'openDate'>
  )> }
);

export type AddQuoteMutationVariables = Exact<{
  quote: Scalars['String'];
  author: Scalars['String'];
  theme: Scalars['ID'];
}>;


export type AddQuoteMutation = (
  { __typename?: 'Mutation' }
  & { addQuote?: Maybe<(
    { __typename?: 'Theme' }
    & { quotes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'author' | 'quote'>
    )>>> }
  )> }
);

export type AddReflectionToThemeMutationVariables = Exact<{
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
}>;


export type AddReflectionToThemeMutation = (
  { __typename?: 'Mutation' }
  & { addReflectionToTheme?: Maybe<(
    { __typename?: 'Theme' }
    & { reflection?: Maybe<Array<Maybe<(
      { __typename?: 'Reflection' }
      & Pick<Reflection, 'id' | 'question'>
    )>>> }
  )> }
);

export type AddRegLinkMutationVariables = Exact<{
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  community?: Maybe<Scalars['ID']>;
  link: Scalars['String'];
}>;


export type AddRegLinkMutation = (
  { __typename?: 'Mutation' }
  & { addRegLink?: Maybe<(
    { __typename?: 'RegLink' }
    & Pick<RegLink, 'id'>
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>>>, community?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'name'>
    )> }
  )> }
);

export type AddRegLinkToGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  link: Scalars['ID'];
}>;


export type AddRegLinkToGroupMutation = (
  { __typename?: 'Mutation' }
  & { addRegLinkToGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type AddSessionsMutationVariables = Exact<{
  date: Scalars['DateISO'];
  group: Scalars['ID'];
  theme: Scalars['ID'];
  freq: Scalars['String'];
  isDirect: Scalars['Boolean'];
}>;


export type AddSessionsMutation = (
  { __typename?: 'Mutation' }
  & { addSessions?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type AddTagToGroupMutationVariables = Exact<{
  tag: Scalars['String'];
  group: Scalars['ID'];
}>;


export type AddTagToGroupMutation = (
  { __typename?: 'Mutation' }
  & { addTagToGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type AddThemeMutationVariables = Exact<{
  name: Scalars['String'];
  category: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  program: Scalars['String'];
}>;


export type AddThemeMutation = (
  { __typename?: 'Mutation' }
  & { addTheme?: Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'id'>
  )> }
);

export type AddTrainingMutationVariables = Exact<{
  user?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  link: Scalars['String'];
  type: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  step?: Maybe<Scalars['Int']>;
}>;


export type AddTrainingMutation = (
  { __typename?: 'Mutation' }
  & { addTraining?: Maybe<(
    { __typename?: 'Training' }
    & Pick<Training, 'id' | 'name' | 'link' | 'type' | 'status' | 'step'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type AddUserMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  ageGroup?: Maybe<Scalars['String']>;
  living?: Maybe<Scalars['String']>;
  community?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['Boolean']>;
  optOut?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['DateISO']>;
}>;


export type AddUserMutation = (
  { __typename?: 'Mutation' }
  & { addUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type AddUserAndPipeGroupMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  ageGroup?: Maybe<Scalars['String']>;
  living?: Maybe<Scalars['String']>;
  community?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['Boolean']>;
  optOut?: Maybe<Scalars['Boolean']>;
  studyConsent?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  validUntil?: Maybe<Scalars['DateISO']>;
}>;


export type AddUserAndPipeGroupMutation = (
  { __typename?: 'Mutation' }
  & { addUserAndPipeGroup?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type AddUserMessageToErrorLogMutationVariables = Exact<{
  id: Scalars['ID'];
  userMessage?: Maybe<Scalars['String']>;
}>;


export type AddUserMessageToErrorLogMutation = (
  { __typename?: 'Mutation' }
  & { addUserMessageToErrorLog?: Maybe<(
    { __typename?: 'ErrorLog' }
    & Pick<ErrorLog, 'id' | 'userMessage'>
  )> }
);

export type AddUserToChatRoomMutationVariables = Exact<{
  id: Scalars['ID'];
  user: Array<Maybe<Scalars['ID']>>;
}>;


export type AddUserToChatRoomMutation = (
  { __typename?: 'Mutation' }
  & { addUserToChatRoom?: Maybe<(
    { __typename?: 'ChatRoom' }
    & Pick<ChatRoom, 'id'>
  )> }
);

export type AddUserToCommunityMutationVariables = Exact<{
  user: Scalars['ID'];
  as: Scalars['String'];
  community: Scalars['ID'];
}>;


export type AddUserToCommunityMutation = (
  { __typename?: 'Mutation' }
  & { addUserToCommunity?: Maybe<(
    { __typename?: 'Community' }
    & Pick<Community, 'id' | 'name'>
  )> }
);

export type AddUserToGroupMutationVariables = Exact<{
  user: Scalars['ID'];
  as: Scalars['String'];
  group: Scalars['ID'];
}>;


export type AddUserToGroupMutation = (
  { __typename?: 'Mutation' }
  & { addUserToGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type AddUserToSessionMutationVariables = Exact<{
  user: Scalars['ID'];
  session: Scalars['ID'];
  as: Scalars['String'];
}>;


export type AddUserToSessionMutation = (
  { __typename?: 'Mutation' }
  & { addUserToSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
    & { sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role'>
      )> }
    )>>>, facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role'>
      )> }
    )>>> }
  )> }
);

export type AddUserToWaitingRoomMutationVariables = Exact<{
  user: Scalars['ID'];
  session: Scalars['ID'];
}>;


export type AddUserToWaitingRoomMutation = (
  { __typename?: 'Mutation' }
  & { addUserToWaitingRoom?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type BeHereNowMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BeHereNowMutation = (
  { __typename?: 'Mutation' }
  & { beHereNow?: Maybe<(
    { __typename?: 'OpenChatRoom' }
    & Pick<OpenChatRoom, 'id' | 'createdAt'>
    & { openChatMembers?: Maybe<Array<Maybe<(
      { __typename?: 'OpenChatMember' }
      & Pick<OpenChatMember, 'palamiState' | 'twilioName' | 'lastActive'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )> }
    )>>>, theme?: Maybe<(
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'name' | 'category' | 'imageUrl' | 'program'>
    )> }
  )> }
);

export type BlockUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BlockUserMutation = (
  { __typename?: 'Mutation' }
  & { blockUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type ChangeDateTimeMutationVariables = Exact<{
  group: Scalars['ID'];
  date: Scalars['DateISO'];
}>;


export type ChangeDateTimeMutation = (
  { __typename?: 'Mutation' }
  & { changeDateTime?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ChangePasswordByResetMutationVariables = Exact<{
  newPassword: Scalars['String'];
  token: Scalars['String'];
}>;


export type ChangePasswordByResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePasswordByReset'>
);

export type ChangeThemeMutationVariables = Exact<{
  session: Scalars['ID'];
  theme: Scalars['ID'];
}>;


export type ChangeThemeMutation = (
  { __typename?: 'Mutation' }
  & { changeTheme?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type ConfirmEmailMutationVariables = Exact<{
  id: Scalars['ID'];
  emailConfirmationCode?: Maybe<Scalars['String']>;
}>;


export type ConfirmEmailMutation = (
  { __typename?: 'Mutation' }
  & { confirmEmail?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type CreatePlanMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  maxGroups?: Maybe<Scalars['Int']>;
  maxUsers?: Maybe<Scalars['Int']>;
  showPlan?: Maybe<Scalars['Boolean']>;
  stripePriceData?: Maybe<Array<Maybe<PriceDataInput>>>;
  currency?: Maybe<CurrencyCode>;
}>;


export type CreatePlanMutation = (
  { __typename?: 'Mutation' }
  & { createPlan?: Maybe<(
    { __typename?: 'Plan' }
    & PlanFragmentFragment
  )> }
);

export type DeleteAnswerMutationVariables = Exact<{
  id: Scalars['ID'];
  questionnaire: Scalars['ID'];
}>;


export type DeleteAnswerMutation = (
  { __typename?: 'Mutation' }
  & { deleteAnswer?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id'>
  )> }
);

export type DeleteChatRoomMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteChatRoomMutation = (
  { __typename?: 'Mutation' }
  & { deleteChatRoom?: Maybe<(
    { __typename?: 'ChatRoom' }
    & Pick<ChatRoom, 'id'>
  )> }
);

export type DeleteFaqMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteFaqMutation = (
  { __typename?: 'Mutation' }
  & { deleteFaq?: Maybe<(
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'question' | 'answer'>
  )> }
);

export type DeleteGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
  )> }
);

export type DeleteMessageMutationVariables = Exact<{
  id: Scalars['ID'];
  message: Scalars['ID'];
}>;


export type DeleteMessageMutation = (
  { __typename?: 'Mutation' }
  & { deleteMessage?: Maybe<(
    { __typename?: 'ChatRoom' }
    & Pick<ChatRoom, 'id'>
  )> }
);

export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteNotificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteNotification?: Maybe<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id'>
  )> }
);

export type DeleteQuestionnaireMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { deleteQuestionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id'>
  )> }
);

export type DeleteRegLinkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteRegLinkMutation = (
  { __typename?: 'Mutation' }
  & { deleteRegLink?: Maybe<(
    { __typename?: 'RegLink' }
    & Pick<RegLink, 'id'>
  )> }
);

export type DeleteTrainingMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTrainingMutation = (
  { __typename?: 'Mutation' }
  & { deleteTraining?: Maybe<(
    { __typename?: 'Training' }
    & Pick<Training, 'id' | 'name' | 'link' | 'type' | 'status' | 'step'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'nickname'>
  )> }
);

export type DenyRequestMutationVariables = Exact<{
  user: Scalars['ID'];
  group: Scalars['ID'];
}>;


export type DenyRequestMutation = (
  { __typename?: 'Mutation' }
  & { denyRequest?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type EditAllMuteStateMutationVariables = Exact<{
  session: Scalars['ID'];
  muteState: Scalars['Boolean'];
}>;


export type EditAllMuteStateMutation = (
  { __typename?: 'Mutation' }
  & { editAllMuteState?: Maybe<(
    { __typename?: 'Session' }
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & Pick<SessionFacilitator, 'sessionRole' | 'muteState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
      )> }
    )>>>, sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & Pick<SessionMember, 'sessionRole' | 'muteState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
      )> }
    )>>> }
  )> }
);

export type EditAllRoomStateMutationVariables = Exact<{
  session: Scalars['ID'];
}>;


export type EditAllRoomStateMutation = (
  { __typename?: 'Mutation' }
  & { editAllRoomState?: Maybe<(
    { __typename?: 'Session' }
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & Pick<SessionFacilitator, 'sessionRole' | 'muteState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
      )> }
    )>>>, sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & Pick<SessionMember, 'sessionRole' | 'muteState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
      )> }
    )>>> }
  )> }
);

export type EditAllVidStateMutationVariables = Exact<{
  session: Scalars['ID'];
  vidState: Scalars['Boolean'];
}>;


export type EditAllVidStateMutation = (
  { __typename?: 'Mutation' }
  & { editAllVidState?: Maybe<(
    { __typename?: 'Session' }
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & Pick<SessionFacilitator, 'sessionRole' | 'vidState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
      )> }
    )>>>, sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & Pick<SessionMember, 'sessionRole' | 'vidState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
      )> }
    )>>> }
  )> }
);

export type EditAnswerMutationVariables = Exact<{
  id: Scalars['ID'];
  questionnaire: Scalars['ID'];
  number: Scalars['Int'];
  body: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
  textAns?: Maybe<Scalars['String']>;
}>;


export type EditAnswerMutation = (
  { __typename?: 'Mutation' }
  & { editAnswer?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id'>
  )> }
);

export type EditCommunityMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  maxUsers?: Maybe<Scalars['Int']>;
  maxGroups?: Maybe<Scalars['Int']>;
}>;


export type EditCommunityMutation = (
  { __typename?: 'Mutation' }
  & { editCommunity?: Maybe<(
    { __typename?: 'Community' }
    & Pick<Community, 'name' | 'phone' | 'email' | 'address' | 'maxUsers' | 'maxGroups'>
  )> }
);

export type EditDefaultsMutationVariables = Exact<{
  id: Scalars['ID'];
  defaultCam?: Maybe<Scalars['Boolean']>;
  defaultMic?: Maybe<Scalars['Boolean']>;
}>;


export type EditDefaultsMutation = (
  { __typename?: 'Mutation' }
  & { editDefaults?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'defaultCam' | 'defaultMic'>
  )> }
);

export type EditDiscussionFromThemeMutationVariables = Exact<{
  id: Scalars['ID'];
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
}>;


export type EditDiscussionFromThemeMutation = (
  { __typename?: 'Mutation' }
  & { editDiscussionFromTheme?: Maybe<(
    { __typename?: 'Theme' }
    & { discussions?: Maybe<Array<Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id' | 'question'>
    )>>> }
  )> }
);

export type EditFaqMutationVariables = Exact<{
  id: Scalars['ID'];
  answer: Scalars['String'];
  question: Scalars['String'];
}>;


export type EditFaqMutation = (
  { __typename?: 'Mutation' }
  & { editFaq?: Maybe<(
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'question' | 'answer'>
  )> }
);

export type EditGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateISO']>;
  frequency?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  community?: Maybe<Scalars['ID']>;
  location?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDirect?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type EditGroupMutation = (
  { __typename?: 'Mutation' }
  & { editGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
  )> }
);

export type EditNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
  owner?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['ID']>;
  session?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  seenEh?: Maybe<Scalars['Boolean']>;
  actionedEh?: Maybe<Scalars['Boolean']>;
}>;


export type EditNotificationMutation = (
  { __typename?: 'Mutation' }
  & { editNotification?: Maybe<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'type' | 'message' | 'seenEh' | 'actionedEh' | 'createdAt'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ), user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id'>
    )>, session?: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'id'>
    )> }
  )> }
);

export type EditPlanMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  maxGroups?: Maybe<Scalars['Int']>;
  maxUsers?: Maybe<Scalars['Int']>;
  showPlan?: Maybe<Scalars['Boolean']>;
  stripePriceData?: Maybe<Array<Maybe<PriceDataInput>>>;
}>;


export type EditPlanMutation = (
  { __typename?: 'Mutation' }
  & { editPlan?: Maybe<(
    { __typename?: 'Plan' }
    & PlanFragmentFragment
  )> }
);

export type EditQuestionnaireMutationVariables = Exact<{
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage: Scalars['String'];
  optOut?: Maybe<Scalars['Boolean']>;
  isDone?: Maybe<Scalars['Boolean']>;
}>;


export type EditQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { editQuestionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'member' | 'session' | 'stage' | 'optOut' | 'isDone'>
    & { answers?: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'number' | 'body' | 'value' | 'createdAt'>
    )>>> }
  )> }
);

export type EditQuestionnaireCommunityMutationVariables = Exact<{
  id: Scalars['ID'];
  member?: Maybe<Scalars['ID']>;
  community?: Maybe<Scalars['ID']>;
  stage?: Maybe<Scalars['String']>;
  optOut: Scalars['Boolean'];
  isDone?: Maybe<Scalars['Boolean']>;
}>;


export type EditQuestionnaireCommunityMutation = (
  { __typename?: 'Mutation' }
  & { editQuestionnaireCommunity?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'member' | 'session' | 'stage' | 'optOut' | 'isDone'>
    & { answers?: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'number' | 'body' | 'value' | 'createdAt'>
    )>>> }
  )> }
);

export type EditQuoteInThemeMutationVariables = Exact<{
  quoteId: Scalars['ID'];
  quote: Scalars['String'];
  author: Scalars['String'];
  theme: Scalars['ID'];
}>;


export type EditQuoteInThemeMutation = (
  { __typename?: 'Mutation' }
  & { editQuoteInTheme?: Maybe<(
    { __typename?: 'Theme' }
    & { quotes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'author' | 'quote'>
    )>>> }
  )> }
);

export type EditReflectionFromThemeMutationVariables = Exact<{
  id: Scalars['ID'];
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
}>;


export type EditReflectionFromThemeMutation = (
  { __typename?: 'Mutation' }
  & { editReflectionFromTheme?: Maybe<(
    { __typename?: 'Theme' }
    & { reflection?: Maybe<Array<Maybe<(
      { __typename?: 'Reflection' }
      & Pick<Reflection, 'id' | 'question'>
    )>>> }
  )> }
);

export type EditRegLinkMutationVariables = Exact<{
  id: Scalars['ID'];
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  community?: Maybe<Scalars['ID']>;
  link: Scalars['String'];
}>;


export type EditRegLinkMutation = (
  { __typename?: 'Mutation' }
  & { editRegLink?: Maybe<(
    { __typename?: 'RegLink' }
    & Pick<RegLink, 'id'>
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>>>, community?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'name'>
    )> }
  )> }
);

export type EditRoleMutationVariables = Exact<{
  id: Scalars['ID'];
  role: Scalars['String'];
}>;


export type EditRoleMutation = (
  { __typename?: 'Mutation' }
  & { editRole?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'phone' | 'email' | 'dateOfBirth' | 'role' | 'address' | 'status' | 'validUntil'>
  )> }
);

export type EditRunningSessionMutationVariables = Exact<{
  id: Scalars['ID'];
  currentScreen?: Maybe<Scalars['Int']>;
  songPlaying?: Maybe<Scalars['String']>;
  isDirect?: Maybe<Scalars['Boolean']>;
  videoChatStatus?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
  globalMessage?: Maybe<Scalars['String']>;
}>;


export type EditRunningSessionMutation = (
  { __typename?: 'Mutation' }
  & { editRunningSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'status' | 'currentScreen' | 'songPlaying' | 'isDirect' | 'videoChatStatus' | 'contentId' | 'contentType' | 'globalMessage'>
  )> }
);

export type EditSessionMemberMirroredMutationVariables = Exact<{
  session: Scalars['ID'];
  member: Scalars['ID'];
  isMirrored: Scalars['Boolean'];
}>;


export type EditSessionMemberMirroredMutation = (
  { __typename?: 'Mutation' }
  & { editSessionMemberMirrored?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type EditSessionMemberMuteStateMutationVariables = Exact<{
  session: Scalars['ID'];
  member: Scalars['ID'];
  muteState: Scalars['Boolean'];
  room: Scalars['Boolean'];
  role: Scalars['String'];
}>;


export type EditSessionMemberMuteStateMutation = (
  { __typename?: 'Mutation' }
  & { editSessionMemberMuteState?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type EditSessionMemberPalamiStateMutationVariables = Exact<{
  session: Scalars['ID'];
  member: Scalars['ID'];
  palamiState: Scalars['Boolean'];
  role: Scalars['String'];
}>;


export type EditSessionMemberPalamiStateMutation = (
  { __typename?: 'Mutation' }
  & { editSessionMemberPalamiState?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type EditSessionMemberRoleMutationVariables = Exact<{
  session: Scalars['ID'];
  member: Scalars['ID'];
  role: Scalars['String'];
}>;


export type EditSessionMemberRoleMutation = (
  { __typename?: 'Mutation' }
  & { editSessionMemberRole?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
    & { sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & Pick<SessionMember, 'id' | 'sessionRole'>
    )>>> }
  )> }
);

export type EditSessionMemberRoomStateMutationVariables = Exact<{
  session: Scalars['ID'];
  member: Scalars['ID'];
  roomState: Scalars['Boolean'];
  muteState: Scalars['Boolean'];
  role: Scalars['String'];
}>;


export type EditSessionMemberRoomStateMutation = (
  { __typename?: 'Mutation' }
  & { editSessionMemberRoomState?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type EditSessionMemberSharingMutationVariables = Exact<{
  session: Scalars['ID'];
  member: Scalars['ID'];
  sharePhoto: Scalars['Boolean'];
  shareQuote: Scalars['Boolean'];
  shareSong: Scalars['Boolean'];
  role: Scalars['String'];
}>;


export type EditSessionMemberSharingMutation = (
  { __typename?: 'Mutation' }
  & { editSessionMemberSharing?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type EditSessionMemberVidStateMutationVariables = Exact<{
  session: Scalars['ID'];
  member: Scalars['ID'];
  vidState: Scalars['Boolean'];
  role: Scalars['String'];
}>;


export type EditSessionMemberVidStateMutation = (
  { __typename?: 'Mutation' }
  & { editSessionMemberVidState?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type EditSongMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  lyrics?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
}>;


export type EditSongMutation = (
  { __typename?: 'Mutation' }
  & { editSong?: Maybe<(
    { __typename?: 'Song' }
    & Pick<Song, 'id' | 'title' | 'lyrics' | 'url' | 'isDeleted'>
  )> }
);

export type EditThemeMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
}>;


export type EditThemeMutation = (
  { __typename?: 'Mutation' }
  & { editTheme?: Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'id'>
  )> }
);

export type EditTrainingMutationVariables = Exact<{
  id: Scalars['ID'];
  user?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  step?: Maybe<Scalars['Int']>;
}>;


export type EditTrainingMutation = (
  { __typename?: 'Mutation' }
  & { editTraining?: Maybe<(
    { __typename?: 'Training' }
    & Pick<Training, 'id' | 'name' | 'link' | 'type' | 'status' | 'step'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type EditTypingMutationVariables = Exact<{
  id: Scalars['ID'];
  areThey: Scalars['Boolean'];
}>;


export type EditTypingMutation = (
  { __typename?: 'Mutation' }
  & { editTyping?: Maybe<(
    { __typename?: 'ChatRoom' }
    & Pick<ChatRoom, 'id'>
  )> }
);

export type EditUserMutationVariables = Exact<{
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<Maybe<Scalars['Int']>>>;
  location?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  ageGroup?: Maybe<Scalars['String']>;
  living?: Maybe<Scalars['String']>;
  community?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['Boolean']>;
  studyConsent?: Maybe<Scalars['Boolean']>;
  lastQuestionnaire?: Maybe<Scalars['ID']>;
  optOut?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['DateISO']>;
  dateOfBirth?: Maybe<Scalars['DateISO']>;
}>;


export type EditUserMutation = (
  { __typename?: 'Mutation' }
  & { editUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'phone' | 'email' | 'nickname' | 'location' | 'lat' | 'lng' | 'ageGroup' | 'living' | 'status' | 'optOut' | 'validUntil'>
  )> }
);

export type EditVersionMutationVariables = Exact<{
  id: Scalars['ID'];
  v: Scalars['String'];
  pass: Scalars['String'];
}>;


export type EditVersionMutation = (
  { __typename?: 'Mutation' }
  & { editVersion?: Maybe<(
    { __typename?: 'Version' }
    & Pick<Version, 'id' | 'v' | 'readAt'>
  )> }
);

export type EnterOpenChatRoomMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EnterOpenChatRoomMutation = (
  { __typename?: 'Mutation' }
  & { enterOpenChatRoom?: Maybe<(
    { __typename?: 'OpenChatRoom' }
    & Pick<OpenChatRoom, 'id' | 'createdAt'>
    & { openChatMembers?: Maybe<Array<Maybe<(
      { __typename?: 'OpenChatMember' }
      & Pick<OpenChatMember, 'palamiState' | 'twilioName' | 'lastActive'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )> }
    )>>>, theme?: Maybe<(
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'name' | 'category' | 'imageUrl' | 'program'>
    )> }
  )> }
);

export type FreezeGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  siONo?: Maybe<Scalars['Boolean']>;
}>;


export type FreezeGroupMutation = (
  { __typename?: 'Mutation' }
  & { freezeGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
  )> }
);

export type HandoverMasterMutationVariables = Exact<{
  session: Scalars['ID'];
  newMaster: Scalars['ID'];
}>;


export type HandoverMasterMutation = (
  { __typename?: 'Mutation' }
  & { handoverMaster?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date'>
    & { sessionMaster?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, group: (
      { __typename?: 'Group' }
      & Pick<Group, 'id'>
    ) }
  )> }
);

export type HideMessageMutationVariables = Exact<{
  id: Scalars['ID'];
  message: Scalars['ID'];
}>;


export type HideMessageMutation = (
  { __typename?: 'Mutation' }
  & { hideMessage?: Maybe<(
    { __typename?: 'ChatRoom' }
    & Pick<ChatRoom, 'id'>
  )> }
);

export type LeaveOpenChatRoomMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LeaveOpenChatRoomMutation = (
  { __typename?: 'Mutation' }
  & { leaveOpenChatRoom?: Maybe<(
    { __typename?: 'OpenChatRoom' }
    & Pick<OpenChatRoom, 'id' | 'createdAt'>
    & { openChatMembers?: Maybe<Array<Maybe<(
      { __typename?: 'OpenChatMember' }
      & Pick<OpenChatMember, 'palamiState' | 'twilioName' | 'lastActive'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )> }
    )>>>, theme?: Maybe<(
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'name' | 'category' | 'imageUrl' | 'program'>
    )> }
  )> }
);

export type LeaveSessionMutationVariables = Exact<{
  session: Scalars['ID'];
}>;


export type LeaveSessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'leaveSession'>
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type MarkSeenNotificationsMutationVariables = Exact<{
  notifs: Array<Maybe<Scalars['ID']>>;
}>;


export type MarkSeenNotificationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markSeenNotifications'>
);

export type NextOrAddOpenChatRoomMutationVariables = Exact<{
  theme: Scalars['ID'];
}>;


export type NextOrAddOpenChatRoomMutation = (
  { __typename?: 'Mutation' }
  & { nextOrAddOpenChatRoom?: Maybe<(
    { __typename?: 'OpenChatRoom' }
    & Pick<OpenChatRoom, 'id' | 'createdAt'>
    & { openChatMembers?: Maybe<Array<Maybe<(
      { __typename?: 'OpenChatMember' }
      & Pick<OpenChatMember, 'palamiState' | 'twilioName' | 'lastActive'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )> }
    )>>> }
  )> }
);

export type RemoveAnswerMutationVariables = Exact<{
  id: Scalars['ID'];
  questionnaire: Scalars['ID'];
}>;


export type RemoveAnswerMutation = (
  { __typename?: 'Mutation' }
  & { removeAnswer?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id'>
  )> }
);

export type RemoveDiscussionMutationVariables = Exact<{
  id: Scalars['ID'];
  theme: Scalars['ID'];
}>;


export type RemoveDiscussionMutation = (
  { __typename?: 'Mutation' }
  & { removeDiscussion?: Maybe<(
    { __typename?: 'Theme' }
    & { discussions?: Maybe<Array<Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id' | 'question'>
    )>>> }
  )> }
);

export type RemoveJavaTipMutationVariables = Exact<{
  id: Scalars['ID'];
  theme: Scalars['ID'];
}>;


export type RemoveJavaTipMutation = (
  { __typename?: 'Mutation' }
  & { removeJavaTip?: Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'id' | 'isDeleted'>
    & { javaTips?: Maybe<Array<Maybe<(
      { __typename?: 'JavaTip' }
      & Pick<JavaTip, 'id'>
      & { author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>>> }
  )> }
);

export type RemoveQuoteFromThemeMutationVariables = Exact<{
  quote: Scalars['ID'];
  theme: Scalars['ID'];
}>;


export type RemoveQuoteFromThemeMutation = (
  { __typename?: 'Mutation' }
  & { removeQuoteFromTheme?: Maybe<(
    { __typename?: 'Theme' }
    & { quotes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'author' | 'quote'>
    )>>> }
  )> }
);

export type RemoveReflectionMutationVariables = Exact<{
  id: Scalars['ID'];
  theme: Scalars['ID'];
}>;


export type RemoveReflectionMutation = (
  { __typename?: 'Mutation' }
  & { removeReflection?: Maybe<(
    { __typename?: 'Theme' }
    & { reflection?: Maybe<Array<Maybe<(
      { __typename?: 'Reflection' }
      & Pick<Reflection, 'id' | 'question'>
    )>>> }
  )> }
);

export type RemoveRegLinkFromGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  link: Scalars['ID'];
}>;


export type RemoveRegLinkFromGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeRegLinkFromGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type RemoveTagFromGroupMutationVariables = Exact<{
  tag: Scalars['String'];
  group: Scalars['ID'];
}>;


export type RemoveTagFromGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeTagFromGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type RemoveUserFromChatRoomMutationVariables = Exact<{
  id: Scalars['ID'];
  user: Array<Maybe<Scalars['ID']>>;
}>;


export type RemoveUserFromChatRoomMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromChatRoom?: Maybe<(
    { __typename?: 'ChatRoom' }
    & Pick<ChatRoom, 'id'>
  )> }
);

export type RemoveUserFromCommunityMutationVariables = Exact<{
  user: Scalars['ID'];
  as: Scalars['String'];
  community: Scalars['ID'];
}>;


export type RemoveUserFromCommunityMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromCommunity?: Maybe<(
    { __typename?: 'Community' }
    & Pick<Community, 'id' | 'name'>
  )> }
);

export type RemoveUserFromGroupMutationVariables = Exact<{
  user: Scalars['ID'];
  group: Scalars['ID'];
  as: Scalars['String'];
}>;


export type RemoveUserFromGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>>>, members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>>> }
  )> }
);

export type RemoveUserFromSessionMutationVariables = Exact<{
  user: Scalars['ID'];
  session: Scalars['ID'];
  as: Scalars['String'];
}>;


export type RemoveUserFromSessionMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
    & { sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role'>
      )> }
    )>>>, facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role'>
      )> }
    )>>> }
  )> }
);

export type RemoveUserFromWaitingRoomMutationVariables = Exact<{
  user: Scalars['ID'];
  session: Scalars['ID'];
}>;


export type RemoveUserFromWaitingRoomMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromWaitingRoom?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
    & { waitingRoom?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>>> }
  )> }
);

export type RequestInviteMutationVariables = Exact<{
  user: Scalars['ID'];
  group: Scalars['ID'];
}>;


export type RequestInviteMutation = (
  { __typename?: 'Mutation' }
  & { requestInvite?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  passReset: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetResponse' }
    & Pick<ResetResponse, 'token'>
  )> }
);

export type SeeMessageMutationVariables = Exact<{
  id: Scalars['ID'];
  message: Scalars['ID'];
}>;


export type SeeMessageMutation = (
  { __typename?: 'Mutation' }
  & { seeMessage?: Maybe<(
    { __typename?: 'ChatRoom' }
    & Pick<ChatRoom, 'id'>
  )> }
);

export type SendMessageMutationVariables = Exact<{
  id: Scalars['ID'];
  message: Scalars['String'];
  from: Scalars['ID'];
  replyTo?: Maybe<Scalars['ID']>;
  sentAt: Scalars['DateISO'];
}>;


export type SendMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendMessage?: Maybe<(
    { __typename?: 'ChatRoom' }
    & Pick<ChatRoom, 'id'>
  )> }
);

export type SendMessageToOpenChatMutationVariables = Exact<{
  id: Scalars['ID'];
  message: Scalars['String'];
  from: Scalars['ID'];
  replyTo?: Maybe<Scalars['ID']>;
  sentAt: Scalars['DateISO'];
}>;


export type SendMessageToOpenChatMutation = (
  { __typename?: 'Mutation' }
  & { sendMessageToOpenChat?: Maybe<(
    { __typename?: 'OpenChatRoom' }
    & Pick<OpenChatRoom, 'id' | 'createdAt'>
    & { openChatMembers?: Maybe<Array<Maybe<(
      { __typename?: 'OpenChatMember' }
      & Pick<OpenChatMember, 'palamiState' | 'twilioName' | 'lastActive'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )> }
    )>>>, theme?: Maybe<(
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'name' | 'category' | 'imageUrl' | 'program'>
    )> }
  )> }
);

export type SendRequestMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SendRequestMutation = (
  { __typename?: 'Mutation' }
  & { sendRequest?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type SendResetEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendResetEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendResetEmail'>
);

export type SetLastActiveMutationVariables = Exact<{
  session: Scalars['ID'];
  member: Scalars['ID'];
  role: Scalars['String'];
  exit?: Maybe<Scalars['Boolean']>;
}>;


export type SetLastActiveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setLastActive'>
);

export type SetUnsentMessageMutationVariables = Exact<{
  id: Scalars['ID'];
  message: Scalars['String'];
}>;


export type SetUnsentMessageMutation = (
  { __typename?: 'Mutation' }
  & { setUnsentMessage?: Maybe<(
    { __typename?: 'ChatRoom' }
    & Pick<ChatRoom, 'id'>
  )> }
);

export type StartSessionMutationVariables = Exact<{
  session: Scalars['ID'];
}>;


export type StartSessionMutation = (
  { __typename?: 'Mutation' }
  & { startSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>>> }
  )> }
);

export type StopSessionMutationVariables = Exact<{
  session: Scalars['ID'];
  boot?: Maybe<Scalars['Boolean']>;
}>;


export type StopSessionMutation = (
  { __typename?: 'Mutation' }
  & { stopSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>>> }
  )> }
);

export type SubscribeToPlanMutationVariables = Exact<{
  communityId?: Maybe<Scalars['ID']>;
  planId?: Maybe<Scalars['String']>;
  priceId?: Maybe<Scalars['String']>;
}>;


export type SubscribeToPlanMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'subscribeToPlan'>
);

export type SwitchPrimeCommunityMutationVariables = Exact<{
  id: Scalars['ID'];
  community?: Maybe<Scalars['ID']>;
}>;


export type SwitchPrimeCommunityMutation = (
  { __typename?: 'Mutation' }
  & { switchPrimeCommunity?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { community?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'name'>
    )>, communities?: Maybe<Array<Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'name'>
    )>>> }
  )> }
);

export type SwitchUserMutationVariables = Exact<{
  user: Scalars['ID'];
}>;


export type SwitchUserMutation = (
  { __typename?: 'Mutation' }
  & { switchUser?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
  )> }
);

export type TriggerHeadCountMutationVariables = Exact<{
  session: Scalars['ID'];
  trigger: Scalars['Boolean'];
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  ip?: Maybe<Scalars['String']>;
}>;


export type TriggerHeadCountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'triggerHeadCount'>
);

export type UnfriendUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnfriendUserMutation = (
  { __typename?: 'Mutation' }
  & { unfriendUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type SessionSessionFacilitatorsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionSessionFacilitatorsQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'Session' }
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & Pick<SessionFacilitator, 'nickname' | 'sessionRole' | 'muteState' | 'vidState' | 'palamiState' | 'roomState' | 'twilioName'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )> }
    )>>> }
  )> }
);

export type AllUiMetricsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type AllUiMetricsQuery = (
  { __typename?: 'Query' }
  & { allUiMetrics?: Maybe<Array<Maybe<(
    { __typename?: 'UiMetrics' }
    & Pick<UiMetrics, 'id' | 'fontSize' | 'mirroredVideo' | 'createdAt' | 'updatedAt'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ), innerDimensions?: Maybe<Array<Maybe<(
      { __typename?: 'Dimensions' }
      & Pick<Dimensions, 'width' | 'height'>
    )>>>, outerDimensions?: Maybe<Array<Maybe<(
      { __typename?: 'Dimensions' }
      & Pick<Dimensions, 'width' | 'height'>
    )>>> }
  )>>> }
);

export type AmIApprovedQueryVariables = Exact<{
  session: Scalars['ID'];
}>;


export type AmIApprovedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'amIApproved'>
);

export type AnswerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AnswerQuery = (
  { __typename?: 'Query' }
  & { answer?: Maybe<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id' | 'number' | 'body' | 'value' | 'textAns' | 'type' | 'section' | 'createdAt'>
  )> }
);

export type AnswerListQueryVariables = Exact<{ [key: string]: never; }>;


export type AnswerListQuery = (
  { __typename?: 'Query' }
  & { answerList?: Maybe<Array<Maybe<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id' | 'number' | 'body' | 'value' | 'createdAt'>
  )>>> }
);

export type ChatRoomQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ChatRoomQuery = (
  { __typename?: 'Query' }
  & { chatRoom?: Maybe<(
    { __typename?: 'ChatRoom' }
    & Pick<ChatRoom, 'id' | 'sortedIdList' | 'isDeleted' | 'isGroup' | 'group' | 'roomName'>
    & { users: Array<Maybe<(
      { __typename?: 'ChatUser' }
      & Pick<ChatUser, 'unsentMessage' | 'isRemoved'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )>, messageLog?: Maybe<Array<Maybe<(
        { __typename?: 'ChatMessage' }
        & Pick<ChatMessage, 'id' | 'message' | 'isSeen' | 'isDeleted'>
        & { from: (
          { __typename?: 'User' }
          & Pick<User, 'id'>
        ), replyTo?: Maybe<(
          { __typename?: 'ChatMessage' }
          & Pick<ChatMessage, 'id' | 'message'>
        )> }
      )>>>, lastSeenMessage?: Maybe<(
        { __typename?: 'ChatMessage' }
        & Pick<ChatMessage, 'id'>
      )> }
    )>> }
  )> }
);

export type CheckForPendingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CheckForPendingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkForPending'>
);

export type CheckQuestionnaireExistsQueryVariables = Exact<{
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage: Scalars['String'];
}>;


export type CheckQuestionnaireExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkQuestionnaireExists'>
);

export type CheckQuestionnaireExistsCommunityQueryVariables = Exact<{
  member: Scalars['ID'];
  community: Scalars['ID'];
  stage: Scalars['String'];
}>;


export type CheckQuestionnaireExistsCommunityQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkQuestionnaireExistsCommunity'>
);

export type CheckRegLinkExistsQueryVariables = Exact<{
  link: Scalars['String'];
}>;


export type CheckRegLinkExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkRegLinkExists'>
);

export type CloseSessionsQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type CloseSessionsQuery = (
  { __typename?: 'Query' }
  & { closeSessions?: Maybe<Array<Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date' | 'program' | 'isDirect' | 'status'>
    & { group: (
      { __typename?: 'Group' }
      & Pick<Group, 'name' | 'isDirect' | 'location' | 'room'>
    ), theme: (
      { __typename?: 'Theme' }
      & Pick<Theme, 'name' | 'imageUrl'>
    ), sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & Pick<SessionMember, 'lastActive'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )> }
    )>>>, facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & Pick<SessionFacilitator, 'lastActive'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )> }
    )>>> }
  )>>> }
);

export type CommunitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type CommunitiesQuery = (
  { __typename?: 'Query' }
  & { communities?: Maybe<Array<Maybe<(
    { __typename?: 'Community' }
    & Pick<Community, 'id' | 'name' | 'phone' | 'email' | 'address' | 'maxUsers' | 'maxGroups' | 'isDeleted'>
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & SmallUserFragmentFragment
    )>>>, members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & SmallUserFragmentFragment
    )>>> }
  )>>> }
);

export type CommunityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CommunityQuery = (
  { __typename?: 'Query' }
  & { community?: Maybe<(
    { __typename?: 'Community' }
    & Pick<Community, 'id' | 'name' | 'phone' | 'email' | 'address' | 'maxUsers' | 'maxGroups' | 'isDeleted'>
    & { plan?: Maybe<(
      { __typename?: 'Plan' }
      & PlanFragmentFragment
    )> }
  )> }
);

export type CommunityByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type CommunityByNameQuery = (
  { __typename?: 'Query' }
  & { communityByName?: Maybe<(
    { __typename?: 'Community' }
    & Pick<Community, 'id' | 'name' | 'phone' | 'email' | 'address' | 'maxUsers' | 'maxGroups' | 'isDeleted'>
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & SmallUserFragmentFragment
    )>>>, members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & SmallUserFragmentFragment
    )>>> }
  )> }
);

export type CommunitySearchByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type CommunitySearchByNameQuery = (
  { __typename?: 'Query' }
  & { communitySearchByName?: Maybe<(
    { __typename?: 'Community' }
    & Pick<Community, 'id' | 'name' | 'phone' | 'email' | 'address' | 'maxUsers' | 'maxGroups'>
  )> }
);

export type CountUniqueDimensionsQueryVariables = Exact<{
  type?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type CountUniqueDimensionsQuery = (
  { __typename?: 'Query' }
  & { countUniqueDimensions?: Maybe<Array<Maybe<(
    { __typename?: 'DimensionStatsPayload' }
    & DimensionsFragmentFragment
  )>>> }
);

export type ErrorLogsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
}>;


export type ErrorLogsQuery = (
  { __typename?: 'Query' }
  & { errorLogs?: Maybe<Array<Maybe<(
    { __typename?: 'ErrorLog' }
    & Pick<ErrorLog, 'id' | 'error' | 'componentStack' | 'platform' | 'userAgent' | 'boundary' | 'userMessage' | 'createdAt'>
  )>>> }
);

export type FaqQueryVariables = Exact<{ [key: string]: never; }>;


export type FaqQuery = (
  { __typename?: 'Query' }
  & { faqs?: Maybe<Array<Maybe<(
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'question' | 'answer'>
  )>>> }
);

export type FollowSessionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FollowSessionQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'status' | 'currentScreen' | 'songPlaying' | 'isDirect' | 'videoChatStatus' | 'contentId' | 'contentType' | 'globalMessage'>
  )> }
);

export type FollowSessionAsSessionMasterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FollowSessionAsSessionMasterQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'status' | 'currentScreen' | 'songPlaying' | 'videoChatStatus' | 'contentId' | 'isDirect' | 'contentType' | 'globalMessage'>
    & { waitingRoom?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role'>
    )>>>, sessionMaster?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type FollowWaitingRoomQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FollowWaitingRoomQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'status'>
    & { waitingRoom?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>>>, sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>>> }
  )> }
);

export type FriendForNewChatQueryVariables = Exact<{ [key: string]: never; }>;


export type FriendForNewChatQuery = (
  { __typename?: 'Query' }
  & { friendForNewChat?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & FriendFragmentFragment
  )>>> }
);

export type FriendRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type FriendRequestsQuery = (
  { __typename?: 'Query' }
  & { friendRequests?: Maybe<(
    { __typename?: 'User' }
    & { friendRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & FriendFragmentFragment
    )>>> }
  )> }
);

export type FriendSuggestionsQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
}>;


export type FriendSuggestionsQuery = (
  { __typename?: 'Query' }
  & { friendSuggestions?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'bio' | 'interests' | 'role'>
    & { community?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'name'>
    )> }
    & SmallUserFragmentFragment
  )>>> }
);

export type FriendsQueryVariables = Exact<{ [key: string]: never; }>;


export type FriendsQuery = (
  { __typename?: 'Query' }
  & { friends?: Maybe<(
    { __typename?: 'User' }
    & { friends?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & FriendFragmentFragment
    )>>> }
  )> }
);

export type FutureSessionsQueryVariables = Exact<{
  nth: Scalars['Int'];
}>;


export type FutureSessionsQuery = (
  { __typename?: 'Query' }
  & { futureSessions?: Maybe<Array<Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date' | 'program' | 'status'>
    & { sessionMaster?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, theme: (
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'name' | 'imageUrl'>
    ), group: (
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name' | 'room'>
    ) }
  )>>> }
);

export type GetPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlansQuery = (
  { __typename?: 'Query' }
  & { getPlans?: Maybe<Array<Maybe<(
    { __typename?: 'Plan' }
    & PlanFragmentFragment
  )>>> }
);

export type GetCommunitiesUsingPlanQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCommunitiesUsingPlanQuery = (
  { __typename?: 'Query' }
  & { getCommunitiesUsingPlan?: Maybe<Array<Maybe<(
    { __typename?: 'Community' }
    & Pick<Community, 'id' | 'name' | 'phone' | 'email' | 'address' | 'maxUsers' | 'maxGroups' | 'isDeleted'>
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & SmallUserFragmentFragment
    )>>>, members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & SmallUserFragmentFragment
    )>>> }
  )>>> }
);

export type GetCustomerPortalQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCustomerPortalQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCustomerPortal'>
);

export type GetOlderMessagesQueryVariables = Exact<{
  id: Scalars['ID'];
  prevLast: Scalars['ID'];
  until?: Maybe<Scalars['ID']>;
}>;


export type GetOlderMessagesQuery = (
  { __typename?: 'Query' }
  & { getOlderMessages?: Maybe<Array<Maybe<(
    { __typename?: 'ChatMessage' }
    & Pick<ChatMessage, 'id' | 'message' | 'isSeen' | 'isDeleted' | 'createdAt' | 'sentAt'>
    & { from: (
      { __typename?: 'User' }
      & SmallUserFragmentFragment
    ), replyTo?: Maybe<(
      { __typename?: 'ChatMessage' }
      & Pick<ChatMessage, 'id' | 'message'>
    )> }
  )>>> }
);

export type GetPlanQueryVariables = Exact<{
  id: Scalars['ID'];
  showPrice?: Maybe<Scalars['Boolean']>;
}>;


export type GetPlanQuery = (
  { __typename?: 'Query' }
  & { getPlan?: Maybe<(
    { __typename?: 'Plan' }
    & PlanFragmentFragment
  )> }
);

export type GetThemeAuditByThemeIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetThemeAuditByThemeIdQuery = (
  { __typename?: 'Query' }
  & { getThemeAuditByThemeId?: Maybe<Array<Maybe<(
    { __typename?: 'ThemeAudit' }
    & ThemeAuditFragmentFragment
  )>>> }
);

export type GetUnsentMessageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUnsentMessageQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUnsentMessage'>
);

export type GroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GroupQuery = (
  { __typename?: 'Query' }
  & { group?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'location' | 'lat' | 'lng' | 'isPublic' | 'tags' | 'isDeleted' | 'isFrozen'>
    & { inviteRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'profilePicUrl'>
    )>>>, owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'profilePicUrl'>
    )> }
    & GroupFragmentFragment
  )> }
);

export type GroupCountStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupCountStatsQuery = (
  { __typename?: 'Query' }
  & { groupCountStats?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'createdAt'>
  )>>> }
);

export type GroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsQuery = (
  { __typename?: 'Query' }
  & { groups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'location' | 'lat' | 'lng' | 'isPublic' | 'tags' | 'isDeleted' | 'isFrozen'>
    & { inviteRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'profilePicUrl'>
    )>>>, owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'profilePicUrl'>
    )> }
    & GroupFragmentFragment
  )>>> }
);

export type GroupsByTagQueryVariables = Exact<{
  tags: Array<Maybe<Scalars['String']>>;
}>;


export type GroupsByTagQuery = (
  { __typename?: 'Query' }
  & { groupsByTag?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'location' | 'lat' | 'lng' | 'isPublic' | 'tags' | 'isDeleted' | 'isFrozen'>
    & { inviteRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>>>, owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )> }
    & GroupFragmentFragment
  )>>> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'postalcode' | 'location' | 'lat' | 'lng' | 'ageGroup' | 'living' | 'country' | 'bio' | 'interests' | 'role' | 'phone' | 'dateOfBirth' | 'address' | 'parentUser' | 'optOut' | 'studyConsent'>
    & { friends?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & SmallUserFragmentFragment
    )>>>, friendRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & SmallUserFragmentFragment
    )>>>, lastQuestionnaire?: Maybe<(
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id' | 'stage' | 'createdAt'>
    )>, community?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'name'>
      & { plan?: Maybe<(
        { __typename?: 'Plan' }
        & PlanFragmentFragment
      )> }
    )> }
    & MeLightFragmentFragment
  )> }
);

export type MeLightQueryVariables = Exact<{ [key: string]: never; }>;


export type MeLightQuery = (
  { __typename?: 'Query' }
  & { meLight?: Maybe<(
    { __typename?: 'User' }
    & MeLightFragmentFragment
  )> }
);

export type MyChatRoomsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyChatRoomsQuery = (
  { __typename?: 'Query' }
  & { myChatRooms?: Maybe<Array<Maybe<(
    { __typename?: 'ChatRoom' }
    & Pick<ChatRoom, 'id' | 'sortedIdList' | 'isDeleted' | 'isGroup' | 'group' | 'roomName' | 'createdAt'>
    & { users: Array<Maybe<(
      { __typename?: 'ChatUser' }
      & Pick<ChatUser, 'unsentMessage' | 'isRemoved'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )>, messageLog?: Maybe<Array<Maybe<(
        { __typename?: 'ChatMessage' }
        & Pick<ChatMessage, 'id' | 'message' | 'isSeen' | 'isDeleted' | 'createdAt' | 'sentAt'>
        & { from: (
          { __typename?: 'User' }
          & Pick<User, 'id'>
        ), replyTo?: Maybe<(
          { __typename?: 'ChatMessage' }
          & Pick<ChatMessage, 'id' | 'message'>
        )> }
      )>>>, lastSeenMessage?: Maybe<(
        { __typename?: 'ChatMessage' }
        & Pick<ChatMessage, 'id'>
      )> }
    )>> }
  )>>> }
);

export type MyFutureSessionsQueryVariables = Exact<{
  nth: Scalars['Int'];
  directOnly?: Maybe<Scalars['Boolean']>;
}>;


export type MyFutureSessionsQuery = (
  { __typename?: 'Query' }
  & { myFutureSessions?: Maybe<Array<Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date' | 'program' | 'isDirect' | 'status'>
    & { sessionMaster?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, theme: (
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'name' | 'imageUrl'>
    ), group: (
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name' | 'room' | 'isDirect'>
    ), sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & Pick<SessionMember, 'lastActive'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'bio' | 'interests'>
        & SmallUserFragmentFragment
      )> }
    )>>>, facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & Pick<SessionFacilitator, 'lastActive'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'bio' | 'interests'>
        & SmallUserFragmentFragment
      )> }
    )>>> }
  )>>> }
);

export type MyGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyGroupsQuery = (
  { __typename?: 'Query' }
  & { myGroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'location' | 'lat' | 'lng' | 'isPublic' | 'tags' | 'isDeleted' | 'isFrozen'>
    & { inviteRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>>>, owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )> }
    & GroupFragmentFragment
  )>>> }
);

export type MyManagedGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyManagedGroupsQuery = (
  { __typename?: 'Query' }
  & { myManagedGroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'location' | 'lat' | 'lng' | 'isPublic' | 'tags' | 'isDeleted' | 'isFrozen'>
    & { inviteRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>>>, owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )> }
    & GroupFragmentFragment
  )>>> }
);

export type MyRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyRequestsQuery = (
  { __typename?: 'Query' }
  & { myRequests?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
    & { inviteRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & SmallUserFragmentFragment
    )>>> }
  )>>> }
);

export type MyTrainingsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MyTrainingsQuery = (
  { __typename?: 'Query' }
  & { myTrainings?: Maybe<Array<Maybe<(
    { __typename?: 'Training' }
    & Pick<Training, 'id' | 'name' | 'link' | 'type' | 'status' | 'step'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )>>> }
);

export type NextSessionByGroupQueryVariables = Exact<{
  group: Scalars['ID'];
}>;


export type NextSessionByGroupQuery = (
  { __typename?: 'Query' }
  & { nextSessionByGroup?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date'>
    & { group: (
      { __typename?: 'Group' }
      & { usedThemes?: Maybe<Array<Maybe<(
        { __typename?: 'UsedThemes' }
        & Pick<UsedThemes, 'theme' | 'lastUsed'>
      )>>> }
    ) }
  )> }
);

export type NotificationsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications?: Maybe<Array<Maybe<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'message' | 'seenEh' | 'actionedEh' | 'createdAt' | 'updatedAt' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ), user?: Maybe<(
      { __typename?: 'User' }
      & SmallUserFragmentFragment
    )>, group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>, session?: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'id'>
    )> }
  )>>> }
);

export type OpenChatRoomQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OpenChatRoomQuery = (
  { __typename?: 'Query' }
  & { openChatRoom?: Maybe<(
    { __typename?: 'OpenChatRoom' }
    & Pick<OpenChatRoom, 'id' | 'createdAt'>
    & { openChatMembers?: Maybe<Array<Maybe<(
      { __typename?: 'OpenChatMember' }
      & Pick<OpenChatMember, 'palamiState' | 'twilioName' | 'lastActive'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )> }
    )>>>, theme?: Maybe<(
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'name' | 'category' | 'imageUrl' | 'program'>
    )> }
  )> }
);

export type OpenChatRoomsQueryVariables = Exact<{ [key: string]: never; }>;


export type OpenChatRoomsQuery = (
  { __typename?: 'Query' }
  & { openChatRooms?: Maybe<Array<Maybe<(
    { __typename?: 'OpenChatRoom' }
    & Pick<OpenChatRoom, 'id' | 'createdAt'>
    & { openChatMembers?: Maybe<Array<Maybe<(
      { __typename?: 'OpenChatMember' }
      & Pick<OpenChatMember, 'palamiState' | 'twilioName' | 'lastActive'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )> }
    )>>>, theme?: Maybe<(
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'name' | 'category' | 'imageUrl' | 'program'>
    )> }
  )>>> }
);

export type OpenChatRoomsByThemeQueryVariables = Exact<{
  theme: Scalars['ID'];
}>;


export type OpenChatRoomsByThemeQuery = (
  { __typename?: 'Query' }
  & { openChatRoomsByTheme?: Maybe<Array<Maybe<(
    { __typename?: 'OpenChatRoom' }
    & Pick<OpenChatRoom, 'id' | 'createdAt'>
    & { openChatMembers?: Maybe<Array<Maybe<(
      { __typename?: 'OpenChatMember' }
      & Pick<OpenChatMember, 'palamiState' | 'twilioName' | 'lastActive'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )> }
    )>>>, theme?: Maybe<(
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'name' | 'category' | 'imageUrl' | 'program'>
    )> }
  )>>> }
);

export type OpenInviteRequestsQueryVariables = Exact<{
  user: Scalars['ID'];
}>;


export type OpenInviteRequestsQuery = (
  { __typename?: 'Query' }
  & { openInviteRequests?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'startDate'>
  )>>> }
);

export type PublicGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type PublicGroupsQuery = (
  { __typename?: 'Query' }
  & { publicGroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'location' | 'lat' | 'lng' | 'isPublic' | 'tags' | 'isDeleted'>
    & { inviteRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>>>, owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )> }
    & GroupFragmentFragment
  )>>> }
);

export type QuestionnaireQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type QuestionnaireQuery = (
  { __typename?: 'Query' }
  & { questionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'member' | 'session' | 'stage' | 'optOut' | 'isDone'>
    & { answers?: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'number' | 'body' | 'value' | 'createdAt'>
    )>>> }
  )> }
);

export type QuestionnaireUserQueryVariables = Exact<{
  member: Scalars['ID'];
  stage?: Maybe<Scalars['String']>;
}>;


export type QuestionnaireUserQuery = (
  { __typename?: 'Query' }
  & { questionnaireUser?: Maybe<Array<Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'member' | 'session' | 'stage' | 'optOut' | 'isDone' | 'openDate'>
    & { answers?: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'number' | 'section' | 'type' | 'body' | 'value' | 'textAns' | 'createdAt'>
    )>>> }
  )>>> }
);

export type QuestionnaireUserSessionQueryVariables = Exact<{
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage?: Maybe<Scalars['String']>;
}>;


export type QuestionnaireUserSessionQuery = (
  { __typename?: 'Query' }
  & { questionnaireUserSession?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'member' | 'session' | 'stage' | 'optOut' | 'isDone'>
    & { answers?: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'number' | 'body' | 'value' | 'createdAt'>
    )>>> }
  )> }
);

export type QuestionnairesQueryVariables = Exact<{ [key: string]: never; }>;


export type QuestionnairesQuery = (
  { __typename?: 'Query' }
  & { questionnaires?: Maybe<Array<Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'member' | 'session' | 'stage' | 'optOut' | 'isDone'>
    & { answers?: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'number' | 'body' | 'value' | 'createdAt'>
    )>>> }
  )>>> }
);

export type RegLinkQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RegLinkQuery = (
  { __typename?: 'Query' }
  & { regLink?: Maybe<(
    { __typename?: 'RegLink' }
    & Pick<RegLink, 'id'>
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>>>, community?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'name'>
    )> }
  )> }
);

export type RegLinkByLinkQueryVariables = Exact<{
  link: Scalars['String'];
}>;


export type RegLinkByLinkQuery = (
  { __typename?: 'Query' }
  & { regLinkByLink?: Maybe<(
    { __typename?: 'RegLink' }
    & Pick<RegLink, 'id'>
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name' | 'program'>
    )>>>, community?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'name'>
    )> }
  )> }
);

export type RegLinkSearchQueryVariables = Exact<{
  link: Scalars['String'];
}>;


export type RegLinkSearchQuery = (
  { __typename?: 'Query' }
  & { regLinkSearch?: Maybe<Array<Maybe<(
    { __typename?: 'RegLink' }
    & Pick<RegLink, 'id'>
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>>>, community?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'name'>
    )> }
  )>>> }
);

export type RegLinksByCommunityQueryVariables = Exact<{
  community: Scalars['ID'];
}>;


export type RegLinksByCommunityQuery = (
  { __typename?: 'Query' }
  & { regLinksByCommunity?: Maybe<Array<Maybe<(
    { __typename?: 'RegLink' }
    & Pick<RegLink, 'id' | 'link'>
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>>>, community?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'name'>
    )> }
  )>>> }
);

export type ResourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type ResourcesQuery = (
  { __typename?: 'Query' }
  & { resources?: Maybe<Array<Maybe<(
    { __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'title' | 'category' | 'thumb' | 'text'>
  )>>> }
);

export type SessionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date' | 'program' | 'status' | 'isDirect'>
    & { sessionMaster?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, group: (
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name' | 'startDate' | 'frequency' | 'room' | 'isDirect'>
      & { usedThemes?: Maybe<Array<Maybe<(
        { __typename?: 'UsedThemes' }
        & Pick<UsedThemes, 'theme' | 'lastUsed'>
      )>>>, facilitators?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'role'>
        & SmallUserFragmentFragment
      )>>>, members?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'role'>
        & { notes?: Maybe<Array<Maybe<(
          { __typename?: 'Note' }
          & Pick<Note, 'id' | 'content'>
          & { author?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
          )> }
        )>>> }
        & SmallUserFragmentFragment
      )>>> }
    ), facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & Pick<SessionFacilitator, 'nickname' | 'sessionRole' | 'muteState' | 'vidState' | 'palamiState' | 'roomState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'bio' | 'interests'>
        & SmallUserFragmentFragment
      )> }
    )>>>, sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & Pick<SessionMember, 'nickname' | 'lastActive' | 'sessionRole' | 'muteState' | 'vidState' | 'palamiState' | 'roomState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'bio' | 'interests'>
        & { notes?: Maybe<Array<Maybe<(
          { __typename?: 'Note' }
          & Pick<Note, 'id' | 'content'>
          & { author?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
          )> }
        )>>> }
        & SmallUserFragmentFragment
      )> }
    )>>>, theme: (
      { __typename?: 'Theme' }
      & { javaTips?: Maybe<Array<Maybe<(
        { __typename?: 'JavaTip' }
        & Pick<JavaTip, 'id' | 'body'>
        & { author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName'>
        )> }
      )>>> }
      & ThemeFragmentFragment
    ) }
  )> }
);

export type SessionSessionMembersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionSessionMembersQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'Session' }
    & { sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & Pick<SessionMember, 'nickname' | 'sessionRole' | 'muteState' | 'vidState' | 'palamiState' | 'roomState' | 'twilioName'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & { notes?: Maybe<Array<Maybe<(
          { __typename?: 'Note' }
          & Pick<Note, 'id' | 'content'>
          & { author?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
          )> }
        )>>> }
        & SmallUserFragmentFragment
      )> }
    )>>> }
  )> }
);

export type SessionStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionStatusQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date' | 'status' | 'isDirect'>
    & { group: (
      { __typename?: 'Group' }
      & { facilitators?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>>> }
    ) }
  )> }
);

export type SessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type SessionsQuery = (
  { __typename?: 'Query' }
  & { sessions?: Maybe<Array<Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date' | 'program' | 'isDirect'>
    & { theme: (
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'name' | 'imageUrl'>
    ), group: (
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name' | 'room'>
    ), facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role'>
      )> }
    )>>>, members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>>> }
  )>>> }
);

export type SessionsByGroupQueryVariables = Exact<{
  group: Scalars['ID'];
  isPast?: Maybe<Scalars['Boolean']>;
}>;


export type SessionsByGroupQuery = (
  { __typename?: 'Query' }
  & { sessionsByGroup?: Maybe<Array<Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date' | 'program'>
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & Pick<SessionFacilitator, 'nickname' | 'sessionRole' | 'muteState' | 'vidState' | 'palamiState' | 'roomState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )> }
    )>>>, sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & Pick<SessionMember, 'sharePhoto' | 'shareQuote' | 'shareSong' | 'nickname' | 'sessionRole' | 'muteState' | 'vidState' | 'palamiState' | 'roomState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )> }
    )>>> }
  )>>> }
);

export type SongsQueryVariables = Exact<{ [key: string]: never; }>;


export type SongsQuery = (
  { __typename?: 'Query' }
  & { songs?: Maybe<Array<Maybe<(
    { __typename?: 'Song' }
    & Pick<Song, 'id' | 'title' | 'lyrics' | 'url' | 'isDeleted'>
  )>>> }
);

export type SubUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type SubUsersQuery = (
  { __typename?: 'Query' }
  & { subUsers?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'dateOfBirth'>
    & SmallUserFragmentFragment
  )>>> }
);

export type ThemeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ThemeQuery = (
  { __typename?: 'Query' }
  & { theme?: Maybe<(
    { __typename?: 'Theme' }
    & { javaTips?: Maybe<Array<Maybe<(
      { __typename?: 'JavaTip' }
      & Pick<JavaTip, 'id' | 'body' | 'isDeleted'>
      & { author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )> }
    )>>> }
    & ThemeFragmentFragment
  )> }
);

export type ThemePhotosQueryVariables = Exact<{ [key: string]: never; }>;


export type ThemePhotosQuery = (
  { __typename?: 'Query' }
  & { themePhotos?: Maybe<Array<Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'imageUrl'>
  )>>> }
);

export type ThemesQueryVariables = Exact<{ [key: string]: never; }>;


export type ThemesQuery = (
  { __typename?: 'Query' }
  & { themes?: Maybe<Array<Maybe<(
    { __typename?: 'Theme' }
    & { javaTips?: Maybe<Array<Maybe<(
      { __typename?: 'JavaTip' }
      & Pick<JavaTip, 'id' | 'body' | 'isDeleted'>
    )>>> }
    & ThemeFragmentFragment
  )>>> }
);

export type TrainingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TrainingQuery = (
  { __typename?: 'Query' }
  & { training?: Maybe<(
    { __typename?: 'Training' }
    & Pick<Training, 'id' | 'name' | 'link' | 'type' | 'status' | 'step'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type UnseenNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnseenNotificationsQuery = (
  { __typename?: 'Query' }
  & { unseenNotifications?: Maybe<Array<Maybe<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'type' | 'message' | 'seenEh' | 'actionedEh' | 'createdAt' | 'updatedAt'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ), user?: Maybe<(
      { __typename?: 'User' }
      & SmallUserFragmentFragment
    )>, group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>, session?: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'id'>
    )> }
  )>>> }
);

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'optOut'>
    & { community?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'id'>
    )>, notes?: Maybe<Array<Maybe<(
      { __typename?: 'Note' }
      & Pick<Note, 'content'>
      & { author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )> }
    )>>> }
    & SmallUserFragmentFragment
  )> }
);

export type UserByFirstNameQueryVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
}>;


export type UserByFirstNameQuery = (
  { __typename?: 'Query' }
  & { userByFirstName?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname' | 'firstName' | 'lastName'>
  )> }
);

export type UserCommunitiesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserCommunitiesQuery = (
  { __typename?: 'Query' }
  & { userCommunities?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { community?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'name'>
    )>, communities?: Maybe<Array<Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'name'>
    )>>> }
  )> }
);

export type UserDefaultsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserDefaultsQuery = (
  { __typename?: 'Query' }
  & { userDefaults?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'defaultCam' | 'defaultMic'>
  )> }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'phone' | 'email' | 'role' | 'country' | 'postalcode' | 'ageGroup' | 'living' | 'parentUser' | 'createdAt' | 'status' | 'validUntil' | 'optOut'>
    & SmallUserFragmentFragment
  )>>> }
);

export type FuzzyUsersQueryVariables = Exact<{
  name: Scalars['String'];
  isReqSearch?: Maybe<Scalars['Boolean']>;
}>;


export type FuzzyUsersQuery = (
  { __typename?: 'Query' }
  & { usersByFuzzyName?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'role'>
    & { community?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'name'>
    )> }
    & SmallUserFragmentFragment
  )>>> }
);

export type VersionQueryVariables = Exact<{ [key: string]: never; }>;


export type VersionQuery = (
  { __typename?: 'Query' }
  & { version?: Maybe<(
    { __typename?: 'Version' }
    & Pick<Version, 'id' | 'v' | 'readAt'>
  )> }
);

export type ChatRoomSeenSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ChatRoomSeenSubscription = (
  { __typename?: 'Subscription' }
  & { chatRoomSeen?: Maybe<Array<Maybe<(
    { __typename?: 'ChatSeenPayload' }
    & Pick<ChatSeenPayload, 'id' | 'firstName' | 'lastName' | 'nickname' | 'profilePicUrl' | 'lastSeenMessage' | 'isTyping'>
  )>>> }
);

export type LiveChatRoomSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LiveChatRoomSubscription = (
  { __typename?: 'Subscription' }
  & { liveChatRoom?: Maybe<(
    { __typename?: 'ChatRoomPayload' }
    & Pick<ChatRoomPayload, 'id' | 'op' | 'sortedIdList' | 'isDeleted' | 'isGroup' | 'group' | 'roomName'>
    & { users: Array<Maybe<(
      { __typename?: 'ChatUser' }
      & Pick<ChatUser, 'isTyping' | 'isHidden' | 'unsentMessage' | 'isRemoved'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & SmallUserFragmentFragment
      )>, messageLog?: Maybe<Array<Maybe<(
        { __typename?: 'ChatMessage' }
        & Pick<ChatMessage, 'id' | 'message' | 'isSeen' | 'isDeleted' | 'createdAt' | 'sentAt'>
        & { from: (
          { __typename?: 'User' }
          & SmallUserFragmentFragment
        ), replyTo?: Maybe<(
          { __typename?: 'ChatMessage' }
          & Pick<ChatMessage, 'id' | 'message'>
        )> }
      )>>>, lastSeenMessage?: Maybe<(
        { __typename?: 'ChatMessage' }
        & Pick<ChatMessage, 'id'>
      )> }
    )>> }
  )> }
);

export type OpenChatMessageLogSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OpenChatMessageLogSubscription = (
  { __typename?: 'Subscription' }
  & { openChatMessageLog?: Maybe<(
    { __typename?: 'OpenChatPayload' }
    & Pick<OpenChatPayload, 'id' | 'op' | 'message' | 'isDeleted' | 'createdAt' | 'sentAt'>
    & { from: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'profilePicUrl' | 'bio' | 'interests'>
    ) }
  )> }
);

export type OpenChatRoomMembersSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OpenChatRoomMembersSubscription = (
  { __typename?: 'Subscription' }
  & { openChatRoomMembers?: Maybe<Array<Maybe<(
    { __typename?: 'OpenChatMembersPayload' }
    & Pick<OpenChatMembersPayload, 'id' | 'firstName' | 'lastName' | 'nickname' | 'palamiState' | 'twilioName' | 'profilePicUrl' | 'bio' | 'interests' | 'lastActive'>
  )>>> }
);

export type SessionFacilitatorsSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionFacilitatorsSubscription = (
  { __typename?: 'Subscription' }
  & { sessionFacilitators?: Maybe<Array<Maybe<(
    { __typename?: 'miniUser' }
    & Pick<MiniUser, 'role' | 'muteState' | 'vidState' | 'palamiState' | 'roomState' | 'twilioName'>
    & MiniUserFragmentFragment
  )>>> }
);

export type SessionHeadCountSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionHeadCountSubscription = (
  { __typename?: 'Subscription' }
  & { sessionHeadCount?: Maybe<(
    { __typename?: 'TriggerHeadCountPayload' }
    & Pick<TriggerHeadCountPayload, 'trigger' | 'lat' | 'lng' | 'ip'>
  )> }
);

export type SessionMembersSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionMembersSubscription = (
  { __typename?: 'Subscription' }
  & { sessionMembers?: Maybe<Array<Maybe<(
    { __typename?: 'miniUser' }
    & Pick<MiniUser, 'role' | 'muteState' | 'vidState' | 'palamiState' | 'roomState' | 'twilioName'>
    & MiniUserFragmentFragment
  )>>> }
);

export type SessionMixedUsersSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionMixedUsersSubscription = (
  { __typename?: 'Subscription' }
  & { sessionMixedUsers?: Maybe<Array<Maybe<(
    { __typename?: 'miniUserMixed' }
    & Pick<MiniUserMixed, 'id' | 'firstName' | 'lastName' | 'nickname' | 'profilePicUrl' | 'bio' | 'interests' | 'role' | 'muteState' | 'vidState' | 'palamiState' | 'isMirrored' | 'roomState' | 'shareSong' | 'shareQuote' | 'sharePhoto' | 'twilioName'>
  )>>> }
);

export type SessionModifiedSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionModifiedSubscription = (
  { __typename?: 'Subscription' }
  & { sessionModified?: Maybe<(
    { __typename?: 'RunningSessionSubscriptionPayload' }
    & Pick<RunningSessionSubscriptionPayload, 'currentScreen' | 'status' | 'songPlaying' | 'isDirect' | 'videoChatStatus' | 'contentType' | 'contentId' | 'globalMessage' | 'sessionMaster'>
  )> }
);

export type SessionWaitingRoomSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionWaitingRoomSubscription = (
  { __typename?: 'Subscription' }
  & { sessionWaitingRoom?: Maybe<Array<Maybe<(
    { __typename?: 'miniUser' }
    & Pick<MiniUser, 'role' | 'muteState'>
    & MiniUserFragmentFragment
  )>>> }
);

export const SmallUserFragmentFragmentDoc = gql`
    fragment SmallUserFragment on User {
  id
  firstName
  lastName
  nickname
  profilePicUrl
}
    `;
export const GroupFragmentFragmentDoc = gql`
    fragment GroupFragment on Group {
  id
  name
  startDate
  frequency
  room
  isDirect
  program
  phantomEnd
  usedThemes {
    theme
    lastUsed
  }
  facilitators {
    ...SmallUserFragment
    role
  }
  members {
    ...SmallUserFragment
    role
  }
}
    ${SmallUserFragmentFragmentDoc}`;
export const DimensionsFragmentFragmentDoc = gql`
    fragment DimensionsFragment on DimensionStatsPayload {
  id
  width
  height
  userCount
}
    `;
export const FriendFragmentFragmentDoc = gql`
    fragment FriendFragment on User {
  ...SmallUserFragment
  email
}
    ${SmallUserFragmentFragmentDoc}`;
export const MeLightFragmentFragmentDoc = gql`
    fragment MeLightFragment on User {
  ...SmallUserFragment
  email
}
    ${SmallUserFragmentFragmentDoc}`;
export const MiniUserFragmentFragmentDoc = gql`
    fragment MiniUserFragment on miniUser {
  id
  firstName
  lastName
  nickname
}
    `;
export const PlanFragmentFragmentDoc = gql`
    fragment PlanFragment on Plan {
  id
  name
  productId
  maxGroups
  maxUsers
  showPlan
  stripePriceData {
    id
    priceId
    price
    interval
    show
  }
  currency
}
    `;
export const SessionFragmentFragmentDoc = gql`
    fragment SessionFragment on Session {
  id
  date
  program
  status
  isDirect
  sessionMaster {
    id
  }
  facilitators {
    user {
      ...SmallUserFragment
      bio
      interests
    }
    nickname
    sessionRole
    muteState
    vidState
    palamiState
    roomState
  }
}
    ${SmallUserFragmentFragmentDoc}`;
export const SessionMemberFragmentFragmentDoc = gql`
    fragment SessionMemberFragment on SessionMember {
  user {
    ...SmallUserFragment
    bio
    interests
  }
  nickname
  lastActive
  sessionRole
  muteState
  vidState
  palamiState
  roomState
}
    ${SmallUserFragmentFragmentDoc}`;
export const ThemeAuditChangesFragmentFragmentDoc = gql`
    fragment ThemeAuditChangesFragment on Changes {
  name
  category
  program
  imageUrl
  songs {
    id
    title
    lyrics
    url
    imageUrl
  }
  discussions {
    question
  }
  reflection {
    question
  }
  quotes {
    author
    quote
  }
  sensoryCues {
    id
    title
    body
    imageUrl
  }
}
    `;
export const ThemeAuditFragmentFragmentDoc = gql`
    fragment ThemeAuditFragment on ThemeAudit {
  id
  editedBy {
    firstName
    lastName
  }
  theme
  audited
  auditedId
  was {
    ...ThemeAuditChangesFragment
  }
  is {
    ...ThemeAuditChangesFragment
  }
  createdAt
}
    ${ThemeAuditChangesFragmentFragmentDoc}`;
export const ThemeFragmentFragmentDoc = gql`
    fragment ThemeFragment on Theme {
  id
  name
  category
  imageUrl
  program
  discussions {
    id
    question
    isDeleted
  }
  reflection {
    id
    question
    isDeleted
  }
  songs {
    id
    title
    lyrics
    url
    imageUrl
    isDeleted
  }
  quotes {
    id
    author
    quote
    isDeleted
  }
  sensoryCues {
    id
    title
    body
    imageUrl
    isDeleted
  }
}
    `;
export const ActionRequestDocument = gql`
    mutation actionRequest($id: ID!, $action: Boolean!, $notif: ID) {
  actionRequest(id: $id, action: $action, notif: $notif) {
    friendRequests {
      ...SmallUserFragment
      email
    }
  }
}
    ${SmallUserFragmentFragmentDoc}`;
export type ActionRequestMutationFn = Apollo.MutationFunction<ActionRequestMutation, ActionRequestMutationVariables>;

/**
 * __useActionRequestMutation__
 *
 * To run a mutation, you first call `useActionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionRequestMutation, { data, loading, error }] = useActionRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      action: // value for 'action'
 *      notif: // value for 'notif'
 *   },
 * });
 */
export function useActionRequestMutation(baseOptions?: Apollo.MutationHookOptions<ActionRequestMutation, ActionRequestMutationVariables>) {
        return Apollo.useMutation<ActionRequestMutation, ActionRequestMutationVariables>(ActionRequestDocument, baseOptions);
      }
export type ActionRequestMutationHookResult = ReturnType<typeof useActionRequestMutation>;
export type ActionRequestMutationResult = Apollo.MutationResult<ActionRequestMutation>;
export type ActionRequestMutationOptions = Apollo.BaseMutationOptions<ActionRequestMutation, ActionRequestMutationVariables>;
export const AddAnswerDocument = gql`
    mutation addAnswer($member: ID!, $session: ID, $stage: String!, $section: String, $type: String, $number: Int!, $body: String!, $value: Int, $textAns: String) {
  addAnswer(
    member: $member
    session: $session
    stage: $stage
    section: $section
    type: $type
    number: $number
    body: $body
    value: $value
    textAns: $textAns
  ) {
    id
  }
}
    `;
export type AddAnswerMutationFn = Apollo.MutationFunction<AddAnswerMutation, AddAnswerMutationVariables>;

/**
 * __useAddAnswerMutation__
 *
 * To run a mutation, you first call `useAddAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAnswerMutation, { data, loading, error }] = useAddAnswerMutation({
 *   variables: {
 *      member: // value for 'member'
 *      session: // value for 'session'
 *      stage: // value for 'stage'
 *      section: // value for 'section'
 *      type: // value for 'type'
 *      number: // value for 'number'
 *      body: // value for 'body'
 *      value: // value for 'value'
 *      textAns: // value for 'textAns'
 *   },
 * });
 */
export function useAddAnswerMutation(baseOptions?: Apollo.MutationHookOptions<AddAnswerMutation, AddAnswerMutationVariables>) {
        return Apollo.useMutation<AddAnswerMutation, AddAnswerMutationVariables>(AddAnswerDocument, baseOptions);
      }
export type AddAnswerMutationHookResult = ReturnType<typeof useAddAnswerMutation>;
export type AddAnswerMutationResult = Apollo.MutationResult<AddAnswerMutation>;
export type AddAnswerMutationOptions = Apollo.BaseMutationOptions<AddAnswerMutation, AddAnswerMutationVariables>;
export const AddChatRoomDocument = gql`
    mutation addChatRoom($users: [ID]!, $roomName: String, $isGroup: Boolean) {
  addChatRoom(users: $users, roomName: $roomName, isGroup: $isGroup) {
    id
    users {
      user {
        ...SmallUserFragment
      }
      messageLog {
        id
        message
        from {
          ...SmallUserFragment
        }
        replyTo {
          id
          message
        }
        isSeen
        isDeleted
        createdAt
        sentAt
      }
      lastSeenMessage {
        id
      }
      isTyping
      isHidden
      unsentMessage
      isRemoved
    }
    sortedIdList
    isDeleted
    isGroup
    group
    roomName
  }
}
    ${SmallUserFragmentFragmentDoc}`;
export type AddChatRoomMutationFn = Apollo.MutationFunction<AddChatRoomMutation, AddChatRoomMutationVariables>;

/**
 * __useAddChatRoomMutation__
 *
 * To run a mutation, you first call `useAddChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChatRoomMutation, { data, loading, error }] = useAddChatRoomMutation({
 *   variables: {
 *      users: // value for 'users'
 *      roomName: // value for 'roomName'
 *      isGroup: // value for 'isGroup'
 *   },
 * });
 */
export function useAddChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<AddChatRoomMutation, AddChatRoomMutationVariables>) {
        return Apollo.useMutation<AddChatRoomMutation, AddChatRoomMutationVariables>(AddChatRoomDocument, baseOptions);
      }
export type AddChatRoomMutationHookResult = ReturnType<typeof useAddChatRoomMutation>;
export type AddChatRoomMutationResult = Apollo.MutationResult<AddChatRoomMutation>;
export type AddChatRoomMutationOptions = Apollo.BaseMutationOptions<AddChatRoomMutation, AddChatRoomMutationVariables>;
export const AddCommunityDocument = gql`
    mutation addCommunity($name: String!, $phone: String, $email: String, $address: String, $maxUsers: Int!, $maxGroups: Int!) {
  addCommunity(
    name: $name
    phone: $phone
    email: $email
    address: $address
    maxUsers: $maxUsers
    maxGroups: $maxGroups
  ) {
    name
    phone
    email
    address
    maxUsers
    maxGroups
  }
}
    `;
export type AddCommunityMutationFn = Apollo.MutationFunction<AddCommunityMutation, AddCommunityMutationVariables>;

/**
 * __useAddCommunityMutation__
 *
 * To run a mutation, you first call `useAddCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommunityMutation, { data, loading, error }] = useAddCommunityMutation({
 *   variables: {
 *      name: // value for 'name'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      address: // value for 'address'
 *      maxUsers: // value for 'maxUsers'
 *      maxGroups: // value for 'maxGroups'
 *   },
 * });
 */
export function useAddCommunityMutation(baseOptions?: Apollo.MutationHookOptions<AddCommunityMutation, AddCommunityMutationVariables>) {
        return Apollo.useMutation<AddCommunityMutation, AddCommunityMutationVariables>(AddCommunityDocument, baseOptions);
      }
export type AddCommunityMutationHookResult = ReturnType<typeof useAddCommunityMutation>;
export type AddCommunityMutationResult = Apollo.MutationResult<AddCommunityMutation>;
export type AddCommunityMutationOptions = Apollo.BaseMutationOptions<AddCommunityMutation, AddCommunityMutationVariables>;
export const AddDiscussionToThemeDocument = gql`
    mutation addDiscussionToTheme($theme: ID!, $question: String) {
  addDiscussionToTheme(theme: $theme, question: $question) {
    discussions {
      id
      question
    }
  }
}
    `;
export type AddDiscussionToThemeMutationFn = Apollo.MutationFunction<AddDiscussionToThemeMutation, AddDiscussionToThemeMutationVariables>;

/**
 * __useAddDiscussionToThemeMutation__
 *
 * To run a mutation, you first call `useAddDiscussionToThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDiscussionToThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDiscussionToThemeMutation, { data, loading, error }] = useAddDiscussionToThemeMutation({
 *   variables: {
 *      theme: // value for 'theme'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useAddDiscussionToThemeMutation(baseOptions?: Apollo.MutationHookOptions<AddDiscussionToThemeMutation, AddDiscussionToThemeMutationVariables>) {
        return Apollo.useMutation<AddDiscussionToThemeMutation, AddDiscussionToThemeMutationVariables>(AddDiscussionToThemeDocument, baseOptions);
      }
export type AddDiscussionToThemeMutationHookResult = ReturnType<typeof useAddDiscussionToThemeMutation>;
export type AddDiscussionToThemeMutationResult = Apollo.MutationResult<AddDiscussionToThemeMutation>;
export type AddDiscussionToThemeMutationOptions = Apollo.BaseMutationOptions<AddDiscussionToThemeMutation, AddDiscussionToThemeMutationVariables>;
export const AddErrorLogDocument = gql`
    mutation addErrorLog($error: String, $componentStack: String, $platform: String, $boundary: String, $userAgent: String, $userMessage: String) {
  addErrorLog(
    error: $error
    componentStack: $componentStack
    platform: $platform
    boundary: $boundary
    userAgent: $userAgent
    userMessage: $userMessage
  ) {
    id
  }
}
    `;
export type AddErrorLogMutationFn = Apollo.MutationFunction<AddErrorLogMutation, AddErrorLogMutationVariables>;

/**
 * __useAddErrorLogMutation__
 *
 * To run a mutation, you first call `useAddErrorLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddErrorLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addErrorLogMutation, { data, loading, error }] = useAddErrorLogMutation({
 *   variables: {
 *      error: // value for 'error'
 *      componentStack: // value for 'componentStack'
 *      platform: // value for 'platform'
 *      boundary: // value for 'boundary'
 *      userAgent: // value for 'userAgent'
 *      userMessage: // value for 'userMessage'
 *   },
 * });
 */
export function useAddErrorLogMutation(baseOptions?: Apollo.MutationHookOptions<AddErrorLogMutation, AddErrorLogMutationVariables>) {
        return Apollo.useMutation<AddErrorLogMutation, AddErrorLogMutationVariables>(AddErrorLogDocument, baseOptions);
      }
export type AddErrorLogMutationHookResult = ReturnType<typeof useAddErrorLogMutation>;
export type AddErrorLogMutationResult = Apollo.MutationResult<AddErrorLogMutation>;
export type AddErrorLogMutationOptions = Apollo.BaseMutationOptions<AddErrorLogMutation, AddErrorLogMutationVariables>;
export const AddFaqDocument = gql`
    mutation addFaq($answer: String!, $question: String!) {
  addFaq(answer: $answer, question: $question) {
    id
    question
    answer
  }
}
    `;
export type AddFaqMutationFn = Apollo.MutationFunction<AddFaqMutation, AddFaqMutationVariables>;

/**
 * __useAddFaqMutation__
 *
 * To run a mutation, you first call `useAddFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFaqMutation, { data, loading, error }] = useAddFaqMutation({
 *   variables: {
 *      answer: // value for 'answer'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useAddFaqMutation(baseOptions?: Apollo.MutationHookOptions<AddFaqMutation, AddFaqMutationVariables>) {
        return Apollo.useMutation<AddFaqMutation, AddFaqMutationVariables>(AddFaqDocument, baseOptions);
      }
export type AddFaqMutationHookResult = ReturnType<typeof useAddFaqMutation>;
export type AddFaqMutationResult = Apollo.MutationResult<AddFaqMutation>;
export type AddFaqMutationOptions = Apollo.BaseMutationOptions<AddFaqMutation, AddFaqMutationVariables>;
export const AddGroupDocument = gql`
    mutation addGroup($name: String!, $startDate: String!, $frequency: String!, $room: String!, $community: ID, $location: String, $lat: Float, $lng: Float, $program: String!, $isPublic: Boolean!, $isDirect: Boolean, $tags: [String]) {
  addGroup(
    name: $name
    startDate: $startDate
    frequency: $frequency
    room: $room
    community: $community
    location: $location
    lat: $lat
    lng: $lng
    program: $program
    isPublic: $isPublic
    isDirect: $isDirect
    tags: $tags
  ) {
    id
  }
}
    `;
export type AddGroupMutationFn = Apollo.MutationFunction<AddGroupMutation, AddGroupMutationVariables>;

/**
 * __useAddGroupMutation__
 *
 * To run a mutation, you first call `useAddGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupMutation, { data, loading, error }] = useAddGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *      frequency: // value for 'frequency'
 *      room: // value for 'room'
 *      community: // value for 'community'
 *      location: // value for 'location'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      program: // value for 'program'
 *      isPublic: // value for 'isPublic'
 *      isDirect: // value for 'isDirect'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useAddGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddGroupMutation, AddGroupMutationVariables>) {
        return Apollo.useMutation<AddGroupMutation, AddGroupMutationVariables>(AddGroupDocument, baseOptions);
      }
export type AddGroupMutationHookResult = ReturnType<typeof useAddGroupMutation>;
export type AddGroupMutationResult = Apollo.MutationResult<AddGroupMutation>;
export type AddGroupMutationOptions = Apollo.BaseMutationOptions<AddGroupMutation, AddGroupMutationVariables>;
export const AddJavaTipToThemeDocument = gql`
    mutation addJavaTipToTheme($theme: ID!, $author: ID, $body: String!) {
  addJavaTipToTheme(theme: $theme, author: $author, body: $body) {
    id
    javaTips {
      id
      author {
        id
      }
    }
  }
}
    `;
export type AddJavaTipToThemeMutationFn = Apollo.MutationFunction<AddJavaTipToThemeMutation, AddJavaTipToThemeMutationVariables>;

/**
 * __useAddJavaTipToThemeMutation__
 *
 * To run a mutation, you first call `useAddJavaTipToThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddJavaTipToThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addJavaTipToThemeMutation, { data, loading, error }] = useAddJavaTipToThemeMutation({
 *   variables: {
 *      theme: // value for 'theme'
 *      author: // value for 'author'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAddJavaTipToThemeMutation(baseOptions?: Apollo.MutationHookOptions<AddJavaTipToThemeMutation, AddJavaTipToThemeMutationVariables>) {
        return Apollo.useMutation<AddJavaTipToThemeMutation, AddJavaTipToThemeMutationVariables>(AddJavaTipToThemeDocument, baseOptions);
      }
export type AddJavaTipToThemeMutationHookResult = ReturnType<typeof useAddJavaTipToThemeMutation>;
export type AddJavaTipToThemeMutationResult = Apollo.MutationResult<AddJavaTipToThemeMutation>;
export type AddJavaTipToThemeMutationOptions = Apollo.BaseMutationOptions<AddJavaTipToThemeMutation, AddJavaTipToThemeMutationVariables>;
export const AddMemberFeedbackDocument = gql`
    mutation addMemberFeedback($session: ID!, $user: ID!, $emotion: String!, $currentScreen: Int, $songPlaying: String, $contentType: String, $contentId: String) {
  addMemberFeedback(
    session: $session
    user: $user
    emotion: $emotion
    currentScreen: $currentScreen
    songPlaying: $songPlaying
    contentType: $contentType
    contentId: $contentId
  ) {
    id
  }
}
    `;
export type AddMemberFeedbackMutationFn = Apollo.MutationFunction<AddMemberFeedbackMutation, AddMemberFeedbackMutationVariables>;

/**
 * __useAddMemberFeedbackMutation__
 *
 * To run a mutation, you first call `useAddMemberFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberFeedbackMutation, { data, loading, error }] = useAddMemberFeedbackMutation({
 *   variables: {
 *      session: // value for 'session'
 *      user: // value for 'user'
 *      emotion: // value for 'emotion'
 *      currentScreen: // value for 'currentScreen'
 *      songPlaying: // value for 'songPlaying'
 *      contentType: // value for 'contentType'
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useAddMemberFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<AddMemberFeedbackMutation, AddMemberFeedbackMutationVariables>) {
        return Apollo.useMutation<AddMemberFeedbackMutation, AddMemberFeedbackMutationVariables>(AddMemberFeedbackDocument, baseOptions);
      }
export type AddMemberFeedbackMutationHookResult = ReturnType<typeof useAddMemberFeedbackMutation>;
export type AddMemberFeedbackMutationResult = Apollo.MutationResult<AddMemberFeedbackMutation>;
export type AddMemberFeedbackMutationOptions = Apollo.BaseMutationOptions<AddMemberFeedbackMutation, AddMemberFeedbackMutationVariables>;
export const AddNoteForUserDocument = gql`
    mutation addNoteForUser($user: ID!, $author: ID!, $content: String!) {
  addNoteForUser(user: $user, author: $author, content: $content) {
    id
  }
}
    `;
export type AddNoteForUserMutationFn = Apollo.MutationFunction<AddNoteForUserMutation, AddNoteForUserMutationVariables>;

/**
 * __useAddNoteForUserMutation__
 *
 * To run a mutation, you first call `useAddNoteForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteForUserMutation, { data, loading, error }] = useAddNoteForUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *      author: // value for 'author'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useAddNoteForUserMutation(baseOptions?: Apollo.MutationHookOptions<AddNoteForUserMutation, AddNoteForUserMutationVariables>) {
        return Apollo.useMutation<AddNoteForUserMutation, AddNoteForUserMutationVariables>(AddNoteForUserDocument, baseOptions);
      }
export type AddNoteForUserMutationHookResult = ReturnType<typeof useAddNoteForUserMutation>;
export type AddNoteForUserMutationResult = Apollo.MutationResult<AddNoteForUserMutation>;
export type AddNoteForUserMutationOptions = Apollo.BaseMutationOptions<AddNoteForUserMutation, AddNoteForUserMutationVariables>;
export const AddNotificationDocument = gql`
    mutation addNotification($owner: ID!, $type: String!, $user: ID, $group: ID, $session: ID, $message: String!) {
  addNotification(
    owner: $owner
    type: $type
    user: $user
    group: $group
    session: $session
    message: $message
  ) {
    id
    owner {
      id
    }
    type
    user {
      id
    }
    group {
      id
    }
    session {
      id
    }
    message
    seenEh
    actionedEh
    createdAt
  }
}
    `;
export type AddNotificationMutationFn = Apollo.MutationFunction<AddNotificationMutation, AddNotificationMutationVariables>;

/**
 * __useAddNotificationMutation__
 *
 * To run a mutation, you first call `useAddNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNotificationMutation, { data, loading, error }] = useAddNotificationMutation({
 *   variables: {
 *      owner: // value for 'owner'
 *      type: // value for 'type'
 *      user: // value for 'user'
 *      group: // value for 'group'
 *      session: // value for 'session'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useAddNotificationMutation(baseOptions?: Apollo.MutationHookOptions<AddNotificationMutation, AddNotificationMutationVariables>) {
        return Apollo.useMutation<AddNotificationMutation, AddNotificationMutationVariables>(AddNotificationDocument, baseOptions);
      }
export type AddNotificationMutationHookResult = ReturnType<typeof useAddNotificationMutation>;
export type AddNotificationMutationResult = Apollo.MutationResult<AddNotificationMutation>;
export type AddNotificationMutationOptions = Apollo.BaseMutationOptions<AddNotificationMutation, AddNotificationMutationVariables>;
export const AddOrEditUiMetricsDocument = gql`
    mutation addOrEditUiMetrics($user: ID!, $fontSize: String, $mirroredVideo: Boolean, $innerDimensions: DimensionsInput, $outerDimensions: DimensionsInput) {
  addOrEditUiMetrics(
    user: $user
    fontSize: $fontSize
    mirroredVideo: $mirroredVideo
    innerDimensions: $innerDimensions
    outerDimensions: $outerDimensions
  )
}
    `;
export type AddOrEditUiMetricsMutationFn = Apollo.MutationFunction<AddOrEditUiMetricsMutation, AddOrEditUiMetricsMutationVariables>;

/**
 * __useAddOrEditUiMetricsMutation__
 *
 * To run a mutation, you first call `useAddOrEditUiMetricsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrEditUiMetricsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrEditUiMetricsMutation, { data, loading, error }] = useAddOrEditUiMetricsMutation({
 *   variables: {
 *      user: // value for 'user'
 *      fontSize: // value for 'fontSize'
 *      mirroredVideo: // value for 'mirroredVideo'
 *      innerDimensions: // value for 'innerDimensions'
 *      outerDimensions: // value for 'outerDimensions'
 *   },
 * });
 */
export function useAddOrEditUiMetricsMutation(baseOptions?: Apollo.MutationHookOptions<AddOrEditUiMetricsMutation, AddOrEditUiMetricsMutationVariables>) {
        return Apollo.useMutation<AddOrEditUiMetricsMutation, AddOrEditUiMetricsMutationVariables>(AddOrEditUiMetricsDocument, baseOptions);
      }
export type AddOrEditUiMetricsMutationHookResult = ReturnType<typeof useAddOrEditUiMetricsMutation>;
export type AddOrEditUiMetricsMutationResult = Apollo.MutationResult<AddOrEditUiMetricsMutation>;
export type AddOrEditUiMetricsMutationOptions = Apollo.BaseMutationOptions<AddOrEditUiMetricsMutation, AddOrEditUiMetricsMutationVariables>;
export const AddProfilePicDocument = gql`
    mutation addProfilePic($pic: Upload!) {
  addProfilePic(pic: $pic) {
    id
    profilePicUrl
  }
}
    `;
export type AddProfilePicMutationFn = Apollo.MutationFunction<AddProfilePicMutation, AddProfilePicMutationVariables>;

/**
 * __useAddProfilePicMutation__
 *
 * To run a mutation, you first call `useAddProfilePicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProfilePicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProfilePicMutation, { data, loading, error }] = useAddProfilePicMutation({
 *   variables: {
 *      pic: // value for 'pic'
 *   },
 * });
 */
export function useAddProfilePicMutation(baseOptions?: Apollo.MutationHookOptions<AddProfilePicMutation, AddProfilePicMutationVariables>) {
        return Apollo.useMutation<AddProfilePicMutation, AddProfilePicMutationVariables>(AddProfilePicDocument, baseOptions);
      }
export type AddProfilePicMutationHookResult = ReturnType<typeof useAddProfilePicMutation>;
export type AddProfilePicMutationResult = Apollo.MutationResult<AddProfilePicMutation>;
export type AddProfilePicMutationOptions = Apollo.BaseMutationOptions<AddProfilePicMutation, AddProfilePicMutationVariables>;
export const AddQuestionnaireDocument = gql`
    mutation addQuestionnaire($member: ID!, $session: ID!, $stage: String!, $optOut: Boolean!, $isDone: Boolean!) {
  addQuestionnaire(
    member: $member
    session: $session
    stage: $stage
    optOut: $optOut
    isDone: $isDone
  ) {
    id
    member
    session
    stage
    optOut
    isDone
  }
}
    `;
export type AddQuestionnaireMutationFn = Apollo.MutationFunction<AddQuestionnaireMutation, AddQuestionnaireMutationVariables>;

/**
 * __useAddQuestionnaireMutation__
 *
 * To run a mutation, you first call `useAddQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuestionnaireMutation, { data, loading, error }] = useAddQuestionnaireMutation({
 *   variables: {
 *      member: // value for 'member'
 *      session: // value for 'session'
 *      stage: // value for 'stage'
 *      optOut: // value for 'optOut'
 *      isDone: // value for 'isDone'
 *   },
 * });
 */
export function useAddQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<AddQuestionnaireMutation, AddQuestionnaireMutationVariables>) {
        return Apollo.useMutation<AddQuestionnaireMutation, AddQuestionnaireMutationVariables>(AddQuestionnaireDocument, baseOptions);
      }
export type AddQuestionnaireMutationHookResult = ReturnType<typeof useAddQuestionnaireMutation>;
export type AddQuestionnaireMutationResult = Apollo.MutationResult<AddQuestionnaireMutation>;
export type AddQuestionnaireMutationOptions = Apollo.BaseMutationOptions<AddQuestionnaireMutation, AddQuestionnaireMutationVariables>;
export const AddQuestionnaireCommunityDocument = gql`
    mutation addQuestionnaireCommunity($member: ID!, $community: ID!, $stage: String!, $optOut: Boolean!, $isDone: Boolean!) {
  addQuestionnaireCommunity(
    member: $member
    community: $community
    stage: $stage
    optOut: $optOut
    isDone: $isDone
  ) {
    id
    member
    session
    stage
    optOut
    isDone
    openDate
  }
}
    `;
export type AddQuestionnaireCommunityMutationFn = Apollo.MutationFunction<AddQuestionnaireCommunityMutation, AddQuestionnaireCommunityMutationVariables>;

/**
 * __useAddQuestionnaireCommunityMutation__
 *
 * To run a mutation, you first call `useAddQuestionnaireCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionnaireCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuestionnaireCommunityMutation, { data, loading, error }] = useAddQuestionnaireCommunityMutation({
 *   variables: {
 *      member: // value for 'member'
 *      community: // value for 'community'
 *      stage: // value for 'stage'
 *      optOut: // value for 'optOut'
 *      isDone: // value for 'isDone'
 *   },
 * });
 */
export function useAddQuestionnaireCommunityMutation(baseOptions?: Apollo.MutationHookOptions<AddQuestionnaireCommunityMutation, AddQuestionnaireCommunityMutationVariables>) {
        return Apollo.useMutation<AddQuestionnaireCommunityMutation, AddQuestionnaireCommunityMutationVariables>(AddQuestionnaireCommunityDocument, baseOptions);
      }
export type AddQuestionnaireCommunityMutationHookResult = ReturnType<typeof useAddQuestionnaireCommunityMutation>;
export type AddQuestionnaireCommunityMutationResult = Apollo.MutationResult<AddQuestionnaireCommunityMutation>;
export type AddQuestionnaireCommunityMutationOptions = Apollo.BaseMutationOptions<AddQuestionnaireCommunityMutation, AddQuestionnaireCommunityMutationVariables>;
export const AddQuoteDocument = gql`
    mutation addQuote($quote: String!, $author: String!, $theme: ID!) {
  addQuote(quote: $quote, author: $author, theme: $theme) {
    quotes {
      id
      author
      quote
    }
  }
}
    `;
export type AddQuoteMutationFn = Apollo.MutationFunction<AddQuoteMutation, AddQuoteMutationVariables>;

/**
 * __useAddQuoteMutation__
 *
 * To run a mutation, you first call `useAddQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuoteMutation, { data, loading, error }] = useAddQuoteMutation({
 *   variables: {
 *      quote: // value for 'quote'
 *      author: // value for 'author'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useAddQuoteMutation(baseOptions?: Apollo.MutationHookOptions<AddQuoteMutation, AddQuoteMutationVariables>) {
        return Apollo.useMutation<AddQuoteMutation, AddQuoteMutationVariables>(AddQuoteDocument, baseOptions);
      }
export type AddQuoteMutationHookResult = ReturnType<typeof useAddQuoteMutation>;
export type AddQuoteMutationResult = Apollo.MutationResult<AddQuoteMutation>;
export type AddQuoteMutationOptions = Apollo.BaseMutationOptions<AddQuoteMutation, AddQuoteMutationVariables>;
export const AddReflectionToThemeDocument = gql`
    mutation addReflectionToTheme($theme: ID!, $question: String) {
  addReflectionToTheme(theme: $theme, question: $question) {
    reflection {
      id
      question
    }
  }
}
    `;
export type AddReflectionToThemeMutationFn = Apollo.MutationFunction<AddReflectionToThemeMutation, AddReflectionToThemeMutationVariables>;

/**
 * __useAddReflectionToThemeMutation__
 *
 * To run a mutation, you first call `useAddReflectionToThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReflectionToThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReflectionToThemeMutation, { data, loading, error }] = useAddReflectionToThemeMutation({
 *   variables: {
 *      theme: // value for 'theme'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useAddReflectionToThemeMutation(baseOptions?: Apollo.MutationHookOptions<AddReflectionToThemeMutation, AddReflectionToThemeMutationVariables>) {
        return Apollo.useMutation<AddReflectionToThemeMutation, AddReflectionToThemeMutationVariables>(AddReflectionToThemeDocument, baseOptions);
      }
export type AddReflectionToThemeMutationHookResult = ReturnType<typeof useAddReflectionToThemeMutation>;
export type AddReflectionToThemeMutationResult = Apollo.MutationResult<AddReflectionToThemeMutation>;
export type AddReflectionToThemeMutationOptions = Apollo.BaseMutationOptions<AddReflectionToThemeMutation, AddReflectionToThemeMutationVariables>;
export const AddRegLinkDocument = gql`
    mutation addRegLink($groups: [ID], $community: ID, $link: String!) {
  addRegLink(groups: $groups, community: $community, link: $link) {
    id
    groups {
      id
      name
    }
    community {
      id
      name
    }
  }
}
    `;
export type AddRegLinkMutationFn = Apollo.MutationFunction<AddRegLinkMutation, AddRegLinkMutationVariables>;

/**
 * __useAddRegLinkMutation__
 *
 * To run a mutation, you first call `useAddRegLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRegLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRegLinkMutation, { data, loading, error }] = useAddRegLinkMutation({
 *   variables: {
 *      groups: // value for 'groups'
 *      community: // value for 'community'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useAddRegLinkMutation(baseOptions?: Apollo.MutationHookOptions<AddRegLinkMutation, AddRegLinkMutationVariables>) {
        return Apollo.useMutation<AddRegLinkMutation, AddRegLinkMutationVariables>(AddRegLinkDocument, baseOptions);
      }
export type AddRegLinkMutationHookResult = ReturnType<typeof useAddRegLinkMutation>;
export type AddRegLinkMutationResult = Apollo.MutationResult<AddRegLinkMutation>;
export type AddRegLinkMutationOptions = Apollo.BaseMutationOptions<AddRegLinkMutation, AddRegLinkMutationVariables>;
export const AddRegLinkToGroupDocument = gql`
    mutation addRegLinkToGroup($id: ID!, $link: ID!) {
  addRegLinkToGroup(id: $id, link: $link) {
    id
    name
  }
}
    `;
export type AddRegLinkToGroupMutationFn = Apollo.MutationFunction<AddRegLinkToGroupMutation, AddRegLinkToGroupMutationVariables>;

/**
 * __useAddRegLinkToGroupMutation__
 *
 * To run a mutation, you first call `useAddRegLinkToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRegLinkToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRegLinkToGroupMutation, { data, loading, error }] = useAddRegLinkToGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useAddRegLinkToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddRegLinkToGroupMutation, AddRegLinkToGroupMutationVariables>) {
        return Apollo.useMutation<AddRegLinkToGroupMutation, AddRegLinkToGroupMutationVariables>(AddRegLinkToGroupDocument, baseOptions);
      }
export type AddRegLinkToGroupMutationHookResult = ReturnType<typeof useAddRegLinkToGroupMutation>;
export type AddRegLinkToGroupMutationResult = Apollo.MutationResult<AddRegLinkToGroupMutation>;
export type AddRegLinkToGroupMutationOptions = Apollo.BaseMutationOptions<AddRegLinkToGroupMutation, AddRegLinkToGroupMutationVariables>;
export const AddSessionsDocument = gql`
    mutation addSessions($date: DateISO!, $group: ID!, $theme: ID!, $freq: String!, $isDirect: Boolean!) {
  addSessions(
    date: $date
    group: $group
    theme: $theme
    freq: $freq
    isDirect: $isDirect
  ) {
    id
  }
}
    `;
export type AddSessionsMutationFn = Apollo.MutationFunction<AddSessionsMutation, AddSessionsMutationVariables>;

/**
 * __useAddSessionsMutation__
 *
 * To run a mutation, you first call `useAddSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSessionsMutation, { data, loading, error }] = useAddSessionsMutation({
 *   variables: {
 *      date: // value for 'date'
 *      group: // value for 'group'
 *      theme: // value for 'theme'
 *      freq: // value for 'freq'
 *      isDirect: // value for 'isDirect'
 *   },
 * });
 */
export function useAddSessionsMutation(baseOptions?: Apollo.MutationHookOptions<AddSessionsMutation, AddSessionsMutationVariables>) {
        return Apollo.useMutation<AddSessionsMutation, AddSessionsMutationVariables>(AddSessionsDocument, baseOptions);
      }
export type AddSessionsMutationHookResult = ReturnType<typeof useAddSessionsMutation>;
export type AddSessionsMutationResult = Apollo.MutationResult<AddSessionsMutation>;
export type AddSessionsMutationOptions = Apollo.BaseMutationOptions<AddSessionsMutation, AddSessionsMutationVariables>;
export const AddTagToGroupDocument = gql`
    mutation addTagToGroup($tag: String!, $group: ID!) {
  addTagToGroup(tag: $tag, group: $group) {
    id
    name
  }
}
    `;
export type AddTagToGroupMutationFn = Apollo.MutationFunction<AddTagToGroupMutation, AddTagToGroupMutationVariables>;

/**
 * __useAddTagToGroupMutation__
 *
 * To run a mutation, you first call `useAddTagToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagToGroupMutation, { data, loading, error }] = useAddTagToGroupMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useAddTagToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddTagToGroupMutation, AddTagToGroupMutationVariables>) {
        return Apollo.useMutation<AddTagToGroupMutation, AddTagToGroupMutationVariables>(AddTagToGroupDocument, baseOptions);
      }
export type AddTagToGroupMutationHookResult = ReturnType<typeof useAddTagToGroupMutation>;
export type AddTagToGroupMutationResult = Apollo.MutationResult<AddTagToGroupMutation>;
export type AddTagToGroupMutationOptions = Apollo.BaseMutationOptions<AddTagToGroupMutation, AddTagToGroupMutationVariables>;
export const AddThemeDocument = gql`
    mutation addTheme($name: String!, $category: String!, $imageUrl: String, $program: String!) {
  addTheme(
    name: $name
    category: $category
    imageUrl: $imageUrl
    program: $program
  ) {
    id
  }
}
    `;
export type AddThemeMutationFn = Apollo.MutationFunction<AddThemeMutation, AddThemeMutationVariables>;

/**
 * __useAddThemeMutation__
 *
 * To run a mutation, you first call `useAddThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addThemeMutation, { data, loading, error }] = useAddThemeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      category: // value for 'category'
 *      imageUrl: // value for 'imageUrl'
 *      program: // value for 'program'
 *   },
 * });
 */
export function useAddThemeMutation(baseOptions?: Apollo.MutationHookOptions<AddThemeMutation, AddThemeMutationVariables>) {
        return Apollo.useMutation<AddThemeMutation, AddThemeMutationVariables>(AddThemeDocument, baseOptions);
      }
export type AddThemeMutationHookResult = ReturnType<typeof useAddThemeMutation>;
export type AddThemeMutationResult = Apollo.MutationResult<AddThemeMutation>;
export type AddThemeMutationOptions = Apollo.BaseMutationOptions<AddThemeMutation, AddThemeMutationVariables>;
export const AddTrainingDocument = gql`
    mutation addTraining($user: ID, $name: String!, $link: String!, $type: String!, $status: String, $step: Int) {
  addTraining(
    user: $user
    name: $name
    link: $link
    type: $type
    status: $status
    step: $step
  ) {
    id
    user {
      id
    }
    name
    link
    type
    status
    step
  }
}
    `;
export type AddTrainingMutationFn = Apollo.MutationFunction<AddTrainingMutation, AddTrainingMutationVariables>;

/**
 * __useAddTrainingMutation__
 *
 * To run a mutation, you first call `useAddTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrainingMutation, { data, loading, error }] = useAddTrainingMutation({
 *   variables: {
 *      user: // value for 'user'
 *      name: // value for 'name'
 *      link: // value for 'link'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useAddTrainingMutation(baseOptions?: Apollo.MutationHookOptions<AddTrainingMutation, AddTrainingMutationVariables>) {
        return Apollo.useMutation<AddTrainingMutation, AddTrainingMutationVariables>(AddTrainingDocument, baseOptions);
      }
export type AddTrainingMutationHookResult = ReturnType<typeof useAddTrainingMutation>;
export type AddTrainingMutationResult = Apollo.MutationResult<AddTrainingMutation>;
export type AddTrainingMutationOptions = Apollo.BaseMutationOptions<AddTrainingMutation, AddTrainingMutationVariables>;
export const AddUserDocument = gql`
    mutation addUser($firstName: String!, $lastName: String!, $phone: String, $email: String!, $password: String, $role: String!, $nickname: String, $location: String, $lat: Float, $lng: Float, $ageGroup: String, $living: String, $community: ID, $status: Boolean, $optOut: Boolean, $validUntil: DateISO) {
  addUser(
    firstName: $firstName
    lastName: $lastName
    phone: $phone
    email: $email
    password: $password
    role: $role
    nickname: $nickname
    location: $location
    lat: $lat
    lng: $lng
    ageGroup: $ageGroup
    living: $living
    community: $community
    status: $status
    optOut: $optOut
    validUntil: $validUntil
  ) {
    id
  }
}
    `;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      role: // value for 'role'
 *      nickname: // value for 'nickname'
 *      location: // value for 'location'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      ageGroup: // value for 'ageGroup'
 *      living: // value for 'living'
 *      community: // value for 'community'
 *      status: // value for 'status'
 *      optOut: // value for 'optOut'
 *      validUntil: // value for 'validUntil'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, baseOptions);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const AddUserAndPipeGroupDocument = gql`
    mutation addUserAndPipeGroup($firstName: String!, $lastName: String!, $phone: String, $email: String!, $password: String, $role: String!, $nickname: String, $location: String, $lat: Float, $lng: Float, $ageGroup: String, $living: String, $community: ID, $status: Boolean, $optOut: Boolean, $studyConsent: Boolean, $groups: [ID], $validUntil: DateISO) {
  addUserAndPipeGroup(
    firstName: $firstName
    lastName: $lastName
    phone: $phone
    email: $email
    password: $password
    role: $role
    nickname: $nickname
    location: $location
    lat: $lat
    lng: $lng
    ageGroup: $ageGroup
    living: $living
    status: $status
    optOut: $optOut
    studyConsent: $studyConsent
    community: $community
    groups: $groups
    validUntil: $validUntil
  ) {
    id
  }
}
    `;
export type AddUserAndPipeGroupMutationFn = Apollo.MutationFunction<AddUserAndPipeGroupMutation, AddUserAndPipeGroupMutationVariables>;

/**
 * __useAddUserAndPipeGroupMutation__
 *
 * To run a mutation, you first call `useAddUserAndPipeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserAndPipeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserAndPipeGroupMutation, { data, loading, error }] = useAddUserAndPipeGroupMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      role: // value for 'role'
 *      nickname: // value for 'nickname'
 *      location: // value for 'location'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      ageGroup: // value for 'ageGroup'
 *      living: // value for 'living'
 *      community: // value for 'community'
 *      status: // value for 'status'
 *      optOut: // value for 'optOut'
 *      studyConsent: // value for 'studyConsent'
 *      groups: // value for 'groups'
 *      validUntil: // value for 'validUntil'
 *   },
 * });
 */
export function useAddUserAndPipeGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddUserAndPipeGroupMutation, AddUserAndPipeGroupMutationVariables>) {
        return Apollo.useMutation<AddUserAndPipeGroupMutation, AddUserAndPipeGroupMutationVariables>(AddUserAndPipeGroupDocument, baseOptions);
      }
export type AddUserAndPipeGroupMutationHookResult = ReturnType<typeof useAddUserAndPipeGroupMutation>;
export type AddUserAndPipeGroupMutationResult = Apollo.MutationResult<AddUserAndPipeGroupMutation>;
export type AddUserAndPipeGroupMutationOptions = Apollo.BaseMutationOptions<AddUserAndPipeGroupMutation, AddUserAndPipeGroupMutationVariables>;
export const AddUserMessageToErrorLogDocument = gql`
    mutation addUserMessageToErrorLog($id: ID!, $userMessage: String) {
  addUserMessageToErrorLog(id: $id, userMessage: $userMessage) {
    id
    userMessage
  }
}
    `;
export type AddUserMessageToErrorLogMutationFn = Apollo.MutationFunction<AddUserMessageToErrorLogMutation, AddUserMessageToErrorLogMutationVariables>;

/**
 * __useAddUserMessageToErrorLogMutation__
 *
 * To run a mutation, you first call `useAddUserMessageToErrorLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMessageToErrorLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMessageToErrorLogMutation, { data, loading, error }] = useAddUserMessageToErrorLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userMessage: // value for 'userMessage'
 *   },
 * });
 */
export function useAddUserMessageToErrorLogMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMessageToErrorLogMutation, AddUserMessageToErrorLogMutationVariables>) {
        return Apollo.useMutation<AddUserMessageToErrorLogMutation, AddUserMessageToErrorLogMutationVariables>(AddUserMessageToErrorLogDocument, baseOptions);
      }
export type AddUserMessageToErrorLogMutationHookResult = ReturnType<typeof useAddUserMessageToErrorLogMutation>;
export type AddUserMessageToErrorLogMutationResult = Apollo.MutationResult<AddUserMessageToErrorLogMutation>;
export type AddUserMessageToErrorLogMutationOptions = Apollo.BaseMutationOptions<AddUserMessageToErrorLogMutation, AddUserMessageToErrorLogMutationVariables>;
export const AddUserToChatRoomDocument = gql`
    mutation addUserToChatRoom($id: ID!, $user: [ID]!) {
  addUserToChatRoom(id: $id, user: $user) {
    id
  }
}
    `;
export type AddUserToChatRoomMutationFn = Apollo.MutationFunction<AddUserToChatRoomMutation, AddUserToChatRoomMutationVariables>;

/**
 * __useAddUserToChatRoomMutation__
 *
 * To run a mutation, you first call `useAddUserToChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToChatRoomMutation, { data, loading, error }] = useAddUserToChatRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useAddUserToChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToChatRoomMutation, AddUserToChatRoomMutationVariables>) {
        return Apollo.useMutation<AddUserToChatRoomMutation, AddUserToChatRoomMutationVariables>(AddUserToChatRoomDocument, baseOptions);
      }
export type AddUserToChatRoomMutationHookResult = ReturnType<typeof useAddUserToChatRoomMutation>;
export type AddUserToChatRoomMutationResult = Apollo.MutationResult<AddUserToChatRoomMutation>;
export type AddUserToChatRoomMutationOptions = Apollo.BaseMutationOptions<AddUserToChatRoomMutation, AddUserToChatRoomMutationVariables>;
export const AddUserToCommunityDocument = gql`
    mutation addUserToCommunity($user: ID!, $as: String!, $community: ID!) {
  addUserToCommunity(user: $user, as: $as, community: $community) {
    id
    name
  }
}
    `;
export type AddUserToCommunityMutationFn = Apollo.MutationFunction<AddUserToCommunityMutation, AddUserToCommunityMutationVariables>;

/**
 * __useAddUserToCommunityMutation__
 *
 * To run a mutation, you first call `useAddUserToCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToCommunityMutation, { data, loading, error }] = useAddUserToCommunityMutation({
 *   variables: {
 *      user: // value for 'user'
 *      as: // value for 'as'
 *      community: // value for 'community'
 *   },
 * });
 */
export function useAddUserToCommunityMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToCommunityMutation, AddUserToCommunityMutationVariables>) {
        return Apollo.useMutation<AddUserToCommunityMutation, AddUserToCommunityMutationVariables>(AddUserToCommunityDocument, baseOptions);
      }
export type AddUserToCommunityMutationHookResult = ReturnType<typeof useAddUserToCommunityMutation>;
export type AddUserToCommunityMutationResult = Apollo.MutationResult<AddUserToCommunityMutation>;
export type AddUserToCommunityMutationOptions = Apollo.BaseMutationOptions<AddUserToCommunityMutation, AddUserToCommunityMutationVariables>;
export const AddUserToGroupDocument = gql`
    mutation addUserToGroup($user: ID!, $as: String!, $group: ID!) {
  addUserToGroup(user: $user, as: $as, group: $group) {
    id
    name
  }
}
    `;
export type AddUserToGroupMutationFn = Apollo.MutationFunction<AddUserToGroupMutation, AddUserToGroupMutationVariables>;

/**
 * __useAddUserToGroupMutation__
 *
 * To run a mutation, you first call `useAddUserToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToGroupMutation, { data, loading, error }] = useAddUserToGroupMutation({
 *   variables: {
 *      user: // value for 'user'
 *      as: // value for 'as'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useAddUserToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToGroupMutation, AddUserToGroupMutationVariables>) {
        return Apollo.useMutation<AddUserToGroupMutation, AddUserToGroupMutationVariables>(AddUserToGroupDocument, baseOptions);
      }
export type AddUserToGroupMutationHookResult = ReturnType<typeof useAddUserToGroupMutation>;
export type AddUserToGroupMutationResult = Apollo.MutationResult<AddUserToGroupMutation>;
export type AddUserToGroupMutationOptions = Apollo.BaseMutationOptions<AddUserToGroupMutation, AddUserToGroupMutationVariables>;
export const AddUserToSessionDocument = gql`
    mutation addUserToSession($user: ID!, $session: ID!, $as: String!) {
  addUserToSession(user: $user, session: $session, as: $as) {
    id
    sessionMembers {
      user {
        id
        firstName
        lastName
        nickname
        role
      }
    }
    facilitators {
      user {
        id
        firstName
        lastName
        nickname
        role
      }
    }
  }
}
    `;
export type AddUserToSessionMutationFn = Apollo.MutationFunction<AddUserToSessionMutation, AddUserToSessionMutationVariables>;

/**
 * __useAddUserToSessionMutation__
 *
 * To run a mutation, you first call `useAddUserToSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToSessionMutation, { data, loading, error }] = useAddUserToSessionMutation({
 *   variables: {
 *      user: // value for 'user'
 *      session: // value for 'session'
 *      as: // value for 'as'
 *   },
 * });
 */
export function useAddUserToSessionMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToSessionMutation, AddUserToSessionMutationVariables>) {
        return Apollo.useMutation<AddUserToSessionMutation, AddUserToSessionMutationVariables>(AddUserToSessionDocument, baseOptions);
      }
export type AddUserToSessionMutationHookResult = ReturnType<typeof useAddUserToSessionMutation>;
export type AddUserToSessionMutationResult = Apollo.MutationResult<AddUserToSessionMutation>;
export type AddUserToSessionMutationOptions = Apollo.BaseMutationOptions<AddUserToSessionMutation, AddUserToSessionMutationVariables>;
export const AddUserToWaitingRoomDocument = gql`
    mutation addUserToWaitingRoom($user: ID!, $session: ID!) {
  addUserToWaitingRoom(user: $user, session: $session) {
    id
  }
}
    `;
export type AddUserToWaitingRoomMutationFn = Apollo.MutationFunction<AddUserToWaitingRoomMutation, AddUserToWaitingRoomMutationVariables>;

/**
 * __useAddUserToWaitingRoomMutation__
 *
 * To run a mutation, you first call `useAddUserToWaitingRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToWaitingRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToWaitingRoomMutation, { data, loading, error }] = useAddUserToWaitingRoomMutation({
 *   variables: {
 *      user: // value for 'user'
 *      session: // value for 'session'
 *   },
 * });
 */
export function useAddUserToWaitingRoomMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToWaitingRoomMutation, AddUserToWaitingRoomMutationVariables>) {
        return Apollo.useMutation<AddUserToWaitingRoomMutation, AddUserToWaitingRoomMutationVariables>(AddUserToWaitingRoomDocument, baseOptions);
      }
export type AddUserToWaitingRoomMutationHookResult = ReturnType<typeof useAddUserToWaitingRoomMutation>;
export type AddUserToWaitingRoomMutationResult = Apollo.MutationResult<AddUserToWaitingRoomMutation>;
export type AddUserToWaitingRoomMutationOptions = Apollo.BaseMutationOptions<AddUserToWaitingRoomMutation, AddUserToWaitingRoomMutationVariables>;
export const BeHereNowDocument = gql`
    mutation beHereNow($id: ID!) {
  beHereNow(id: $id) {
    id
    openChatMembers {
      user {
        ...SmallUserFragment
      }
      palamiState
      twilioName
      lastActive
    }
    theme {
      id
      name
      category
      imageUrl
      program
    }
    createdAt
  }
}
    ${SmallUserFragmentFragmentDoc}`;
export type BeHereNowMutationFn = Apollo.MutationFunction<BeHereNowMutation, BeHereNowMutationVariables>;

/**
 * __useBeHereNowMutation__
 *
 * To run a mutation, you first call `useBeHereNowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeHereNowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beHereNowMutation, { data, loading, error }] = useBeHereNowMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBeHereNowMutation(baseOptions?: Apollo.MutationHookOptions<BeHereNowMutation, BeHereNowMutationVariables>) {
        return Apollo.useMutation<BeHereNowMutation, BeHereNowMutationVariables>(BeHereNowDocument, baseOptions);
      }
export type BeHereNowMutationHookResult = ReturnType<typeof useBeHereNowMutation>;
export type BeHereNowMutationResult = Apollo.MutationResult<BeHereNowMutation>;
export type BeHereNowMutationOptions = Apollo.BaseMutationOptions<BeHereNowMutation, BeHereNowMutationVariables>;
export const BlockUserDocument = gql`
    mutation blockUser($id: ID!) {
  blockUser(id: $id) {
    id
  }
}
    `;
export type BlockUserMutationFn = Apollo.MutationFunction<BlockUserMutation, BlockUserMutationVariables>;

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlockUserMutation(baseOptions?: Apollo.MutationHookOptions<BlockUserMutation, BlockUserMutationVariables>) {
        return Apollo.useMutation<BlockUserMutation, BlockUserMutationVariables>(BlockUserDocument, baseOptions);
      }
export type BlockUserMutationHookResult = ReturnType<typeof useBlockUserMutation>;
export type BlockUserMutationResult = Apollo.MutationResult<BlockUserMutation>;
export type BlockUserMutationOptions = Apollo.BaseMutationOptions<BlockUserMutation, BlockUserMutationVariables>;
export const ChangeDateTimeDocument = gql`
    mutation changeDateTime($group: ID!, $date: DateISO!) {
  changeDateTime(group: $group, date: $date) {
    id
  }
}
    `;
export type ChangeDateTimeMutationFn = Apollo.MutationFunction<ChangeDateTimeMutation, ChangeDateTimeMutationVariables>;

/**
 * __useChangeDateTimeMutation__
 *
 * To run a mutation, you first call `useChangeDateTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDateTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDateTimeMutation, { data, loading, error }] = useChangeDateTimeMutation({
 *   variables: {
 *      group: // value for 'group'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useChangeDateTimeMutation(baseOptions?: Apollo.MutationHookOptions<ChangeDateTimeMutation, ChangeDateTimeMutationVariables>) {
        return Apollo.useMutation<ChangeDateTimeMutation, ChangeDateTimeMutationVariables>(ChangeDateTimeDocument, baseOptions);
      }
export type ChangeDateTimeMutationHookResult = ReturnType<typeof useChangeDateTimeMutation>;
export type ChangeDateTimeMutationResult = Apollo.MutationResult<ChangeDateTimeMutation>;
export type ChangeDateTimeMutationOptions = Apollo.BaseMutationOptions<ChangeDateTimeMutation, ChangeDateTimeMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($currentPassword: String!, $newPassword: String!) {
  changePassword(currentPassword: $currentPassword, newPassword: $newPassword)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ChangePasswordByResetDocument = gql`
    mutation changePasswordByReset($newPassword: String!, $token: String!) {
  changePasswordByReset(newPassword: $newPassword, token: $token)
}
    `;
export type ChangePasswordByResetMutationFn = Apollo.MutationFunction<ChangePasswordByResetMutation, ChangePasswordByResetMutationVariables>;

/**
 * __useChangePasswordByResetMutation__
 *
 * To run a mutation, you first call `useChangePasswordByResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordByResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordByResetMutation, { data, loading, error }] = useChangePasswordByResetMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useChangePasswordByResetMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordByResetMutation, ChangePasswordByResetMutationVariables>) {
        return Apollo.useMutation<ChangePasswordByResetMutation, ChangePasswordByResetMutationVariables>(ChangePasswordByResetDocument, baseOptions);
      }
export type ChangePasswordByResetMutationHookResult = ReturnType<typeof useChangePasswordByResetMutation>;
export type ChangePasswordByResetMutationResult = Apollo.MutationResult<ChangePasswordByResetMutation>;
export type ChangePasswordByResetMutationOptions = Apollo.BaseMutationOptions<ChangePasswordByResetMutation, ChangePasswordByResetMutationVariables>;
export const ChangeThemeDocument = gql`
    mutation changeTheme($session: ID!, $theme: ID!) {
  changeTheme(session: $session, theme: $theme) {
    id
  }
}
    `;
export type ChangeThemeMutationFn = Apollo.MutationFunction<ChangeThemeMutation, ChangeThemeMutationVariables>;

/**
 * __useChangeThemeMutation__
 *
 * To run a mutation, you first call `useChangeThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeThemeMutation, { data, loading, error }] = useChangeThemeMutation({
 *   variables: {
 *      session: // value for 'session'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useChangeThemeMutation(baseOptions?: Apollo.MutationHookOptions<ChangeThemeMutation, ChangeThemeMutationVariables>) {
        return Apollo.useMutation<ChangeThemeMutation, ChangeThemeMutationVariables>(ChangeThemeDocument, baseOptions);
      }
export type ChangeThemeMutationHookResult = ReturnType<typeof useChangeThemeMutation>;
export type ChangeThemeMutationResult = Apollo.MutationResult<ChangeThemeMutation>;
export type ChangeThemeMutationOptions = Apollo.BaseMutationOptions<ChangeThemeMutation, ChangeThemeMutationVariables>;
export const ConfirmEmailDocument = gql`
    mutation confirmEmail($id: ID!, $emailConfirmationCode: String) {
  confirmEmail(id: $id, emailConfirmationCode: $emailConfirmationCode) {
    id
  }
}
    `;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<ConfirmEmailMutation, ConfirmEmailMutationVariables>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      emailConfirmationCode: // value for 'emailConfirmationCode'
 *   },
 * });
 */
export function useConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>) {
        return Apollo.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, baseOptions);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export const CreatePlanDocument = gql`
    mutation CreatePlan($name: String, $maxGroups: Int, $maxUsers: Int, $showPlan: Boolean, $stripePriceData: [PriceDataInput], $currency: CurrencyCode) {
  createPlan(
    name: $name
    maxGroups: $maxGroups
    maxUsers: $maxUsers
    showPlan: $showPlan
    stripePriceData: $stripePriceData
    currency: $currency
  ) {
    ...PlanFragment
  }
}
    ${PlanFragmentFragmentDoc}`;
export type CreatePlanMutationFn = Apollo.MutationFunction<CreatePlanMutation, CreatePlanMutationVariables>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      name: // value for 'name'
 *      maxGroups: // value for 'maxGroups'
 *      maxUsers: // value for 'maxUsers'
 *      showPlan: // value for 'showPlan'
 *      stripePriceData: // value for 'stripePriceData'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useCreatePlanMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>) {
        return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(CreatePlanDocument, baseOptions);
      }
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>;
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<CreatePlanMutation, CreatePlanMutationVariables>;
export const DeleteAnswerDocument = gql`
    mutation deleteAnswer($id: ID!, $questionnaire: ID!) {
  deleteAnswer(id: $id, questionnaire: $questionnaire) {
    id
  }
}
    `;
export type DeleteAnswerMutationFn = Apollo.MutationFunction<DeleteAnswerMutation, DeleteAnswerMutationVariables>;

/**
 * __useDeleteAnswerMutation__
 *
 * To run a mutation, you first call `useDeleteAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnswerMutation, { data, loading, error }] = useDeleteAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      questionnaire: // value for 'questionnaire'
 *   },
 * });
 */
export function useDeleteAnswerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAnswerMutation, DeleteAnswerMutationVariables>) {
        return Apollo.useMutation<DeleteAnswerMutation, DeleteAnswerMutationVariables>(DeleteAnswerDocument, baseOptions);
      }
export type DeleteAnswerMutationHookResult = ReturnType<typeof useDeleteAnswerMutation>;
export type DeleteAnswerMutationResult = Apollo.MutationResult<DeleteAnswerMutation>;
export type DeleteAnswerMutationOptions = Apollo.BaseMutationOptions<DeleteAnswerMutation, DeleteAnswerMutationVariables>;
export const DeleteChatRoomDocument = gql`
    mutation deleteChatRoom($id: ID!) {
  deleteChatRoom(id: $id) {
    id
  }
}
    `;
export type DeleteChatRoomMutationFn = Apollo.MutationFunction<DeleteChatRoomMutation, DeleteChatRoomMutationVariables>;

/**
 * __useDeleteChatRoomMutation__
 *
 * To run a mutation, you first call `useDeleteChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChatRoomMutation, { data, loading, error }] = useDeleteChatRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChatRoomMutation, DeleteChatRoomMutationVariables>) {
        return Apollo.useMutation<DeleteChatRoomMutation, DeleteChatRoomMutationVariables>(DeleteChatRoomDocument, baseOptions);
      }
export type DeleteChatRoomMutationHookResult = ReturnType<typeof useDeleteChatRoomMutation>;
export type DeleteChatRoomMutationResult = Apollo.MutationResult<DeleteChatRoomMutation>;
export type DeleteChatRoomMutationOptions = Apollo.BaseMutationOptions<DeleteChatRoomMutation, DeleteChatRoomMutationVariables>;
export const DeleteFaqDocument = gql`
    mutation deleteFaq($id: ID!) {
  deleteFaq(id: $id) {
    id
    question
    answer
  }
}
    `;
export type DeleteFaqMutationFn = Apollo.MutationFunction<DeleteFaqMutation, DeleteFaqMutationVariables>;

/**
 * __useDeleteFaqMutation__
 *
 * To run a mutation, you first call `useDeleteFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFaqMutation, { data, loading, error }] = useDeleteFaqMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFaqMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFaqMutation, DeleteFaqMutationVariables>) {
        return Apollo.useMutation<DeleteFaqMutation, DeleteFaqMutationVariables>(DeleteFaqDocument, baseOptions);
      }
export type DeleteFaqMutationHookResult = ReturnType<typeof useDeleteFaqMutation>;
export type DeleteFaqMutationResult = Apollo.MutationResult<DeleteFaqMutation>;
export type DeleteFaqMutationOptions = Apollo.BaseMutationOptions<DeleteFaqMutation, DeleteFaqMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation deleteGroup($id: ID!) {
  deleteGroup(id: $id) {
    id
  }
}
    `;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, baseOptions);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const DeleteMessageDocument = gql`
    mutation deleteMessage($id: ID!, $message: ID!) {
  deleteMessage(id: $id, message: $message) {
    id
  }
}
    `;
export type DeleteMessageMutationFn = Apollo.MutationFunction<DeleteMessageMutation, DeleteMessageMutationVariables>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useDeleteMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMessageMutation, DeleteMessageMutationVariables>) {
        return Apollo.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(DeleteMessageDocument, baseOptions);
      }
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<DeleteMessageMutation, DeleteMessageMutationVariables>;
export const DeleteNotificationDocument = gql`
    mutation deleteNotification($id: ID!) {
  deleteNotification(id: $id) {
    id
  }
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<DeleteNotificationMutation, DeleteNotificationMutationVariables>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>) {
        return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, baseOptions);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>;
export const DeleteQuestionnaireDocument = gql`
    mutation deleteQuestionnaire($id: ID!) {
  deleteQuestionnaire(id: $id) {
    id
  }
}
    `;
export type DeleteQuestionnaireMutationFn = Apollo.MutationFunction<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>;

/**
 * __useDeleteQuestionnaireMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionnaireMutation, { data, loading, error }] = useDeleteQuestionnaireMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>) {
        return Apollo.useMutation<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>(DeleteQuestionnaireDocument, baseOptions);
      }
export type DeleteQuestionnaireMutationHookResult = ReturnType<typeof useDeleteQuestionnaireMutation>;
export type DeleteQuestionnaireMutationResult = Apollo.MutationResult<DeleteQuestionnaireMutation>;
export type DeleteQuestionnaireMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>;
export const DeleteRegLinkDocument = gql`
    mutation deleteRegLink($id: ID!) {
  deleteRegLink(id: $id) {
    id
  }
}
    `;
export type DeleteRegLinkMutationFn = Apollo.MutationFunction<DeleteRegLinkMutation, DeleteRegLinkMutationVariables>;

/**
 * __useDeleteRegLinkMutation__
 *
 * To run a mutation, you first call `useDeleteRegLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegLinkMutation, { data, loading, error }] = useDeleteRegLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRegLinkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegLinkMutation, DeleteRegLinkMutationVariables>) {
        return Apollo.useMutation<DeleteRegLinkMutation, DeleteRegLinkMutationVariables>(DeleteRegLinkDocument, baseOptions);
      }
export type DeleteRegLinkMutationHookResult = ReturnType<typeof useDeleteRegLinkMutation>;
export type DeleteRegLinkMutationResult = Apollo.MutationResult<DeleteRegLinkMutation>;
export type DeleteRegLinkMutationOptions = Apollo.BaseMutationOptions<DeleteRegLinkMutation, DeleteRegLinkMutationVariables>;
export const DeleteTrainingDocument = gql`
    mutation deleteTraining($id: ID!) {
  deleteTraining(id: $id) {
    id
    user {
      id
    }
    name
    link
    type
    status
    step
  }
}
    `;
export type DeleteTrainingMutationFn = Apollo.MutationFunction<DeleteTrainingMutation, DeleteTrainingMutationVariables>;

/**
 * __useDeleteTrainingMutation__
 *
 * To run a mutation, you first call `useDeleteTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrainingMutation, { data, loading, error }] = useDeleteTrainingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTrainingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTrainingMutation, DeleteTrainingMutationVariables>) {
        return Apollo.useMutation<DeleteTrainingMutation, DeleteTrainingMutationVariables>(DeleteTrainingDocument, baseOptions);
      }
export type DeleteTrainingMutationHookResult = ReturnType<typeof useDeleteTrainingMutation>;
export type DeleteTrainingMutationResult = Apollo.MutationResult<DeleteTrainingMutation>;
export type DeleteTrainingMutationOptions = Apollo.BaseMutationOptions<DeleteTrainingMutation, DeleteTrainingMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: ID!) {
  deleteUser(id: $id) {
    id
    firstName
    nickname
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DenyRequestDocument = gql`
    mutation denyRequest($user: ID!, $group: ID!) {
  denyRequest(user: $user, group: $group) {
    id
    name
  }
}
    `;
export type DenyRequestMutationFn = Apollo.MutationFunction<DenyRequestMutation, DenyRequestMutationVariables>;

/**
 * __useDenyRequestMutation__
 *
 * To run a mutation, you first call `useDenyRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDenyRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [denyRequestMutation, { data, loading, error }] = useDenyRequestMutation({
 *   variables: {
 *      user: // value for 'user'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useDenyRequestMutation(baseOptions?: Apollo.MutationHookOptions<DenyRequestMutation, DenyRequestMutationVariables>) {
        return Apollo.useMutation<DenyRequestMutation, DenyRequestMutationVariables>(DenyRequestDocument, baseOptions);
      }
export type DenyRequestMutationHookResult = ReturnType<typeof useDenyRequestMutation>;
export type DenyRequestMutationResult = Apollo.MutationResult<DenyRequestMutation>;
export type DenyRequestMutationOptions = Apollo.BaseMutationOptions<DenyRequestMutation, DenyRequestMutationVariables>;
export const EditAllMuteStateDocument = gql`
    mutation editAllMuteState($session: ID!, $muteState: Boolean!) {
  editAllMuteState(session: $session, muteState: $muteState) {
    facilitators {
      user {
        id
        firstName
        lastName
        nickname
      }
      sessionRole
      muteState
    }
    sessionMembers {
      user {
        id
        firstName
        lastName
        nickname
      }
      sessionRole
      muteState
    }
  }
}
    `;
export type EditAllMuteStateMutationFn = Apollo.MutationFunction<EditAllMuteStateMutation, EditAllMuteStateMutationVariables>;

/**
 * __useEditAllMuteStateMutation__
 *
 * To run a mutation, you first call `useEditAllMuteStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAllMuteStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAllMuteStateMutation, { data, loading, error }] = useEditAllMuteStateMutation({
 *   variables: {
 *      session: // value for 'session'
 *      muteState: // value for 'muteState'
 *   },
 * });
 */
export function useEditAllMuteStateMutation(baseOptions?: Apollo.MutationHookOptions<EditAllMuteStateMutation, EditAllMuteStateMutationVariables>) {
        return Apollo.useMutation<EditAllMuteStateMutation, EditAllMuteStateMutationVariables>(EditAllMuteStateDocument, baseOptions);
      }
export type EditAllMuteStateMutationHookResult = ReturnType<typeof useEditAllMuteStateMutation>;
export type EditAllMuteStateMutationResult = Apollo.MutationResult<EditAllMuteStateMutation>;
export type EditAllMuteStateMutationOptions = Apollo.BaseMutationOptions<EditAllMuteStateMutation, EditAllMuteStateMutationVariables>;
export const EditAllRoomStateDocument = gql`
    mutation editAllRoomState($session: ID!) {
  editAllRoomState(session: $session) {
    facilitators {
      user {
        id
        firstName
        lastName
        nickname
      }
      sessionRole
      muteState
    }
    sessionMembers {
      user {
        id
        firstName
        lastName
        nickname
      }
      sessionRole
      muteState
    }
  }
}
    `;
export type EditAllRoomStateMutationFn = Apollo.MutationFunction<EditAllRoomStateMutation, EditAllRoomStateMutationVariables>;

/**
 * __useEditAllRoomStateMutation__
 *
 * To run a mutation, you first call `useEditAllRoomStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAllRoomStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAllRoomStateMutation, { data, loading, error }] = useEditAllRoomStateMutation({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useEditAllRoomStateMutation(baseOptions?: Apollo.MutationHookOptions<EditAllRoomStateMutation, EditAllRoomStateMutationVariables>) {
        return Apollo.useMutation<EditAllRoomStateMutation, EditAllRoomStateMutationVariables>(EditAllRoomStateDocument, baseOptions);
      }
export type EditAllRoomStateMutationHookResult = ReturnType<typeof useEditAllRoomStateMutation>;
export type EditAllRoomStateMutationResult = Apollo.MutationResult<EditAllRoomStateMutation>;
export type EditAllRoomStateMutationOptions = Apollo.BaseMutationOptions<EditAllRoomStateMutation, EditAllRoomStateMutationVariables>;
export const EditAllVidStateDocument = gql`
    mutation editAllVidState($session: ID!, $vidState: Boolean!) {
  editAllVidState(session: $session, vidState: $vidState) {
    facilitators {
      user {
        id
        firstName
        lastName
        nickname
      }
      sessionRole
      vidState
    }
    sessionMembers {
      user {
        id
        firstName
        lastName
        nickname
      }
      sessionRole
      vidState
    }
  }
}
    `;
export type EditAllVidStateMutationFn = Apollo.MutationFunction<EditAllVidStateMutation, EditAllVidStateMutationVariables>;

/**
 * __useEditAllVidStateMutation__
 *
 * To run a mutation, you first call `useEditAllVidStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAllVidStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAllVidStateMutation, { data, loading, error }] = useEditAllVidStateMutation({
 *   variables: {
 *      session: // value for 'session'
 *      vidState: // value for 'vidState'
 *   },
 * });
 */
export function useEditAllVidStateMutation(baseOptions?: Apollo.MutationHookOptions<EditAllVidStateMutation, EditAllVidStateMutationVariables>) {
        return Apollo.useMutation<EditAllVidStateMutation, EditAllVidStateMutationVariables>(EditAllVidStateDocument, baseOptions);
      }
export type EditAllVidStateMutationHookResult = ReturnType<typeof useEditAllVidStateMutation>;
export type EditAllVidStateMutationResult = Apollo.MutationResult<EditAllVidStateMutation>;
export type EditAllVidStateMutationOptions = Apollo.BaseMutationOptions<EditAllVidStateMutation, EditAllVidStateMutationVariables>;
export const EditAnswerDocument = gql`
    mutation editAnswer($id: ID!, $questionnaire: ID!, $number: Int!, $body: String!, $value: Int, $textAns: String) {
  editAnswer(
    id: $id
    questionnaire: $questionnaire
    number: $number
    body: $body
    value: $value
    textAns: $textAns
  ) {
    id
  }
}
    `;
export type EditAnswerMutationFn = Apollo.MutationFunction<EditAnswerMutation, EditAnswerMutationVariables>;

/**
 * __useEditAnswerMutation__
 *
 * To run a mutation, you first call `useEditAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAnswerMutation, { data, loading, error }] = useEditAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      questionnaire: // value for 'questionnaire'
 *      number: // value for 'number'
 *      body: // value for 'body'
 *      value: // value for 'value'
 *      textAns: // value for 'textAns'
 *   },
 * });
 */
export function useEditAnswerMutation(baseOptions?: Apollo.MutationHookOptions<EditAnswerMutation, EditAnswerMutationVariables>) {
        return Apollo.useMutation<EditAnswerMutation, EditAnswerMutationVariables>(EditAnswerDocument, baseOptions);
      }
export type EditAnswerMutationHookResult = ReturnType<typeof useEditAnswerMutation>;
export type EditAnswerMutationResult = Apollo.MutationResult<EditAnswerMutation>;
export type EditAnswerMutationOptions = Apollo.BaseMutationOptions<EditAnswerMutation, EditAnswerMutationVariables>;
export const EditCommunityDocument = gql`
    mutation editCommunity($id: ID!, $name: String, $phone: String, $email: String, $address: String, $maxUsers: Int, $maxGroups: Int) {
  editCommunity(
    id: $id
    name: $name
    phone: $phone
    email: $email
    address: $address
    maxUsers: $maxUsers
    maxGroups: $maxGroups
  ) {
    name
    phone
    email
    address
    maxUsers
    maxGroups
  }
}
    `;
export type EditCommunityMutationFn = Apollo.MutationFunction<EditCommunityMutation, EditCommunityMutationVariables>;

/**
 * __useEditCommunityMutation__
 *
 * To run a mutation, you first call `useEditCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCommunityMutation, { data, loading, error }] = useEditCommunityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      address: // value for 'address'
 *      maxUsers: // value for 'maxUsers'
 *      maxGroups: // value for 'maxGroups'
 *   },
 * });
 */
export function useEditCommunityMutation(baseOptions?: Apollo.MutationHookOptions<EditCommunityMutation, EditCommunityMutationVariables>) {
        return Apollo.useMutation<EditCommunityMutation, EditCommunityMutationVariables>(EditCommunityDocument, baseOptions);
      }
export type EditCommunityMutationHookResult = ReturnType<typeof useEditCommunityMutation>;
export type EditCommunityMutationResult = Apollo.MutationResult<EditCommunityMutation>;
export type EditCommunityMutationOptions = Apollo.BaseMutationOptions<EditCommunityMutation, EditCommunityMutationVariables>;
export const EditDefaultsDocument = gql`
    mutation editDefaults($id: ID!, $defaultCam: Boolean, $defaultMic: Boolean) {
  editDefaults(id: $id, defaultCam: $defaultCam, defaultMic: $defaultMic) {
    id
    defaultCam
    defaultMic
  }
}
    `;
export type EditDefaultsMutationFn = Apollo.MutationFunction<EditDefaultsMutation, EditDefaultsMutationVariables>;

/**
 * __useEditDefaultsMutation__
 *
 * To run a mutation, you first call `useEditDefaultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDefaultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDefaultsMutation, { data, loading, error }] = useEditDefaultsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      defaultCam: // value for 'defaultCam'
 *      defaultMic: // value for 'defaultMic'
 *   },
 * });
 */
export function useEditDefaultsMutation(baseOptions?: Apollo.MutationHookOptions<EditDefaultsMutation, EditDefaultsMutationVariables>) {
        return Apollo.useMutation<EditDefaultsMutation, EditDefaultsMutationVariables>(EditDefaultsDocument, baseOptions);
      }
export type EditDefaultsMutationHookResult = ReturnType<typeof useEditDefaultsMutation>;
export type EditDefaultsMutationResult = Apollo.MutationResult<EditDefaultsMutation>;
export type EditDefaultsMutationOptions = Apollo.BaseMutationOptions<EditDefaultsMutation, EditDefaultsMutationVariables>;
export const EditDiscussionFromThemeDocument = gql`
    mutation editDiscussionFromTheme($id: ID!, $theme: ID!, $question: String) {
  editDiscussionFromTheme(id: $id, theme: $theme, question: $question) {
    discussions {
      id
      question
    }
  }
}
    `;
export type EditDiscussionFromThemeMutationFn = Apollo.MutationFunction<EditDiscussionFromThemeMutation, EditDiscussionFromThemeMutationVariables>;

/**
 * __useEditDiscussionFromThemeMutation__
 *
 * To run a mutation, you first call `useEditDiscussionFromThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDiscussionFromThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDiscussionFromThemeMutation, { data, loading, error }] = useEditDiscussionFromThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      theme: // value for 'theme'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useEditDiscussionFromThemeMutation(baseOptions?: Apollo.MutationHookOptions<EditDiscussionFromThemeMutation, EditDiscussionFromThemeMutationVariables>) {
        return Apollo.useMutation<EditDiscussionFromThemeMutation, EditDiscussionFromThemeMutationVariables>(EditDiscussionFromThemeDocument, baseOptions);
      }
export type EditDiscussionFromThemeMutationHookResult = ReturnType<typeof useEditDiscussionFromThemeMutation>;
export type EditDiscussionFromThemeMutationResult = Apollo.MutationResult<EditDiscussionFromThemeMutation>;
export type EditDiscussionFromThemeMutationOptions = Apollo.BaseMutationOptions<EditDiscussionFromThemeMutation, EditDiscussionFromThemeMutationVariables>;
export const EditFaqDocument = gql`
    mutation editFaq($id: ID!, $answer: String!, $question: String!) {
  editFaq(id: $id, answer: $answer, question: $question) {
    id
    question
    answer
  }
}
    `;
export type EditFaqMutationFn = Apollo.MutationFunction<EditFaqMutation, EditFaqMutationVariables>;

/**
 * __useEditFaqMutation__
 *
 * To run a mutation, you first call `useEditFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFaqMutation, { data, loading, error }] = useEditFaqMutation({
 *   variables: {
 *      id: // value for 'id'
 *      answer: // value for 'answer'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useEditFaqMutation(baseOptions?: Apollo.MutationHookOptions<EditFaqMutation, EditFaqMutationVariables>) {
        return Apollo.useMutation<EditFaqMutation, EditFaqMutationVariables>(EditFaqDocument, baseOptions);
      }
export type EditFaqMutationHookResult = ReturnType<typeof useEditFaqMutation>;
export type EditFaqMutationResult = Apollo.MutationResult<EditFaqMutation>;
export type EditFaqMutationOptions = Apollo.BaseMutationOptions<EditFaqMutation, EditFaqMutationVariables>;
export const EditGroupDocument = gql`
    mutation editGroup($id: ID!, $name: String, $startDate: DateISO, $frequency: String, $room: String, $program: String, $community: ID, $location: String, $lat: Float, $lng: Float, $isPublic: Boolean, $isDirect: Boolean, $tags: [String]) {
  editGroup(
    id: $id
    name: $name
    startDate: $startDate
    frequency: $frequency
    room: $room
    program: $program
    location: $location
    lat: $lat
    lng: $lng
    community: $community
    isPublic: $isPublic
    isDirect: $isDirect
    tags: $tags
  ) {
    id
  }
}
    `;
export type EditGroupMutationFn = Apollo.MutationFunction<EditGroupMutation, EditGroupMutationVariables>;

/**
 * __useEditGroupMutation__
 *
 * To run a mutation, you first call `useEditGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGroupMutation, { data, loading, error }] = useEditGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *      frequency: // value for 'frequency'
 *      room: // value for 'room'
 *      program: // value for 'program'
 *      community: // value for 'community'
 *      location: // value for 'location'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      isPublic: // value for 'isPublic'
 *      isDirect: // value for 'isDirect'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useEditGroupMutation(baseOptions?: Apollo.MutationHookOptions<EditGroupMutation, EditGroupMutationVariables>) {
        return Apollo.useMutation<EditGroupMutation, EditGroupMutationVariables>(EditGroupDocument, baseOptions);
      }
export type EditGroupMutationHookResult = ReturnType<typeof useEditGroupMutation>;
export type EditGroupMutationResult = Apollo.MutationResult<EditGroupMutation>;
export type EditGroupMutationOptions = Apollo.BaseMutationOptions<EditGroupMutation, EditGroupMutationVariables>;
export const EditNotificationDocument = gql`
    mutation editNotification($id: ID!, $owner: ID, $type: String, $user: ID, $group: ID, $session: ID, $message: String, $seenEh: Boolean, $actionedEh: Boolean) {
  editNotification(
    id: $id
    owner: $owner
    type: $type
    user: $user
    group: $group
    session: $session
    message: $message
    seenEh: $seenEh
    actionedEh: $actionedEh
  ) {
    id
    owner {
      id
    }
    type
    user {
      id
    }
    group {
      id
    }
    session {
      id
    }
    message
    seenEh
    actionedEh
    createdAt
  }
}
    `;
export type EditNotificationMutationFn = Apollo.MutationFunction<EditNotificationMutation, EditNotificationMutationVariables>;

/**
 * __useEditNotificationMutation__
 *
 * To run a mutation, you first call `useEditNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNotificationMutation, { data, loading, error }] = useEditNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      owner: // value for 'owner'
 *      type: // value for 'type'
 *      user: // value for 'user'
 *      group: // value for 'group'
 *      session: // value for 'session'
 *      message: // value for 'message'
 *      seenEh: // value for 'seenEh'
 *      actionedEh: // value for 'actionedEh'
 *   },
 * });
 */
export function useEditNotificationMutation(baseOptions?: Apollo.MutationHookOptions<EditNotificationMutation, EditNotificationMutationVariables>) {
        return Apollo.useMutation<EditNotificationMutation, EditNotificationMutationVariables>(EditNotificationDocument, baseOptions);
      }
export type EditNotificationMutationHookResult = ReturnType<typeof useEditNotificationMutation>;
export type EditNotificationMutationResult = Apollo.MutationResult<EditNotificationMutation>;
export type EditNotificationMutationOptions = Apollo.BaseMutationOptions<EditNotificationMutation, EditNotificationMutationVariables>;
export const EditPlanDocument = gql`
    mutation EditPlan($id: ID, $name: String, $maxGroups: Int, $maxUsers: Int, $showPlan: Boolean, $stripePriceData: [PriceDataInput]) {
  editPlan(
    id: $id
    name: $name
    maxGroups: $maxGroups
    maxUsers: $maxUsers
    showPlan: $showPlan
    stripePriceData: $stripePriceData
  ) {
    ...PlanFragment
  }
}
    ${PlanFragmentFragmentDoc}`;
export type EditPlanMutationFn = Apollo.MutationFunction<EditPlanMutation, EditPlanMutationVariables>;

/**
 * __useEditPlanMutation__
 *
 * To run a mutation, you first call `useEditPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlanMutation, { data, loading, error }] = useEditPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      maxGroups: // value for 'maxGroups'
 *      maxUsers: // value for 'maxUsers'
 *      showPlan: // value for 'showPlan'
 *      stripePriceData: // value for 'stripePriceData'
 *   },
 * });
 */
export function useEditPlanMutation(baseOptions?: Apollo.MutationHookOptions<EditPlanMutation, EditPlanMutationVariables>) {
        return Apollo.useMutation<EditPlanMutation, EditPlanMutationVariables>(EditPlanDocument, baseOptions);
      }
export type EditPlanMutationHookResult = ReturnType<typeof useEditPlanMutation>;
export type EditPlanMutationResult = Apollo.MutationResult<EditPlanMutation>;
export type EditPlanMutationOptions = Apollo.BaseMutationOptions<EditPlanMutation, EditPlanMutationVariables>;
export const EditQuestionnaireDocument = gql`
    mutation editQuestionnaire($member: ID!, $session: ID!, $stage: String!, $optOut: Boolean, $isDone: Boolean) {
  editQuestionnaire(
    member: $member
    session: $session
    stage: $stage
    optOut: $optOut
    isDone: $isDone
  ) {
    id
    member
    session
    stage
    answers {
      id
      number
      body
      value
      createdAt
    }
    optOut
    isDone
  }
}
    `;
export type EditQuestionnaireMutationFn = Apollo.MutationFunction<EditQuestionnaireMutation, EditQuestionnaireMutationVariables>;

/**
 * __useEditQuestionnaireMutation__
 *
 * To run a mutation, you first call `useEditQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editQuestionnaireMutation, { data, loading, error }] = useEditQuestionnaireMutation({
 *   variables: {
 *      member: // value for 'member'
 *      session: // value for 'session'
 *      stage: // value for 'stage'
 *      optOut: // value for 'optOut'
 *      isDone: // value for 'isDone'
 *   },
 * });
 */
export function useEditQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<EditQuestionnaireMutation, EditQuestionnaireMutationVariables>) {
        return Apollo.useMutation<EditQuestionnaireMutation, EditQuestionnaireMutationVariables>(EditQuestionnaireDocument, baseOptions);
      }
export type EditQuestionnaireMutationHookResult = ReturnType<typeof useEditQuestionnaireMutation>;
export type EditQuestionnaireMutationResult = Apollo.MutationResult<EditQuestionnaireMutation>;
export type EditQuestionnaireMutationOptions = Apollo.BaseMutationOptions<EditQuestionnaireMutation, EditQuestionnaireMutationVariables>;
export const EditQuestionnaireCommunityDocument = gql`
    mutation editQuestionnaireCommunity($id: ID!, $member: ID, $community: ID, $stage: String, $optOut: Boolean!, $isDone: Boolean) {
  editQuestionnaireCommunity(
    id: $id
    member: $member
    community: $community
    stage: $stage
    optOut: $optOut
    isDone: $isDone
  ) {
    id
    member
    session
    stage
    answers {
      id
      number
      body
      value
      createdAt
    }
    optOut
    isDone
  }
}
    `;
export type EditQuestionnaireCommunityMutationFn = Apollo.MutationFunction<EditQuestionnaireCommunityMutation, EditQuestionnaireCommunityMutationVariables>;

/**
 * __useEditQuestionnaireCommunityMutation__
 *
 * To run a mutation, you first call `useEditQuestionnaireCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditQuestionnaireCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editQuestionnaireCommunityMutation, { data, loading, error }] = useEditQuestionnaireCommunityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      member: // value for 'member'
 *      community: // value for 'community'
 *      stage: // value for 'stage'
 *      optOut: // value for 'optOut'
 *      isDone: // value for 'isDone'
 *   },
 * });
 */
export function useEditQuestionnaireCommunityMutation(baseOptions?: Apollo.MutationHookOptions<EditQuestionnaireCommunityMutation, EditQuestionnaireCommunityMutationVariables>) {
        return Apollo.useMutation<EditQuestionnaireCommunityMutation, EditQuestionnaireCommunityMutationVariables>(EditQuestionnaireCommunityDocument, baseOptions);
      }
export type EditQuestionnaireCommunityMutationHookResult = ReturnType<typeof useEditQuestionnaireCommunityMutation>;
export type EditQuestionnaireCommunityMutationResult = Apollo.MutationResult<EditQuestionnaireCommunityMutation>;
export type EditQuestionnaireCommunityMutationOptions = Apollo.BaseMutationOptions<EditQuestionnaireCommunityMutation, EditQuestionnaireCommunityMutationVariables>;
export const EditQuoteInThemeDocument = gql`
    mutation editQuoteInTheme($quoteId: ID!, $quote: String!, $author: String!, $theme: ID!) {
  editQuoteInTheme(
    quoteId: $quoteId
    quote: $quote
    author: $author
    theme: $theme
  ) {
    quotes {
      id
      author
      quote
    }
  }
}
    `;
export type EditQuoteInThemeMutationFn = Apollo.MutationFunction<EditQuoteInThemeMutation, EditQuoteInThemeMutationVariables>;

/**
 * __useEditQuoteInThemeMutation__
 *
 * To run a mutation, you first call `useEditQuoteInThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditQuoteInThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editQuoteInThemeMutation, { data, loading, error }] = useEditQuoteInThemeMutation({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *      quote: // value for 'quote'
 *      author: // value for 'author'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useEditQuoteInThemeMutation(baseOptions?: Apollo.MutationHookOptions<EditQuoteInThemeMutation, EditQuoteInThemeMutationVariables>) {
        return Apollo.useMutation<EditQuoteInThemeMutation, EditQuoteInThemeMutationVariables>(EditQuoteInThemeDocument, baseOptions);
      }
export type EditQuoteInThemeMutationHookResult = ReturnType<typeof useEditQuoteInThemeMutation>;
export type EditQuoteInThemeMutationResult = Apollo.MutationResult<EditQuoteInThemeMutation>;
export type EditQuoteInThemeMutationOptions = Apollo.BaseMutationOptions<EditQuoteInThemeMutation, EditQuoteInThemeMutationVariables>;
export const EditReflectionFromThemeDocument = gql`
    mutation editReflectionFromTheme($id: ID!, $theme: ID!, $question: String) {
  editReflectionFromTheme(id: $id, theme: $theme, question: $question) {
    reflection {
      id
      question
    }
  }
}
    `;
export type EditReflectionFromThemeMutationFn = Apollo.MutationFunction<EditReflectionFromThemeMutation, EditReflectionFromThemeMutationVariables>;

/**
 * __useEditReflectionFromThemeMutation__
 *
 * To run a mutation, you first call `useEditReflectionFromThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReflectionFromThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReflectionFromThemeMutation, { data, loading, error }] = useEditReflectionFromThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      theme: // value for 'theme'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useEditReflectionFromThemeMutation(baseOptions?: Apollo.MutationHookOptions<EditReflectionFromThemeMutation, EditReflectionFromThemeMutationVariables>) {
        return Apollo.useMutation<EditReflectionFromThemeMutation, EditReflectionFromThemeMutationVariables>(EditReflectionFromThemeDocument, baseOptions);
      }
export type EditReflectionFromThemeMutationHookResult = ReturnType<typeof useEditReflectionFromThemeMutation>;
export type EditReflectionFromThemeMutationResult = Apollo.MutationResult<EditReflectionFromThemeMutation>;
export type EditReflectionFromThemeMutationOptions = Apollo.BaseMutationOptions<EditReflectionFromThemeMutation, EditReflectionFromThemeMutationVariables>;
export const EditRegLinkDocument = gql`
    mutation editRegLink($id: ID!, $groups: [ID], $community: ID, $link: String!) {
  editRegLink(id: $id, groups: $groups, community: $community, link: $link) {
    id
    groups {
      id
      name
    }
    community {
      id
      name
    }
  }
}
    `;
export type EditRegLinkMutationFn = Apollo.MutationFunction<EditRegLinkMutation, EditRegLinkMutationVariables>;

/**
 * __useEditRegLinkMutation__
 *
 * To run a mutation, you first call `useEditRegLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRegLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRegLinkMutation, { data, loading, error }] = useEditRegLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      groups: // value for 'groups'
 *      community: // value for 'community'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useEditRegLinkMutation(baseOptions?: Apollo.MutationHookOptions<EditRegLinkMutation, EditRegLinkMutationVariables>) {
        return Apollo.useMutation<EditRegLinkMutation, EditRegLinkMutationVariables>(EditRegLinkDocument, baseOptions);
      }
export type EditRegLinkMutationHookResult = ReturnType<typeof useEditRegLinkMutation>;
export type EditRegLinkMutationResult = Apollo.MutationResult<EditRegLinkMutation>;
export type EditRegLinkMutationOptions = Apollo.BaseMutationOptions<EditRegLinkMutation, EditRegLinkMutationVariables>;
export const EditRoleDocument = gql`
    mutation editRole($id: ID!, $role: String!) {
  editRole(id: $id, role: $role) {
    id
    firstName
    lastName
    nickname
    phone
    email
    dateOfBirth
    role
    address
    status
    validUntil
  }
}
    `;
export type EditRoleMutationFn = Apollo.MutationFunction<EditRoleMutation, EditRoleMutationVariables>;

/**
 * __useEditRoleMutation__
 *
 * To run a mutation, you first call `useEditRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRoleMutation, { data, loading, error }] = useEditRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditRoleMutation, EditRoleMutationVariables>) {
        return Apollo.useMutation<EditRoleMutation, EditRoleMutationVariables>(EditRoleDocument, baseOptions);
      }
export type EditRoleMutationHookResult = ReturnType<typeof useEditRoleMutation>;
export type EditRoleMutationResult = Apollo.MutationResult<EditRoleMutation>;
export type EditRoleMutationOptions = Apollo.BaseMutationOptions<EditRoleMutation, EditRoleMutationVariables>;
export const EditRunningSessionDocument = gql`
    mutation editRunningSession($id: ID!, $currentScreen: Int, $songPlaying: String, $isDirect: Boolean, $videoChatStatus: String, $contentType: String, $contentId: String, $globalMessage: String) {
  editRunningSession(
    id: $id
    currentScreen: $currentScreen
    songPlaying: $songPlaying
    isDirect: $isDirect
    videoChatStatus: $videoChatStatus
    contentType: $contentType
    contentId: $contentId
    globalMessage: $globalMessage
  ) {
    status
    currentScreen
    songPlaying
    isDirect
    videoChatStatus
    contentId
    contentType
    globalMessage
  }
}
    `;
export type EditRunningSessionMutationFn = Apollo.MutationFunction<EditRunningSessionMutation, EditRunningSessionMutationVariables>;

/**
 * __useEditRunningSessionMutation__
 *
 * To run a mutation, you first call `useEditRunningSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRunningSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRunningSessionMutation, { data, loading, error }] = useEditRunningSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      currentScreen: // value for 'currentScreen'
 *      songPlaying: // value for 'songPlaying'
 *      isDirect: // value for 'isDirect'
 *      videoChatStatus: // value for 'videoChatStatus'
 *      contentType: // value for 'contentType'
 *      contentId: // value for 'contentId'
 *      globalMessage: // value for 'globalMessage'
 *   },
 * });
 */
export function useEditRunningSessionMutation(baseOptions?: Apollo.MutationHookOptions<EditRunningSessionMutation, EditRunningSessionMutationVariables>) {
        return Apollo.useMutation<EditRunningSessionMutation, EditRunningSessionMutationVariables>(EditRunningSessionDocument, baseOptions);
      }
export type EditRunningSessionMutationHookResult = ReturnType<typeof useEditRunningSessionMutation>;
export type EditRunningSessionMutationResult = Apollo.MutationResult<EditRunningSessionMutation>;
export type EditRunningSessionMutationOptions = Apollo.BaseMutationOptions<EditRunningSessionMutation, EditRunningSessionMutationVariables>;
export const EditSessionMemberMirroredDocument = gql`
    mutation editSessionMemberMirrored($session: ID!, $member: ID!, $isMirrored: Boolean!) {
  editSessionMemberMirrored(
    session: $session
    member: $member
    isMirrored: $isMirrored
  ) {
    id
  }
}
    `;
export type EditSessionMemberMirroredMutationFn = Apollo.MutationFunction<EditSessionMemberMirroredMutation, EditSessionMemberMirroredMutationVariables>;

/**
 * __useEditSessionMemberMirroredMutation__
 *
 * To run a mutation, you first call `useEditSessionMemberMirroredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSessionMemberMirroredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSessionMemberMirroredMutation, { data, loading, error }] = useEditSessionMemberMirroredMutation({
 *   variables: {
 *      session: // value for 'session'
 *      member: // value for 'member'
 *      isMirrored: // value for 'isMirrored'
 *   },
 * });
 */
export function useEditSessionMemberMirroredMutation(baseOptions?: Apollo.MutationHookOptions<EditSessionMemberMirroredMutation, EditSessionMemberMirroredMutationVariables>) {
        return Apollo.useMutation<EditSessionMemberMirroredMutation, EditSessionMemberMirroredMutationVariables>(EditSessionMemberMirroredDocument, baseOptions);
      }
export type EditSessionMemberMirroredMutationHookResult = ReturnType<typeof useEditSessionMemberMirroredMutation>;
export type EditSessionMemberMirroredMutationResult = Apollo.MutationResult<EditSessionMemberMirroredMutation>;
export type EditSessionMemberMirroredMutationOptions = Apollo.BaseMutationOptions<EditSessionMemberMirroredMutation, EditSessionMemberMirroredMutationVariables>;
export const EditSessionMemberMuteStateDocument = gql`
    mutation editSessionMemberMuteState($session: ID!, $member: ID!, $muteState: Boolean!, $room: Boolean!, $role: String!) {
  editSessionMemberMuteState(
    session: $session
    member: $member
    muteState: $muteState
    room: $room
    role: $role
  ) {
    id
  }
}
    `;
export type EditSessionMemberMuteStateMutationFn = Apollo.MutationFunction<EditSessionMemberMuteStateMutation, EditSessionMemberMuteStateMutationVariables>;

/**
 * __useEditSessionMemberMuteStateMutation__
 *
 * To run a mutation, you first call `useEditSessionMemberMuteStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSessionMemberMuteStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSessionMemberMuteStateMutation, { data, loading, error }] = useEditSessionMemberMuteStateMutation({
 *   variables: {
 *      session: // value for 'session'
 *      member: // value for 'member'
 *      muteState: // value for 'muteState'
 *      room: // value for 'room'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditSessionMemberMuteStateMutation(baseOptions?: Apollo.MutationHookOptions<EditSessionMemberMuteStateMutation, EditSessionMemberMuteStateMutationVariables>) {
        return Apollo.useMutation<EditSessionMemberMuteStateMutation, EditSessionMemberMuteStateMutationVariables>(EditSessionMemberMuteStateDocument, baseOptions);
      }
export type EditSessionMemberMuteStateMutationHookResult = ReturnType<typeof useEditSessionMemberMuteStateMutation>;
export type EditSessionMemberMuteStateMutationResult = Apollo.MutationResult<EditSessionMemberMuteStateMutation>;
export type EditSessionMemberMuteStateMutationOptions = Apollo.BaseMutationOptions<EditSessionMemberMuteStateMutation, EditSessionMemberMuteStateMutationVariables>;
export const EditSessionMemberPalamiStateDocument = gql`
    mutation editSessionMemberPalamiState($session: ID!, $member: ID!, $palamiState: Boolean!, $role: String!) {
  editSessionMemberPalamiState(
    session: $session
    member: $member
    palamiState: $palamiState
    role: $role
  ) {
    id
  }
}
    `;
export type EditSessionMemberPalamiStateMutationFn = Apollo.MutationFunction<EditSessionMemberPalamiStateMutation, EditSessionMemberPalamiStateMutationVariables>;

/**
 * __useEditSessionMemberPalamiStateMutation__
 *
 * To run a mutation, you first call `useEditSessionMemberPalamiStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSessionMemberPalamiStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSessionMemberPalamiStateMutation, { data, loading, error }] = useEditSessionMemberPalamiStateMutation({
 *   variables: {
 *      session: // value for 'session'
 *      member: // value for 'member'
 *      palamiState: // value for 'palamiState'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditSessionMemberPalamiStateMutation(baseOptions?: Apollo.MutationHookOptions<EditSessionMemberPalamiStateMutation, EditSessionMemberPalamiStateMutationVariables>) {
        return Apollo.useMutation<EditSessionMemberPalamiStateMutation, EditSessionMemberPalamiStateMutationVariables>(EditSessionMemberPalamiStateDocument, baseOptions);
      }
export type EditSessionMemberPalamiStateMutationHookResult = ReturnType<typeof useEditSessionMemberPalamiStateMutation>;
export type EditSessionMemberPalamiStateMutationResult = Apollo.MutationResult<EditSessionMemberPalamiStateMutation>;
export type EditSessionMemberPalamiStateMutationOptions = Apollo.BaseMutationOptions<EditSessionMemberPalamiStateMutation, EditSessionMemberPalamiStateMutationVariables>;
export const EditSessionMemberRoleDocument = gql`
    mutation editSessionMemberRole($session: ID!, $member: ID!, $role: String!) {
  editSessionMemberRole(session: $session, member: $member, role: $role) {
    id
    sessionMembers {
      id
      sessionRole
    }
  }
}
    `;
export type EditSessionMemberRoleMutationFn = Apollo.MutationFunction<EditSessionMemberRoleMutation, EditSessionMemberRoleMutationVariables>;

/**
 * __useEditSessionMemberRoleMutation__
 *
 * To run a mutation, you first call `useEditSessionMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSessionMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSessionMemberRoleMutation, { data, loading, error }] = useEditSessionMemberRoleMutation({
 *   variables: {
 *      session: // value for 'session'
 *      member: // value for 'member'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditSessionMemberRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditSessionMemberRoleMutation, EditSessionMemberRoleMutationVariables>) {
        return Apollo.useMutation<EditSessionMemberRoleMutation, EditSessionMemberRoleMutationVariables>(EditSessionMemberRoleDocument, baseOptions);
      }
export type EditSessionMemberRoleMutationHookResult = ReturnType<typeof useEditSessionMemberRoleMutation>;
export type EditSessionMemberRoleMutationResult = Apollo.MutationResult<EditSessionMemberRoleMutation>;
export type EditSessionMemberRoleMutationOptions = Apollo.BaseMutationOptions<EditSessionMemberRoleMutation, EditSessionMemberRoleMutationVariables>;
export const EditSessionMemberRoomStateDocument = gql`
    mutation editSessionMemberRoomState($session: ID!, $member: ID!, $roomState: Boolean!, $muteState: Boolean!, $role: String!) {
  editSessionMemberRoomState(
    session: $session
    member: $member
    roomState: $roomState
    muteState: $muteState
    role: $role
  ) {
    id
  }
}
    `;
export type EditSessionMemberRoomStateMutationFn = Apollo.MutationFunction<EditSessionMemberRoomStateMutation, EditSessionMemberRoomStateMutationVariables>;

/**
 * __useEditSessionMemberRoomStateMutation__
 *
 * To run a mutation, you first call `useEditSessionMemberRoomStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSessionMemberRoomStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSessionMemberRoomStateMutation, { data, loading, error }] = useEditSessionMemberRoomStateMutation({
 *   variables: {
 *      session: // value for 'session'
 *      member: // value for 'member'
 *      roomState: // value for 'roomState'
 *      muteState: // value for 'muteState'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditSessionMemberRoomStateMutation(baseOptions?: Apollo.MutationHookOptions<EditSessionMemberRoomStateMutation, EditSessionMemberRoomStateMutationVariables>) {
        return Apollo.useMutation<EditSessionMemberRoomStateMutation, EditSessionMemberRoomStateMutationVariables>(EditSessionMemberRoomStateDocument, baseOptions);
      }
export type EditSessionMemberRoomStateMutationHookResult = ReturnType<typeof useEditSessionMemberRoomStateMutation>;
export type EditSessionMemberRoomStateMutationResult = Apollo.MutationResult<EditSessionMemberRoomStateMutation>;
export type EditSessionMemberRoomStateMutationOptions = Apollo.BaseMutationOptions<EditSessionMemberRoomStateMutation, EditSessionMemberRoomStateMutationVariables>;
export const EditSessionMemberSharingDocument = gql`
    mutation editSessionMemberSharing($session: ID!, $member: ID!, $sharePhoto: Boolean!, $shareQuote: Boolean!, $shareSong: Boolean!, $role: String!) {
  editSessionMemberSharing(
    session: $session
    member: $member
    sharePhoto: $sharePhoto
    shareQuote: $shareQuote
    shareSong: $shareSong
    role: $role
  ) {
    id
  }
}
    `;
export type EditSessionMemberSharingMutationFn = Apollo.MutationFunction<EditSessionMemberSharingMutation, EditSessionMemberSharingMutationVariables>;

/**
 * __useEditSessionMemberSharingMutation__
 *
 * To run a mutation, you first call `useEditSessionMemberSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSessionMemberSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSessionMemberSharingMutation, { data, loading, error }] = useEditSessionMemberSharingMutation({
 *   variables: {
 *      session: // value for 'session'
 *      member: // value for 'member'
 *      sharePhoto: // value for 'sharePhoto'
 *      shareQuote: // value for 'shareQuote'
 *      shareSong: // value for 'shareSong'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditSessionMemberSharingMutation(baseOptions?: Apollo.MutationHookOptions<EditSessionMemberSharingMutation, EditSessionMemberSharingMutationVariables>) {
        return Apollo.useMutation<EditSessionMemberSharingMutation, EditSessionMemberSharingMutationVariables>(EditSessionMemberSharingDocument, baseOptions);
      }
export type EditSessionMemberSharingMutationHookResult = ReturnType<typeof useEditSessionMemberSharingMutation>;
export type EditSessionMemberSharingMutationResult = Apollo.MutationResult<EditSessionMemberSharingMutation>;
export type EditSessionMemberSharingMutationOptions = Apollo.BaseMutationOptions<EditSessionMemberSharingMutation, EditSessionMemberSharingMutationVariables>;
export const EditSessionMemberVidStateDocument = gql`
    mutation editSessionMemberVidState($session: ID!, $member: ID!, $vidState: Boolean!, $role: String!) {
  editSessionMemberVidState(
    session: $session
    member: $member
    vidState: $vidState
    role: $role
  ) {
    id
  }
}
    `;
export type EditSessionMemberVidStateMutationFn = Apollo.MutationFunction<EditSessionMemberVidStateMutation, EditSessionMemberVidStateMutationVariables>;

/**
 * __useEditSessionMemberVidStateMutation__
 *
 * To run a mutation, you first call `useEditSessionMemberVidStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSessionMemberVidStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSessionMemberVidStateMutation, { data, loading, error }] = useEditSessionMemberVidStateMutation({
 *   variables: {
 *      session: // value for 'session'
 *      member: // value for 'member'
 *      vidState: // value for 'vidState'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditSessionMemberVidStateMutation(baseOptions?: Apollo.MutationHookOptions<EditSessionMemberVidStateMutation, EditSessionMemberVidStateMutationVariables>) {
        return Apollo.useMutation<EditSessionMemberVidStateMutation, EditSessionMemberVidStateMutationVariables>(EditSessionMemberVidStateDocument, baseOptions);
      }
export type EditSessionMemberVidStateMutationHookResult = ReturnType<typeof useEditSessionMemberVidStateMutation>;
export type EditSessionMemberVidStateMutationResult = Apollo.MutationResult<EditSessionMemberVidStateMutation>;
export type EditSessionMemberVidStateMutationOptions = Apollo.BaseMutationOptions<EditSessionMemberVidStateMutation, EditSessionMemberVidStateMutationVariables>;
export const EditSongDocument = gql`
    mutation editSong($id: ID!, $title: String, $lyrics: String, $url: String, $imageUrl: String) {
  editSong(
    id: $id
    title: $title
    lyrics: $lyrics
    url: $url
    imageUrl: $imageUrl
  ) {
    id
    title
    lyrics
    url
    isDeleted
  }
}
    `;
export type EditSongMutationFn = Apollo.MutationFunction<EditSongMutation, EditSongMutationVariables>;

/**
 * __useEditSongMutation__
 *
 * To run a mutation, you first call `useEditSongMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSongMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSongMutation, { data, loading, error }] = useEditSongMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      lyrics: // value for 'lyrics'
 *      url: // value for 'url'
 *      imageUrl: // value for 'imageUrl'
 *   },
 * });
 */
export function useEditSongMutation(baseOptions?: Apollo.MutationHookOptions<EditSongMutation, EditSongMutationVariables>) {
        return Apollo.useMutation<EditSongMutation, EditSongMutationVariables>(EditSongDocument, baseOptions);
      }
export type EditSongMutationHookResult = ReturnType<typeof useEditSongMutation>;
export type EditSongMutationResult = Apollo.MutationResult<EditSongMutation>;
export type EditSongMutationOptions = Apollo.BaseMutationOptions<EditSongMutation, EditSongMutationVariables>;
export const EditThemeDocument = gql`
    mutation editTheme($id: ID!, $name: String, $category: String, $imageUrl: String, $program: String) {
  editTheme(
    id: $id
    name: $name
    category: $category
    imageUrl: $imageUrl
    program: $program
  ) {
    id
  }
}
    `;
export type EditThemeMutationFn = Apollo.MutationFunction<EditThemeMutation, EditThemeMutationVariables>;

/**
 * __useEditThemeMutation__
 *
 * To run a mutation, you first call `useEditThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editThemeMutation, { data, loading, error }] = useEditThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      category: // value for 'category'
 *      imageUrl: // value for 'imageUrl'
 *      program: // value for 'program'
 *   },
 * });
 */
export function useEditThemeMutation(baseOptions?: Apollo.MutationHookOptions<EditThemeMutation, EditThemeMutationVariables>) {
        return Apollo.useMutation<EditThemeMutation, EditThemeMutationVariables>(EditThemeDocument, baseOptions);
      }
export type EditThemeMutationHookResult = ReturnType<typeof useEditThemeMutation>;
export type EditThemeMutationResult = Apollo.MutationResult<EditThemeMutation>;
export type EditThemeMutationOptions = Apollo.BaseMutationOptions<EditThemeMutation, EditThemeMutationVariables>;
export const EditTrainingDocument = gql`
    mutation editTraining($id: ID!, $user: ID, $name: String, $link: String, $type: String, $status: String, $step: Int) {
  editTraining(
    id: $id
    user: $user
    name: $name
    link: $link
    type: $type
    status: $status
    step: $step
  ) {
    id
    user {
      id
    }
    name
    link
    type
    status
    step
  }
}
    `;
export type EditTrainingMutationFn = Apollo.MutationFunction<EditTrainingMutation, EditTrainingMutationVariables>;

/**
 * __useEditTrainingMutation__
 *
 * To run a mutation, you first call `useEditTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTrainingMutation, { data, loading, error }] = useEditTrainingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *      name: // value for 'name'
 *      link: // value for 'link'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useEditTrainingMutation(baseOptions?: Apollo.MutationHookOptions<EditTrainingMutation, EditTrainingMutationVariables>) {
        return Apollo.useMutation<EditTrainingMutation, EditTrainingMutationVariables>(EditTrainingDocument, baseOptions);
      }
export type EditTrainingMutationHookResult = ReturnType<typeof useEditTrainingMutation>;
export type EditTrainingMutationResult = Apollo.MutationResult<EditTrainingMutation>;
export type EditTrainingMutationOptions = Apollo.BaseMutationOptions<EditTrainingMutation, EditTrainingMutationVariables>;
export const EditTypingDocument = gql`
    mutation editTyping($id: ID!, $areThey: Boolean!) {
  editTyping(id: $id, areThey: $areThey) {
    id
  }
}
    `;
export type EditTypingMutationFn = Apollo.MutationFunction<EditTypingMutation, EditTypingMutationVariables>;

/**
 * __useEditTypingMutation__
 *
 * To run a mutation, you first call `useEditTypingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTypingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTypingMutation, { data, loading, error }] = useEditTypingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      areThey: // value for 'areThey'
 *   },
 * });
 */
export function useEditTypingMutation(baseOptions?: Apollo.MutationHookOptions<EditTypingMutation, EditTypingMutationVariables>) {
        return Apollo.useMutation<EditTypingMutation, EditTypingMutationVariables>(EditTypingDocument, baseOptions);
      }
export type EditTypingMutationHookResult = ReturnType<typeof useEditTypingMutation>;
export type EditTypingMutationResult = Apollo.MutationResult<EditTypingMutation>;
export type EditTypingMutationOptions = Apollo.BaseMutationOptions<EditTypingMutation, EditTypingMutationVariables>;
export const EditUserDocument = gql`
    mutation editUser($id: ID!, $firstName: String, $lastName: String, $phone: String, $email: String, $password: String, $nickname: String, $bio: String, $interests: [Int], $location: String, $lat: Float, $lng: Float, $ageGroup: String, $living: String, $community: ID, $status: Boolean, $studyConsent: Boolean, $lastQuestionnaire: ID, $optOut: Boolean, $validUntil: DateISO, $dateOfBirth: DateISO) {
  editUser(
    id: $id
    firstName: $firstName
    lastName: $lastName
    phone: $phone
    email: $email
    nickname: $nickname
    bio: $bio
    interests: $interests
    location: $location
    lat: $lat
    lng: $lng
    ageGroup: $ageGroup
    living: $living
    community: $community
    password: $password
    status: $status
    lastQuestionnaire: $lastQuestionnaire
    studyConsent: $studyConsent
    optOut: $optOut
    validUntil: $validUntil
    dateOfBirth: $dateOfBirth
  ) {
    id
    firstName
    lastName
    phone
    email
    nickname
    location
    lat
    lng
    ageGroup
    living
    status
    optOut
    validUntil
  }
}
    `;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      nickname: // value for 'nickname'
 *      bio: // value for 'bio'
 *      interests: // value for 'interests'
 *      location: // value for 'location'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      ageGroup: // value for 'ageGroup'
 *      living: // value for 'living'
 *      community: // value for 'community'
 *      status: // value for 'status'
 *      studyConsent: // value for 'studyConsent'
 *      lastQuestionnaire: // value for 'lastQuestionnaire'
 *      optOut: // value for 'optOut'
 *      validUntil: // value for 'validUntil'
 *      dateOfBirth: // value for 'dateOfBirth'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, baseOptions);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const EditVersionDocument = gql`
    mutation editVersion($id: ID!, $v: String!, $pass: String!) {
  editVersion(id: $id, v: $v, pass: $pass) {
    id
    v
    readAt
  }
}
    `;
export type EditVersionMutationFn = Apollo.MutationFunction<EditVersionMutation, EditVersionMutationVariables>;

/**
 * __useEditVersionMutation__
 *
 * To run a mutation, you first call `useEditVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVersionMutation, { data, loading, error }] = useEditVersionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      v: // value for 'v'
 *      pass: // value for 'pass'
 *   },
 * });
 */
export function useEditVersionMutation(baseOptions?: Apollo.MutationHookOptions<EditVersionMutation, EditVersionMutationVariables>) {
        return Apollo.useMutation<EditVersionMutation, EditVersionMutationVariables>(EditVersionDocument, baseOptions);
      }
export type EditVersionMutationHookResult = ReturnType<typeof useEditVersionMutation>;
export type EditVersionMutationResult = Apollo.MutationResult<EditVersionMutation>;
export type EditVersionMutationOptions = Apollo.BaseMutationOptions<EditVersionMutation, EditVersionMutationVariables>;
export const EnterOpenChatRoomDocument = gql`
    mutation enterOpenChatRoom($id: ID!) {
  enterOpenChatRoom(id: $id) {
    id
    openChatMembers {
      user {
        ...SmallUserFragment
      }
      palamiState
      twilioName
      lastActive
    }
    theme {
      id
      name
      category
      imageUrl
      program
    }
    createdAt
  }
}
    ${SmallUserFragmentFragmentDoc}`;
export type EnterOpenChatRoomMutationFn = Apollo.MutationFunction<EnterOpenChatRoomMutation, EnterOpenChatRoomMutationVariables>;

/**
 * __useEnterOpenChatRoomMutation__
 *
 * To run a mutation, you first call `useEnterOpenChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnterOpenChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enterOpenChatRoomMutation, { data, loading, error }] = useEnterOpenChatRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnterOpenChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<EnterOpenChatRoomMutation, EnterOpenChatRoomMutationVariables>) {
        return Apollo.useMutation<EnterOpenChatRoomMutation, EnterOpenChatRoomMutationVariables>(EnterOpenChatRoomDocument, baseOptions);
      }
export type EnterOpenChatRoomMutationHookResult = ReturnType<typeof useEnterOpenChatRoomMutation>;
export type EnterOpenChatRoomMutationResult = Apollo.MutationResult<EnterOpenChatRoomMutation>;
export type EnterOpenChatRoomMutationOptions = Apollo.BaseMutationOptions<EnterOpenChatRoomMutation, EnterOpenChatRoomMutationVariables>;
export const FreezeGroupDocument = gql`
    mutation freezeGroup($id: ID!, $siONo: Boolean) {
  freezeGroup(id: $id, siONo: $siONo) {
    id
  }
}
    `;
export type FreezeGroupMutationFn = Apollo.MutationFunction<FreezeGroupMutation, FreezeGroupMutationVariables>;

/**
 * __useFreezeGroupMutation__
 *
 * To run a mutation, you first call `useFreezeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFreezeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [freezeGroupMutation, { data, loading, error }] = useFreezeGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      siONo: // value for 'siONo'
 *   },
 * });
 */
export function useFreezeGroupMutation(baseOptions?: Apollo.MutationHookOptions<FreezeGroupMutation, FreezeGroupMutationVariables>) {
        return Apollo.useMutation<FreezeGroupMutation, FreezeGroupMutationVariables>(FreezeGroupDocument, baseOptions);
      }
export type FreezeGroupMutationHookResult = ReturnType<typeof useFreezeGroupMutation>;
export type FreezeGroupMutationResult = Apollo.MutationResult<FreezeGroupMutation>;
export type FreezeGroupMutationOptions = Apollo.BaseMutationOptions<FreezeGroupMutation, FreezeGroupMutationVariables>;
export const HandoverMasterDocument = gql`
    mutation handoverMaster($session: ID!, $newMaster: ID!) {
  handoverMaster(session: $session, newMaster: $newMaster) {
    id
    date
    sessionMaster {
      id
    }
    group {
      id
    }
  }
}
    `;
export type HandoverMasterMutationFn = Apollo.MutationFunction<HandoverMasterMutation, HandoverMasterMutationVariables>;

/**
 * __useHandoverMasterMutation__
 *
 * To run a mutation, you first call `useHandoverMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandoverMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handoverMasterMutation, { data, loading, error }] = useHandoverMasterMutation({
 *   variables: {
 *      session: // value for 'session'
 *      newMaster: // value for 'newMaster'
 *   },
 * });
 */
export function useHandoverMasterMutation(baseOptions?: Apollo.MutationHookOptions<HandoverMasterMutation, HandoverMasterMutationVariables>) {
        return Apollo.useMutation<HandoverMasterMutation, HandoverMasterMutationVariables>(HandoverMasterDocument, baseOptions);
      }
export type HandoverMasterMutationHookResult = ReturnType<typeof useHandoverMasterMutation>;
export type HandoverMasterMutationResult = Apollo.MutationResult<HandoverMasterMutation>;
export type HandoverMasterMutationOptions = Apollo.BaseMutationOptions<HandoverMasterMutation, HandoverMasterMutationVariables>;
export const HideMessageDocument = gql`
    mutation hideMessage($id: ID!, $message: ID!) {
  hideMessage(id: $id, message: $message) {
    id
  }
}
    `;
export type HideMessageMutationFn = Apollo.MutationFunction<HideMessageMutation, HideMessageMutationVariables>;

/**
 * __useHideMessageMutation__
 *
 * To run a mutation, you first call `useHideMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideMessageMutation, { data, loading, error }] = useHideMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useHideMessageMutation(baseOptions?: Apollo.MutationHookOptions<HideMessageMutation, HideMessageMutationVariables>) {
        return Apollo.useMutation<HideMessageMutation, HideMessageMutationVariables>(HideMessageDocument, baseOptions);
      }
export type HideMessageMutationHookResult = ReturnType<typeof useHideMessageMutation>;
export type HideMessageMutationResult = Apollo.MutationResult<HideMessageMutation>;
export type HideMessageMutationOptions = Apollo.BaseMutationOptions<HideMessageMutation, HideMessageMutationVariables>;
export const LeaveOpenChatRoomDocument = gql`
    mutation leaveOpenChatRoom($id: ID!) {
  leaveOpenChatRoom(id: $id) {
    id
    openChatMembers {
      user {
        ...SmallUserFragment
      }
      palamiState
      twilioName
      lastActive
    }
    theme {
      id
      name
      category
      imageUrl
      program
    }
    createdAt
  }
}
    ${SmallUserFragmentFragmentDoc}`;
export type LeaveOpenChatRoomMutationFn = Apollo.MutationFunction<LeaveOpenChatRoomMutation, LeaveOpenChatRoomMutationVariables>;

/**
 * __useLeaveOpenChatRoomMutation__
 *
 * To run a mutation, you first call `useLeaveOpenChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveOpenChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveOpenChatRoomMutation, { data, loading, error }] = useLeaveOpenChatRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaveOpenChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<LeaveOpenChatRoomMutation, LeaveOpenChatRoomMutationVariables>) {
        return Apollo.useMutation<LeaveOpenChatRoomMutation, LeaveOpenChatRoomMutationVariables>(LeaveOpenChatRoomDocument, baseOptions);
      }
export type LeaveOpenChatRoomMutationHookResult = ReturnType<typeof useLeaveOpenChatRoomMutation>;
export type LeaveOpenChatRoomMutationResult = Apollo.MutationResult<LeaveOpenChatRoomMutation>;
export type LeaveOpenChatRoomMutationOptions = Apollo.BaseMutationOptions<LeaveOpenChatRoomMutation, LeaveOpenChatRoomMutationVariables>;
export const LeaveSessionDocument = gql`
    mutation leaveSession($session: ID!) {
  leaveSession(session: $session)
}
    `;
export type LeaveSessionMutationFn = Apollo.MutationFunction<LeaveSessionMutation, LeaveSessionMutationVariables>;

/**
 * __useLeaveSessionMutation__
 *
 * To run a mutation, you first call `useLeaveSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveSessionMutation, { data, loading, error }] = useLeaveSessionMutation({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useLeaveSessionMutation(baseOptions?: Apollo.MutationHookOptions<LeaveSessionMutation, LeaveSessionMutationVariables>) {
        return Apollo.useMutation<LeaveSessionMutation, LeaveSessionMutationVariables>(LeaveSessionDocument, baseOptions);
      }
export type LeaveSessionMutationHookResult = ReturnType<typeof useLeaveSessionMutation>;
export type LeaveSessionMutationResult = Apollo.MutationResult<LeaveSessionMutation>;
export type LeaveSessionMutationOptions = Apollo.BaseMutationOptions<LeaveSessionMutation, LeaveSessionMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MarkSeenNotificationsDocument = gql`
    mutation markSeenNotifications($notifs: [ID]!) {
  markSeenNotifications(notifs: $notifs)
}
    `;
export type MarkSeenNotificationsMutationFn = Apollo.MutationFunction<MarkSeenNotificationsMutation, MarkSeenNotificationsMutationVariables>;

/**
 * __useMarkSeenNotificationsMutation__
 *
 * To run a mutation, you first call `useMarkSeenNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkSeenNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markSeenNotificationsMutation, { data, loading, error }] = useMarkSeenNotificationsMutation({
 *   variables: {
 *      notifs: // value for 'notifs'
 *   },
 * });
 */
export function useMarkSeenNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<MarkSeenNotificationsMutation, MarkSeenNotificationsMutationVariables>) {
        return Apollo.useMutation<MarkSeenNotificationsMutation, MarkSeenNotificationsMutationVariables>(MarkSeenNotificationsDocument, baseOptions);
      }
export type MarkSeenNotificationsMutationHookResult = ReturnType<typeof useMarkSeenNotificationsMutation>;
export type MarkSeenNotificationsMutationResult = Apollo.MutationResult<MarkSeenNotificationsMutation>;
export type MarkSeenNotificationsMutationOptions = Apollo.BaseMutationOptions<MarkSeenNotificationsMutation, MarkSeenNotificationsMutationVariables>;
export const NextOrAddOpenChatRoomDocument = gql`
    mutation nextOrAddOpenChatRoom($theme: ID!) {
  nextOrAddOpenChatRoom(theme: $theme) {
    id
    openChatMembers {
      user {
        ...SmallUserFragment
      }
      palamiState
      twilioName
      lastActive
    }
    createdAt
  }
}
    ${SmallUserFragmentFragmentDoc}`;
export type NextOrAddOpenChatRoomMutationFn = Apollo.MutationFunction<NextOrAddOpenChatRoomMutation, NextOrAddOpenChatRoomMutationVariables>;

/**
 * __useNextOrAddOpenChatRoomMutation__
 *
 * To run a mutation, you first call `useNextOrAddOpenChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNextOrAddOpenChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nextOrAddOpenChatRoomMutation, { data, loading, error }] = useNextOrAddOpenChatRoomMutation({
 *   variables: {
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useNextOrAddOpenChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<NextOrAddOpenChatRoomMutation, NextOrAddOpenChatRoomMutationVariables>) {
        return Apollo.useMutation<NextOrAddOpenChatRoomMutation, NextOrAddOpenChatRoomMutationVariables>(NextOrAddOpenChatRoomDocument, baseOptions);
      }
export type NextOrAddOpenChatRoomMutationHookResult = ReturnType<typeof useNextOrAddOpenChatRoomMutation>;
export type NextOrAddOpenChatRoomMutationResult = Apollo.MutationResult<NextOrAddOpenChatRoomMutation>;
export type NextOrAddOpenChatRoomMutationOptions = Apollo.BaseMutationOptions<NextOrAddOpenChatRoomMutation, NextOrAddOpenChatRoomMutationVariables>;
export const RemoveAnswerDocument = gql`
    mutation removeAnswer($id: ID!, $questionnaire: ID!) {
  removeAnswer(id: $id, questionnaire: $questionnaire) {
    id
  }
}
    `;
export type RemoveAnswerMutationFn = Apollo.MutationFunction<RemoveAnswerMutation, RemoveAnswerMutationVariables>;

/**
 * __useRemoveAnswerMutation__
 *
 * To run a mutation, you first call `useRemoveAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAnswerMutation, { data, loading, error }] = useRemoveAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      questionnaire: // value for 'questionnaire'
 *   },
 * });
 */
export function useRemoveAnswerMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAnswerMutation, RemoveAnswerMutationVariables>) {
        return Apollo.useMutation<RemoveAnswerMutation, RemoveAnswerMutationVariables>(RemoveAnswerDocument, baseOptions);
      }
export type RemoveAnswerMutationHookResult = ReturnType<typeof useRemoveAnswerMutation>;
export type RemoveAnswerMutationResult = Apollo.MutationResult<RemoveAnswerMutation>;
export type RemoveAnswerMutationOptions = Apollo.BaseMutationOptions<RemoveAnswerMutation, RemoveAnswerMutationVariables>;
export const RemoveDiscussionDocument = gql`
    mutation removeDiscussion($id: ID!, $theme: ID!) {
  removeDiscussion(id: $id, theme: $theme) {
    discussions {
      id
      question
    }
  }
}
    `;
export type RemoveDiscussionMutationFn = Apollo.MutationFunction<RemoveDiscussionMutation, RemoveDiscussionMutationVariables>;

/**
 * __useRemoveDiscussionMutation__
 *
 * To run a mutation, you first call `useRemoveDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDiscussionMutation, { data, loading, error }] = useRemoveDiscussionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useRemoveDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDiscussionMutation, RemoveDiscussionMutationVariables>) {
        return Apollo.useMutation<RemoveDiscussionMutation, RemoveDiscussionMutationVariables>(RemoveDiscussionDocument, baseOptions);
      }
export type RemoveDiscussionMutationHookResult = ReturnType<typeof useRemoveDiscussionMutation>;
export type RemoveDiscussionMutationResult = Apollo.MutationResult<RemoveDiscussionMutation>;
export type RemoveDiscussionMutationOptions = Apollo.BaseMutationOptions<RemoveDiscussionMutation, RemoveDiscussionMutationVariables>;
export const RemoveJavaTipDocument = gql`
    mutation removeJavaTip($id: ID!, $theme: ID!) {
  removeJavaTip(id: $id, theme: $theme) {
    id
    isDeleted
    javaTips {
      id
      author {
        id
      }
    }
  }
}
    `;
export type RemoveJavaTipMutationFn = Apollo.MutationFunction<RemoveJavaTipMutation, RemoveJavaTipMutationVariables>;

/**
 * __useRemoveJavaTipMutation__
 *
 * To run a mutation, you first call `useRemoveJavaTipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveJavaTipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeJavaTipMutation, { data, loading, error }] = useRemoveJavaTipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useRemoveJavaTipMutation(baseOptions?: Apollo.MutationHookOptions<RemoveJavaTipMutation, RemoveJavaTipMutationVariables>) {
        return Apollo.useMutation<RemoveJavaTipMutation, RemoveJavaTipMutationVariables>(RemoveJavaTipDocument, baseOptions);
      }
export type RemoveJavaTipMutationHookResult = ReturnType<typeof useRemoveJavaTipMutation>;
export type RemoveJavaTipMutationResult = Apollo.MutationResult<RemoveJavaTipMutation>;
export type RemoveJavaTipMutationOptions = Apollo.BaseMutationOptions<RemoveJavaTipMutation, RemoveJavaTipMutationVariables>;
export const RemoveQuoteFromThemeDocument = gql`
    mutation removeQuoteFromTheme($quote: ID!, $theme: ID!) {
  removeQuoteFromTheme(quote: $quote, theme: $theme) {
    quotes {
      id
      author
      quote
    }
  }
}
    `;
export type RemoveQuoteFromThemeMutationFn = Apollo.MutationFunction<RemoveQuoteFromThemeMutation, RemoveQuoteFromThemeMutationVariables>;

/**
 * __useRemoveQuoteFromThemeMutation__
 *
 * To run a mutation, you first call `useRemoveQuoteFromThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQuoteFromThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQuoteFromThemeMutation, { data, loading, error }] = useRemoveQuoteFromThemeMutation({
 *   variables: {
 *      quote: // value for 'quote'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useRemoveQuoteFromThemeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveQuoteFromThemeMutation, RemoveQuoteFromThemeMutationVariables>) {
        return Apollo.useMutation<RemoveQuoteFromThemeMutation, RemoveQuoteFromThemeMutationVariables>(RemoveQuoteFromThemeDocument, baseOptions);
      }
export type RemoveQuoteFromThemeMutationHookResult = ReturnType<typeof useRemoveQuoteFromThemeMutation>;
export type RemoveQuoteFromThemeMutationResult = Apollo.MutationResult<RemoveQuoteFromThemeMutation>;
export type RemoveQuoteFromThemeMutationOptions = Apollo.BaseMutationOptions<RemoveQuoteFromThemeMutation, RemoveQuoteFromThemeMutationVariables>;
export const RemoveReflectionDocument = gql`
    mutation removeReflection($id: ID!, $theme: ID!) {
  removeReflection(id: $id, theme: $theme) {
    reflection {
      id
      question
    }
  }
}
    `;
export type RemoveReflectionMutationFn = Apollo.MutationFunction<RemoveReflectionMutation, RemoveReflectionMutationVariables>;

/**
 * __useRemoveReflectionMutation__
 *
 * To run a mutation, you first call `useRemoveReflectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReflectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReflectionMutation, { data, loading, error }] = useRemoveReflectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useRemoveReflectionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveReflectionMutation, RemoveReflectionMutationVariables>) {
        return Apollo.useMutation<RemoveReflectionMutation, RemoveReflectionMutationVariables>(RemoveReflectionDocument, baseOptions);
      }
export type RemoveReflectionMutationHookResult = ReturnType<typeof useRemoveReflectionMutation>;
export type RemoveReflectionMutationResult = Apollo.MutationResult<RemoveReflectionMutation>;
export type RemoveReflectionMutationOptions = Apollo.BaseMutationOptions<RemoveReflectionMutation, RemoveReflectionMutationVariables>;
export const RemoveRegLinkFromGroupDocument = gql`
    mutation removeRegLinkFromGroup($id: ID!, $link: ID!) {
  removeRegLinkFromGroup(id: $id, link: $link) {
    id
    name
  }
}
    `;
export type RemoveRegLinkFromGroupMutationFn = Apollo.MutationFunction<RemoveRegLinkFromGroupMutation, RemoveRegLinkFromGroupMutationVariables>;

/**
 * __useRemoveRegLinkFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveRegLinkFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRegLinkFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRegLinkFromGroupMutation, { data, loading, error }] = useRemoveRegLinkFromGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useRemoveRegLinkFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRegLinkFromGroupMutation, RemoveRegLinkFromGroupMutationVariables>) {
        return Apollo.useMutation<RemoveRegLinkFromGroupMutation, RemoveRegLinkFromGroupMutationVariables>(RemoveRegLinkFromGroupDocument, baseOptions);
      }
export type RemoveRegLinkFromGroupMutationHookResult = ReturnType<typeof useRemoveRegLinkFromGroupMutation>;
export type RemoveRegLinkFromGroupMutationResult = Apollo.MutationResult<RemoveRegLinkFromGroupMutation>;
export type RemoveRegLinkFromGroupMutationOptions = Apollo.BaseMutationOptions<RemoveRegLinkFromGroupMutation, RemoveRegLinkFromGroupMutationVariables>;
export const RemoveTagFromGroupDocument = gql`
    mutation removeTagFromGroup($tag: String!, $group: ID!) {
  removeTagFromGroup(tag: $tag, group: $group) {
    id
    name
  }
}
    `;
export type RemoveTagFromGroupMutationFn = Apollo.MutationFunction<RemoveTagFromGroupMutation, RemoveTagFromGroupMutationVariables>;

/**
 * __useRemoveTagFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveTagFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagFromGroupMutation, { data, loading, error }] = useRemoveTagFromGroupMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useRemoveTagFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTagFromGroupMutation, RemoveTagFromGroupMutationVariables>) {
        return Apollo.useMutation<RemoveTagFromGroupMutation, RemoveTagFromGroupMutationVariables>(RemoveTagFromGroupDocument, baseOptions);
      }
export type RemoveTagFromGroupMutationHookResult = ReturnType<typeof useRemoveTagFromGroupMutation>;
export type RemoveTagFromGroupMutationResult = Apollo.MutationResult<RemoveTagFromGroupMutation>;
export type RemoveTagFromGroupMutationOptions = Apollo.BaseMutationOptions<RemoveTagFromGroupMutation, RemoveTagFromGroupMutationVariables>;
export const RemoveUserFromChatRoomDocument = gql`
    mutation removeUserFromChatRoom($id: ID!, $user: [ID]!) {
  removeUserFromChatRoom(id: $id, user: $user) {
    id
  }
}
    `;
export type RemoveUserFromChatRoomMutationFn = Apollo.MutationFunction<RemoveUserFromChatRoomMutation, RemoveUserFromChatRoomMutationVariables>;

/**
 * __useRemoveUserFromChatRoomMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromChatRoomMutation, { data, loading, error }] = useRemoveUserFromChatRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useRemoveUserFromChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromChatRoomMutation, RemoveUserFromChatRoomMutationVariables>) {
        return Apollo.useMutation<RemoveUserFromChatRoomMutation, RemoveUserFromChatRoomMutationVariables>(RemoveUserFromChatRoomDocument, baseOptions);
      }
export type RemoveUserFromChatRoomMutationHookResult = ReturnType<typeof useRemoveUserFromChatRoomMutation>;
export type RemoveUserFromChatRoomMutationResult = Apollo.MutationResult<RemoveUserFromChatRoomMutation>;
export type RemoveUserFromChatRoomMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromChatRoomMutation, RemoveUserFromChatRoomMutationVariables>;
export const RemoveUserFromCommunityDocument = gql`
    mutation removeUserFromCommunity($user: ID!, $as: String!, $community: ID!) {
  removeUserFromCommunity(user: $user, as: $as, community: $community) {
    id
    name
  }
}
    `;
export type RemoveUserFromCommunityMutationFn = Apollo.MutationFunction<RemoveUserFromCommunityMutation, RemoveUserFromCommunityMutationVariables>;

/**
 * __useRemoveUserFromCommunityMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromCommunityMutation, { data, loading, error }] = useRemoveUserFromCommunityMutation({
 *   variables: {
 *      user: // value for 'user'
 *      as: // value for 'as'
 *      community: // value for 'community'
 *   },
 * });
 */
export function useRemoveUserFromCommunityMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromCommunityMutation, RemoveUserFromCommunityMutationVariables>) {
        return Apollo.useMutation<RemoveUserFromCommunityMutation, RemoveUserFromCommunityMutationVariables>(RemoveUserFromCommunityDocument, baseOptions);
      }
export type RemoveUserFromCommunityMutationHookResult = ReturnType<typeof useRemoveUserFromCommunityMutation>;
export type RemoveUserFromCommunityMutationResult = Apollo.MutationResult<RemoveUserFromCommunityMutation>;
export type RemoveUserFromCommunityMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromCommunityMutation, RemoveUserFromCommunityMutationVariables>;
export const RemoveUserFromGroupDocument = gql`
    mutation removeUserFromGroup($user: ID!, $group: ID!, $as: String!) {
  removeUserFromGroup(user: $user, group: $group, as: $as) {
    id
    facilitators {
      id
      firstName
      lastName
      nickname
    }
    members {
      id
      firstName
      lastName
      nickname
    }
  }
}
    `;
export type RemoveUserFromGroupMutationFn = Apollo.MutationFunction<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>;

/**
 * __useRemoveUserFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromGroupMutation, { data, loading, error }] = useRemoveUserFromGroupMutation({
 *   variables: {
 *      user: // value for 'user'
 *      group: // value for 'group'
 *      as: // value for 'as'
 *   },
 * });
 */
export function useRemoveUserFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>) {
        return Apollo.useMutation<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>(RemoveUserFromGroupDocument, baseOptions);
      }
export type RemoveUserFromGroupMutationHookResult = ReturnType<typeof useRemoveUserFromGroupMutation>;
export type RemoveUserFromGroupMutationResult = Apollo.MutationResult<RemoveUserFromGroupMutation>;
export type RemoveUserFromGroupMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>;
export const RemoveUserFromSessionDocument = gql`
    mutation removeUserFromSession($user: ID!, $session: ID!, $as: String!) {
  removeUserFromSession(user: $user, session: $session, as: $as) {
    id
    sessionMembers {
      user {
        id
        firstName
        lastName
        nickname
        role
      }
    }
    facilitators {
      user {
        id
        firstName
        lastName
        nickname
        role
      }
    }
  }
}
    `;
export type RemoveUserFromSessionMutationFn = Apollo.MutationFunction<RemoveUserFromSessionMutation, RemoveUserFromSessionMutationVariables>;

/**
 * __useRemoveUserFromSessionMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromSessionMutation, { data, loading, error }] = useRemoveUserFromSessionMutation({
 *   variables: {
 *      user: // value for 'user'
 *      session: // value for 'session'
 *      as: // value for 'as'
 *   },
 * });
 */
export function useRemoveUserFromSessionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromSessionMutation, RemoveUserFromSessionMutationVariables>) {
        return Apollo.useMutation<RemoveUserFromSessionMutation, RemoveUserFromSessionMutationVariables>(RemoveUserFromSessionDocument, baseOptions);
      }
export type RemoveUserFromSessionMutationHookResult = ReturnType<typeof useRemoveUserFromSessionMutation>;
export type RemoveUserFromSessionMutationResult = Apollo.MutationResult<RemoveUserFromSessionMutation>;
export type RemoveUserFromSessionMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromSessionMutation, RemoveUserFromSessionMutationVariables>;
export const RemoveUserFromWaitingRoomDocument = gql`
    mutation removeUserFromWaitingRoom($user: ID!, $session: ID!) {
  removeUserFromWaitingRoom(user: $user, session: $session) {
    id
    waitingRoom {
      id
    }
  }
}
    `;
export type RemoveUserFromWaitingRoomMutationFn = Apollo.MutationFunction<RemoveUserFromWaitingRoomMutation, RemoveUserFromWaitingRoomMutationVariables>;

/**
 * __useRemoveUserFromWaitingRoomMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromWaitingRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromWaitingRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromWaitingRoomMutation, { data, loading, error }] = useRemoveUserFromWaitingRoomMutation({
 *   variables: {
 *      user: // value for 'user'
 *      session: // value for 'session'
 *   },
 * });
 */
export function useRemoveUserFromWaitingRoomMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromWaitingRoomMutation, RemoveUserFromWaitingRoomMutationVariables>) {
        return Apollo.useMutation<RemoveUserFromWaitingRoomMutation, RemoveUserFromWaitingRoomMutationVariables>(RemoveUserFromWaitingRoomDocument, baseOptions);
      }
export type RemoveUserFromWaitingRoomMutationHookResult = ReturnType<typeof useRemoveUserFromWaitingRoomMutation>;
export type RemoveUserFromWaitingRoomMutationResult = Apollo.MutationResult<RemoveUserFromWaitingRoomMutation>;
export type RemoveUserFromWaitingRoomMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromWaitingRoomMutation, RemoveUserFromWaitingRoomMutationVariables>;
export const RequestInviteDocument = gql`
    mutation requestInvite($user: ID!, $group: ID!) {
  requestInvite(user: $user, group: $group) {
    id
    name
  }
}
    `;
export type RequestInviteMutationFn = Apollo.MutationFunction<RequestInviteMutation, RequestInviteMutationVariables>;

/**
 * __useRequestInviteMutation__
 *
 * To run a mutation, you first call `useRequestInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestInviteMutation, { data, loading, error }] = useRequestInviteMutation({
 *   variables: {
 *      user: // value for 'user'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useRequestInviteMutation(baseOptions?: Apollo.MutationHookOptions<RequestInviteMutation, RequestInviteMutationVariables>) {
        return Apollo.useMutation<RequestInviteMutation, RequestInviteMutationVariables>(RequestInviteDocument, baseOptions);
      }
export type RequestInviteMutationHookResult = ReturnType<typeof useRequestInviteMutation>;
export type RequestInviteMutationResult = Apollo.MutationResult<RequestInviteMutation>;
export type RequestInviteMutationOptions = Apollo.BaseMutationOptions<RequestInviteMutation, RequestInviteMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($passReset: String!) {
  resetPassword(passReset: $passReset) {
    token
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      passReset: // value for 'passReset'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SeeMessageDocument = gql`
    mutation seeMessage($id: ID!, $message: ID!) {
  seeMessage(id: $id, message: $message) {
    id
  }
}
    `;
export type SeeMessageMutationFn = Apollo.MutationFunction<SeeMessageMutation, SeeMessageMutationVariables>;

/**
 * __useSeeMessageMutation__
 *
 * To run a mutation, you first call `useSeeMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeeMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seeMessageMutation, { data, loading, error }] = useSeeMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSeeMessageMutation(baseOptions?: Apollo.MutationHookOptions<SeeMessageMutation, SeeMessageMutationVariables>) {
        return Apollo.useMutation<SeeMessageMutation, SeeMessageMutationVariables>(SeeMessageDocument, baseOptions);
      }
export type SeeMessageMutationHookResult = ReturnType<typeof useSeeMessageMutation>;
export type SeeMessageMutationResult = Apollo.MutationResult<SeeMessageMutation>;
export type SeeMessageMutationOptions = Apollo.BaseMutationOptions<SeeMessageMutation, SeeMessageMutationVariables>;
export const SendMessageDocument = gql`
    mutation sendMessage($id: ID!, $message: String!, $from: ID!, $replyTo: ID, $sentAt: DateISO!) {
  sendMessage(
    id: $id
    message: $message
    from: $from
    replyTo: $replyTo
    sentAt: $sentAt
  ) {
    id
  }
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *      from: // value for 'from'
 *      replyTo: // value for 'replyTo'
 *      sentAt: // value for 'sentAt'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, baseOptions);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const SendMessageToOpenChatDocument = gql`
    mutation sendMessageToOpenChat($id: ID!, $message: String!, $from: ID!, $replyTo: ID, $sentAt: DateISO!) {
  sendMessageToOpenChat(
    id: $id
    message: $message
    from: $from
    replyTo: $replyTo
    sentAt: $sentAt
  ) {
    id
    openChatMembers {
      user {
        ...SmallUserFragment
      }
      palamiState
      twilioName
      lastActive
    }
    theme {
      id
      name
      category
      imageUrl
      program
    }
    createdAt
  }
}
    ${SmallUserFragmentFragmentDoc}`;
export type SendMessageToOpenChatMutationFn = Apollo.MutationFunction<SendMessageToOpenChatMutation, SendMessageToOpenChatMutationVariables>;

/**
 * __useSendMessageToOpenChatMutation__
 *
 * To run a mutation, you first call `useSendMessageToOpenChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageToOpenChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageToOpenChatMutation, { data, loading, error }] = useSendMessageToOpenChatMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *      from: // value for 'from'
 *      replyTo: // value for 'replyTo'
 *      sentAt: // value for 'sentAt'
 *   },
 * });
 */
export function useSendMessageToOpenChatMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageToOpenChatMutation, SendMessageToOpenChatMutationVariables>) {
        return Apollo.useMutation<SendMessageToOpenChatMutation, SendMessageToOpenChatMutationVariables>(SendMessageToOpenChatDocument, baseOptions);
      }
export type SendMessageToOpenChatMutationHookResult = ReturnType<typeof useSendMessageToOpenChatMutation>;
export type SendMessageToOpenChatMutationResult = Apollo.MutationResult<SendMessageToOpenChatMutation>;
export type SendMessageToOpenChatMutationOptions = Apollo.BaseMutationOptions<SendMessageToOpenChatMutation, SendMessageToOpenChatMutationVariables>;
export const SendRequestDocument = gql`
    mutation sendRequest($id: ID!) {
  sendRequest(id: $id) {
    id
  }
}
    `;
export type SendRequestMutationFn = Apollo.MutationFunction<SendRequestMutation, SendRequestMutationVariables>;

/**
 * __useSendRequestMutation__
 *
 * To run a mutation, you first call `useSendRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRequestMutation, { data, loading, error }] = useSendRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendRequestMutation(baseOptions?: Apollo.MutationHookOptions<SendRequestMutation, SendRequestMutationVariables>) {
        return Apollo.useMutation<SendRequestMutation, SendRequestMutationVariables>(SendRequestDocument, baseOptions);
      }
export type SendRequestMutationHookResult = ReturnType<typeof useSendRequestMutation>;
export type SendRequestMutationResult = Apollo.MutationResult<SendRequestMutation>;
export type SendRequestMutationOptions = Apollo.BaseMutationOptions<SendRequestMutation, SendRequestMutationVariables>;
export const SendResetEmailDocument = gql`
    mutation sendResetEmail($email: String!) {
  sendResetEmail(email: $email)
}
    `;
export type SendResetEmailMutationFn = Apollo.MutationFunction<SendResetEmailMutation, SendResetEmailMutationVariables>;

/**
 * __useSendResetEmailMutation__
 *
 * To run a mutation, you first call `useSendResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetEmailMutation, { data, loading, error }] = useSendResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendResetEmailMutation, SendResetEmailMutationVariables>) {
        return Apollo.useMutation<SendResetEmailMutation, SendResetEmailMutationVariables>(SendResetEmailDocument, baseOptions);
      }
export type SendResetEmailMutationHookResult = ReturnType<typeof useSendResetEmailMutation>;
export type SendResetEmailMutationResult = Apollo.MutationResult<SendResetEmailMutation>;
export type SendResetEmailMutationOptions = Apollo.BaseMutationOptions<SendResetEmailMutation, SendResetEmailMutationVariables>;
export const SetLastActiveDocument = gql`
    mutation setLastActive($session: ID!, $member: ID!, $role: String!, $exit: Boolean) {
  setLastActive(session: $session, member: $member, role: $role, exit: $exit)
}
    `;
export type SetLastActiveMutationFn = Apollo.MutationFunction<SetLastActiveMutation, SetLastActiveMutationVariables>;

/**
 * __useSetLastActiveMutation__
 *
 * To run a mutation, you first call `useSetLastActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLastActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLastActiveMutation, { data, loading, error }] = useSetLastActiveMutation({
 *   variables: {
 *      session: // value for 'session'
 *      member: // value for 'member'
 *      role: // value for 'role'
 *      exit: // value for 'exit'
 *   },
 * });
 */
export function useSetLastActiveMutation(baseOptions?: Apollo.MutationHookOptions<SetLastActiveMutation, SetLastActiveMutationVariables>) {
        return Apollo.useMutation<SetLastActiveMutation, SetLastActiveMutationVariables>(SetLastActiveDocument, baseOptions);
      }
export type SetLastActiveMutationHookResult = ReturnType<typeof useSetLastActiveMutation>;
export type SetLastActiveMutationResult = Apollo.MutationResult<SetLastActiveMutation>;
export type SetLastActiveMutationOptions = Apollo.BaseMutationOptions<SetLastActiveMutation, SetLastActiveMutationVariables>;
export const SetUnsentMessageDocument = gql`
    mutation setUnsentMessage($id: ID!, $message: String!) {
  setUnsentMessage(id: $id, message: $message) {
    id
  }
}
    `;
export type SetUnsentMessageMutationFn = Apollo.MutationFunction<SetUnsentMessageMutation, SetUnsentMessageMutationVariables>;

/**
 * __useSetUnsentMessageMutation__
 *
 * To run a mutation, you first call `useSetUnsentMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUnsentMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUnsentMessageMutation, { data, loading, error }] = useSetUnsentMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSetUnsentMessageMutation(baseOptions?: Apollo.MutationHookOptions<SetUnsentMessageMutation, SetUnsentMessageMutationVariables>) {
        return Apollo.useMutation<SetUnsentMessageMutation, SetUnsentMessageMutationVariables>(SetUnsentMessageDocument, baseOptions);
      }
export type SetUnsentMessageMutationHookResult = ReturnType<typeof useSetUnsentMessageMutation>;
export type SetUnsentMessageMutationResult = Apollo.MutationResult<SetUnsentMessageMutation>;
export type SetUnsentMessageMutationOptions = Apollo.BaseMutationOptions<SetUnsentMessageMutation, SetUnsentMessageMutationVariables>;
export const StartSessionDocument = gql`
    mutation startSession($session: ID!) {
  startSession(session: $session) {
    id
    facilitators {
      user {
        id
      }
    }
  }
}
    `;
export type StartSessionMutationFn = Apollo.MutationFunction<StartSessionMutation, StartSessionMutationVariables>;

/**
 * __useStartSessionMutation__
 *
 * To run a mutation, you first call `useStartSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSessionMutation, { data, loading, error }] = useStartSessionMutation({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useStartSessionMutation(baseOptions?: Apollo.MutationHookOptions<StartSessionMutation, StartSessionMutationVariables>) {
        return Apollo.useMutation<StartSessionMutation, StartSessionMutationVariables>(StartSessionDocument, baseOptions);
      }
export type StartSessionMutationHookResult = ReturnType<typeof useStartSessionMutation>;
export type StartSessionMutationResult = Apollo.MutationResult<StartSessionMutation>;
export type StartSessionMutationOptions = Apollo.BaseMutationOptions<StartSessionMutation, StartSessionMutationVariables>;
export const StopSessionDocument = gql`
    mutation stopSession($session: ID!, $boot: Boolean) {
  stopSession(session: $session, boot: $boot) {
    id
    facilitators {
      user {
        id
      }
    }
  }
}
    `;
export type StopSessionMutationFn = Apollo.MutationFunction<StopSessionMutation, StopSessionMutationVariables>;

/**
 * __useStopSessionMutation__
 *
 * To run a mutation, you first call `useStopSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopSessionMutation, { data, loading, error }] = useStopSessionMutation({
 *   variables: {
 *      session: // value for 'session'
 *      boot: // value for 'boot'
 *   },
 * });
 */
export function useStopSessionMutation(baseOptions?: Apollo.MutationHookOptions<StopSessionMutation, StopSessionMutationVariables>) {
        return Apollo.useMutation<StopSessionMutation, StopSessionMutationVariables>(StopSessionDocument, baseOptions);
      }
export type StopSessionMutationHookResult = ReturnType<typeof useStopSessionMutation>;
export type StopSessionMutationResult = Apollo.MutationResult<StopSessionMutation>;
export type StopSessionMutationOptions = Apollo.BaseMutationOptions<StopSessionMutation, StopSessionMutationVariables>;
export const SubscribeToPlanDocument = gql`
    mutation SubscribeToPlan($communityId: ID, $planId: String, $priceId: String) {
  subscribeToPlan(id: $communityId, planId: $planId, priceId: $priceId)
}
    `;
export type SubscribeToPlanMutationFn = Apollo.MutationFunction<SubscribeToPlanMutation, SubscribeToPlanMutationVariables>;

/**
 * __useSubscribeToPlanMutation__
 *
 * To run a mutation, you first call `useSubscribeToPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToPlanMutation, { data, loading, error }] = useSubscribeToPlanMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      planId: // value for 'planId'
 *      priceId: // value for 'priceId'
 *   },
 * });
 */
export function useSubscribeToPlanMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeToPlanMutation, SubscribeToPlanMutationVariables>) {
        return Apollo.useMutation<SubscribeToPlanMutation, SubscribeToPlanMutationVariables>(SubscribeToPlanDocument, baseOptions);
      }
export type SubscribeToPlanMutationHookResult = ReturnType<typeof useSubscribeToPlanMutation>;
export type SubscribeToPlanMutationResult = Apollo.MutationResult<SubscribeToPlanMutation>;
export type SubscribeToPlanMutationOptions = Apollo.BaseMutationOptions<SubscribeToPlanMutation, SubscribeToPlanMutationVariables>;
export const SwitchPrimeCommunityDocument = gql`
    mutation switchPrimeCommunity($id: ID!, $community: ID) {
  switchPrimeCommunity(id: $id, community: $community) {
    id
    community {
      id
      name
    }
    communities {
      id
      name
    }
  }
}
    `;
export type SwitchPrimeCommunityMutationFn = Apollo.MutationFunction<SwitchPrimeCommunityMutation, SwitchPrimeCommunityMutationVariables>;

/**
 * __useSwitchPrimeCommunityMutation__
 *
 * To run a mutation, you first call `useSwitchPrimeCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchPrimeCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchPrimeCommunityMutation, { data, loading, error }] = useSwitchPrimeCommunityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      community: // value for 'community'
 *   },
 * });
 */
export function useSwitchPrimeCommunityMutation(baseOptions?: Apollo.MutationHookOptions<SwitchPrimeCommunityMutation, SwitchPrimeCommunityMutationVariables>) {
        return Apollo.useMutation<SwitchPrimeCommunityMutation, SwitchPrimeCommunityMutationVariables>(SwitchPrimeCommunityDocument, baseOptions);
      }
export type SwitchPrimeCommunityMutationHookResult = ReturnType<typeof useSwitchPrimeCommunityMutation>;
export type SwitchPrimeCommunityMutationResult = Apollo.MutationResult<SwitchPrimeCommunityMutation>;
export type SwitchPrimeCommunityMutationOptions = Apollo.BaseMutationOptions<SwitchPrimeCommunityMutation, SwitchPrimeCommunityMutationVariables>;
export const SwitchUserDocument = gql`
    mutation switchUser($user: ID!) {
  switchUser(user: $user) {
    token
  }
}
    `;
export type SwitchUserMutationFn = Apollo.MutationFunction<SwitchUserMutation, SwitchUserMutationVariables>;

/**
 * __useSwitchUserMutation__
 *
 * To run a mutation, you first call `useSwitchUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchUserMutation, { data, loading, error }] = useSwitchUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useSwitchUserMutation(baseOptions?: Apollo.MutationHookOptions<SwitchUserMutation, SwitchUserMutationVariables>) {
        return Apollo.useMutation<SwitchUserMutation, SwitchUserMutationVariables>(SwitchUserDocument, baseOptions);
      }
export type SwitchUserMutationHookResult = ReturnType<typeof useSwitchUserMutation>;
export type SwitchUserMutationResult = Apollo.MutationResult<SwitchUserMutation>;
export type SwitchUserMutationOptions = Apollo.BaseMutationOptions<SwitchUserMutation, SwitchUserMutationVariables>;
export const TriggerHeadCountDocument = gql`
    mutation triggerHeadCount($session: ID!, $trigger: Boolean!, $lat: Float, $lng: Float, $ip: String) {
  triggerHeadCount(
    session: $session
    trigger: $trigger
    lat: $lat
    lng: $lng
    ip: $ip
  )
}
    `;
export type TriggerHeadCountMutationFn = Apollo.MutationFunction<TriggerHeadCountMutation, TriggerHeadCountMutationVariables>;

/**
 * __useTriggerHeadCountMutation__
 *
 * To run a mutation, you first call `useTriggerHeadCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerHeadCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerHeadCountMutation, { data, loading, error }] = useTriggerHeadCountMutation({
 *   variables: {
 *      session: // value for 'session'
 *      trigger: // value for 'trigger'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      ip: // value for 'ip'
 *   },
 * });
 */
export function useTriggerHeadCountMutation(baseOptions?: Apollo.MutationHookOptions<TriggerHeadCountMutation, TriggerHeadCountMutationVariables>) {
        return Apollo.useMutation<TriggerHeadCountMutation, TriggerHeadCountMutationVariables>(TriggerHeadCountDocument, baseOptions);
      }
export type TriggerHeadCountMutationHookResult = ReturnType<typeof useTriggerHeadCountMutation>;
export type TriggerHeadCountMutationResult = Apollo.MutationResult<TriggerHeadCountMutation>;
export type TriggerHeadCountMutationOptions = Apollo.BaseMutationOptions<TriggerHeadCountMutation, TriggerHeadCountMutationVariables>;
export const UnfriendUserDocument = gql`
    mutation unfriendUser($id: ID!) {
  unfriendUser(id: $id) {
    id
  }
}
    `;
export type UnfriendUserMutationFn = Apollo.MutationFunction<UnfriendUserMutation, UnfriendUserMutationVariables>;

/**
 * __useUnfriendUserMutation__
 *
 * To run a mutation, you first call `useUnfriendUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfriendUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfriendUserMutation, { data, loading, error }] = useUnfriendUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnfriendUserMutation(baseOptions?: Apollo.MutationHookOptions<UnfriendUserMutation, UnfriendUserMutationVariables>) {
        return Apollo.useMutation<UnfriendUserMutation, UnfriendUserMutationVariables>(UnfriendUserDocument, baseOptions);
      }
export type UnfriendUserMutationHookResult = ReturnType<typeof useUnfriendUserMutation>;
export type UnfriendUserMutationResult = Apollo.MutationResult<UnfriendUserMutation>;
export type UnfriendUserMutationOptions = Apollo.BaseMutationOptions<UnfriendUserMutation, UnfriendUserMutationVariables>;
export const SessionSessionFacilitatorsDocument = gql`
    query sessionSessionFacilitators($id: ID!) {
  session(id: $id) {
    facilitators {
      user {
        ...SmallUserFragment
      }
      nickname
      sessionRole
      muteState
      vidState
      palamiState
      roomState
      twilioName
    }
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useSessionSessionFacilitatorsQuery__
 *
 * To run a query within a React component, call `useSessionSessionFacilitatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionSessionFacilitatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionSessionFacilitatorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionSessionFacilitatorsQuery(baseOptions: Apollo.QueryHookOptions<SessionSessionFacilitatorsQuery, SessionSessionFacilitatorsQueryVariables>) {
        return Apollo.useQuery<SessionSessionFacilitatorsQuery, SessionSessionFacilitatorsQueryVariables>(SessionSessionFacilitatorsDocument, baseOptions);
      }
export function useSessionSessionFacilitatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionSessionFacilitatorsQuery, SessionSessionFacilitatorsQueryVariables>) {
          return Apollo.useLazyQuery<SessionSessionFacilitatorsQuery, SessionSessionFacilitatorsQueryVariables>(SessionSessionFacilitatorsDocument, baseOptions);
        }
export type SessionSessionFacilitatorsQueryHookResult = ReturnType<typeof useSessionSessionFacilitatorsQuery>;
export type SessionSessionFacilitatorsLazyQueryHookResult = ReturnType<typeof useSessionSessionFacilitatorsLazyQuery>;
export type SessionSessionFacilitatorsQueryResult = Apollo.QueryResult<SessionSessionFacilitatorsQuery, SessionSessionFacilitatorsQueryVariables>;
export const AllUiMetricsDocument = gql`
    query allUiMetrics($page: Int, $limit: Int) {
  allUiMetrics(page: $page, limit: $limit) {
    id
    user {
      id
    }
    fontSize
    mirroredVideo
    innerDimensions {
      width
      height
    }
    outerDimensions {
      width
      height
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useAllUiMetricsQuery__
 *
 * To run a query within a React component, call `useAllUiMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUiMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUiMetricsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAllUiMetricsQuery(baseOptions?: Apollo.QueryHookOptions<AllUiMetricsQuery, AllUiMetricsQueryVariables>) {
        return Apollo.useQuery<AllUiMetricsQuery, AllUiMetricsQueryVariables>(AllUiMetricsDocument, baseOptions);
      }
export function useAllUiMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUiMetricsQuery, AllUiMetricsQueryVariables>) {
          return Apollo.useLazyQuery<AllUiMetricsQuery, AllUiMetricsQueryVariables>(AllUiMetricsDocument, baseOptions);
        }
export type AllUiMetricsQueryHookResult = ReturnType<typeof useAllUiMetricsQuery>;
export type AllUiMetricsLazyQueryHookResult = ReturnType<typeof useAllUiMetricsLazyQuery>;
export type AllUiMetricsQueryResult = Apollo.QueryResult<AllUiMetricsQuery, AllUiMetricsQueryVariables>;
export const AmIApprovedDocument = gql`
    query amIApproved($session: ID!) {
  amIApproved(session: $session)
}
    `;

/**
 * __useAmIApprovedQuery__
 *
 * To run a query within a React component, call `useAmIApprovedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmIApprovedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAmIApprovedQuery({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useAmIApprovedQuery(baseOptions: Apollo.QueryHookOptions<AmIApprovedQuery, AmIApprovedQueryVariables>) {
        return Apollo.useQuery<AmIApprovedQuery, AmIApprovedQueryVariables>(AmIApprovedDocument, baseOptions);
      }
export function useAmIApprovedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AmIApprovedQuery, AmIApprovedQueryVariables>) {
          return Apollo.useLazyQuery<AmIApprovedQuery, AmIApprovedQueryVariables>(AmIApprovedDocument, baseOptions);
        }
export type AmIApprovedQueryHookResult = ReturnType<typeof useAmIApprovedQuery>;
export type AmIApprovedLazyQueryHookResult = ReturnType<typeof useAmIApprovedLazyQuery>;
export type AmIApprovedQueryResult = Apollo.QueryResult<AmIApprovedQuery, AmIApprovedQueryVariables>;
export const AnswerDocument = gql`
    query answer($id: ID!) {
  answer(id: $id) {
    id
    number
    body
    value
    textAns
    type
    section
    createdAt
  }
}
    `;

/**
 * __useAnswerQuery__
 *
 * To run a query within a React component, call `useAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnswerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnswerQuery(baseOptions: Apollo.QueryHookOptions<AnswerQuery, AnswerQueryVariables>) {
        return Apollo.useQuery<AnswerQuery, AnswerQueryVariables>(AnswerDocument, baseOptions);
      }
export function useAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnswerQuery, AnswerQueryVariables>) {
          return Apollo.useLazyQuery<AnswerQuery, AnswerQueryVariables>(AnswerDocument, baseOptions);
        }
export type AnswerQueryHookResult = ReturnType<typeof useAnswerQuery>;
export type AnswerLazyQueryHookResult = ReturnType<typeof useAnswerLazyQuery>;
export type AnswerQueryResult = Apollo.QueryResult<AnswerQuery, AnswerQueryVariables>;
export const AnswerListDocument = gql`
    query answerList {
  answerList {
    id
    number
    body
    value
    createdAt
  }
}
    `;

/**
 * __useAnswerListQuery__
 *
 * To run a query within a React component, call `useAnswerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnswerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnswerListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnswerListQuery(baseOptions?: Apollo.QueryHookOptions<AnswerListQuery, AnswerListQueryVariables>) {
        return Apollo.useQuery<AnswerListQuery, AnswerListQueryVariables>(AnswerListDocument, baseOptions);
      }
export function useAnswerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnswerListQuery, AnswerListQueryVariables>) {
          return Apollo.useLazyQuery<AnswerListQuery, AnswerListQueryVariables>(AnswerListDocument, baseOptions);
        }
export type AnswerListQueryHookResult = ReturnType<typeof useAnswerListQuery>;
export type AnswerListLazyQueryHookResult = ReturnType<typeof useAnswerListLazyQuery>;
export type AnswerListQueryResult = Apollo.QueryResult<AnswerListQuery, AnswerListQueryVariables>;
export const ChatRoomDocument = gql`
    query chatRoom($id: ID!) {
  chatRoom(id: $id) {
    id
    users {
      user {
        ...SmallUserFragment
      }
      messageLog {
        id
        message
        from {
          id
        }
        replyTo {
          id
          message
        }
        isSeen
        isDeleted
      }
      lastSeenMessage {
        id
      }
      unsentMessage
      isRemoved
    }
    sortedIdList
    isDeleted
    isGroup
    group
    roomName
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useChatRoomQuery__
 *
 * To run a query within a React component, call `useChatRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatRoomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChatRoomQuery(baseOptions: Apollo.QueryHookOptions<ChatRoomQuery, ChatRoomQueryVariables>) {
        return Apollo.useQuery<ChatRoomQuery, ChatRoomQueryVariables>(ChatRoomDocument, baseOptions);
      }
export function useChatRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatRoomQuery, ChatRoomQueryVariables>) {
          return Apollo.useLazyQuery<ChatRoomQuery, ChatRoomQueryVariables>(ChatRoomDocument, baseOptions);
        }
export type ChatRoomQueryHookResult = ReturnType<typeof useChatRoomQuery>;
export type ChatRoomLazyQueryHookResult = ReturnType<typeof useChatRoomLazyQuery>;
export type ChatRoomQueryResult = Apollo.QueryResult<ChatRoomQuery, ChatRoomQueryVariables>;
export const CheckForPendingDocument = gql`
    query checkForPending($id: ID!) {
  checkForPending(id: $id)
}
    `;

/**
 * __useCheckForPendingQuery__
 *
 * To run a query within a React component, call `useCheckForPendingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckForPendingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckForPendingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckForPendingQuery(baseOptions: Apollo.QueryHookOptions<CheckForPendingQuery, CheckForPendingQueryVariables>) {
        return Apollo.useQuery<CheckForPendingQuery, CheckForPendingQueryVariables>(CheckForPendingDocument, baseOptions);
      }
export function useCheckForPendingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckForPendingQuery, CheckForPendingQueryVariables>) {
          return Apollo.useLazyQuery<CheckForPendingQuery, CheckForPendingQueryVariables>(CheckForPendingDocument, baseOptions);
        }
export type CheckForPendingQueryHookResult = ReturnType<typeof useCheckForPendingQuery>;
export type CheckForPendingLazyQueryHookResult = ReturnType<typeof useCheckForPendingLazyQuery>;
export type CheckForPendingQueryResult = Apollo.QueryResult<CheckForPendingQuery, CheckForPendingQueryVariables>;
export const CheckQuestionnaireExistsDocument = gql`
    query checkQuestionnaireExists($member: ID!, $session: ID!, $stage: String!) {
  checkQuestionnaireExists(member: $member, session: $session, stage: $stage)
}
    `;

/**
 * __useCheckQuestionnaireExistsQuery__
 *
 * To run a query within a React component, call `useCheckQuestionnaireExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckQuestionnaireExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckQuestionnaireExistsQuery({
 *   variables: {
 *      member: // value for 'member'
 *      session: // value for 'session'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useCheckQuestionnaireExistsQuery(baseOptions: Apollo.QueryHookOptions<CheckQuestionnaireExistsQuery, CheckQuestionnaireExistsQueryVariables>) {
        return Apollo.useQuery<CheckQuestionnaireExistsQuery, CheckQuestionnaireExistsQueryVariables>(CheckQuestionnaireExistsDocument, baseOptions);
      }
export function useCheckQuestionnaireExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckQuestionnaireExistsQuery, CheckQuestionnaireExistsQueryVariables>) {
          return Apollo.useLazyQuery<CheckQuestionnaireExistsQuery, CheckQuestionnaireExistsQueryVariables>(CheckQuestionnaireExistsDocument, baseOptions);
        }
export type CheckQuestionnaireExistsQueryHookResult = ReturnType<typeof useCheckQuestionnaireExistsQuery>;
export type CheckQuestionnaireExistsLazyQueryHookResult = ReturnType<typeof useCheckQuestionnaireExistsLazyQuery>;
export type CheckQuestionnaireExistsQueryResult = Apollo.QueryResult<CheckQuestionnaireExistsQuery, CheckQuestionnaireExistsQueryVariables>;
export const CheckQuestionnaireExistsCommunityDocument = gql`
    query checkQuestionnaireExistsCommunity($member: ID!, $community: ID!, $stage: String!) {
  checkQuestionnaireExistsCommunity(
    member: $member
    community: $community
    stage: $stage
  )
}
    `;

/**
 * __useCheckQuestionnaireExistsCommunityQuery__
 *
 * To run a query within a React component, call `useCheckQuestionnaireExistsCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckQuestionnaireExistsCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckQuestionnaireExistsCommunityQuery({
 *   variables: {
 *      member: // value for 'member'
 *      community: // value for 'community'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useCheckQuestionnaireExistsCommunityQuery(baseOptions: Apollo.QueryHookOptions<CheckQuestionnaireExistsCommunityQuery, CheckQuestionnaireExistsCommunityQueryVariables>) {
        return Apollo.useQuery<CheckQuestionnaireExistsCommunityQuery, CheckQuestionnaireExistsCommunityQueryVariables>(CheckQuestionnaireExistsCommunityDocument, baseOptions);
      }
export function useCheckQuestionnaireExistsCommunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckQuestionnaireExistsCommunityQuery, CheckQuestionnaireExistsCommunityQueryVariables>) {
          return Apollo.useLazyQuery<CheckQuestionnaireExistsCommunityQuery, CheckQuestionnaireExistsCommunityQueryVariables>(CheckQuestionnaireExistsCommunityDocument, baseOptions);
        }
export type CheckQuestionnaireExistsCommunityQueryHookResult = ReturnType<typeof useCheckQuestionnaireExistsCommunityQuery>;
export type CheckQuestionnaireExistsCommunityLazyQueryHookResult = ReturnType<typeof useCheckQuestionnaireExistsCommunityLazyQuery>;
export type CheckQuestionnaireExistsCommunityQueryResult = Apollo.QueryResult<CheckQuestionnaireExistsCommunityQuery, CheckQuestionnaireExistsCommunityQueryVariables>;
export const CheckRegLinkExistsDocument = gql`
    query checkRegLinkExists($link: String!) {
  checkRegLinkExists(link: $link)
}
    `;

/**
 * __useCheckRegLinkExistsQuery__
 *
 * To run a query within a React component, call `useCheckRegLinkExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckRegLinkExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckRegLinkExistsQuery({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useCheckRegLinkExistsQuery(baseOptions: Apollo.QueryHookOptions<CheckRegLinkExistsQuery, CheckRegLinkExistsQueryVariables>) {
        return Apollo.useQuery<CheckRegLinkExistsQuery, CheckRegLinkExistsQueryVariables>(CheckRegLinkExistsDocument, baseOptions);
      }
export function useCheckRegLinkExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckRegLinkExistsQuery, CheckRegLinkExistsQueryVariables>) {
          return Apollo.useLazyQuery<CheckRegLinkExistsQuery, CheckRegLinkExistsQueryVariables>(CheckRegLinkExistsDocument, baseOptions);
        }
export type CheckRegLinkExistsQueryHookResult = ReturnType<typeof useCheckRegLinkExistsQuery>;
export type CheckRegLinkExistsLazyQueryHookResult = ReturnType<typeof useCheckRegLinkExistsLazyQuery>;
export type CheckRegLinkExistsQueryResult = Apollo.QueryResult<CheckRegLinkExistsQuery, CheckRegLinkExistsQueryVariables>;
export const CloseSessionsDocument = gql`
    query closeSessions($offset: Int, $limit: Int) {
  closeSessions(offset: $offset, limit: $limit) {
    id
    date
    program
    isDirect
    group {
      name
      isDirect
      location
      room
    }
    theme {
      name
      imageUrl
    }
    status
    sessionMembers {
      user {
        ...SmallUserFragment
      }
      lastActive
    }
    facilitators {
      user {
        ...SmallUserFragment
      }
      lastActive
    }
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useCloseSessionsQuery__
 *
 * To run a query within a React component, call `useCloseSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloseSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloseSessionsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCloseSessionsQuery(baseOptions?: Apollo.QueryHookOptions<CloseSessionsQuery, CloseSessionsQueryVariables>) {
        return Apollo.useQuery<CloseSessionsQuery, CloseSessionsQueryVariables>(CloseSessionsDocument, baseOptions);
      }
export function useCloseSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CloseSessionsQuery, CloseSessionsQueryVariables>) {
          return Apollo.useLazyQuery<CloseSessionsQuery, CloseSessionsQueryVariables>(CloseSessionsDocument, baseOptions);
        }
export type CloseSessionsQueryHookResult = ReturnType<typeof useCloseSessionsQuery>;
export type CloseSessionsLazyQueryHookResult = ReturnType<typeof useCloseSessionsLazyQuery>;
export type CloseSessionsQueryResult = Apollo.QueryResult<CloseSessionsQuery, CloseSessionsQueryVariables>;
export const CommunitiesDocument = gql`
    query communities {
  communities {
    id
    name
    phone
    email
    address
    maxUsers
    maxGroups
    facilitators {
      ...SmallUserFragment
    }
    members {
      ...SmallUserFragment
    }
    isDeleted
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useCommunitiesQuery__
 *
 * To run a query within a React component, call `useCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommunitiesQuery(baseOptions?: Apollo.QueryHookOptions<CommunitiesQuery, CommunitiesQueryVariables>) {
        return Apollo.useQuery<CommunitiesQuery, CommunitiesQueryVariables>(CommunitiesDocument, baseOptions);
      }
export function useCommunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunitiesQuery, CommunitiesQueryVariables>) {
          return Apollo.useLazyQuery<CommunitiesQuery, CommunitiesQueryVariables>(CommunitiesDocument, baseOptions);
        }
export type CommunitiesQueryHookResult = ReturnType<typeof useCommunitiesQuery>;
export type CommunitiesLazyQueryHookResult = ReturnType<typeof useCommunitiesLazyQuery>;
export type CommunitiesQueryResult = Apollo.QueryResult<CommunitiesQuery, CommunitiesQueryVariables>;
export const CommunityDocument = gql`
    query community($id: ID!) {
  community(id: $id) {
    id
    name
    phone
    email
    address
    maxUsers
    maxGroups
    isDeleted
    plan {
      ...PlanFragment
    }
  }
}
    ${PlanFragmentFragmentDoc}`;

/**
 * __useCommunityQuery__
 *
 * To run a query within a React component, call `useCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommunityQuery(baseOptions: Apollo.QueryHookOptions<CommunityQuery, CommunityQueryVariables>) {
        return Apollo.useQuery<CommunityQuery, CommunityQueryVariables>(CommunityDocument, baseOptions);
      }
export function useCommunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityQuery, CommunityQueryVariables>) {
          return Apollo.useLazyQuery<CommunityQuery, CommunityQueryVariables>(CommunityDocument, baseOptions);
        }
export type CommunityQueryHookResult = ReturnType<typeof useCommunityQuery>;
export type CommunityLazyQueryHookResult = ReturnType<typeof useCommunityLazyQuery>;
export type CommunityQueryResult = Apollo.QueryResult<CommunityQuery, CommunityQueryVariables>;
export const CommunityByNameDocument = gql`
    query communityByName($name: String!) {
  communityByName(name: $name) {
    id
    name
    phone
    email
    address
    maxUsers
    maxGroups
    facilitators {
      ...SmallUserFragment
    }
    members {
      ...SmallUserFragment
    }
    isDeleted
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useCommunityByNameQuery__
 *
 * To run a query within a React component, call `useCommunityByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCommunityByNameQuery(baseOptions: Apollo.QueryHookOptions<CommunityByNameQuery, CommunityByNameQueryVariables>) {
        return Apollo.useQuery<CommunityByNameQuery, CommunityByNameQueryVariables>(CommunityByNameDocument, baseOptions);
      }
export function useCommunityByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityByNameQuery, CommunityByNameQueryVariables>) {
          return Apollo.useLazyQuery<CommunityByNameQuery, CommunityByNameQueryVariables>(CommunityByNameDocument, baseOptions);
        }
export type CommunityByNameQueryHookResult = ReturnType<typeof useCommunityByNameQuery>;
export type CommunityByNameLazyQueryHookResult = ReturnType<typeof useCommunityByNameLazyQuery>;
export type CommunityByNameQueryResult = Apollo.QueryResult<CommunityByNameQuery, CommunityByNameQueryVariables>;
export const CommunitySearchByNameDocument = gql`
    query communitySearchByName($name: String!) {
  communitySearchByName(name: $name) {
    id
    name
    phone
    email
    address
    maxUsers
    maxGroups
  }
}
    `;

/**
 * __useCommunitySearchByNameQuery__
 *
 * To run a query within a React component, call `useCommunitySearchByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunitySearchByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunitySearchByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCommunitySearchByNameQuery(baseOptions: Apollo.QueryHookOptions<CommunitySearchByNameQuery, CommunitySearchByNameQueryVariables>) {
        return Apollo.useQuery<CommunitySearchByNameQuery, CommunitySearchByNameQueryVariables>(CommunitySearchByNameDocument, baseOptions);
      }
export function useCommunitySearchByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunitySearchByNameQuery, CommunitySearchByNameQueryVariables>) {
          return Apollo.useLazyQuery<CommunitySearchByNameQuery, CommunitySearchByNameQueryVariables>(CommunitySearchByNameDocument, baseOptions);
        }
export type CommunitySearchByNameQueryHookResult = ReturnType<typeof useCommunitySearchByNameQuery>;
export type CommunitySearchByNameLazyQueryHookResult = ReturnType<typeof useCommunitySearchByNameLazyQuery>;
export type CommunitySearchByNameQueryResult = Apollo.QueryResult<CommunitySearchByNameQuery, CommunitySearchByNameQueryVariables>;
export const CountUniqueDimensionsDocument = gql`
    query countUniqueDimensions($type: String, $limit: Int) {
  countUniqueDimensions(type: $type, limit: $limit) {
    ...DimensionsFragment
  }
}
    ${DimensionsFragmentFragmentDoc}`;

/**
 * __useCountUniqueDimensionsQuery__
 *
 * To run a query within a React component, call `useCountUniqueDimensionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountUniqueDimensionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountUniqueDimensionsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCountUniqueDimensionsQuery(baseOptions?: Apollo.QueryHookOptions<CountUniqueDimensionsQuery, CountUniqueDimensionsQueryVariables>) {
        return Apollo.useQuery<CountUniqueDimensionsQuery, CountUniqueDimensionsQueryVariables>(CountUniqueDimensionsDocument, baseOptions);
      }
export function useCountUniqueDimensionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountUniqueDimensionsQuery, CountUniqueDimensionsQueryVariables>) {
          return Apollo.useLazyQuery<CountUniqueDimensionsQuery, CountUniqueDimensionsQueryVariables>(CountUniqueDimensionsDocument, baseOptions);
        }
export type CountUniqueDimensionsQueryHookResult = ReturnType<typeof useCountUniqueDimensionsQuery>;
export type CountUniqueDimensionsLazyQueryHookResult = ReturnType<typeof useCountUniqueDimensionsLazyQuery>;
export type CountUniqueDimensionsQueryResult = Apollo.QueryResult<CountUniqueDimensionsQuery, CountUniqueDimensionsQueryVariables>;
export const ErrorLogsDocument = gql`
    query errorLogs($page: Int) {
  errorLogs(page: $page) {
    id
    error
    componentStack
    platform
    userAgent
    boundary
    userMessage
    createdAt
  }
}
    `;

/**
 * __useErrorLogsQuery__
 *
 * To run a query within a React component, call `useErrorLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useErrorLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useErrorLogsQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useErrorLogsQuery(baseOptions?: Apollo.QueryHookOptions<ErrorLogsQuery, ErrorLogsQueryVariables>) {
        return Apollo.useQuery<ErrorLogsQuery, ErrorLogsQueryVariables>(ErrorLogsDocument, baseOptions);
      }
export function useErrorLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ErrorLogsQuery, ErrorLogsQueryVariables>) {
          return Apollo.useLazyQuery<ErrorLogsQuery, ErrorLogsQueryVariables>(ErrorLogsDocument, baseOptions);
        }
export type ErrorLogsQueryHookResult = ReturnType<typeof useErrorLogsQuery>;
export type ErrorLogsLazyQueryHookResult = ReturnType<typeof useErrorLogsLazyQuery>;
export type ErrorLogsQueryResult = Apollo.QueryResult<ErrorLogsQuery, ErrorLogsQueryVariables>;
export const FaqDocument = gql`
    query faq {
  faqs {
    id
    question
    answer
  }
}
    `;

/**
 * __useFaqQuery__
 *
 * To run a query within a React component, call `useFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqQuery({
 *   variables: {
 *   },
 * });
 */
export function useFaqQuery(baseOptions?: Apollo.QueryHookOptions<FaqQuery, FaqQueryVariables>) {
        return Apollo.useQuery<FaqQuery, FaqQueryVariables>(FaqDocument, baseOptions);
      }
export function useFaqLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaqQuery, FaqQueryVariables>) {
          return Apollo.useLazyQuery<FaqQuery, FaqQueryVariables>(FaqDocument, baseOptions);
        }
export type FaqQueryHookResult = ReturnType<typeof useFaqQuery>;
export type FaqLazyQueryHookResult = ReturnType<typeof useFaqLazyQuery>;
export type FaqQueryResult = Apollo.QueryResult<FaqQuery, FaqQueryVariables>;
export const FollowSessionDocument = gql`
    query followSession($id: ID!) {
  session(id: $id) {
    status
    currentScreen
    songPlaying
    isDirect
    videoChatStatus
    contentId
    contentType
    globalMessage
  }
}
    `;

/**
 * __useFollowSessionQuery__
 *
 * To run a query within a React component, call `useFollowSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFollowSessionQuery(baseOptions: Apollo.QueryHookOptions<FollowSessionQuery, FollowSessionQueryVariables>) {
        return Apollo.useQuery<FollowSessionQuery, FollowSessionQueryVariables>(FollowSessionDocument, baseOptions);
      }
export function useFollowSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowSessionQuery, FollowSessionQueryVariables>) {
          return Apollo.useLazyQuery<FollowSessionQuery, FollowSessionQueryVariables>(FollowSessionDocument, baseOptions);
        }
export type FollowSessionQueryHookResult = ReturnType<typeof useFollowSessionQuery>;
export type FollowSessionLazyQueryHookResult = ReturnType<typeof useFollowSessionLazyQuery>;
export type FollowSessionQueryResult = Apollo.QueryResult<FollowSessionQuery, FollowSessionQueryVariables>;
export const FollowSessionAsSessionMasterDocument = gql`
    query followSessionAsSessionMaster($id: ID!) {
  session(id: $id) {
    status
    currentScreen
    songPlaying
    videoChatStatus
    contentId
    isDirect
    contentType
    globalMessage
    waitingRoom {
      id
      firstName
      lastName
      nickname
      role
    }
    sessionMaster {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useFollowSessionAsSessionMasterQuery__
 *
 * To run a query within a React component, call `useFollowSessionAsSessionMasterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowSessionAsSessionMasterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowSessionAsSessionMasterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFollowSessionAsSessionMasterQuery(baseOptions: Apollo.QueryHookOptions<FollowSessionAsSessionMasterQuery, FollowSessionAsSessionMasterQueryVariables>) {
        return Apollo.useQuery<FollowSessionAsSessionMasterQuery, FollowSessionAsSessionMasterQueryVariables>(FollowSessionAsSessionMasterDocument, baseOptions);
      }
export function useFollowSessionAsSessionMasterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowSessionAsSessionMasterQuery, FollowSessionAsSessionMasterQueryVariables>) {
          return Apollo.useLazyQuery<FollowSessionAsSessionMasterQuery, FollowSessionAsSessionMasterQueryVariables>(FollowSessionAsSessionMasterDocument, baseOptions);
        }
export type FollowSessionAsSessionMasterQueryHookResult = ReturnType<typeof useFollowSessionAsSessionMasterQuery>;
export type FollowSessionAsSessionMasterLazyQueryHookResult = ReturnType<typeof useFollowSessionAsSessionMasterLazyQuery>;
export type FollowSessionAsSessionMasterQueryResult = Apollo.QueryResult<FollowSessionAsSessionMasterQuery, FollowSessionAsSessionMasterQueryVariables>;
export const FollowWaitingRoomDocument = gql`
    query followWaitingRoom($id: ID!) {
  session(id: $id) {
    waitingRoom {
      id
    }
    sessionMembers {
      user {
        id
      }
    }
    status
  }
}
    `;

/**
 * __useFollowWaitingRoomQuery__
 *
 * To run a query within a React component, call `useFollowWaitingRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowWaitingRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowWaitingRoomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFollowWaitingRoomQuery(baseOptions: Apollo.QueryHookOptions<FollowWaitingRoomQuery, FollowWaitingRoomQueryVariables>) {
        return Apollo.useQuery<FollowWaitingRoomQuery, FollowWaitingRoomQueryVariables>(FollowWaitingRoomDocument, baseOptions);
      }
export function useFollowWaitingRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowWaitingRoomQuery, FollowWaitingRoomQueryVariables>) {
          return Apollo.useLazyQuery<FollowWaitingRoomQuery, FollowWaitingRoomQueryVariables>(FollowWaitingRoomDocument, baseOptions);
        }
export type FollowWaitingRoomQueryHookResult = ReturnType<typeof useFollowWaitingRoomQuery>;
export type FollowWaitingRoomLazyQueryHookResult = ReturnType<typeof useFollowWaitingRoomLazyQuery>;
export type FollowWaitingRoomQueryResult = Apollo.QueryResult<FollowWaitingRoomQuery, FollowWaitingRoomQueryVariables>;
export const FriendForNewChatDocument = gql`
    query friendForNewChat {
  friendForNewChat {
    ...FriendFragment
  }
}
    ${FriendFragmentFragmentDoc}`;

/**
 * __useFriendForNewChatQuery__
 *
 * To run a query within a React component, call `useFriendForNewChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useFriendForNewChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFriendForNewChatQuery({
 *   variables: {
 *   },
 * });
 */
export function useFriendForNewChatQuery(baseOptions?: Apollo.QueryHookOptions<FriendForNewChatQuery, FriendForNewChatQueryVariables>) {
        return Apollo.useQuery<FriendForNewChatQuery, FriendForNewChatQueryVariables>(FriendForNewChatDocument, baseOptions);
      }
export function useFriendForNewChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FriendForNewChatQuery, FriendForNewChatQueryVariables>) {
          return Apollo.useLazyQuery<FriendForNewChatQuery, FriendForNewChatQueryVariables>(FriendForNewChatDocument, baseOptions);
        }
export type FriendForNewChatQueryHookResult = ReturnType<typeof useFriendForNewChatQuery>;
export type FriendForNewChatLazyQueryHookResult = ReturnType<typeof useFriendForNewChatLazyQuery>;
export type FriendForNewChatQueryResult = Apollo.QueryResult<FriendForNewChatQuery, FriendForNewChatQueryVariables>;
export const FriendRequestsDocument = gql`
    query friendRequests {
  friendRequests {
    friendRequests {
      ...FriendFragment
    }
  }
}
    ${FriendFragmentFragmentDoc}`;

/**
 * __useFriendRequestsQuery__
 *
 * To run a query within a React component, call `useFriendRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFriendRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFriendRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFriendRequestsQuery(baseOptions?: Apollo.QueryHookOptions<FriendRequestsQuery, FriendRequestsQueryVariables>) {
        return Apollo.useQuery<FriendRequestsQuery, FriendRequestsQueryVariables>(FriendRequestsDocument, baseOptions);
      }
export function useFriendRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FriendRequestsQuery, FriendRequestsQueryVariables>) {
          return Apollo.useLazyQuery<FriendRequestsQuery, FriendRequestsQueryVariables>(FriendRequestsDocument, baseOptions);
        }
export type FriendRequestsQueryHookResult = ReturnType<typeof useFriendRequestsQuery>;
export type FriendRequestsLazyQueryHookResult = ReturnType<typeof useFriendRequestsLazyQuery>;
export type FriendRequestsQueryResult = Apollo.QueryResult<FriendRequestsQuery, FriendRequestsQueryVariables>;
export const FriendSuggestionsDocument = gql`
    query friendSuggestions($skip: Int) {
  friendSuggestions(skip: $skip) {
    ...SmallUserFragment
    email
    bio
    interests
    role
    community {
      id
      name
    }
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useFriendSuggestionsQuery__
 *
 * To run a query within a React component, call `useFriendSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFriendSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFriendSuggestionsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useFriendSuggestionsQuery(baseOptions?: Apollo.QueryHookOptions<FriendSuggestionsQuery, FriendSuggestionsQueryVariables>) {
        return Apollo.useQuery<FriendSuggestionsQuery, FriendSuggestionsQueryVariables>(FriendSuggestionsDocument, baseOptions);
      }
export function useFriendSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FriendSuggestionsQuery, FriendSuggestionsQueryVariables>) {
          return Apollo.useLazyQuery<FriendSuggestionsQuery, FriendSuggestionsQueryVariables>(FriendSuggestionsDocument, baseOptions);
        }
export type FriendSuggestionsQueryHookResult = ReturnType<typeof useFriendSuggestionsQuery>;
export type FriendSuggestionsLazyQueryHookResult = ReturnType<typeof useFriendSuggestionsLazyQuery>;
export type FriendSuggestionsQueryResult = Apollo.QueryResult<FriendSuggestionsQuery, FriendSuggestionsQueryVariables>;
export const FriendsDocument = gql`
    query friends {
  friends {
    friends {
      ...FriendFragment
    }
  }
}
    ${FriendFragmentFragmentDoc}`;

/**
 * __useFriendsQuery__
 *
 * To run a query within a React component, call `useFriendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFriendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFriendsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFriendsQuery(baseOptions?: Apollo.QueryHookOptions<FriendsQuery, FriendsQueryVariables>) {
        return Apollo.useQuery<FriendsQuery, FriendsQueryVariables>(FriendsDocument, baseOptions);
      }
export function useFriendsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FriendsQuery, FriendsQueryVariables>) {
          return Apollo.useLazyQuery<FriendsQuery, FriendsQueryVariables>(FriendsDocument, baseOptions);
        }
export type FriendsQueryHookResult = ReturnType<typeof useFriendsQuery>;
export type FriendsLazyQueryHookResult = ReturnType<typeof useFriendsLazyQuery>;
export type FriendsQueryResult = Apollo.QueryResult<FriendsQuery, FriendsQueryVariables>;
export const FutureSessionsDocument = gql`
    query futureSessions($nth: Int!) {
  futureSessions(nth: $nth) {
    id
    date
    program
    sessionMaster {
      id
    }
    theme {
      id
      name
      imageUrl
    }
    group {
      id
      name
      room
    }
    status
  }
}
    `;

/**
 * __useFutureSessionsQuery__
 *
 * To run a query within a React component, call `useFutureSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFutureSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFutureSessionsQuery({
 *   variables: {
 *      nth: // value for 'nth'
 *   },
 * });
 */
export function useFutureSessionsQuery(baseOptions: Apollo.QueryHookOptions<FutureSessionsQuery, FutureSessionsQueryVariables>) {
        return Apollo.useQuery<FutureSessionsQuery, FutureSessionsQueryVariables>(FutureSessionsDocument, baseOptions);
      }
export function useFutureSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FutureSessionsQuery, FutureSessionsQueryVariables>) {
          return Apollo.useLazyQuery<FutureSessionsQuery, FutureSessionsQueryVariables>(FutureSessionsDocument, baseOptions);
        }
export type FutureSessionsQueryHookResult = ReturnType<typeof useFutureSessionsQuery>;
export type FutureSessionsLazyQueryHookResult = ReturnType<typeof useFutureSessionsLazyQuery>;
export type FutureSessionsQueryResult = Apollo.QueryResult<FutureSessionsQuery, FutureSessionsQueryVariables>;
export const GetPlansDocument = gql`
    query GetPlans {
  getPlans {
    ...PlanFragment
  }
}
    ${PlanFragmentFragmentDoc}`;

/**
 * __useGetPlansQuery__
 *
 * To run a query within a React component, call `useGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetPlansQuery, GetPlansQueryVariables>) {
        return Apollo.useQuery<GetPlansQuery, GetPlansQueryVariables>(GetPlansDocument, baseOptions);
      }
export function useGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlansQuery, GetPlansQueryVariables>) {
          return Apollo.useLazyQuery<GetPlansQuery, GetPlansQueryVariables>(GetPlansDocument, baseOptions);
        }
export type GetPlansQueryHookResult = ReturnType<typeof useGetPlansQuery>;
export type GetPlansLazyQueryHookResult = ReturnType<typeof useGetPlansLazyQuery>;
export type GetPlansQueryResult = Apollo.QueryResult<GetPlansQuery, GetPlansQueryVariables>;
export const GetCommunitiesUsingPlanDocument = gql`
    query GetCommunitiesUsingPlan($id: ID!) {
  getCommunitiesUsingPlan(id: $id) {
    id
    name
    phone
    email
    address
    maxUsers
    maxGroups
    facilitators {
      ...SmallUserFragment
    }
    members {
      ...SmallUserFragment
    }
    isDeleted
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useGetCommunitiesUsingPlanQuery__
 *
 * To run a query within a React component, call `useGetCommunitiesUsingPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunitiesUsingPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunitiesUsingPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCommunitiesUsingPlanQuery(baseOptions: Apollo.QueryHookOptions<GetCommunitiesUsingPlanQuery, GetCommunitiesUsingPlanQueryVariables>) {
        return Apollo.useQuery<GetCommunitiesUsingPlanQuery, GetCommunitiesUsingPlanQueryVariables>(GetCommunitiesUsingPlanDocument, baseOptions);
      }
export function useGetCommunitiesUsingPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunitiesUsingPlanQuery, GetCommunitiesUsingPlanQueryVariables>) {
          return Apollo.useLazyQuery<GetCommunitiesUsingPlanQuery, GetCommunitiesUsingPlanQueryVariables>(GetCommunitiesUsingPlanDocument, baseOptions);
        }
export type GetCommunitiesUsingPlanQueryHookResult = ReturnType<typeof useGetCommunitiesUsingPlanQuery>;
export type GetCommunitiesUsingPlanLazyQueryHookResult = ReturnType<typeof useGetCommunitiesUsingPlanLazyQuery>;
export type GetCommunitiesUsingPlanQueryResult = Apollo.QueryResult<GetCommunitiesUsingPlanQuery, GetCommunitiesUsingPlanQueryVariables>;
export const GetCustomerPortalDocument = gql`
    query GetCustomerPortal($id: ID!) {
  getCustomerPortal(id: $id)
}
    `;

/**
 * __useGetCustomerPortalQuery__
 *
 * To run a query within a React component, call `useGetCustomerPortalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerPortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerPortalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerPortalQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerPortalQuery, GetCustomerPortalQueryVariables>) {
        return Apollo.useQuery<GetCustomerPortalQuery, GetCustomerPortalQueryVariables>(GetCustomerPortalDocument, baseOptions);
      }
export function useGetCustomerPortalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerPortalQuery, GetCustomerPortalQueryVariables>) {
          return Apollo.useLazyQuery<GetCustomerPortalQuery, GetCustomerPortalQueryVariables>(GetCustomerPortalDocument, baseOptions);
        }
export type GetCustomerPortalQueryHookResult = ReturnType<typeof useGetCustomerPortalQuery>;
export type GetCustomerPortalLazyQueryHookResult = ReturnType<typeof useGetCustomerPortalLazyQuery>;
export type GetCustomerPortalQueryResult = Apollo.QueryResult<GetCustomerPortalQuery, GetCustomerPortalQueryVariables>;
export const GetOlderMessagesDocument = gql`
    query getOlderMessages($id: ID!, $prevLast: ID!, $until: ID) {
  getOlderMessages(id: $id, prevLast: $prevLast, until: $until) {
    id
    message
    from {
      ...SmallUserFragment
    }
    replyTo {
      id
      message
    }
    isSeen
    isDeleted
    createdAt
    sentAt
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useGetOlderMessagesQuery__
 *
 * To run a query within a React component, call `useGetOlderMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOlderMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOlderMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      prevLast: // value for 'prevLast'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useGetOlderMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetOlderMessagesQuery, GetOlderMessagesQueryVariables>) {
        return Apollo.useQuery<GetOlderMessagesQuery, GetOlderMessagesQueryVariables>(GetOlderMessagesDocument, baseOptions);
      }
export function useGetOlderMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOlderMessagesQuery, GetOlderMessagesQueryVariables>) {
          return Apollo.useLazyQuery<GetOlderMessagesQuery, GetOlderMessagesQueryVariables>(GetOlderMessagesDocument, baseOptions);
        }
export type GetOlderMessagesQueryHookResult = ReturnType<typeof useGetOlderMessagesQuery>;
export type GetOlderMessagesLazyQueryHookResult = ReturnType<typeof useGetOlderMessagesLazyQuery>;
export type GetOlderMessagesQueryResult = Apollo.QueryResult<GetOlderMessagesQuery, GetOlderMessagesQueryVariables>;
export const GetPlanDocument = gql`
    query GetPlan($id: ID!, $showPrice: Boolean) {
  getPlan(id: $id, showPrice: $showPrice) {
    ...PlanFragment
  }
}
    ${PlanFragmentFragmentDoc}`;

/**
 * __useGetPlanQuery__
 *
 * To run a query within a React component, call `useGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *      showPrice: // value for 'showPrice'
 *   },
 * });
 */
export function useGetPlanQuery(baseOptions: Apollo.QueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
        return Apollo.useQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, baseOptions);
      }
export function useGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
          return Apollo.useLazyQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, baseOptions);
        }
export type GetPlanQueryHookResult = ReturnType<typeof useGetPlanQuery>;
export type GetPlanLazyQueryHookResult = ReturnType<typeof useGetPlanLazyQuery>;
export type GetPlanQueryResult = Apollo.QueryResult<GetPlanQuery, GetPlanQueryVariables>;
export const GetThemeAuditByThemeIdDocument = gql`
    query getThemeAuditByThemeId($id: ID!) {
  getThemeAuditByThemeId(id: $id) {
    ...ThemeAuditFragment
  }
}
    ${ThemeAuditFragmentFragmentDoc}`;

/**
 * __useGetThemeAuditByThemeIdQuery__
 *
 * To run a query within a React component, call `useGetThemeAuditByThemeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeAuditByThemeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeAuditByThemeIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetThemeAuditByThemeIdQuery(baseOptions: Apollo.QueryHookOptions<GetThemeAuditByThemeIdQuery, GetThemeAuditByThemeIdQueryVariables>) {
        return Apollo.useQuery<GetThemeAuditByThemeIdQuery, GetThemeAuditByThemeIdQueryVariables>(GetThemeAuditByThemeIdDocument, baseOptions);
      }
export function useGetThemeAuditByThemeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetThemeAuditByThemeIdQuery, GetThemeAuditByThemeIdQueryVariables>) {
          return Apollo.useLazyQuery<GetThemeAuditByThemeIdQuery, GetThemeAuditByThemeIdQueryVariables>(GetThemeAuditByThemeIdDocument, baseOptions);
        }
export type GetThemeAuditByThemeIdQueryHookResult = ReturnType<typeof useGetThemeAuditByThemeIdQuery>;
export type GetThemeAuditByThemeIdLazyQueryHookResult = ReturnType<typeof useGetThemeAuditByThemeIdLazyQuery>;
export type GetThemeAuditByThemeIdQueryResult = Apollo.QueryResult<GetThemeAuditByThemeIdQuery, GetThemeAuditByThemeIdQueryVariables>;
export const GetUnsentMessageDocument = gql`
    query getUnsentMessage($id: ID!) {
  getUnsentMessage(id: $id)
}
    `;

/**
 * __useGetUnsentMessageQuery__
 *
 * To run a query within a React component, call `useGetUnsentMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnsentMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnsentMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnsentMessageQuery(baseOptions: Apollo.QueryHookOptions<GetUnsentMessageQuery, GetUnsentMessageQueryVariables>) {
        return Apollo.useQuery<GetUnsentMessageQuery, GetUnsentMessageQueryVariables>(GetUnsentMessageDocument, baseOptions);
      }
export function useGetUnsentMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnsentMessageQuery, GetUnsentMessageQueryVariables>) {
          return Apollo.useLazyQuery<GetUnsentMessageQuery, GetUnsentMessageQueryVariables>(GetUnsentMessageDocument, baseOptions);
        }
export type GetUnsentMessageQueryHookResult = ReturnType<typeof useGetUnsentMessageQuery>;
export type GetUnsentMessageLazyQueryHookResult = ReturnType<typeof useGetUnsentMessageLazyQuery>;
export type GetUnsentMessageQueryResult = Apollo.QueryResult<GetUnsentMessageQuery, GetUnsentMessageQueryVariables>;
export const GroupDocument = gql`
    query group($id: ID!) {
  group(id: $id) {
    ...GroupFragment
    location
    lat
    lng
    isPublic
    tags
    isDeleted
    isFrozen
    inviteRequests {
      id
      firstName
      lastName
      profilePicUrl
    }
    owner {
      id
      firstName
      lastName
      profilePicUrl
    }
  }
}
    ${GroupFragmentFragmentDoc}`;

/**
 * __useGroupQuery__
 *
 * To run a query within a React component, call `useGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupQuery(baseOptions: Apollo.QueryHookOptions<GroupQuery, GroupQueryVariables>) {
        return Apollo.useQuery<GroupQuery, GroupQueryVariables>(GroupDocument, baseOptions);
      }
export function useGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupQuery, GroupQueryVariables>) {
          return Apollo.useLazyQuery<GroupQuery, GroupQueryVariables>(GroupDocument, baseOptions);
        }
export type GroupQueryHookResult = ReturnType<typeof useGroupQuery>;
export type GroupLazyQueryHookResult = ReturnType<typeof useGroupLazyQuery>;
export type GroupQueryResult = Apollo.QueryResult<GroupQuery, GroupQueryVariables>;
export const GroupCountStatsDocument = gql`
    query groupCountStats {
  groupCountStats {
    id
    createdAt
  }
}
    `;

/**
 * __useGroupCountStatsQuery__
 *
 * To run a query within a React component, call `useGroupCountStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupCountStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupCountStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupCountStatsQuery(baseOptions?: Apollo.QueryHookOptions<GroupCountStatsQuery, GroupCountStatsQueryVariables>) {
        return Apollo.useQuery<GroupCountStatsQuery, GroupCountStatsQueryVariables>(GroupCountStatsDocument, baseOptions);
      }
export function useGroupCountStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupCountStatsQuery, GroupCountStatsQueryVariables>) {
          return Apollo.useLazyQuery<GroupCountStatsQuery, GroupCountStatsQueryVariables>(GroupCountStatsDocument, baseOptions);
        }
export type GroupCountStatsQueryHookResult = ReturnType<typeof useGroupCountStatsQuery>;
export type GroupCountStatsLazyQueryHookResult = ReturnType<typeof useGroupCountStatsLazyQuery>;
export type GroupCountStatsQueryResult = Apollo.QueryResult<GroupCountStatsQuery, GroupCountStatsQueryVariables>;
export const GroupsDocument = gql`
    query groups {
  groups {
    ...GroupFragment
    location
    lat
    lng
    isPublic
    tags
    isDeleted
    isFrozen
    inviteRequests {
      id
      profilePicUrl
    }
    owner {
      id
      firstName
      lastName
      profilePicUrl
    }
  }
}
    ${GroupFragmentFragmentDoc}`;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
        return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, baseOptions);
      }
export function useGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, baseOptions);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = Apollo.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const GroupsByTagDocument = gql`
    query groupsByTag($tags: [String]!) {
  groupsByTag(tags: $tags) {
    ...GroupFragment
    location
    lat
    lng
    isPublic
    tags
    isDeleted
    isFrozen
    inviteRequests {
      id
    }
    owner {
      id
      firstName
      lastName
    }
  }
}
    ${GroupFragmentFragmentDoc}`;

/**
 * __useGroupsByTagQuery__
 *
 * To run a query within a React component, call `useGroupsByTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsByTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsByTagQuery({
 *   variables: {
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGroupsByTagQuery(baseOptions: Apollo.QueryHookOptions<GroupsByTagQuery, GroupsByTagQueryVariables>) {
        return Apollo.useQuery<GroupsByTagQuery, GroupsByTagQueryVariables>(GroupsByTagDocument, baseOptions);
      }
export function useGroupsByTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsByTagQuery, GroupsByTagQueryVariables>) {
          return Apollo.useLazyQuery<GroupsByTagQuery, GroupsByTagQueryVariables>(GroupsByTagDocument, baseOptions);
        }
export type GroupsByTagQueryHookResult = ReturnType<typeof useGroupsByTagQuery>;
export type GroupsByTagLazyQueryHookResult = ReturnType<typeof useGroupsByTagLazyQuery>;
export type GroupsByTagQueryResult = Apollo.QueryResult<GroupsByTagQuery, GroupsByTagQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...MeLightFragment
    postalcode
    location
    lat
    lng
    ageGroup
    living
    country
    bio
    interests
    role
    phone
    dateOfBirth
    address
    parentUser
    friends {
      ...SmallUserFragment
    }
    friendRequests {
      ...SmallUserFragment
    }
    optOut
    studyConsent
    lastQuestionnaire {
      id
      stage
      createdAt
    }
    community {
      id
      name
      plan {
        ...PlanFragment
      }
    }
  }
}
    ${MeLightFragmentFragmentDoc}
${SmallUserFragmentFragmentDoc}
${PlanFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MeLightDocument = gql`
    query meLight {
  meLight {
    ...MeLightFragment
  }
}
    ${MeLightFragmentFragmentDoc}`;

/**
 * __useMeLightQuery__
 *
 * To run a query within a React component, call `useMeLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeLightQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeLightQuery(baseOptions?: Apollo.QueryHookOptions<MeLightQuery, MeLightQueryVariables>) {
        return Apollo.useQuery<MeLightQuery, MeLightQueryVariables>(MeLightDocument, baseOptions);
      }
export function useMeLightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeLightQuery, MeLightQueryVariables>) {
          return Apollo.useLazyQuery<MeLightQuery, MeLightQueryVariables>(MeLightDocument, baseOptions);
        }
export type MeLightQueryHookResult = ReturnType<typeof useMeLightQuery>;
export type MeLightLazyQueryHookResult = ReturnType<typeof useMeLightLazyQuery>;
export type MeLightQueryResult = Apollo.QueryResult<MeLightQuery, MeLightQueryVariables>;
export const MyChatRoomsDocument = gql`
    query myChatRooms {
  myChatRooms {
    id
    sortedIdList
    isDeleted
    isGroup
    group
    roomName
    createdAt
    users {
      user {
        ...SmallUserFragment
      }
      messageLog {
        id
        message
        from {
          id
        }
        replyTo {
          id
          message
        }
        isSeen
        isDeleted
        createdAt
        sentAt
      }
      lastSeenMessage {
        id
      }
      unsentMessage
      isRemoved
    }
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useMyChatRoomsQuery__
 *
 * To run a query within a React component, call `useMyChatRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyChatRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyChatRoomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyChatRoomsQuery(baseOptions?: Apollo.QueryHookOptions<MyChatRoomsQuery, MyChatRoomsQueryVariables>) {
        return Apollo.useQuery<MyChatRoomsQuery, MyChatRoomsQueryVariables>(MyChatRoomsDocument, baseOptions);
      }
export function useMyChatRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyChatRoomsQuery, MyChatRoomsQueryVariables>) {
          return Apollo.useLazyQuery<MyChatRoomsQuery, MyChatRoomsQueryVariables>(MyChatRoomsDocument, baseOptions);
        }
export type MyChatRoomsQueryHookResult = ReturnType<typeof useMyChatRoomsQuery>;
export type MyChatRoomsLazyQueryHookResult = ReturnType<typeof useMyChatRoomsLazyQuery>;
export type MyChatRoomsQueryResult = Apollo.QueryResult<MyChatRoomsQuery, MyChatRoomsQueryVariables>;
export const MyFutureSessionsDocument = gql`
    query myFutureSessions($nth: Int!, $directOnly: Boolean) {
  myFutureSessions(nth: $nth, directOnly: $directOnly) {
    id
    date
    program
    isDirect
    sessionMaster {
      id
    }
    theme {
      id
      name
      imageUrl
    }
    group {
      id
      name
      room
      isDirect
    }
    sessionMembers {
      user {
        ...SmallUserFragment
        bio
        interests
      }
      lastActive
    }
    facilitators {
      user {
        ...SmallUserFragment
        bio
        interests
      }
      lastActive
    }
    status
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useMyFutureSessionsQuery__
 *
 * To run a query within a React component, call `useMyFutureSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyFutureSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyFutureSessionsQuery({
 *   variables: {
 *      nth: // value for 'nth'
 *      directOnly: // value for 'directOnly'
 *   },
 * });
 */
export function useMyFutureSessionsQuery(baseOptions: Apollo.QueryHookOptions<MyFutureSessionsQuery, MyFutureSessionsQueryVariables>) {
        return Apollo.useQuery<MyFutureSessionsQuery, MyFutureSessionsQueryVariables>(MyFutureSessionsDocument, baseOptions);
      }
export function useMyFutureSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyFutureSessionsQuery, MyFutureSessionsQueryVariables>) {
          return Apollo.useLazyQuery<MyFutureSessionsQuery, MyFutureSessionsQueryVariables>(MyFutureSessionsDocument, baseOptions);
        }
export type MyFutureSessionsQueryHookResult = ReturnType<typeof useMyFutureSessionsQuery>;
export type MyFutureSessionsLazyQueryHookResult = ReturnType<typeof useMyFutureSessionsLazyQuery>;
export type MyFutureSessionsQueryResult = Apollo.QueryResult<MyFutureSessionsQuery, MyFutureSessionsQueryVariables>;
export const MyGroupsDocument = gql`
    query myGroups {
  myGroups {
    ...GroupFragment
    location
    lat
    lng
    isPublic
    tags
    isDeleted
    isFrozen
    inviteRequests {
      id
    }
    owner {
      id
      firstName
      lastName
    }
  }
}
    ${GroupFragmentFragmentDoc}`;

/**
 * __useMyGroupsQuery__
 *
 * To run a query within a React component, call `useMyGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyGroupsQuery(baseOptions?: Apollo.QueryHookOptions<MyGroupsQuery, MyGroupsQueryVariables>) {
        return Apollo.useQuery<MyGroupsQuery, MyGroupsQueryVariables>(MyGroupsDocument, baseOptions);
      }
export function useMyGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyGroupsQuery, MyGroupsQueryVariables>) {
          return Apollo.useLazyQuery<MyGroupsQuery, MyGroupsQueryVariables>(MyGroupsDocument, baseOptions);
        }
export type MyGroupsQueryHookResult = ReturnType<typeof useMyGroupsQuery>;
export type MyGroupsLazyQueryHookResult = ReturnType<typeof useMyGroupsLazyQuery>;
export type MyGroupsQueryResult = Apollo.QueryResult<MyGroupsQuery, MyGroupsQueryVariables>;
export const MyManagedGroupsDocument = gql`
    query myManagedGroups {
  myManagedGroups {
    ...GroupFragment
    location
    lat
    lng
    isPublic
    tags
    isDeleted
    isFrozen
    inviteRequests {
      id
    }
    owner {
      id
      firstName
      lastName
    }
  }
}
    ${GroupFragmentFragmentDoc}`;

/**
 * __useMyManagedGroupsQuery__
 *
 * To run a query within a React component, call `useMyManagedGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyManagedGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyManagedGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyManagedGroupsQuery(baseOptions?: Apollo.QueryHookOptions<MyManagedGroupsQuery, MyManagedGroupsQueryVariables>) {
        return Apollo.useQuery<MyManagedGroupsQuery, MyManagedGroupsQueryVariables>(MyManagedGroupsDocument, baseOptions);
      }
export function useMyManagedGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyManagedGroupsQuery, MyManagedGroupsQueryVariables>) {
          return Apollo.useLazyQuery<MyManagedGroupsQuery, MyManagedGroupsQueryVariables>(MyManagedGroupsDocument, baseOptions);
        }
export type MyManagedGroupsQueryHookResult = ReturnType<typeof useMyManagedGroupsQuery>;
export type MyManagedGroupsLazyQueryHookResult = ReturnType<typeof useMyManagedGroupsLazyQuery>;
export type MyManagedGroupsQueryResult = Apollo.QueryResult<MyManagedGroupsQuery, MyManagedGroupsQueryVariables>;
export const MyRequestsDocument = gql`
    query myRequests {
  myRequests {
    id
    name
    inviteRequests {
      ...SmallUserFragment
    }
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useMyRequestsQuery__
 *
 * To run a query within a React component, call `useMyRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyRequestsQuery(baseOptions?: Apollo.QueryHookOptions<MyRequestsQuery, MyRequestsQueryVariables>) {
        return Apollo.useQuery<MyRequestsQuery, MyRequestsQueryVariables>(MyRequestsDocument, baseOptions);
      }
export function useMyRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyRequestsQuery, MyRequestsQueryVariables>) {
          return Apollo.useLazyQuery<MyRequestsQuery, MyRequestsQueryVariables>(MyRequestsDocument, baseOptions);
        }
export type MyRequestsQueryHookResult = ReturnType<typeof useMyRequestsQuery>;
export type MyRequestsLazyQueryHookResult = ReturnType<typeof useMyRequestsLazyQuery>;
export type MyRequestsQueryResult = Apollo.QueryResult<MyRequestsQuery, MyRequestsQueryVariables>;
export const MyTrainingsDocument = gql`
    query myTrainings($id: ID!) {
  myTrainings(id: $id) {
    id
    user {
      id
    }
    name
    link
    type
    status
    step
  }
}
    `;

/**
 * __useMyTrainingsQuery__
 *
 * To run a query within a React component, call `useMyTrainingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTrainingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTrainingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMyTrainingsQuery(baseOptions: Apollo.QueryHookOptions<MyTrainingsQuery, MyTrainingsQueryVariables>) {
        return Apollo.useQuery<MyTrainingsQuery, MyTrainingsQueryVariables>(MyTrainingsDocument, baseOptions);
      }
export function useMyTrainingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTrainingsQuery, MyTrainingsQueryVariables>) {
          return Apollo.useLazyQuery<MyTrainingsQuery, MyTrainingsQueryVariables>(MyTrainingsDocument, baseOptions);
        }
export type MyTrainingsQueryHookResult = ReturnType<typeof useMyTrainingsQuery>;
export type MyTrainingsLazyQueryHookResult = ReturnType<typeof useMyTrainingsLazyQuery>;
export type MyTrainingsQueryResult = Apollo.QueryResult<MyTrainingsQuery, MyTrainingsQueryVariables>;
export const NextSessionByGroupDocument = gql`
    query nextSessionByGroup($group: ID!) {
  nextSessionByGroup(group: $group) {
    id
    date
    group {
      usedThemes {
        theme
        lastUsed
      }
    }
  }
}
    `;

/**
 * __useNextSessionByGroupQuery__
 *
 * To run a query within a React component, call `useNextSessionByGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextSessionByGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextSessionByGroupQuery({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useNextSessionByGroupQuery(baseOptions: Apollo.QueryHookOptions<NextSessionByGroupQuery, NextSessionByGroupQueryVariables>) {
        return Apollo.useQuery<NextSessionByGroupQuery, NextSessionByGroupQueryVariables>(NextSessionByGroupDocument, baseOptions);
      }
export function useNextSessionByGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NextSessionByGroupQuery, NextSessionByGroupQueryVariables>) {
          return Apollo.useLazyQuery<NextSessionByGroupQuery, NextSessionByGroupQueryVariables>(NextSessionByGroupDocument, baseOptions);
        }
export type NextSessionByGroupQueryHookResult = ReturnType<typeof useNextSessionByGroupQuery>;
export type NextSessionByGroupLazyQueryHookResult = ReturnType<typeof useNextSessionByGroupLazyQuery>;
export type NextSessionByGroupQueryResult = Apollo.QueryResult<NextSessionByGroupQuery, NextSessionByGroupQueryVariables>;
export const NotificationsDocument = gql`
    query notifications($offset: Int!, $limit: Int!) {
  notifications(offset: $offset, limit: $limit) {
    id
    message
    seenEh
    actionedEh
    createdAt
    updatedAt
    type
    owner {
      id
    }
    user {
      ...SmallUserFragment
    }
    group {
      id
      name
    }
    session {
      id
    }
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, baseOptions);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, baseOptions);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const OpenChatRoomDocument = gql`
    query openChatRoom($id: ID!) {
  openChatRoom(id: $id) {
    id
    openChatMembers {
      user {
        ...SmallUserFragment
      }
      palamiState
      twilioName
      lastActive
    }
    theme {
      id
      name
      category
      imageUrl
      program
    }
    createdAt
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useOpenChatRoomQuery__
 *
 * To run a query within a React component, call `useOpenChatRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenChatRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenChatRoomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpenChatRoomQuery(baseOptions: Apollo.QueryHookOptions<OpenChatRoomQuery, OpenChatRoomQueryVariables>) {
        return Apollo.useQuery<OpenChatRoomQuery, OpenChatRoomQueryVariables>(OpenChatRoomDocument, baseOptions);
      }
export function useOpenChatRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenChatRoomQuery, OpenChatRoomQueryVariables>) {
          return Apollo.useLazyQuery<OpenChatRoomQuery, OpenChatRoomQueryVariables>(OpenChatRoomDocument, baseOptions);
        }
export type OpenChatRoomQueryHookResult = ReturnType<typeof useOpenChatRoomQuery>;
export type OpenChatRoomLazyQueryHookResult = ReturnType<typeof useOpenChatRoomLazyQuery>;
export type OpenChatRoomQueryResult = Apollo.QueryResult<OpenChatRoomQuery, OpenChatRoomQueryVariables>;
export const OpenChatRoomsDocument = gql`
    query openChatRooms {
  openChatRooms {
    id
    openChatMembers {
      user {
        ...SmallUserFragment
      }
      palamiState
      twilioName
      lastActive
    }
    theme {
      id
      name
      category
      imageUrl
      program
    }
    createdAt
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useOpenChatRoomsQuery__
 *
 * To run a query within a React component, call `useOpenChatRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenChatRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenChatRoomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOpenChatRoomsQuery(baseOptions?: Apollo.QueryHookOptions<OpenChatRoomsQuery, OpenChatRoomsQueryVariables>) {
        return Apollo.useQuery<OpenChatRoomsQuery, OpenChatRoomsQueryVariables>(OpenChatRoomsDocument, baseOptions);
      }
export function useOpenChatRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenChatRoomsQuery, OpenChatRoomsQueryVariables>) {
          return Apollo.useLazyQuery<OpenChatRoomsQuery, OpenChatRoomsQueryVariables>(OpenChatRoomsDocument, baseOptions);
        }
export type OpenChatRoomsQueryHookResult = ReturnType<typeof useOpenChatRoomsQuery>;
export type OpenChatRoomsLazyQueryHookResult = ReturnType<typeof useOpenChatRoomsLazyQuery>;
export type OpenChatRoomsQueryResult = Apollo.QueryResult<OpenChatRoomsQuery, OpenChatRoomsQueryVariables>;
export const OpenChatRoomsByThemeDocument = gql`
    query openChatRoomsByTheme($theme: ID!) {
  openChatRoomsByTheme(theme: $theme) {
    id
    openChatMembers {
      user {
        ...SmallUserFragment
      }
      palamiState
      twilioName
      lastActive
    }
    theme {
      id
      name
      category
      imageUrl
      program
    }
    createdAt
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useOpenChatRoomsByThemeQuery__
 *
 * To run a query within a React component, call `useOpenChatRoomsByThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenChatRoomsByThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenChatRoomsByThemeQuery({
 *   variables: {
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useOpenChatRoomsByThemeQuery(baseOptions: Apollo.QueryHookOptions<OpenChatRoomsByThemeQuery, OpenChatRoomsByThemeQueryVariables>) {
        return Apollo.useQuery<OpenChatRoomsByThemeQuery, OpenChatRoomsByThemeQueryVariables>(OpenChatRoomsByThemeDocument, baseOptions);
      }
export function useOpenChatRoomsByThemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenChatRoomsByThemeQuery, OpenChatRoomsByThemeQueryVariables>) {
          return Apollo.useLazyQuery<OpenChatRoomsByThemeQuery, OpenChatRoomsByThemeQueryVariables>(OpenChatRoomsByThemeDocument, baseOptions);
        }
export type OpenChatRoomsByThemeQueryHookResult = ReturnType<typeof useOpenChatRoomsByThemeQuery>;
export type OpenChatRoomsByThemeLazyQueryHookResult = ReturnType<typeof useOpenChatRoomsByThemeLazyQuery>;
export type OpenChatRoomsByThemeQueryResult = Apollo.QueryResult<OpenChatRoomsByThemeQuery, OpenChatRoomsByThemeQueryVariables>;
export const OpenInviteRequestsDocument = gql`
    query openInviteRequests($user: ID!) {
  openInviteRequests(user: $user) {
    id
    name
    startDate
  }
}
    `;

/**
 * __useOpenInviteRequestsQuery__
 *
 * To run a query within a React component, call `useOpenInviteRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenInviteRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenInviteRequestsQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useOpenInviteRequestsQuery(baseOptions: Apollo.QueryHookOptions<OpenInviteRequestsQuery, OpenInviteRequestsQueryVariables>) {
        return Apollo.useQuery<OpenInviteRequestsQuery, OpenInviteRequestsQueryVariables>(OpenInviteRequestsDocument, baseOptions);
      }
export function useOpenInviteRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenInviteRequestsQuery, OpenInviteRequestsQueryVariables>) {
          return Apollo.useLazyQuery<OpenInviteRequestsQuery, OpenInviteRequestsQueryVariables>(OpenInviteRequestsDocument, baseOptions);
        }
export type OpenInviteRequestsQueryHookResult = ReturnType<typeof useOpenInviteRequestsQuery>;
export type OpenInviteRequestsLazyQueryHookResult = ReturnType<typeof useOpenInviteRequestsLazyQuery>;
export type OpenInviteRequestsQueryResult = Apollo.QueryResult<OpenInviteRequestsQuery, OpenInviteRequestsQueryVariables>;
export const PublicGroupsDocument = gql`
    query publicGroups {
  publicGroups {
    ...GroupFragment
    location
    lat
    lng
    inviteRequests {
      id
    }
    owner {
      id
      firstName
      lastName
    }
    isPublic
    tags
    isDeleted
  }
}
    ${GroupFragmentFragmentDoc}`;

/**
 * __usePublicGroupsQuery__
 *
 * To run a query within a React component, call `usePublicGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicGroupsQuery(baseOptions?: Apollo.QueryHookOptions<PublicGroupsQuery, PublicGroupsQueryVariables>) {
        return Apollo.useQuery<PublicGroupsQuery, PublicGroupsQueryVariables>(PublicGroupsDocument, baseOptions);
      }
export function usePublicGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicGroupsQuery, PublicGroupsQueryVariables>) {
          return Apollo.useLazyQuery<PublicGroupsQuery, PublicGroupsQueryVariables>(PublicGroupsDocument, baseOptions);
        }
export type PublicGroupsQueryHookResult = ReturnType<typeof usePublicGroupsQuery>;
export type PublicGroupsLazyQueryHookResult = ReturnType<typeof usePublicGroupsLazyQuery>;
export type PublicGroupsQueryResult = Apollo.QueryResult<PublicGroupsQuery, PublicGroupsQueryVariables>;
export const QuestionnaireDocument = gql`
    query questionnaire($id: ID!) {
  questionnaire(id: $id) {
    id
    member
    session
    stage
    answers {
      id
      number
      body
      value
      createdAt
    }
    optOut
    isDone
  }
}
    `;

/**
 * __useQuestionnaireQuery__
 *
 * To run a query within a React component, call `useQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireQuery, QuestionnaireQueryVariables>) {
        return Apollo.useQuery<QuestionnaireQuery, QuestionnaireQueryVariables>(QuestionnaireDocument, baseOptions);
      }
export function useQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireQuery, QuestionnaireQueryVariables>) {
          return Apollo.useLazyQuery<QuestionnaireQuery, QuestionnaireQueryVariables>(QuestionnaireDocument, baseOptions);
        }
export type QuestionnaireQueryHookResult = ReturnType<typeof useQuestionnaireQuery>;
export type QuestionnaireLazyQueryHookResult = ReturnType<typeof useQuestionnaireLazyQuery>;
export type QuestionnaireQueryResult = Apollo.QueryResult<QuestionnaireQuery, QuestionnaireQueryVariables>;
export const QuestionnaireUserDocument = gql`
    query questionnaireUser($member: ID!, $stage: String) {
  questionnaireUser(member: $member, stage: $stage) {
    id
    member
    session
    stage
    answers {
      id
      number
      section
      type
      body
      value
      textAns
      createdAt
    }
    optOut
    isDone
    openDate
  }
}
    `;

/**
 * __useQuestionnaireUserQuery__
 *
 * To run a query within a React component, call `useQuestionnaireUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireUserQuery({
 *   variables: {
 *      member: // value for 'member'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useQuestionnaireUserQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireUserQuery, QuestionnaireUserQueryVariables>) {
        return Apollo.useQuery<QuestionnaireUserQuery, QuestionnaireUserQueryVariables>(QuestionnaireUserDocument, baseOptions);
      }
export function useQuestionnaireUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireUserQuery, QuestionnaireUserQueryVariables>) {
          return Apollo.useLazyQuery<QuestionnaireUserQuery, QuestionnaireUserQueryVariables>(QuestionnaireUserDocument, baseOptions);
        }
export type QuestionnaireUserQueryHookResult = ReturnType<typeof useQuestionnaireUserQuery>;
export type QuestionnaireUserLazyQueryHookResult = ReturnType<typeof useQuestionnaireUserLazyQuery>;
export type QuestionnaireUserQueryResult = Apollo.QueryResult<QuestionnaireUserQuery, QuestionnaireUserQueryVariables>;
export const QuestionnaireUserSessionDocument = gql`
    query questionnaireUserSession($member: ID!, $session: ID!, $stage: String) {
  questionnaireUserSession(member: $member, session: $session, stage: $stage) {
    id
    member
    session
    stage
    answers {
      id
      number
      body
      value
      createdAt
    }
    optOut
    isDone
  }
}
    `;

/**
 * __useQuestionnaireUserSessionQuery__
 *
 * To run a query within a React component, call `useQuestionnaireUserSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireUserSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireUserSessionQuery({
 *   variables: {
 *      member: // value for 'member'
 *      session: // value for 'session'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useQuestionnaireUserSessionQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireUserSessionQuery, QuestionnaireUserSessionQueryVariables>) {
        return Apollo.useQuery<QuestionnaireUserSessionQuery, QuestionnaireUserSessionQueryVariables>(QuestionnaireUserSessionDocument, baseOptions);
      }
export function useQuestionnaireUserSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireUserSessionQuery, QuestionnaireUserSessionQueryVariables>) {
          return Apollo.useLazyQuery<QuestionnaireUserSessionQuery, QuestionnaireUserSessionQueryVariables>(QuestionnaireUserSessionDocument, baseOptions);
        }
export type QuestionnaireUserSessionQueryHookResult = ReturnType<typeof useQuestionnaireUserSessionQuery>;
export type QuestionnaireUserSessionLazyQueryHookResult = ReturnType<typeof useQuestionnaireUserSessionLazyQuery>;
export type QuestionnaireUserSessionQueryResult = Apollo.QueryResult<QuestionnaireUserSessionQuery, QuestionnaireUserSessionQueryVariables>;
export const QuestionnairesDocument = gql`
    query questionnaires {
  questionnaires {
    id
    member
    session
    stage
    answers {
      id
      number
      body
      value
      createdAt
    }
    optOut
    isDone
  }
}
    `;

/**
 * __useQuestionnairesQuery__
 *
 * To run a query within a React component, call `useQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnairesQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuestionnairesQuery(baseOptions?: Apollo.QueryHookOptions<QuestionnairesQuery, QuestionnairesQueryVariables>) {
        return Apollo.useQuery<QuestionnairesQuery, QuestionnairesQueryVariables>(QuestionnairesDocument, baseOptions);
      }
export function useQuestionnairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnairesQuery, QuestionnairesQueryVariables>) {
          return Apollo.useLazyQuery<QuestionnairesQuery, QuestionnairesQueryVariables>(QuestionnairesDocument, baseOptions);
        }
export type QuestionnairesQueryHookResult = ReturnType<typeof useQuestionnairesQuery>;
export type QuestionnairesLazyQueryHookResult = ReturnType<typeof useQuestionnairesLazyQuery>;
export type QuestionnairesQueryResult = Apollo.QueryResult<QuestionnairesQuery, QuestionnairesQueryVariables>;
export const RegLinkDocument = gql`
    query regLink($id: ID!) {
  regLink(id: $id) {
    id
    groups {
      id
      name
    }
    community {
      id
      name
    }
  }
}
    `;

/**
 * __useRegLinkQuery__
 *
 * To run a query within a React component, call `useRegLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegLinkQuery(baseOptions: Apollo.QueryHookOptions<RegLinkQuery, RegLinkQueryVariables>) {
        return Apollo.useQuery<RegLinkQuery, RegLinkQueryVariables>(RegLinkDocument, baseOptions);
      }
export function useRegLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegLinkQuery, RegLinkQueryVariables>) {
          return Apollo.useLazyQuery<RegLinkQuery, RegLinkQueryVariables>(RegLinkDocument, baseOptions);
        }
export type RegLinkQueryHookResult = ReturnType<typeof useRegLinkQuery>;
export type RegLinkLazyQueryHookResult = ReturnType<typeof useRegLinkLazyQuery>;
export type RegLinkQueryResult = Apollo.QueryResult<RegLinkQuery, RegLinkQueryVariables>;
export const RegLinkByLinkDocument = gql`
    query regLinkByLink($link: String!) {
  regLinkByLink(link: $link) {
    id
    groups {
      id
      name
      program
    }
    community {
      id
      name
    }
  }
}
    `;

/**
 * __useRegLinkByLinkQuery__
 *
 * To run a query within a React component, call `useRegLinkByLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegLinkByLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegLinkByLinkQuery({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useRegLinkByLinkQuery(baseOptions: Apollo.QueryHookOptions<RegLinkByLinkQuery, RegLinkByLinkQueryVariables>) {
        return Apollo.useQuery<RegLinkByLinkQuery, RegLinkByLinkQueryVariables>(RegLinkByLinkDocument, baseOptions);
      }
export function useRegLinkByLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegLinkByLinkQuery, RegLinkByLinkQueryVariables>) {
          return Apollo.useLazyQuery<RegLinkByLinkQuery, RegLinkByLinkQueryVariables>(RegLinkByLinkDocument, baseOptions);
        }
export type RegLinkByLinkQueryHookResult = ReturnType<typeof useRegLinkByLinkQuery>;
export type RegLinkByLinkLazyQueryHookResult = ReturnType<typeof useRegLinkByLinkLazyQuery>;
export type RegLinkByLinkQueryResult = Apollo.QueryResult<RegLinkByLinkQuery, RegLinkByLinkQueryVariables>;
export const RegLinkSearchDocument = gql`
    query regLinkSearch($link: String!) {
  regLinkSearch(link: $link) {
    id
    groups {
      id
      name
    }
    community {
      id
      name
    }
  }
}
    `;

/**
 * __useRegLinkSearchQuery__
 *
 * To run a query within a React component, call `useRegLinkSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegLinkSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegLinkSearchQuery({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useRegLinkSearchQuery(baseOptions: Apollo.QueryHookOptions<RegLinkSearchQuery, RegLinkSearchQueryVariables>) {
        return Apollo.useQuery<RegLinkSearchQuery, RegLinkSearchQueryVariables>(RegLinkSearchDocument, baseOptions);
      }
export function useRegLinkSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegLinkSearchQuery, RegLinkSearchQueryVariables>) {
          return Apollo.useLazyQuery<RegLinkSearchQuery, RegLinkSearchQueryVariables>(RegLinkSearchDocument, baseOptions);
        }
export type RegLinkSearchQueryHookResult = ReturnType<typeof useRegLinkSearchQuery>;
export type RegLinkSearchLazyQueryHookResult = ReturnType<typeof useRegLinkSearchLazyQuery>;
export type RegLinkSearchQueryResult = Apollo.QueryResult<RegLinkSearchQuery, RegLinkSearchQueryVariables>;
export const RegLinksByCommunityDocument = gql`
    query regLinksByCommunity($community: ID!) {
  regLinksByCommunity(community: $community) {
    id
    link
    groups {
      id
      name
    }
    community {
      id
      name
    }
  }
}
    `;

/**
 * __useRegLinksByCommunityQuery__
 *
 * To run a query within a React component, call `useRegLinksByCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegLinksByCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegLinksByCommunityQuery({
 *   variables: {
 *      community: // value for 'community'
 *   },
 * });
 */
export function useRegLinksByCommunityQuery(baseOptions: Apollo.QueryHookOptions<RegLinksByCommunityQuery, RegLinksByCommunityQueryVariables>) {
        return Apollo.useQuery<RegLinksByCommunityQuery, RegLinksByCommunityQueryVariables>(RegLinksByCommunityDocument, baseOptions);
      }
export function useRegLinksByCommunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegLinksByCommunityQuery, RegLinksByCommunityQueryVariables>) {
          return Apollo.useLazyQuery<RegLinksByCommunityQuery, RegLinksByCommunityQueryVariables>(RegLinksByCommunityDocument, baseOptions);
        }
export type RegLinksByCommunityQueryHookResult = ReturnType<typeof useRegLinksByCommunityQuery>;
export type RegLinksByCommunityLazyQueryHookResult = ReturnType<typeof useRegLinksByCommunityLazyQuery>;
export type RegLinksByCommunityQueryResult = Apollo.QueryResult<RegLinksByCommunityQuery, RegLinksByCommunityQueryVariables>;
export const ResourcesDocument = gql`
    query resources {
  resources {
    id
    title
    category
    thumb
    text
  }
}
    `;

/**
 * __useResourcesQuery__
 *
 * To run a query within a React component, call `useResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useResourcesQuery(baseOptions?: Apollo.QueryHookOptions<ResourcesQuery, ResourcesQueryVariables>) {
        return Apollo.useQuery<ResourcesQuery, ResourcesQueryVariables>(ResourcesDocument, baseOptions);
      }
export function useResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcesQuery, ResourcesQueryVariables>) {
          return Apollo.useLazyQuery<ResourcesQuery, ResourcesQueryVariables>(ResourcesDocument, baseOptions);
        }
export type ResourcesQueryHookResult = ReturnType<typeof useResourcesQuery>;
export type ResourcesLazyQueryHookResult = ReturnType<typeof useResourcesLazyQuery>;
export type ResourcesQueryResult = Apollo.QueryResult<ResourcesQuery, ResourcesQueryVariables>;
export const SessionDocument = gql`
    query session($id: ID!) {
  session(id: $id) {
    id
    date
    program
    status
    isDirect
    sessionMaster {
      id
    }
    group {
      id
      name
      startDate
      frequency
      room
      isDirect
      usedThemes {
        theme
        lastUsed
      }
      facilitators {
        ...SmallUserFragment
        role
      }
      members {
        ...SmallUserFragment
        notes {
          id
          author {
            id
            firstName
            lastName
          }
          content
        }
        role
      }
    }
    facilitators {
      user {
        ...SmallUserFragment
        bio
        interests
      }
      nickname
      sessionRole
      muteState
      vidState
      palamiState
      roomState
    }
    sessionMembers {
      user {
        ...SmallUserFragment
        bio
        interests
        notes {
          id
          content
          author {
            id
            firstName
            lastName
          }
        }
      }
      nickname
      lastActive
      sessionRole
      muteState
      vidState
      palamiState
      roomState
    }
    theme {
      ...ThemeFragment
      javaTips {
        id
        body
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    ${SmallUserFragmentFragmentDoc}
${ThemeFragmentFragmentDoc}`;

/**
 * __useSessionQuery__
 *
 * To run a query within a React component, call `useSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionQuery(baseOptions: Apollo.QueryHookOptions<SessionQuery, SessionQueryVariables>) {
        return Apollo.useQuery<SessionQuery, SessionQueryVariables>(SessionDocument, baseOptions);
      }
export function useSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionQuery, SessionQueryVariables>) {
          return Apollo.useLazyQuery<SessionQuery, SessionQueryVariables>(SessionDocument, baseOptions);
        }
export type SessionQueryHookResult = ReturnType<typeof useSessionQuery>;
export type SessionLazyQueryHookResult = ReturnType<typeof useSessionLazyQuery>;
export type SessionQueryResult = Apollo.QueryResult<SessionQuery, SessionQueryVariables>;
export const SessionSessionMembersDocument = gql`
    query sessionSessionMembers($id: ID!) {
  session(id: $id) {
    sessionMembers {
      user {
        ...SmallUserFragment
        notes {
          id
          content
          author {
            id
            firstName
            lastName
          }
        }
      }
      nickname
      sessionRole
      muteState
      vidState
      palamiState
      roomState
      twilioName
    }
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useSessionSessionMembersQuery__
 *
 * To run a query within a React component, call `useSessionSessionMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionSessionMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionSessionMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionSessionMembersQuery(baseOptions: Apollo.QueryHookOptions<SessionSessionMembersQuery, SessionSessionMembersQueryVariables>) {
        return Apollo.useQuery<SessionSessionMembersQuery, SessionSessionMembersQueryVariables>(SessionSessionMembersDocument, baseOptions);
      }
export function useSessionSessionMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionSessionMembersQuery, SessionSessionMembersQueryVariables>) {
          return Apollo.useLazyQuery<SessionSessionMembersQuery, SessionSessionMembersQueryVariables>(SessionSessionMembersDocument, baseOptions);
        }
export type SessionSessionMembersQueryHookResult = ReturnType<typeof useSessionSessionMembersQuery>;
export type SessionSessionMembersLazyQueryHookResult = ReturnType<typeof useSessionSessionMembersLazyQuery>;
export type SessionSessionMembersQueryResult = Apollo.QueryResult<SessionSessionMembersQuery, SessionSessionMembersQueryVariables>;
export const SessionStatusDocument = gql`
    query sessionStatus($id: ID!) {
  session(id: $id) {
    id
    date
    status
    isDirect
    group {
      facilitators {
        id
      }
    }
  }
}
    `;

/**
 * __useSessionStatusQuery__
 *
 * To run a query within a React component, call `useSessionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionStatusQuery(baseOptions: Apollo.QueryHookOptions<SessionStatusQuery, SessionStatusQueryVariables>) {
        return Apollo.useQuery<SessionStatusQuery, SessionStatusQueryVariables>(SessionStatusDocument, baseOptions);
      }
export function useSessionStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionStatusQuery, SessionStatusQueryVariables>) {
          return Apollo.useLazyQuery<SessionStatusQuery, SessionStatusQueryVariables>(SessionStatusDocument, baseOptions);
        }
export type SessionStatusQueryHookResult = ReturnType<typeof useSessionStatusQuery>;
export type SessionStatusLazyQueryHookResult = ReturnType<typeof useSessionStatusLazyQuery>;
export type SessionStatusQueryResult = Apollo.QueryResult<SessionStatusQuery, SessionStatusQueryVariables>;
export const SessionsDocument = gql`
    query sessions {
  sessions {
    id
    date
    theme {
      id
      name
      imageUrl
    }
    program
    isDirect
    group {
      id
      name
      room
    }
    facilitators {
      user {
        id
        firstName
        lastName
        nickname
        role
      }
    }
    members {
      id
      firstName
      lastName
      nickname
    }
  }
}
    `;

/**
 * __useSessionsQuery__
 *
 * To run a query within a React component, call `useSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionsQuery(baseOptions?: Apollo.QueryHookOptions<SessionsQuery, SessionsQueryVariables>) {
        return Apollo.useQuery<SessionsQuery, SessionsQueryVariables>(SessionsDocument, baseOptions);
      }
export function useSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionsQuery, SessionsQueryVariables>) {
          return Apollo.useLazyQuery<SessionsQuery, SessionsQueryVariables>(SessionsDocument, baseOptions);
        }
export type SessionsQueryHookResult = ReturnType<typeof useSessionsQuery>;
export type SessionsLazyQueryHookResult = ReturnType<typeof useSessionsLazyQuery>;
export type SessionsQueryResult = Apollo.QueryResult<SessionsQuery, SessionsQueryVariables>;
export const SessionsByGroupDocument = gql`
    query sessionsByGroup($group: ID!, $isPast: Boolean) {
  sessionsByGroup(group: $group, isPast: $isPast) {
    id
    date
    program
    facilitators {
      user {
        ...SmallUserFragment
      }
      nickname
      sessionRole
      muteState
      vidState
      palamiState
      roomState
    }
    sessionMembers {
      user {
        ...SmallUserFragment
      }
      sharePhoto
      shareQuote
      shareSong
      nickname
      sessionRole
      muteState
      vidState
      palamiState
      roomState
    }
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useSessionsByGroupQuery__
 *
 * To run a query within a React component, call `useSessionsByGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsByGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsByGroupQuery({
 *   variables: {
 *      group: // value for 'group'
 *      isPast: // value for 'isPast'
 *   },
 * });
 */
export function useSessionsByGroupQuery(baseOptions: Apollo.QueryHookOptions<SessionsByGroupQuery, SessionsByGroupQueryVariables>) {
        return Apollo.useQuery<SessionsByGroupQuery, SessionsByGroupQueryVariables>(SessionsByGroupDocument, baseOptions);
      }
export function useSessionsByGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionsByGroupQuery, SessionsByGroupQueryVariables>) {
          return Apollo.useLazyQuery<SessionsByGroupQuery, SessionsByGroupQueryVariables>(SessionsByGroupDocument, baseOptions);
        }
export type SessionsByGroupQueryHookResult = ReturnType<typeof useSessionsByGroupQuery>;
export type SessionsByGroupLazyQueryHookResult = ReturnType<typeof useSessionsByGroupLazyQuery>;
export type SessionsByGroupQueryResult = Apollo.QueryResult<SessionsByGroupQuery, SessionsByGroupQueryVariables>;
export const SongsDocument = gql`
    query songs {
  songs {
    id
    title
    lyrics
    url
    isDeleted
  }
}
    `;

/**
 * __useSongsQuery__
 *
 * To run a query within a React component, call `useSongsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSongsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSongsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSongsQuery(baseOptions?: Apollo.QueryHookOptions<SongsQuery, SongsQueryVariables>) {
        return Apollo.useQuery<SongsQuery, SongsQueryVariables>(SongsDocument, baseOptions);
      }
export function useSongsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SongsQuery, SongsQueryVariables>) {
          return Apollo.useLazyQuery<SongsQuery, SongsQueryVariables>(SongsDocument, baseOptions);
        }
export type SongsQueryHookResult = ReturnType<typeof useSongsQuery>;
export type SongsLazyQueryHookResult = ReturnType<typeof useSongsLazyQuery>;
export type SongsQueryResult = Apollo.QueryResult<SongsQuery, SongsQueryVariables>;
export const SubUsersDocument = gql`
    query subUsers {
  subUsers {
    ...SmallUserFragment
    email
    dateOfBirth
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useSubUsersQuery__
 *
 * To run a query within a React component, call `useSubUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubUsersQuery(baseOptions?: Apollo.QueryHookOptions<SubUsersQuery, SubUsersQueryVariables>) {
        return Apollo.useQuery<SubUsersQuery, SubUsersQueryVariables>(SubUsersDocument, baseOptions);
      }
export function useSubUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubUsersQuery, SubUsersQueryVariables>) {
          return Apollo.useLazyQuery<SubUsersQuery, SubUsersQueryVariables>(SubUsersDocument, baseOptions);
        }
export type SubUsersQueryHookResult = ReturnType<typeof useSubUsersQuery>;
export type SubUsersLazyQueryHookResult = ReturnType<typeof useSubUsersLazyQuery>;
export type SubUsersQueryResult = Apollo.QueryResult<SubUsersQuery, SubUsersQueryVariables>;
export const ThemeDocument = gql`
    query theme($id: ID!) {
  theme(id: $id) {
    ...ThemeFragment
    javaTips {
      id
      body
      author {
        id
        firstName
        lastName
      }
      isDeleted
    }
  }
}
    ${ThemeFragmentFragmentDoc}`;

/**
 * __useThemeQuery__
 *
 * To run a query within a React component, call `useThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useThemeQuery(baseOptions: Apollo.QueryHookOptions<ThemeQuery, ThemeQueryVariables>) {
        return Apollo.useQuery<ThemeQuery, ThemeQueryVariables>(ThemeDocument, baseOptions);
      }
export function useThemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemeQuery, ThemeQueryVariables>) {
          return Apollo.useLazyQuery<ThemeQuery, ThemeQueryVariables>(ThemeDocument, baseOptions);
        }
export type ThemeQueryHookResult = ReturnType<typeof useThemeQuery>;
export type ThemeLazyQueryHookResult = ReturnType<typeof useThemeLazyQuery>;
export type ThemeQueryResult = Apollo.QueryResult<ThemeQuery, ThemeQueryVariables>;
export const ThemePhotosDocument = gql`
    query themePhotos {
  themePhotos {
    imageUrl
  }
}
    `;

/**
 * __useThemePhotosQuery__
 *
 * To run a query within a React component, call `useThemePhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemePhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemePhotosQuery({
 *   variables: {
 *   },
 * });
 */
export function useThemePhotosQuery(baseOptions?: Apollo.QueryHookOptions<ThemePhotosQuery, ThemePhotosQueryVariables>) {
        return Apollo.useQuery<ThemePhotosQuery, ThemePhotosQueryVariables>(ThemePhotosDocument, baseOptions);
      }
export function useThemePhotosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemePhotosQuery, ThemePhotosQueryVariables>) {
          return Apollo.useLazyQuery<ThemePhotosQuery, ThemePhotosQueryVariables>(ThemePhotosDocument, baseOptions);
        }
export type ThemePhotosQueryHookResult = ReturnType<typeof useThemePhotosQuery>;
export type ThemePhotosLazyQueryHookResult = ReturnType<typeof useThemePhotosLazyQuery>;
export type ThemePhotosQueryResult = Apollo.QueryResult<ThemePhotosQuery, ThemePhotosQueryVariables>;
export const ThemesDocument = gql`
    query themes {
  themes {
    ...ThemeFragment
    javaTips {
      id
      body
      isDeleted
    }
  }
}
    ${ThemeFragmentFragmentDoc}`;

/**
 * __useThemesQuery__
 *
 * To run a query within a React component, call `useThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemesQuery({
 *   variables: {
 *   },
 * });
 */
export function useThemesQuery(baseOptions?: Apollo.QueryHookOptions<ThemesQuery, ThemesQueryVariables>) {
        return Apollo.useQuery<ThemesQuery, ThemesQueryVariables>(ThemesDocument, baseOptions);
      }
export function useThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemesQuery, ThemesQueryVariables>) {
          return Apollo.useLazyQuery<ThemesQuery, ThemesQueryVariables>(ThemesDocument, baseOptions);
        }
export type ThemesQueryHookResult = ReturnType<typeof useThemesQuery>;
export type ThemesLazyQueryHookResult = ReturnType<typeof useThemesLazyQuery>;
export type ThemesQueryResult = Apollo.QueryResult<ThemesQuery, ThemesQueryVariables>;
export const TrainingDocument = gql`
    query training($id: ID!) {
  training(id: $id) {
    id
    user {
      id
    }
    name
    link
    type
    status
    step
  }
}
    `;

/**
 * __useTrainingQuery__
 *
 * To run a query within a React component, call `useTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrainingQuery(baseOptions: Apollo.QueryHookOptions<TrainingQuery, TrainingQueryVariables>) {
        return Apollo.useQuery<TrainingQuery, TrainingQueryVariables>(TrainingDocument, baseOptions);
      }
export function useTrainingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrainingQuery, TrainingQueryVariables>) {
          return Apollo.useLazyQuery<TrainingQuery, TrainingQueryVariables>(TrainingDocument, baseOptions);
        }
export type TrainingQueryHookResult = ReturnType<typeof useTrainingQuery>;
export type TrainingLazyQueryHookResult = ReturnType<typeof useTrainingLazyQuery>;
export type TrainingQueryResult = Apollo.QueryResult<TrainingQuery, TrainingQueryVariables>;
export const UnseenNotificationsDocument = gql`
    query unseenNotifications {
  unseenNotifications {
    id
    owner {
      id
    }
    type
    user {
      ...SmallUserFragment
    }
    group {
      id
      name
    }
    session {
      id
    }
    message
    seenEh
    actionedEh
    createdAt
    updatedAt
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useUnseenNotificationsQuery__
 *
 * To run a query within a React component, call `useUnseenNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnseenNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnseenNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnseenNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<UnseenNotificationsQuery, UnseenNotificationsQueryVariables>) {
        return Apollo.useQuery<UnseenNotificationsQuery, UnseenNotificationsQueryVariables>(UnseenNotificationsDocument, baseOptions);
      }
export function useUnseenNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnseenNotificationsQuery, UnseenNotificationsQueryVariables>) {
          return Apollo.useLazyQuery<UnseenNotificationsQuery, UnseenNotificationsQueryVariables>(UnseenNotificationsDocument, baseOptions);
        }
export type UnseenNotificationsQueryHookResult = ReturnType<typeof useUnseenNotificationsQuery>;
export type UnseenNotificationsLazyQueryHookResult = ReturnType<typeof useUnseenNotificationsLazyQuery>;
export type UnseenNotificationsQueryResult = Apollo.QueryResult<UnseenNotificationsQuery, UnseenNotificationsQueryVariables>;
export const UserDocument = gql`
    query user($id: ID!) {
  user(id: $id) {
    ...SmallUserFragment
    optOut
    community {
      id
    }
    notes {
      author {
        id
        firstName
        lastName
      }
      content
    }
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserByFirstNameDocument = gql`
    query userByFirstName($firstName: String) {
  userByFirstName(firstName: $firstName) {
    id
    nickname
    firstName
    lastName
  }
}
    `;

/**
 * __useUserByFirstNameQuery__
 *
 * To run a query within a React component, call `useUserByFirstNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByFirstNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByFirstNameQuery({
 *   variables: {
 *      firstName: // value for 'firstName'
 *   },
 * });
 */
export function useUserByFirstNameQuery(baseOptions?: Apollo.QueryHookOptions<UserByFirstNameQuery, UserByFirstNameQueryVariables>) {
        return Apollo.useQuery<UserByFirstNameQuery, UserByFirstNameQueryVariables>(UserByFirstNameDocument, baseOptions);
      }
export function useUserByFirstNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByFirstNameQuery, UserByFirstNameQueryVariables>) {
          return Apollo.useLazyQuery<UserByFirstNameQuery, UserByFirstNameQueryVariables>(UserByFirstNameDocument, baseOptions);
        }
export type UserByFirstNameQueryHookResult = ReturnType<typeof useUserByFirstNameQuery>;
export type UserByFirstNameLazyQueryHookResult = ReturnType<typeof useUserByFirstNameLazyQuery>;
export type UserByFirstNameQueryResult = Apollo.QueryResult<UserByFirstNameQuery, UserByFirstNameQueryVariables>;
export const UserCommunitiesDocument = gql`
    query userCommunities($id: ID!) {
  userCommunities(id: $id) {
    id
    community {
      id
      name
    }
    communities {
      id
      name
    }
  }
}
    `;

/**
 * __useUserCommunitiesQuery__
 *
 * To run a query within a React component, call `useUserCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCommunitiesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserCommunitiesQuery(baseOptions: Apollo.QueryHookOptions<UserCommunitiesQuery, UserCommunitiesQueryVariables>) {
        return Apollo.useQuery<UserCommunitiesQuery, UserCommunitiesQueryVariables>(UserCommunitiesDocument, baseOptions);
      }
export function useUserCommunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserCommunitiesQuery, UserCommunitiesQueryVariables>) {
          return Apollo.useLazyQuery<UserCommunitiesQuery, UserCommunitiesQueryVariables>(UserCommunitiesDocument, baseOptions);
        }
export type UserCommunitiesQueryHookResult = ReturnType<typeof useUserCommunitiesQuery>;
export type UserCommunitiesLazyQueryHookResult = ReturnType<typeof useUserCommunitiesLazyQuery>;
export type UserCommunitiesQueryResult = Apollo.QueryResult<UserCommunitiesQuery, UserCommunitiesQueryVariables>;
export const UserDefaultsDocument = gql`
    query userDefaults($id: ID!) {
  userDefaults(id: $id) {
    id
    defaultCam
    defaultMic
  }
}
    `;

/**
 * __useUserDefaultsQuery__
 *
 * To run a query within a React component, call `useUserDefaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDefaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDefaultsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserDefaultsQuery(baseOptions: Apollo.QueryHookOptions<UserDefaultsQuery, UserDefaultsQueryVariables>) {
        return Apollo.useQuery<UserDefaultsQuery, UserDefaultsQueryVariables>(UserDefaultsDocument, baseOptions);
      }
export function useUserDefaultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDefaultsQuery, UserDefaultsQueryVariables>) {
          return Apollo.useLazyQuery<UserDefaultsQuery, UserDefaultsQueryVariables>(UserDefaultsDocument, baseOptions);
        }
export type UserDefaultsQueryHookResult = ReturnType<typeof useUserDefaultsQuery>;
export type UserDefaultsLazyQueryHookResult = ReturnType<typeof useUserDefaultsLazyQuery>;
export type UserDefaultsQueryResult = Apollo.QueryResult<UserDefaultsQuery, UserDefaultsQueryVariables>;
export const UsersDocument = gql`
    query users {
  users {
    ...SmallUserFragment
    phone
    email
    role
    country
    postalcode
    ageGroup
    living
    parentUser
    createdAt
    status
    validUntil
    optOut
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const FuzzyUsersDocument = gql`
    query fuzzyUsers($name: String!, $isReqSearch: Boolean) {
  usersByFuzzyName(name: $name, isReqSearch: $isReqSearch) {
    ...SmallUserFragment
    email
    role
    community {
      id
      name
    }
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useFuzzyUsersQuery__
 *
 * To run a query within a React component, call `useFuzzyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFuzzyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFuzzyUsersQuery({
 *   variables: {
 *      name: // value for 'name'
 *      isReqSearch: // value for 'isReqSearch'
 *   },
 * });
 */
export function useFuzzyUsersQuery(baseOptions: Apollo.QueryHookOptions<FuzzyUsersQuery, FuzzyUsersQueryVariables>) {
        return Apollo.useQuery<FuzzyUsersQuery, FuzzyUsersQueryVariables>(FuzzyUsersDocument, baseOptions);
      }
export function useFuzzyUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FuzzyUsersQuery, FuzzyUsersQueryVariables>) {
          return Apollo.useLazyQuery<FuzzyUsersQuery, FuzzyUsersQueryVariables>(FuzzyUsersDocument, baseOptions);
        }
export type FuzzyUsersQueryHookResult = ReturnType<typeof useFuzzyUsersQuery>;
export type FuzzyUsersLazyQueryHookResult = ReturnType<typeof useFuzzyUsersLazyQuery>;
export type FuzzyUsersQueryResult = Apollo.QueryResult<FuzzyUsersQuery, FuzzyUsersQueryVariables>;
export const VersionDocument = gql`
    query version {
  version {
    id
    v
    readAt
  }
}
    `;

/**
 * __useVersionQuery__
 *
 * To run a query within a React component, call `useVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useVersionQuery(baseOptions?: Apollo.QueryHookOptions<VersionQuery, VersionQueryVariables>) {
        return Apollo.useQuery<VersionQuery, VersionQueryVariables>(VersionDocument, baseOptions);
      }
export function useVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VersionQuery, VersionQueryVariables>) {
          return Apollo.useLazyQuery<VersionQuery, VersionQueryVariables>(VersionDocument, baseOptions);
        }
export type VersionQueryHookResult = ReturnType<typeof useVersionQuery>;
export type VersionLazyQueryHookResult = ReturnType<typeof useVersionLazyQuery>;
export type VersionQueryResult = Apollo.QueryResult<VersionQuery, VersionQueryVariables>;
export const ChatRoomSeenDocument = gql`
    subscription chatRoomSeen($id: ID!) {
  chatRoomSeen(id: $id) {
    id
    firstName
    lastName
    nickname
    profilePicUrl
    lastSeenMessage
    isTyping
  }
}
    `;

/**
 * __useChatRoomSeenSubscription__
 *
 * To run a query within a React component, call `useChatRoomSeenSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatRoomSeenSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatRoomSeenSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChatRoomSeenSubscription(baseOptions: Apollo.SubscriptionHookOptions<ChatRoomSeenSubscription, ChatRoomSeenSubscriptionVariables>) {
        return Apollo.useSubscription<ChatRoomSeenSubscription, ChatRoomSeenSubscriptionVariables>(ChatRoomSeenDocument, baseOptions);
      }
export type ChatRoomSeenSubscriptionHookResult = ReturnType<typeof useChatRoomSeenSubscription>;
export type ChatRoomSeenSubscriptionResult = Apollo.SubscriptionResult<ChatRoomSeenSubscription>;
export const LiveChatRoomDocument = gql`
    subscription liveChatRoom($id: ID!) {
  liveChatRoom(id: $id) {
    id
    op
    users {
      user {
        ...SmallUserFragment
      }
      messageLog {
        id
        message
        from {
          ...SmallUserFragment
        }
        replyTo {
          id
          message
        }
        isSeen
        isDeleted
        createdAt
        sentAt
      }
      lastSeenMessage {
        id
      }
      isTyping
      isHidden
      unsentMessage
      isRemoved
    }
    sortedIdList
    isDeleted
    isGroup
    group
    roomName
  }
}
    ${SmallUserFragmentFragmentDoc}`;

/**
 * __useLiveChatRoomSubscription__
 *
 * To run a query within a React component, call `useLiveChatRoomSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLiveChatRoomSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveChatRoomSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLiveChatRoomSubscription(baseOptions: Apollo.SubscriptionHookOptions<LiveChatRoomSubscription, LiveChatRoomSubscriptionVariables>) {
        return Apollo.useSubscription<LiveChatRoomSubscription, LiveChatRoomSubscriptionVariables>(LiveChatRoomDocument, baseOptions);
      }
export type LiveChatRoomSubscriptionHookResult = ReturnType<typeof useLiveChatRoomSubscription>;
export type LiveChatRoomSubscriptionResult = Apollo.SubscriptionResult<LiveChatRoomSubscription>;
export const OpenChatMessageLogDocument = gql`
    subscription openChatMessageLog($id: ID!) {
  openChatMessageLog(id: $id) {
    id
    op
    message
    from {
      id
      firstName
      lastName
      nickname
      profilePicUrl
      bio
      interests
    }
    isDeleted
    createdAt
    sentAt
  }
}
    `;

/**
 * __useOpenChatMessageLogSubscription__
 *
 * To run a query within a React component, call `useOpenChatMessageLogSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOpenChatMessageLogSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenChatMessageLogSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpenChatMessageLogSubscription(baseOptions: Apollo.SubscriptionHookOptions<OpenChatMessageLogSubscription, OpenChatMessageLogSubscriptionVariables>) {
        return Apollo.useSubscription<OpenChatMessageLogSubscription, OpenChatMessageLogSubscriptionVariables>(OpenChatMessageLogDocument, baseOptions);
      }
export type OpenChatMessageLogSubscriptionHookResult = ReturnType<typeof useOpenChatMessageLogSubscription>;
export type OpenChatMessageLogSubscriptionResult = Apollo.SubscriptionResult<OpenChatMessageLogSubscription>;
export const OpenChatRoomMembersDocument = gql`
    subscription openChatRoomMembers($id: ID!) {
  openChatRoomMembers(id: $id) {
    id
    firstName
    lastName
    nickname
    palamiState
    twilioName
    profilePicUrl
    bio
    interests
    lastActive
  }
}
    `;

/**
 * __useOpenChatRoomMembersSubscription__
 *
 * To run a query within a React component, call `useOpenChatRoomMembersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOpenChatRoomMembersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenChatRoomMembersSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpenChatRoomMembersSubscription(baseOptions: Apollo.SubscriptionHookOptions<OpenChatRoomMembersSubscription, OpenChatRoomMembersSubscriptionVariables>) {
        return Apollo.useSubscription<OpenChatRoomMembersSubscription, OpenChatRoomMembersSubscriptionVariables>(OpenChatRoomMembersDocument, baseOptions);
      }
export type OpenChatRoomMembersSubscriptionHookResult = ReturnType<typeof useOpenChatRoomMembersSubscription>;
export type OpenChatRoomMembersSubscriptionResult = Apollo.SubscriptionResult<OpenChatRoomMembersSubscription>;
export const SessionFacilitatorsDocument = gql`
    subscription sessionFacilitators($id: ID!) {
  sessionFacilitators(id: $id) {
    ...MiniUserFragment
    role
    muteState
    vidState
    palamiState
    roomState
    twilioName
  }
}
    ${MiniUserFragmentFragmentDoc}`;

/**
 * __useSessionFacilitatorsSubscription__
 *
 * To run a query within a React component, call `useSessionFacilitatorsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionFacilitatorsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionFacilitatorsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionFacilitatorsSubscription(baseOptions: Apollo.SubscriptionHookOptions<SessionFacilitatorsSubscription, SessionFacilitatorsSubscriptionVariables>) {
        return Apollo.useSubscription<SessionFacilitatorsSubscription, SessionFacilitatorsSubscriptionVariables>(SessionFacilitatorsDocument, baseOptions);
      }
export type SessionFacilitatorsSubscriptionHookResult = ReturnType<typeof useSessionFacilitatorsSubscription>;
export type SessionFacilitatorsSubscriptionResult = Apollo.SubscriptionResult<SessionFacilitatorsSubscription>;
export const SessionHeadCountDocument = gql`
    subscription sessionHeadCount($id: ID!) {
  sessionHeadCount(id: $id) {
    trigger
    lat
    lng
    ip
  }
}
    `;

/**
 * __useSessionHeadCountSubscription__
 *
 * To run a query within a React component, call `useSessionHeadCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionHeadCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionHeadCountSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionHeadCountSubscription(baseOptions: Apollo.SubscriptionHookOptions<SessionHeadCountSubscription, SessionHeadCountSubscriptionVariables>) {
        return Apollo.useSubscription<SessionHeadCountSubscription, SessionHeadCountSubscriptionVariables>(SessionHeadCountDocument, baseOptions);
      }
export type SessionHeadCountSubscriptionHookResult = ReturnType<typeof useSessionHeadCountSubscription>;
export type SessionHeadCountSubscriptionResult = Apollo.SubscriptionResult<SessionHeadCountSubscription>;
export const SessionMembersDocument = gql`
    subscription sessionMembers($id: ID!) {
  sessionMembers(id: $id) {
    ...MiniUserFragment
    role
    muteState
    vidState
    palamiState
    roomState
    twilioName
  }
}
    ${MiniUserFragmentFragmentDoc}`;

/**
 * __useSessionMembersSubscription__
 *
 * To run a query within a React component, call `useSessionMembersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionMembersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionMembersSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionMembersSubscription(baseOptions: Apollo.SubscriptionHookOptions<SessionMembersSubscription, SessionMembersSubscriptionVariables>) {
        return Apollo.useSubscription<SessionMembersSubscription, SessionMembersSubscriptionVariables>(SessionMembersDocument, baseOptions);
      }
export type SessionMembersSubscriptionHookResult = ReturnType<typeof useSessionMembersSubscription>;
export type SessionMembersSubscriptionResult = Apollo.SubscriptionResult<SessionMembersSubscription>;
export const SessionMixedUsersDocument = gql`
    subscription sessionMixedUsers($id: ID!) {
  sessionMixedUsers(id: $id) {
    id
    firstName
    lastName
    nickname
    profilePicUrl
    bio
    interests
    role
    muteState
    vidState
    palamiState
    isMirrored
    roomState
    shareSong
    shareQuote
    sharePhoto
    twilioName
  }
}
    `;

/**
 * __useSessionMixedUsersSubscription__
 *
 * To run a query within a React component, call `useSessionMixedUsersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionMixedUsersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionMixedUsersSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionMixedUsersSubscription(baseOptions: Apollo.SubscriptionHookOptions<SessionMixedUsersSubscription, SessionMixedUsersSubscriptionVariables>) {
        return Apollo.useSubscription<SessionMixedUsersSubscription, SessionMixedUsersSubscriptionVariables>(SessionMixedUsersDocument, baseOptions);
      }
export type SessionMixedUsersSubscriptionHookResult = ReturnType<typeof useSessionMixedUsersSubscription>;
export type SessionMixedUsersSubscriptionResult = Apollo.SubscriptionResult<SessionMixedUsersSubscription>;
export const SessionModifiedDocument = gql`
    subscription sessionModified($id: ID!) {
  sessionModified(id: $id) {
    currentScreen
    status
    songPlaying
    isDirect
    videoChatStatus
    contentType
    contentId
    globalMessage
    sessionMaster
  }
}
    `;

/**
 * __useSessionModifiedSubscription__
 *
 * To run a query within a React component, call `useSessionModifiedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionModifiedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionModifiedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionModifiedSubscription(baseOptions: Apollo.SubscriptionHookOptions<SessionModifiedSubscription, SessionModifiedSubscriptionVariables>) {
        return Apollo.useSubscription<SessionModifiedSubscription, SessionModifiedSubscriptionVariables>(SessionModifiedDocument, baseOptions);
      }
export type SessionModifiedSubscriptionHookResult = ReturnType<typeof useSessionModifiedSubscription>;
export type SessionModifiedSubscriptionResult = Apollo.SubscriptionResult<SessionModifiedSubscription>;
export const SessionWaitingRoomDocument = gql`
    subscription sessionWaitingRoom($id: ID!) {
  sessionWaitingRoom(id: $id) {
    ...MiniUserFragment
    role
    muteState
  }
}
    ${MiniUserFragmentFragmentDoc}`;

/**
 * __useSessionWaitingRoomSubscription__
 *
 * To run a query within a React component, call `useSessionWaitingRoomSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionWaitingRoomSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionWaitingRoomSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionWaitingRoomSubscription(baseOptions: Apollo.SubscriptionHookOptions<SessionWaitingRoomSubscription, SessionWaitingRoomSubscriptionVariables>) {
        return Apollo.useSubscription<SessionWaitingRoomSubscription, SessionWaitingRoomSubscriptionVariables>(SessionWaitingRoomDocument, baseOptions);
      }
export type SessionWaitingRoomSubscriptionHookResult = ReturnType<typeof useSessionWaitingRoomSubscription>;
export type SessionWaitingRoomSubscriptionResult = Apollo.SubscriptionResult<SessionWaitingRoomSubscription>;