import React, { createContext, useReducer } from "react";

const initialState = {
    communityId: "",
    communityName: "",
    groups: [],
};


const ACTIONS = {
    CLEAR: "clear",
    UPDATE_ALL: "update",
    UPDATE_ONE: "update",
}




const reducer = (state, action) => {
  switch (action.type) {
    
    case ACTIONS.UPDATE_ALL:
      return {...action.payload}
    case ACTIONS.UPDATE_ONE:   
      return { ...state, [action.payload.name]: action.payload.value }; 
    default:
      return state;
  }
};

const CommunityRegContext = createContext(initialState);

const CommunityRegProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CommunityRegContext.Provider value={{ state, dispatch }}>
      {props.children}
    </CommunityRegContext.Provider>
  );
};

export { CommunityRegContext, CommunityRegProvider, ACTIONS};