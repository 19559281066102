import React, { createContext, useReducer } from "react";

const initialState = {
    themes: [],
    music: [],
    reflections: [],
    

};


const ACTIONS = {
    CLEAR: "clear",
    UPDATE_ALL: "update",
    UPDATE_ONE: "update",
}




const reducer = (state, action) => {
  switch (action.type) {
    
    case ACTIONS.UPDATE_ALL:
      return {...action.payload}
    case ACTIONS.UPDATE_ONE:   
      return { ...state, [action.payload.name]: action.payload.value }; 
    case ACTIONS.CLEAR:   
      return initialState; 
    default:
      return state;
  }
};

const DataStoreContext = createContext(initialState);

const DataStoreProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DataStoreContext.Provider value={{ state, dispatch }}>
      {props.children}
    </DataStoreContext.Provider>
  );
};

export { DataStoreContext, DataStoreProvider, ACTIONS};
