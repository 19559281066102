import React, { useState } from 'react'
import { toast } from 'react-toastify';

import './Landing.css';

const ContactUs = () => {
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [body, setBody] = useState("")
    const [name, setName] = useState("")

    const [wait, setWait] = useState(false)
    // THE CODE TO SEND EMAIL
    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    const handleSend = () => {


        if (name.trim() === "") {
            toast.error("Your name cannot be empty, how else will we know what to call you?");
            
        } else if (!validateEmail(email) || email.trim() === "") {
            toast.error("That looks like an invalid email (it cannot be empty either), please try again or use a different email address.");
            return;
        } else if (subject.trim() === "") {
            toast.error("Subject cannot be empty, what is your message about?");
            return;
        }else if (body.trim() === "") {
            toast.error("Your message cannot be empty, what did you want to ask or talk about?");
            return;
        } else if (validateEmail(email) && subject != "" && body != "" && name != "") {

            fetch(process.env.REACT_APP_MAIL_API, {
                method: 'POST',
                body: JSON.stringify({
                    from: `${email}`,
                    to: "info@javaconnects.com",
                    subject: `${subject}`,
                    emailBody: `Name: ${name} 
                            Email: ${email}
                            Message: ${body}`

                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json())
            .then((json) => console.log(json))

            // Empty the boxes so they don't resend the same email again:

            setName("");
            setEmail("");
            setSubject("");
            setBody("");

            toast.success("Email sent successfully!");

        } else {
            toast.error("Oops, looks like some boxes are empty or filled incorrectly");
        }
    }
    return (
        <div class="landing-page">
            <div className="d-flex flex-row justify-content-center">
                <div className="mt-5 col-10 col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8">
                    <div className="row">
                        <div className={`col col-12 d-flex flex-column justify-content-center`}>
                            <div className="landing-heading">
                                Contact <span className="text-landing">Us</span>
                            </div>
                        </div>
                        <div className={`col col-6 col-xs-10 col-sm-10 col-md-10 col-lg-8 d-flex flex-column justify-content-center`}>
                            <p>
                                If you have any questions or would like to purchase a license for the JAVAconnects program, please don’t hesitate to send us an email at:

                                <a className={`text-landing`} href="mailto:info@javaconnects.com"> info@javaconnects.com</a>
                            </p>
                            <p>
                                or </p>
                            <p>
                                call us direct <a className={`text-landing`} href="tel:647-660-3910">647-660-3910</a> or toll free: <a className={`text-landing`} href="tel:1-866-523-2411">1-866-523-2411</a> (Canada and US)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="landing-background text-light">
                <div className="d-flex flex-row justify-content-center">
                    <div className="mt-5 mb-5 col-10 col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8">
                        <div className="row">
                            <div className={`col col-12 d-flex flex-column justify-content-center`}>
                                <div className="landing-subheading">
                                    Direct Message
                                </div>
                                <p className="lead">You can also use the form below to get in touch with us, and we will email you back about your inquery.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="d-flex flex-row justify-content-center">
                <div className="mt-5 py-4 px-3 mb-5 col-10 col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8">
                    <div className="row">
                        <div className={`col col-12 d-flex flex-column justify-content-center`}>
                            <form id='contact-form' className={`d-flex flex-column justify-content-center`} onSubmit={(e) => {
                                e.preventDefault()
                                handleSend()
                            }}>
                                <div className="form-group d-flex align-items-center">
                                    <label className={`col-1 pl-0`} style={{ minWidth: "100px !important" }}>Your Name: </label>
                                    <input type='text' name='user_name' value={name} className={`form-control ml-3 ml-md-0  col-10 col-lg-6`} onChange={(e) => setName(e.target.value)} placeholder='Name' />
                                </div>
                                <br />
                                <div className="form-group d-flex align-items-center">
                                    <label className={`col-1 pl-0`}>Your Email: </label>
                                    <input type='email' name='user_email' value={email} className={`form-control  ml-3 ml-md-0 col-10 col-lg-6`} onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
                                </div>
                                <br />
                                <div className="form-group align-items-center">
                                    <label className={`col-2 pl-0`}>Subject: </label>
                                    <input type='text' name='subject' value={subject} className={`form-control`} onChange={(e) => setSubject(e.target.value)} placeholder='Subject' />
                                </div>
                                <br />
                                <div className="form-group align-items-center">
                                    <label className={`col-2 pl-0`}>Message: </label>
                                    <textarea name='message' value={body} style={{ height: "250px", maxHeight: `450px` }} className={`form-control`} onChange={(e) => setBody(e.target.value)} placeholder='Message' />
                                </div>
                                <br />
                                <input type='submit' className={`btn landing-button btn-lg`} value='Send' />
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ContactUs
