import React from "react";
import ReactDOM from "react-dom";

// CSS and SASS
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/zoom-ui.scss";
import "./scss/bootstrap-override.scss";
import "./scss/style.scss";
import "./index.css";
import "animate.css/animate.min.css";
import App from "./components/App";
import { UserProvider } from "./context/UserContext";
import { StyleProvider } from "./context/StyleContext";

import Apollo from "./components/Apollo";
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from "./components/ErrorBoundary";
import 'react-image-crop/dist/ReactCrop.css';
if (process.env.NODE_ENV === 'production') {
  // console.log = function () {};
  // console.warn = function () {};
}

ReactDOM.render(
   <ErrorBoundary boundary={"SUPER OOF... DIDN'T EVEN MAKE IT INTO APP"}>
  <React.StrictMode>
      <UserProvider>
    <StyleProvider>

      <Apollo>
        <App />
      </Apollo>

    </StyleProvider>
    </UserProvider>
  </React.StrictMode>
     </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
