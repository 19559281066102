import React from 'react';
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import './Landing.css';

const AboutUs = () => {
    return (
        <div className="landing-page">
            <div className="d-flex flex-row justify-content-center">
                <div className="mt-5 mb-5 col-10 col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8">
                    <div className="row">
                        <div className={`col  col-md-5 d-flex flex-column justify-content-center`}>
                            <div className="landing-heading">
                                About <span className="text-landing">Java Group Programs</span>
                            </div>
                            <p>
                            Java Group Programs are the first standardized peer support interventions designed to address the critical rates of depression and loneliness across the senior living spectrum and older adults in the community.
                                <br /><br />
                                The programs are research-based and successfully trialed and tested for effectiveness. They have been implemented in long-term care homes, memory care, assisted living and retirement homes, adult day centers, hospitals and community centres. A summary of the published research on these programs is available here: <a href="http://javagp.com/research/">The Science Behind Java</a>
                                <br /><br />
                                JAVAconnects is the virtual platform developed to engage seniors with peers, no mater where they live. The technology has been developed to encourage engagement and group sharing by seniors and others, using minimal technical skills.
                                <br /><br />
                                Java Group Programs Inc. is based in Vancouver, British Columbia, and its sister company, Java Group Programs LLC, is based in Florida. The Java programs are currently in over 1200 organizations throughout North America.
                            </p>
                        </div>
                        <div className={`col col-7 d-none d-md-flex flex-column`}>
                            <Carousel fade nextIcon="" nextLabel="" prevIcon="" prevLabel="">
                                <Carousel.Item interval={5000}>
                                    <div className="position-relative text-center">
                                        <img
                                            className="mx-auto my-3 rounded box-shadow"
                                            src="https://d30mg0zmvh01j5.cloudfront.net/assets/photos/600/LANDING.jpg"
                                            alt="First slide"
                                            style={{  filter: "brightness(70%)" }}
                                        />
                                        {/* <p className="position-absolute align-self-center bg-cleard round-box box-shadow p-2" style={{ left: "50%", transform: "translateX(-50%)", bottom: "3%" }} > JAVAconnects provides online weekly meetings for like-minded adults to share their experience on important topics… like Gratitude, Family, Loneliness and Helping Others. </p> */}

                                    </div>
                                </Carousel.Item>
                                <Carousel.Item interval={5000}>
                                    <div className="position-relative text-center">
                                        <img
                                            className="mx-auto my-3 rounded box-shadow"
                                            src="https://d30mg0zmvh01j5.cloudfront.net/assets/photos/600/VVTRUTH.jpg"
                                            alt="First slide"
                                            style={{  filter: "brightness(70%)" }}
                                        />
                                        {/* <p className="position-absolute align-self-center bg-cleard round-box box-shadow p-2" style={{ left: "50%", transform: "translateX(-50%)", bottom: "3%" }} > Everyone is welcome. Everyone gets a chance to share.</p> */}

                                    </div>
                                </Carousel.Item>
                                <Carousel.Item interval={5000}>
                                    <div className="position-relative text-center">
                                        <img
                                            className="mx-auto my-3 rounded box-shadow"
                                            src="https://d30mg0zmvh01j5.cloudfront.net/assets/photos/600/RINCLUSION.jpg"
                                            alt="First slide"
                                            style={{  filter: "brightness(70%)" }}
                                        />
                                        {/* <p className="position-absolute align-self-center bg-cleard round-box box-shadow p-2" style={{ left: "50%", transform: "translateX(-50%)", bottom: "3%" }} >All you need is a computer with camera and mic. Nothing to download, no expertise needed. Once you’ve signed in there’s no need to click, scroll or swipe. Just watch, listen and speak. </p> */}

                                    </div>
                                </Carousel.Item>

                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>

            <div class="landing-background">
                <div className="d-flex flex-row justify-content-center">
                    <div className="mt-5 mb-5 col-10 col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8">
                        <div className="row text-light">
                            <div className="col col-12">
                                <div className="landing-subheading">
                                    Ending Loneliness & Spreading Happiness
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-row justify-content-center">
                <div className="mt-5 mb-5 col-10 col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8">
                    <div className="row mt-3">
                        <div className="col col-12">
                            <div className="landing-subheading">
                                About <span className="text-landing">the Team</span>
                            </div>
                        </div>
                        <div className={`col col-12 col-md-4 d-flex flex-column my-2`}>
                            <img height="450" src="https://d30mg0zmvh01j5.cloudfront.net/assets/photos/landing/5ffcdb4b02ca4637f2d201671402-1.jpg" className="img-fluid" />
                        
                              <p className="lead font-weight-bold">Dr. Kristine Theurer, <br /><span className="text-landing">President</span></p>
                              <p>Dr. Kristine Theurer is a researcher who is pioneering the use of standardized peer support and peer mentoring programs to address loneliness and social isolation in senior living.
                                   She is a published author of a number of research articles, the most recent of which <span className={`font-italic`}>‘It makes life worthwhile!’ Peer mentoring in long-term care—a feasibility </span>  
                                   study in the Journal of Aging & Mental Health, <span className={`font-italic`}>The Impact of Peer Mentoring on Loneliness, Depression, and Social Engagement in Long-Term Care </span> 
                                   in the Journal of Applied Gerontology and <span className={`font-italic`}>The Need for a Social Revolution in Residential Care</span>, the most downloaded article in the Journal of Aging Studies. Kristine has completed her PhD at the University of British Columbia, leads training workshops and webinars in Canada and the US and presents regularly at international conferences. She has received numerous research awards including the Michael Smith Foundation for Health Research and the Social Sciences and Humanities Research Council of Canada. She serves on the planning committee for the national conference on culture change in Canada, hosted by the Schlegel-UW Research Institute for Aging.</p>                             
                        </div>

                        <div className={`col col-12 col-md-4 d-flex flex-column  my-2`}>
                        <img height="450" src="https://d30mg0zmvh01j5.cloudfront.net/assets/photos/landing/5ff484a4429cfb18f04921183418-1.jpg" className="img-fluid "  />
                            <p className="lead font-weight-bold">Clayton MacKay, <br /><span className="text-landing">CEO and Vice-President</span></p>
                            <p>As Co-founder and CEO of the Java Group Programs, Clayton brings many years of business experience. He has served on the Insurance Council of British Columbia and has been a contract supervisor for the Provincial Emergency Program. In addition, he worked closely with Ministry of Health in their third party cost recovery program. Clayton has also volunteered for many years with men in recovery from addiction and has served on a board member for a non-profit agency that provides a vital link in the continuum of recovery services.</p>
                            <p>He has built an understanding of the strong impact of peer support groups and the ability of these groups to create positive and supportive communities. He currently serves on the committee for the Canadian Culture Change movement called ‘Walk with Me’. His plan for Java Group Programs is to standardize the use of peer support programs within senior living across Canada and the US.</p>

                        
                        </div>
                        <div className={`col col-12 col-md-4 d-flex flex-column my-2`}>
                            <img  src="https://d30mg0zmvh01j5.cloudfront.net/assets/photos/landing/ALEXLANDING2.jpg" className="img-fluid"  />
                      
                            <p className="lead font-weight-bold">Alexander Koumarianos, <br /><span className="text-landing">Lead Developer</span></p>
                            <p>Alexander has an extensive background in software development, sales, and marketing. This combination makes him uniquely equipt to understand what the target user needs from the app, how it can best serve them, and how to bring that idea to life from a technical perspective.</p>
                            <p>He is currently responsible for development team project managment, DevOps, software QA, and the majority of front-end and back-end development. </p>
                       
                        </div>

                    </div>
                </div>
            </div>

            <div class="landing-background">
                <div className="d-flex flex-row justify-content-center">
                    <div className="mt-5 mb-5 col-10 col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8">
                        <div className="row text-light">
                            <div className="col col-6 col-md-3">
                                <div className="landing-subheading">
                                    30+
                                </div>
                                <p>Years of Experience</p>
                            </div>
                            <div className="col col-6 col-md-3">
                                <div className="landing-subheading">
                                    1200+
                                </div>
                                <p>Senior Living Organizations</p>
                            </div>
                            <div className="col col-6 col-md-3">
                                <div className="landing-subheading">
                                    15%
                                </div>
                                <p>Decrease in Loneliness</p>
                            </div>
                            <div className="col col-6 col-md-3">
                                <div className="landing-subheading">
                                    60%
                                </div>
                                <p>Increase in Participation</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-row justify-content-center">
                <div className="mt-5 mb-5 col-10 col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8">
                    <div className="row mt-3">
                        <div className="col col-12">
                            <div className="landing-subheading">
                                Want to <span className="text-landing">find out more</span>?
                            </div>
                            <p className="lead">Click below to find out more about our programs!</p>
                             <Link to="/programs" className="btn landing-button btn-lg">Our Programs </Link>&nbsp;
                            <Link to="/javaconnects" className="btn landing-button btn-lg">JavaConnects </Link>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>



    )
}

export default AboutUs
