import React, { createContext, useReducer } from "react";

const initialState = {
  fontSize: parseFloat(localStorage.getItem("fontSize") ?? 1.0),
};

const ACTIONS = {
  CLEAR: "clear",
  UPDATE_ALL: "update",
  UPDATE_ONE: "update",
};

const reducer = (state, action) => {
  localStorage.setItem("fontSize", action.payload.fontSize);
  switch (action.type) {
    case ACTIONS.UPDATE_ALL:
      return { ...action.payload };
    case ACTIONS.UPDATE_ONE:
      return { ...state, [action.payload.name]: action.payload.value };
    default:
      return state;
  }
};

const StyleContext = createContext(initialState);

const StyleProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StyleContext.Provider value={{ state, dispatch }}>
      {props.children}
    </StyleContext.Provider>
  );
};

export { StyleContext, StyleProvider, ACTIONS };
