import {useContext} from "react";
import { UserContext } from "../../../../../context/UserContext";
import ThemeReflection from "../../components/ThemeReflection";
import { GiSaveArrow } from "react-icons/gi";
import FacilitationPrompt from "../../../FacilitationPrompt";
import {  RunSessContext } from "../../../../../context/RunSessContext";
const Reflection = props => {
  const { reflection, onThemeCardClick, isRunningSession, currentPage } = props;
  const { state } = useContext(UserContext);
        const { state: sessState } = useContext(RunSessContext);
  return (
    <>
      <div className="d-flex mb-5" >
        <h3>{state.user.role == "ROLE_FACILITATOR"&&sessState.memberView !== true&&`${currentPage+1}.`} Reflection</h3>

        {!isRunningSession ? (
          <button
            className="ml-2  btn-info btn-extra-radius border-0 px-3 text-white align-items-center"
            onClick={() => onThemeCardClick("addNote", "_", 1)}
          >
            <GiSaveArrow className="mr-2" />
            <span>Add Note</span>
          </button>
        ) : (
          <></>
        )}
      </div>
      {state.user.role == "ROLE_FACILITATOR"&& sessState.memberView !== true&&
               

 <FacilitationPrompt content={["The following reflection is written by Kristine, founder of JAVA programs, based on her thoughts and experiences around the topic. This reflection is meant to provide a jumping off point for discussion. I'd like to invite someone to read it now. How about ___? (call on a member). "]}/>
                
}
      <ThemeReflection
        reflection={reflection}
        isJavaTips={false}
        onThemeCardClick={() => onThemeCardClick("reflection")}
      />
    </>
  );
};

export default Reflection;