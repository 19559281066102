
import SongPlayer from "./SongPlayer";
//import  from "./SongPlayerRefProp";
export default function MusicController(props) {

  return (
    <div className="col">
      <SongPlayer
        src={props.musicUrl}
        songurl={props.musicUrl}
      />
    </div>
  );
}
