import { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Slide from "@material-ui/core/Slide";
import {
  FaChevronRight,
  FaChevronLeft,
  FaQuestion,
  FaDoorOpen,
  FaPenFancy,
} from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { BiLogInCircle } from "react-icons/bi";
import { BsShieldLockFill } from "react-icons/bs";
import { MdHome, MdEmail, MdInfoOutline } from "react-icons/md";
import "./Landing.css";

const SideNav = () => {
  const [checked, setChecked] = useState(true);
  const isMobile = useMediaQuery({ query: `(max-width: 1375px)` });
  useEffect(() => {
    if (isMobile) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }, [isMobile]);
  return (
    <div>
      <div
        style={{
          position: "fixed",
          left: 0,
          minHeight: "100vh",
          maxWidth: isMobile ? "8vw" : "15vw",
          zIndex: "100",
        }}
        className={` p-0  d-flex align-items-center   `}
        onClick={() => {
          if (isMobile && checked) {
            setChecked(false);
          }
        }}
      >
        {!checked && (
          <div
            className={` position-fixed h-100  side-nav-nub  side-nav bg-nav-menu box-shadow d-flex flex-column align-items-start`}
            style={{ minHeight: "100vh", height: "100vh", maxHeight: "100vh" }}
            onClick={() => setChecked(!checked)}
          >
            <button
              className={`btn btn-none`}
              type="button"
              onClick={() => setChecked(!checked)}
            >
              <span className={`text-light`}>
                {" "}
                {checked ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
              </span>
            </button>
            <div className={`w-100 px-0 mx-0 pb-`}>
              <div className={`w-100 pl-0 ml-0 mr-0 pr-0 pl-0  `}>
                <div className={` col col-12 use-border`}></div>
              </div>
            </div>
            <NavLink
              to="/welcome"
              onClick={e => {
                e.stopPropagation();
              }}
              className="side-nav-link d-flex py-2 mx-auto d-flex justify-content-center align-items-center "
            >
              <span className=" font-size-3  ">
                <MdHome />
              </span>
            </NavLink>
            <NavLink
              to="/about"
              onClick={e => {
                e.stopPropagation();
              }}
              className="side-nav-link d-flex py-2 mx-auto d-flex justify-content-center align-items-center "
            >
              <span className=" font-size-3  ">
                <MdInfoOutline />
              </span>
            </NavLink>
            <NavLink
              to="/contact"
              onClick={e => {
                e.stopPropagation();
              }}
              className="side-nav-link d-flex py-2 mx-auto d-flex justify-content-center align-items-center "
            >
              <span className=" font-size-3  ">
                <MdEmail />
              </span>
            </NavLink>
            <NavLink
              to="/faq"
              onClick={e => {
                e.stopPropagation();
              }}
              className="side-nav-link d-flex py-2 mx-auto d-flex justify-content-center align-items-center "
            >
              <span className=" font-size-3  ">
                <FaQuestion />
              </span>
            </NavLink>
            <NavLink
              to="/login"
              onClick={e => {
                e.stopPropagation();
              }}
              className="side-nav-link pt-5 d-flex py-2 mx-auto d-flex justify-content-center align-items-center "
            >
              <span className=" font-size-3  ">
                <BsShieldLockFill />
              </span>
            </NavLink>
            <NavLink
              to="/register"
              onClick={e => {
                e.stopPropagation();
              }}
              className="side-nav-link d-flex py-2 mx-auto d-flex justify-content-center align-items-center "
            >
              <span className=" font-size-3  ">
                <FaPenFancy />
              </span>
            </NavLink>
          </div>
        )}
        <Slide
          direction="right"
          timeout={{ enter: 300, exit: 320 }}
          in={checked}
          mountOnEnter
          unmountOnExit
        >
          <div
            style={{
              height: "100vh",
              maxHeight: "100vh",
              position: "relative",
              width: isMobile ? "450vw" : "15vw",
            }}
            className={` p-0  side-nav d-flex bg-cleard-menu box-shadow align-items-between   `}
          >
            <div className="col-12 mb-5 pb-5 ">
              <div className="col-12">
                <div
                  className={`col col-12 d-flex justify-content-center my-2`}
                >
                  <img
                    src={`https://d30mg0zmvh01j5.cloudfront.net/assets/photos/logos/JClogo6.svg`}
                    id="icon"
                    alt="Java Group"
                    style={{ width: "70%", minWidth: "130px" }}
                  />
                </div>
              </div>
              <div className={`d-flex row justify-content-center pb-1`}>
                <div
                  className={`col col-12 pl-0 ml-0 mr-0 pr-0  d-flex  align-items-center `}
                >
                  <div className={`col col-12 use-border`}></div>
                </div>
              </div>

              <div
                style={{ minHeight: "400px" }}
                className={` d-flex flex-column h-100 align-items-around my-2 justify-content-center p-0 pt-4 pb-5 mb-5`}
              >
                <div className={`col    `}>
                  <div className={`col d-flex py-4  `}>
                    <div className={`col d-flex align-items-start col-12  `}>
                      <NavLink to="/welcome" className="side-nav-link d-flex ">
                        <span className="mr-2 font-size-3  d-flex align-items-center">
                          <MdHome />
                        </span>{" "}
                        <span className="mr-2 d-flex font-size-4  align-items-center">
                          {" "}
                          Welcome
                        </span>
                      </NavLink>
                    </div>
                  </div>
                  {/* <div className={`col d-flex  `}>
                  <div className={`col d-flex  align-items-start col-12  `}>
                    <NavLink to="/javaconnects" className="h4 side-nav-link">
                      <span className="mr-2"> JAVAconnects</span>
                    </NavLink>
                  </div>
                </div>
                <div className={`col d-flex   `}>
                  <div className={`col d-flex  align-items-start col-12  `}>
                    <NavLink to="/programs" className="h4 side-nav-link">
                      <span className="mr-2"> Programs</span>
                    </NavLink>
                  </div>
                </div> */}
                  <div className={`col d-flex  py-4 `}>
                    <div className={`col d-flex  align-items-start col-12  `}>
                      <NavLink to="/about" className=" side-nav-link d-flex">
                        <span className="mr-2 font-size-3  d-flex align-items-center">
                          <MdInfoOutline />
                        </span>{" "}
                        <span className="mr-2 d-flex font-size-4 align-items-center">
                          {" "}
                          About
                        </span>
                      </NavLink>
                    </div>
                  </div>
                  <div className={`col d-flex  py-4 `}>
                    <div className={`col d-flex  align-items-start col-12  `}>
                      <NavLink to="/contact" className=" side-nav-link d-flex ">
                        <span className="mr-2 font-size-3  d-flex align-items-center">
                          <MdEmail />
                        </span>{" "}
                        <span className="mr-2 d-flex font-size-4 align-items-center">
                          {" "}
                          Contact
                        </span>
                      </NavLink>
                    </div>
                  </div>
                  <div className={`col d-flex  py-3 `}>
                    <div className={`col d-flex  align-items-start col-12  `}>
                      <NavLink to="/faq" className=" side-nav-link d-flex ">
                        <span className="mr-2 font-size-3 d-flex align-items-center">
                          <FaQuestion />
                        </span>{" "}
                        <span className="mr-2 d-flex font-size-4 align-items-center">
                          {" "}
                          FAQ
                        </span>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div
                  style={{ maxHeight: "100vh" }}
                  className={`flex-column  d-flex align-items-end   `}
                >
                  <div className={`col d-flex  my-0 pb-2 `}>
                    <div
                      className={`col d-flex  my-0 pl-0 align-items-end col-12  `}
                    >
                      <Link
                        to="/login"
                        className="font-size-3 bnt landing-button-rev btn-lg w-100 side-nav-link d-flex justify-content-center"
                      >
                        <span className="  ">
                          <BsShieldLockFill className=" mb-1 mr-1" />
                        </span>{" "}
                        <span className="ml-1"> Log-in</span>
                      </Link>
                    </div>
                  </div>
                  <div className={`col d-flex  my-0 `}>
                    <div
                      className={`col d-flex pl-0  my-0 align-items-start col-12  `}
                    >
                      <Link
                        to="/register"
                        className="font-size-3 bnt landing-button-rev btn-lg w-100 side-nav-link d-flex justify-content-center"
                      >
                        <span className="  mr-1">
                          <FaPenFancy />
                        </span>
                        <span className="ml-1"> Register</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {checked && (
              <button
                style={{
                  position: "absolute",
                  right: 0,
                  minHeight: "100vh",
                  maxWidth: isMobile ? "7vw" : "17vw",
                  zIndex: "100",
                }}
                className={`btn btn-none`}
                type="button"
                onDragStart={() => setChecked(!checked)}
                onClick={() => setChecked(!checked)}
              >
                <span className={`text-light mr-2 pr-2`}>
                  {" "}
                  {checked ? <FaChevronLeft /> : <FaChevronRight />}
                </span>
              </button>
            )}
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default SideNav;
