import { useRef, useCallback, useEffect } from "react";

export const useDebouncedCallback = (func, wait) => {
  const timeout = useRef();
  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);
  return useCallback(
    (...args) => {
      const later = () => {
        clearTimeout(timeout.current);
        func(...args);
      };

      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
    },
    [func, wait]
  );
};
