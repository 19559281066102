import jwtDecode from "jwt-decode";
import React, { createContext, useReducer } from "react";

const initialState = {
  accessToken: null,
  user: null,
  friends: null
};

const CLEAR = "CLEAR";
const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
const SET_PASS_RESET_TOKEN = "SET_PASS_RESET_TOKEN";
const SET_COMMUNITY_NAME = "SET_COMMUNITY_NAME";
const SET_ME_DATA = "SET_ME_DATA";
const SET_FRIENDS = "SET_FRIENDS";

const userFromToken = token => {
  const {
    userId,
    role,
    validUntil,
    status,
    communityId
  } = jwtDecode(token);
  return { id: userId, role, validUntil, status, communityId };
};
const infoFromToken = token => {
  const {
    userId,
    email,
    passResetExpire,
  } = jwtDecode(token);
  return {  email, id: userId, passResetExpire  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case CLEAR:
      return initialState;
    case SET_ACCESS_TOKEN:
      const user = userFromToken(action.payload.accessToken);
      return { ...state, user, accessToken: action.payload.accessToken };
    case SET_PASS_RESET_TOKEN:
      const info = infoFromToken(action.payload.resetToken);
      return { ...state, info, resetToken: action.payload.resetToken };
    case SET_COMMUNITY_NAME:
      return { ...state, user: {...state.user, communityName: action.payload.communityName} };
    case SET_FRIENDS:
      return { ...state, friends: [...action.payload.friends] };
    case SET_ME_DATA:
      console.log(action.payload);
      return { ... state, user: { ...state.user, 
        email: action.payload.email, 
        firstName: action.payload.firstName, 
        lastName: action.payload.lastName,
        nickname: action.payload.nickname,
        profilePicUrl: action.payload.profilePicUrl
      }
    }
    default:
      return;
  }
};

const UserContext = createContext(initialState);

const UserProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider, CLEAR, SET_ACCESS_TOKEN, SET_PASS_RESET_TOKEN, SET_COMMUNITY_NAME, SET_ME_DATA,SET_FRIENDS };
