import { useApolloClient } from "@apollo/client";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { setAccessToken } from "../../auth/accessToken";
import { CLEAR, UserContext } from "../../context/UserContext";
import { useLogoutMutation } from "../../graphql/generated/graphql";
import LoadingSpinner from "../LoadingSpinner";

const Logout = () => {
  const history = useHistory();
  const client = useApolloClient();
  const { dispatch } = useContext(UserContext);

  const [logout] = useLogoutMutation();

  const logoutProcedure = async () => {
    const logoutResponse = await logout();
    setAccessToken("");
    dispatch({ type: CLEAR });
    // Clears the apollo cache
    await client.clearStore().then(res => {
      history.push("/login");
    });
  };

  useEffect(() => {
    logoutProcedure();
  }, []);

  return <LoadingSpinner />;
};

export default Logout;
