import  { useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import LoadingSpinner from "../../LoadingSpinner";

import {
  useThemeQuery,
  useRemoveJavaTipMutation,
} from "./../../../graphql/generated/graphql";
import { UserContext } from "../../../context/UserContext";

// Sections
import TopThemePage from "./sections/TopThemePage";
import DiscussionQuestions from "./sections/DiscussionQuestions";
import Quotes from "./sections/Quotes";
import SensoryCues from "./sections/ThemePhoto";
import Songs from "./sections/Songs";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import GenericModal from "../../GenericModal";
import ThemeModalContent from "./ThemeModalContent";
import CommentBox from "../../CommentBox";
import { toast } from "react-toastify";
import Reflection from "./sections/Reflection";
import Sharing from "./sections/Sharing";

/**
 * 
 * @namespace RunningSession.Theme
 */

export default function ThemeContainer(props) {
  // HOOKS
  // const locationHook = useLocation();
  // const { isPageEditable, theme } = locationHook;
  const { id: themeId } = useParams();
  const { state } = useContext(UserContext);
  const history = useHistory();

  const { data, loading, error } = useThemeQuery({
    variables: {
      id: themeId,
    },
  });

  // const [data, setData] = useState(theme, []);

  const [modalContent, setModalContent] = useState(
    { content: "", id: "", position: "" },
    []
  );

  const [modalIsOpen, setModalIsOpen] = useState(false, []);

  const [playedSongs, setPlayedSongs] = useState([], []);

  const onThemeCardClick = (content, id, position) => {
    setModalIsOpen(true);
    setModalContent({ content, id, position });

    if (content === "song") {
      // in the theme songs page, those of songs that have been played need to be grayed out. by using object, we keep track of only an unique id of played song.
      setPlayedSongs({
        ...playedSongs,
        [id]: true,
      });
    }
  };

  const [currentPage, setCurrentPage] = useState(0, []);
  const handleGoToPage = {
    next: () => setCurrentPage(currentPage + 1),
    prev: () => setCurrentPage(currentPage - 1),
  };

  const [isNoteOpen, setIsNoteOpen] = useState(false, []);

  const [removeJavaTip] = useRemoveJavaTipMutation();

  const handleDeleteJavaTip = (e, id) => {
    e.preventDefault();

    removeJavaTip({
      variables: {
        id: id,
        theme: themeId,
      },
    })
      .then(res => {
        if (res.errors) {
          toast.error("Oops, something went wrong");
          console.log(res.errors);
        } else {
          toast.success("Note deleted successfully");
        }
      })
      .catch(err => {
        toast.error("Oops, something went wrong");
        console.log("ERR", err);
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }
  if(!loading && data && (data.theme === null|| data.theme === undefined)){
    toast.error("Uh-oh, looks like that address was wrong")
  
    return (
    <div>{history.push("/home")}</div>
    )
  }
  return (
    <div className="theme-container mt-3">
      <button
        style={{
          transition: ".5s all ease-in-out",
          top: "105px",
          right: "1vw",
          zIndex: 1,
        }}
        className={`notes-button position-fixed btn-extra-radius btn ${
          isNoteOpen ? "btn-danger px-4 px-lg-5" : "btn-info"
        }`}
        onClick={() => setIsNoteOpen(!isNoteOpen)}
      >
        <div className="d-flex justify-content-center align-items-center">
          {isNoteOpen ? (
            <>
              <MdKeyboardArrowRight
                className="d-flex"
                style={{ fontSize: "1.5em" }}
              />
              <span style={{ fontSize: ".9em" }}>Hide notes</span>
            </>
          ) : (
            <>
              <MdKeyboardArrowLeft
                className="d-flex"
                style={{ fontSize: "1.5em" }}
              />
              <span style={{ fontSize: ".9em" }}>Show notes</span>
            </>
          )}
        </div>
      </button>

      <div className={`note-transition ${isNoteOpen ? "w-75" : "w-100"}`}>
       { data.theme.program == "music" && 
       <div className="mb-5 container">
          {/**************************** MAIN CONTENT COMES HERE ****************************/}
          {currentPage === 0 && (
            <TopThemePage
              themeTitle={data.theme.name}
              themePhoto={data.theme.imageUrl}
              onThemeCardClick={onThemeCardClick}
            />
          )}

          {currentPage == 1 && (
            <DiscussionQuestions
              discussions={data.theme.discussions}
              onThemeCardClick={onThemeCardClick}
            />
          )}

          {currentPage == 2 && (
            <Quotes
              onThemeCardClick={onThemeCardClick}
              quotes={data.theme.quotes}
            />
          )}

          {currentPage == 3 && (
            <SensoryCues
              onThemeCardClick={onThemeCardClick}
              themePhoto={data.theme.imageUrl}
            />
          )}

          {currentPage == 4 && (
            <Songs
              onThemeCardClick={onThemeCardClick}
              songs={data.theme.songs}
              playedSongs={playedSongs}
            />
          )}
        </div>}
       { data.theme.program == "reflections" && 
       <div className="mb-5 container">
          {/**************************** MAIN CONTENT COMES HERE ****************************/}
          {currentPage === 0 && (
             <Quotes
             onThemeCardClick={onThemeCardClick}
             quotes={data.theme.quotes}
           />
          )}

          {currentPage == 1 && (
            <Reflection
              reflection={data.theme.reflection}
              onThemeCardClick={onThemeCardClick}
              />
              )}

          {currentPage == 2 && (
            <Sharing
            currentPage={currentPage}
            onThemeCardClick={onThemeCardClick}
            discussions={data.theme.discussions}
            program={data.theme.program}
            
           />
          )}

         

          
        </div>}
       
        {/*MODAL*/}
        <GenericModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}>
          <ThemeModalContent
            modalContent={modalContent}
            theme={data.theme}
            onThemeCardClick={onThemeCardClick}
            playedSongs={playedSongs}
            setModalIsOpen={setModalIsOpen}
          />
        </GenericModal>
        {/*MODAL*/}

        <div
          style={{
            overflow: isNoteOpen ? "scroll" : "hidden",
            height: "100vh",
            right: 0,
            top: 0,
          }}
          className={`pb-5 position-fixed note-transition ${
            isNoteOpen ? "w-25 pr-3" : "w-0"
          }`}
        >
          <div className="d-flex flex-column justify-content-center">
            <div className="w-100" style={{ height: "150px" }}>
              {/*
                as we do not want to render the Java tips under note toggled btn, the space needs to be filled with
                an empty box
              */}
            </div>

            {data.theme.javaTips.map(item => {
              return (
                <CommentBox
                  key={item.id}
                  content={item.body}
                  isSessionNote={item.author != null}
                  author={item.author}
                  noteId={item.id}
                  handleDeleteJavaTip={handleDeleteJavaTip}
                  role={state.user.role}
                  isRunningSession={false}
                />
              );
            })}
          </div>
        </div>
        <div className="d-flex justify-content-around w-100">
          <button
            onClick={handleGoToPage.prev}
            disabled={currentPage === 0 && true}
            className="btn btn-primary py-1 px-5 font-size-2 "
          >
            Prev
          </button>
          <button
            onClick={handleGoToPage.next}
            disabled={(data.theme.program =="music" &&currentPage === 4) || (data.theme.program =="reflections" &&currentPage === 2)  && true}
            className="btn btn-primary py-1 px-5 font-size-2 "
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
