import {useContext} from "react";
import { UserContext } from "../../../../../context/UserContext";
import { GiSaveArrow } from "react-icons/gi";
import FacilitationPrompt from "../../../FacilitationPrompt";
import {  RunSessContext } from "../../../../../context/RunSessContext";
const SensoryCues = props => {
  const { themePhoto, isRunningSession, onThemeCardClick, program, themeTitle, currentPage, isSharing } = props;
  const { state } = useContext(UserContext);     
    const { state: sessState } = useContext(RunSessContext);
  const getURL = (url)=>{
    let num =isSharing?"/600/":"/400/"
    return url.slice(0,14)+num+url.slice(15) 
  }
  return (
    <>
      <div className="d-flex mb-5">
      {program=="reflections"&&  <h3>{state.user.role == "ROLE_FACILITATOR"&&sessState.memberView !== true&&( currentPage?`${currentPage+1}.`:`10.`)} {isSharing?" Sharing on the Photo":"JAVAsocial Theme"}</h3>}
     
      {program!="reflections"&&  <h3>{state.user.role == "ROLE_FACILITATOR"&&sessState.memberView !== true&&"10."} Theme photo</h3>
}
        {!isRunningSession ? (
          <button
            className="ml-2  btn-info btn-extra-radius border-0 px-3 text-white align-items-center"
            onClick={() => onThemeCardClick("addNote", "_", 3)}
          >
            <GiSaveArrow className="mr-2" />
            <span>Add Note</span>
          </button>
        ) : (
          <></>
        )}
      </div>
          {isSharing&& state.user.role=="ROLE_FACILITATOR"&&sessState.memberView !== true&&<FacilitationPrompt content={[" I'd like to take a pause now from the sharing and take a look at this photo. What do you see going on in this picture?"]}></FacilitationPrompt>}
          {program=="reflections"&&!isSharing&& state.user.role=="ROLE_FACILITATOR"&&sessState.memberView !== true&& <FacilitationPrompt content={["Thank you to ____ for choosing this theme today. To get the discussion going, let's take a look at a few quotes."]}></FacilitationPrompt>}

      <div className="cues-container d-flex justify-content-center">
        <div className="position-relative">
          <img
            // TODO replace with data from db
            src={"https://d30mg0zmvh01j5.cloudfront.net" + getURL(themePhoto)}
            alt="themePhoto"
            width={isSharing?"600":"400"}
            height={isSharing?"600":"400"}
            style={{ filter: "brightness(75%)" }}
            className="round-box m-2 clickable-card not-draggable gray-filled"
            onClick={() => onThemeCardClick("themePhoto")}
            />
         {!isSharing&& <h5 className="sensory-img">{program=="reflections"?themeTitle:""}</h5>}
        </div>
      </div>
    </>
  );
};

export default SensoryCues;
