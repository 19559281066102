import { useContext } from "react";
import { MdMusicNote } from "react-icons/md";
import { FiCheckSquare } from "react-icons/fi";
import { UserContext } from "../../../../context/UserContext";
import {  RunSessContext } from "../../../../context/RunSessContext";
export default function ThemeSongCard(props) {
  const {
    song: { id, title },
    songCard,
    onButtonClick,
    setSelectedSongId,
    selectedSongId,
    addPlayedSongs,
    playedSongs,
    cssClassName,
    onThemeCardClick,
  } = props;
  const { state } = useContext(UserContext);
      const { state: sessState } = useContext(RunSessContext);
  return (
    <div
      onClick={songCard ? onThemeCardClick : () => setSelectedSongId(id)}
      data-content="song"
      data-id={id}
      className={cssClassName +` col col-6  ${songCard && Object.keys(playedSongs).includes(id)&&(state.user.role=="ROLE_MEMBER"||sessState.memberView === true)?" d-none":""}`}
    >
      <div
        className={`round-box box-shadow-3d d-flex flex-row align-items-center  row mx-auto bg-clear

          ${
            !songCard && id === selectedSongId
              ? "bg-clearl"
              : ""
          }
          ${
            songCard && Object.keys(playedSongs).includes(id)
              ?(state.user.role=="ROLE_MEMBER"||sessState.memberView === true)?" d-none":"selected-card gray-filled "
              : ""
          }  
        `}
      >
        <div className="col-12 align-items-center my-3">
          {songCard ? (
            <MdMusicNote style={{ fontSize: "2em" }} />
          ) : id === selectedSongId ? (
            <FiCheckSquare style={{ fontSize: "2em" }} />
          ) : (
            <MdMusicNote style={{ fontSize: "2em" }} />
          )}

          <span
            className="font-size-1 mb-0 ml-1"
            style={{ verticalAlign: "middle" }}
          >
            {title}
          </span>
        </div>
      </div>
    </div>
  );
}
