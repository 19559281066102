import { createContext, useReducer } from "react";

const initialState = {
  sessionId: "",
  currentScreen: null,
  status: null,
  songPlaying: null,
  songUrl: null,
  videoChatStatus: null,
  contentType: null,
  contentId: null,
  globalMessage: null,
  viewMode: "horizontal",
  isSilent: false,
  members: {
    facilitators: [],
    sessionMembers: [],
  },
  participantList: [],
  userList: [],
};

const ACTIONS = {
  CLEAR: "clear",
  UPDATE_ALL: "UPDATE_ALL",
  UPDATE_ONE: "UPDATE_ONE",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_ALL:
      return { ...action.payload };
    case ACTIONS.UPDATE_ONE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const SubscriptionContext = createContext(initialState);

const SubscriptionProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SubscriptionContext.Provider value={{ state, dispatch }}>
      {props.children}
    </SubscriptionContext.Provider>
  );
};

export { SubscriptionContext, SubscriptionProvider, ACTIONS };
