
import { FaPlus, FaMinus, FaFont } from "react-icons/fa";


export default function FontSizeButtons(props) {
  const { fontSize, handleChangeFont, isHome } = props;

  return (
    <div
      className={`position-fixed d-flex flex-column-reverse`}
      style={{ marginLeft:"2px",zIndex: 3, top:isHome?`${5}rem`:"" }}
    >
      <button
          disabled={fontSize <= 1.0}
        style={{
          width: "55px",
          height: "60px",
          fontSize: "0.70em",
          opacity: fontSize <= 1.0?0.2:0.6
        }}
        className={`d-flex align-items-center justify-content-center btn  btn-outline-info box-shadow-3d  useBorder p-0 mb-1 `}
        onClick={() => handleChangeFont("-")}
        >
        <FaMinus className="d-flex" /><FaFont className="d-flex" />
      </button>
      <button
        disabled={fontSize >= 1.4}
        className={`d-flex align-items-center justify-content-center btn btn-outline-info box-shadow-3d useBorder p-0 mt-1 mb-2 `}
        style={{
          width: "55px",
          height: "60px",
          fontSize: "1.3em",
          opacity: fontSize >= 1.4?0.3:0.6
        }}
        onClick={() => handleChangeFont("+")}
      >
        <FaPlus className="d-flex" /><FaFont className="d-flex" />
      </button>
    </div>
  );
}
