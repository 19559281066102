import {useContext} from "react";
import { UserContext } from "../../../../../context/UserContext";
import ThemeQuoteCard from "../../components/ThemeQuoteCard";
import { GiSaveArrow } from "react-icons/gi";
import FacilitationPrompt from "../../../FacilitationPrompt";
import { MdInfoOutline } from "react-icons/md";
import {  RunSessContext, Scopes } from "../../../../../context/RunSessContext";
const Quotes = props => {
  const { quotes, isRunningSession, onThemeCardClick,currentPage, program,themePhoto,themeTitle } = props;  
  const { state } = useContext(UserContext);
        const { state: sessState } = useContext(RunSessContext);
  return (
    <>
      <div className="d-flex">
        <h3>{state.user.role == "ROLE_FACILITATOR"&&sessState.memberView !== true&&( currentPage?`${program=='reflections'?currentPage+1:currentPage+3}.`:`9.`)} {program=="reflections"?"JAVAsocial Theme Quotes":"Quotes"}</h3>

        {!isRunningSession ? (
          <button
            className="ml-2  btn-info btn-extra-radius border-0 px-3 text-white align-items-center"
            onClick={() => onThemeCardClick("addNote", "_", 2)}
          >
            <GiSaveArrow className="mr-2" />
            <span>Add Note</span>
          </button>
        ) : (
          <></>
        )}
      </div>
      {program=='reflections'&&state.user.role == "ROLE_FACILITATOR"&&sessState.memberView !== true&&sessState.scope !== Scopes.DIRECTSECONDARY &&
      <>
      <FacilitationPrompt content={[`I'd like to invite someone to choose and read one of the quotes. How about __? (call on a member).`]}/>
     <h6 className="text-center d-flex  align-items-center"><span className={` d-flex justify-content-center align-items-center px-2 text-fac`}>
     <MdInfoOutline className={`mr-1`}/> Facilitation tip:
      </span>To give them time and help them choose their quote, read out a few of the authors' names.</h6> 
      </>

      }
      {quotes.map(quote => (
        <ThemeQuoteCard
          key={quote.id}
          quote={quote}
          onThemeCardClick={() => onThemeCardClick("quote", quote.id)}
        />
      ))}
    </>
  );
};

export default Quotes;
