import { useContext, useState, useEffect } from "react";

import { useHistory, Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getAccessToken, setAccessToken } from "../../auth/accessToken";
import { SET_ACCESS_TOKEN, UserContext } from "../../context/UserContext";
import { useLoginMutation } from "../../graphql/generated/graphql";
/**
 *
 * @namespace Login
 */

export default function LoginForm(props) {
  const { reason } = props;
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  let location = useLocation();
  const [error, setError] = useState(false);

  let isSubmitting = false;

  const history = useHistory();

  const { dispatch } = useContext(UserContext);

  const [login] = useLoginMutation();

  const handleOnChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (location && location.pathname !== "/login") {
      toast.error("You need to login first to see that page.", {
        toastId: "loginFirst",
      });
    }
  }, [location]);

  const handleOnSubmit = async e => {
    e.preventDefault();
    isSubmitting = true;
    const { email, password } = state;
    try {
      const loginResponse = await login({ variables: { email, password } });
      //console.log(loginResponse);
      if (loginResponse && loginResponse.data.login.token) {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: { accessToken: loginResponse.data.login.token },
        });
        setAccessToken(loginResponse.data.login.token);

        history.push(location.pathname);
      }
    } catch (err) {
      setError(true);
      toast.error("It seems that email and/or password isn't correct", {
        toastId: 100,
      });
      console.log(err);
    }
  };

  if (getAccessToken() !== "") {
    // toast.error("Log out first before trying to sign in as another user.", {
    //   toastId: "already signed in",
    // });
    history.push("/");
  }

  return (
    <div className="d-flex flex-row justify-content-center align-items-center vh-100">
      <form
        onSubmit={handleOnSubmit}
        className="border useBorder side-nav round-box box-shadow my-5 pt-5 px-5 pb-3 offset-1 offset-md-0 col-10 col-sm-8 col-md-10 col-lg-9 col-xl-6"
      >
        <div className="row mt-3">
          <div className="col-12">
            <div className="col-12 d-flex flex-row justify-content-center  align-items-center mb-4 ">
              <img
                src={`https://d30mg0zmvh01j5.cloudfront.net/assets/photos/logos/JClogo6.svg`}
                id="icon"
                className="logo-image-proc"
                alt="Java Group"
                style={{ maxWidth: "100%" }}
                width="250"
              />
            </div>
          </div>
          {/*  <div className="col-12">
            
          </div>
         <div className="col-sm-12 col-md-4 d-flex flex-row justify-content-center justify-content-md-end align-items-center mb-5">
            <img
              src={`https://d30mg0zmvh01j5.cloudfront.net/assets/photos/logos/JClogo.png`}
              id="icon"
              className="logo-image-proc"
              alt="Java Group"
              style={{maxWidth:"100%"}}
              width="250"
            />
          </div>

          <div className="col-sm-12 col-md-6">
             */}
          <div className="col-sm-12 offset-md-2 col-md-8">
            {/* Username */}
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  id="email"
                  style={{ width: "90px" }}
                >
                  Email:
                </span>
              </div>
              <input
                onChange={handleOnChange}
                value={state.email}
                name="email"
                type="email"
                className="form-control"
                autoComplete={"email"}
                aria-label="Email"
                aria-describedby="email"
              />
            </div>
            {/* Password */}
            <div className="input-group mb-1">
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  id="password"
                  style={{ width: "90px" }}
                >
                  Password:
                </span>
              </div>
              <input
                onChange={handleOnChange}
                value={state.password}
                name="password"
                type="password"
                autoComplete={"current-password"}
                className="form-control"
                aria-label="Password"
                aria-describedby="password"
              />
            </div>

            <input
              type="submit"
              className="btn btn-info w-100 mt-2 mb-2 "
              value="LOG IN"
              style={{ width: "" }}
            />
            <div className="">
              Forgot Password?
              <Link className=" login-link" to="/recover_password">
                {" "}
                Click here to reset it!
              </Link>
            </div>
            <div className="mb-3">
              New to JAVAconnects?
              <Link className=" login-link" to="/register">
                {" "}
                Click here to sign up!
              </Link>
            </div>
          </div>
        </div>

        {/* Log in button */}
        <div className="row mx-auto d-flex flex-row justify-content-center mt-3"></div>
        <div className="d-flex flex-row justify-content-center align-items-center mt-3"></div>
      </form>
    </div>
  );
}
